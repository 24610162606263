import { useEffect, useState } from "react";
import { InventoryTypeView } from "../../types/views";

export interface InventorySearchTypeFilterHooks {
  onChange(id: number): void;
  isFilterChecked(id: number): boolean;
}

export interface InventorySearchTypeFilterProps {
  inventoryTypeViews: InventoryTypeView[];
  selectedTypeIds: number[];

  onTypesChanged(selectedTypeIds: number[]): void;
}

export function useInventorySearchTypeFilter(props: InventorySearchTypeFilterProps): InventorySearchTypeFilterHooks {
  const [selectedTypeIds, setSelectedTypeIds] = useState<number[]>(props.selectedTypeIds || []);

  function onChange(id: number): void {
    let updatedTypeIds = [...selectedTypeIds];

    if (updatedTypeIds.includes(id)) {
      updatedTypeIds = updatedTypeIds.filter((typeId) => typeId !== id);
    } else {
      updatedTypeIds.push(id);
    }

    props.onTypesChanged(updatedTypeIds);
    setSelectedTypeIds(updatedTypeIds);
  }

  function isFilterChecked(id: number): boolean {
    return selectedTypeIds.includes(id);
  }

  useEffect(() => {
    setSelectedTypeIds(props.selectedTypeIds);
  }, [props.selectedTypeIds]);

  return {
    onChange,
    isFilterChecked,
  };
}
