import { UserStatus } from "@labarchives/inventory-shared/build/inventory";

export const getStatusName = (status: UserStatus): string => {
  switch (status) {
    case UserStatus.Active: {
      return "active";
    }
    case UserStatus.Inactive: {
      return "inactive";
    }
    default: {
      return "active";
    }
  }
};
