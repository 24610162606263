import { useEffect, useState } from "react";
import { AdvancedSearchCriteria, AdvancedSearchCriteriaMatchType, InventorySavedSearch } from "@labarchives/inventory-shared/build/inventory";
import { CustomAttributeDefinitionView, InventoryTypeView } from "../../types/views";
import { InventoryApi } from "../../../api/InventoryApi";

export interface InventoryAdvancedSearchHooksProps {
  inventoryTypes: InventoryTypeView[];
  selectedCriteria: AdvancedSearchCriteria[];
  selectedInventoryTypes: InventoryTypeView[];
  api: InventoryApi;

  onSearch(criteria: AdvancedSearchCriteria[]): void;

  onClosed(): void;

  onSearchSaved(): void;
}

export interface InventoryAdvancedSearchHooks {
  criteria: AdvancedSearchCriteria[];
  savedSearches: InventorySavedSearch[];
  savedSearchName: string;
  allAttributes: CustomAttributeDefinitionView[];

  onCriteriaChanged(index: number, fieldName: string, matchType: AdvancedSearchCriteriaMatchType, values: string[], attributeId?: number): void;

  onCriteriaRemoved(index: number): void;

  onSearch(): void;

  onSearchSaved(searchName: string): void;

  onSavedSearchLoaded(savedSearch: InventorySavedSearch): void;

  onSavedSearchDeleted(savedSearch: InventorySavedSearch): void;

  onClosed(): void;

  isExcluded(c: AdvancedSearchCriteria): boolean;

  hasFocus(c: AdvancedSearchCriteria): boolean;
}

export function useInventoryAdvancedSearchHooks(props: InventoryAdvancedSearchHooksProps): InventoryAdvancedSearchHooks {
  const api = props.api;
  const initialCriteria = [
    { index: 0, fieldName: "name", values: [], matchType: AdvancedSearchCriteriaMatchType.Matches },
    { index: 1, fieldName: "", values: [], matchType: 1 },
  ];

  function getCriteriaFromProps(selected: AdvancedSearchCriteria[]): AdvancedSearchCriteria[] {
    return selected.length === 0 ? initialCriteria : [...selected, { index: selected.length, fieldName: "", values: [], matchType: 1 }];
  }

  const [criteria, setCriteria] = useState<AdvancedSearchCriteria[]>(getCriteriaFromProps(props.selectedCriteria));
  const [savedSearches, setSavedSearches] = useState<InventorySavedSearch[]>([]);
  const [currentSavedSearch, setCurrentSavedSearch] = useState<InventorySavedSearch | undefined>();
  const [lastChangedCriteria, setLastChangedCriteria] = useState<AdvancedSearchCriteria | undefined>(undefined);

  const allAttributes: CustomAttributeDefinitionView[] = [];
  const attributeToTypeMap: Map<number, number> = new Map<number, number>();
  props.inventoryTypes.forEach((i) =>
    i.attributes.forEach((a) => {
      allAttributes.push(a);
      attributeToTypeMap.set(a.id, i.id);
    }),
  );

  function onCriteriaChanged(
    index: number,
    fieldName: string,
    matchType: AdvancedSearchCriteriaMatchType,
    values: string[],
    attributeId?: number,
  ): void {
    const newCriteriaValue = [...criteria];
    const existingIndex = newCriteriaValue.findIndex((p) => p.index === index);

    newCriteriaValue[existingIndex] = {
      index,
      fieldName,
      matchType,
      values,
      attributeId,
    };

    if (newCriteriaValue[newCriteriaValue.length - 1].fieldName !== "") {
      newCriteriaValue.push({ index: newCriteriaValue.length, fieldName: "", matchType: 1, values: [] });
      setLastChangedCriteria(newCriteriaValue[index]);
    }

    setCriteria(newCriteriaValue);
    // setLastChangedCriteria(newCriteriaValue[index]);
  }

  function onCriteriaRemoved(index: number): void {
    if (index === criteria.length - 1) {
      // don't remove the "add" option
      return;
    }
    const newCriteria = criteria.filter((p) => p.index !== index);
    newCriteria.forEach((c, i) => {
      // eslint-disable-next-line no-param-reassign
      c.index = i;
    });
    setCriteria(newCriteria);
    setLastChangedCriteria(newCriteria[newCriteria.length - 1]);
  }

  function isExcluded(c: AdvancedSearchCriteria): boolean {
    if (props.selectedInventoryTypes.length === 0 || !c.attributeId) {
      return false;
    }

    const criteriaInventoryType = attributeToTypeMap.get(c.attributeId);
    return criteriaInventoryType === undefined || !props.selectedInventoryTypes.some((i) => i.id === criteriaInventoryType);
  }

  function onSearch(): void {
    props.onSearch(criteria.filter((p) => p.fieldName !== "" && !isExcluded(p)));
  }

  function onSavedSearchLoaded(savedSearch: InventorySavedSearch): void {
    setCriteria([...savedSearch.criteria, { index: savedSearch.criteria.length, fieldName: "", matchType: 1, values: [] }]);
    setCurrentSavedSearch(savedSearch);
  }

  function saveNewSearch(name: string): void {
    api
      .saveSearch(
        name,
        criteria.filter((c) => c.fieldName !== ""),
      )
      .then((addedSearch) => {
        setSavedSearches([...savedSearches, addedSearch]);
        setCurrentSavedSearch(addedSearch);
        props.onSearchSaved();
        return addedSearch;
      })
      .catch((error) => api.logError(error));
  }

  function saveUpdatedSearch(name: string): void {
    api
      .updateSavedSearch({ id: currentSavedSearch!.id, name, criteria: criteria.filter((c) => c.fieldName !== "") })
      .then((updatedSearch) => {
        setSavedSearches([...savedSearches.filter((s) => s.id !== updatedSearch.id), updatedSearch]);
        setCurrentSavedSearch(updatedSearch);
        props.onSearchSaved();
        return updatedSearch;
      })
      .catch((error) => api.logError(error));
  }

  function onSearchSaved(name: string): void {
    const trimmedName = name.trim();
    const nameExistsWithAnother = savedSearches.find(
      (s) => s.name === trimmedName && (currentSavedSearch === undefined || s.id !== currentSavedSearch.id),
    );

    if (nameExistsWithAnother) {
      saveNewSearch(`${trimmedName} (1)`);
    } else if (currentSavedSearch && trimmedName === currentSavedSearch.name.trim()) {
      saveUpdatedSearch(trimmedName);
    } else {
      saveNewSearch(trimmedName);
    }
  }

  function onSavedSearchDeleted(savedSearch: InventorySavedSearch): void {
    api
      .deleteSavedSearch(savedSearch)
      .then(() => {
        setSavedSearches(savedSearches.filter((s) => s.id !== savedSearch.id));
        // eslint-disable-next-line promise/always-return
        if (currentSavedSearch && savedSearch.name === currentSavedSearch.name) {
          setCurrentSavedSearch(undefined);
        }
      })
      .catch((error) => api.logError(error));
  }

  function hasFocus(testCriteria: AdvancedSearchCriteria): boolean {
    if (lastChangedCriteria === undefined) {
      return testCriteria.index === 0;
    }
    return lastChangedCriteria.index === testCriteria.index;
  }

  function onClosed(): void {
    setLastChangedCriteria(undefined);
    setCurrentSavedSearch(undefined);
    setCriteria(getCriteriaFromProps(props.selectedCriteria));
    props.onClosed();
  }

  useEffect(() => {
    api
      .getSavedSearches()
      .then((searches) => setSavedSearches(searches))
      .catch((error) => api.logError(error));
  }, []);

  useEffect(() => {
    setCriteria(getCriteriaFromProps(props.selectedCriteria));
  }, [JSON.stringify(props.selectedCriteria)]);

  return {
    criteria,
    savedSearches: [...savedSearches].sort((s1, s2) => s1.name.localeCompare(s2.name)),
    savedSearchName: currentSavedSearch === undefined ? "" : currentSavedSearch.name,
    allAttributes,
    isExcluded,
    onCriteriaChanged,
    onCriteriaRemoved,
    onSearch,
    onSearchSaved,
    onSavedSearchLoaded,
    onSavedSearchDeleted,
    onClosed,
    hasFocus,
  };
}
