import { Button } from "@labarchives/ui-design";
import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InventoryApi } from "../api/InventoryApi";
import { ItemReportDownloadHooks } from "./ItemReportDownloadHooks";
import { useItemLowQuantityReport } from "./ItemLowQuantityReportHooks";

export function LowQuantityReport(props: { downloadHooks: ItemReportDownloadHooks; api: InventoryApi }): ReactElement {
  const { onReportDownloaded } = useItemLowQuantityReport({
    downloadHooks: props.downloadHooks,
    api: props.api,
  });

  const intl = useIntl();

  return (
    <div className={"mt-5"}>
      <h2 className={"reports-header"}>
        <FormattedMessage id={"low.quantity.report.nav"} />
      </h2>

      <div className={"mt-3 mb-3"}>
        <FormattedMessage id={"low.quantity.report.description"} />
      </div>

      <div>
        <Button outline={true} onClick={() => onReportDownloaded()}>
          <img src="/images/Inventory_Export.svg" className="link button-icon" alt={intl.formatMessage({ id: "export.items" })} />
          <FormattedMessage id={"download.low.quantity.report"} />
        </Button>
      </div>
    </div>
  );
}
