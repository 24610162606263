import React, { ReactElement } from "react";
import { Button, CheckboxInput, CheckboxLabel } from "@labarchives/ui-design/dist";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";
import { SearchDates } from "../../../components/SearchDates/SearchDates";
import { InventorySearchStorageLocationFilter } from "./InventorySearchStorageLocationFilter";
import { InventorySearchTypeFilter } from "./InventorySearchTypeFilter";
import "./InventorySearchFilter.scss";
import { InventorySearchFilterProps, useInventorySearchFilter } from "./InventorySearchFilterHooks";

export function InventorySearchFilter(props: InventorySearchFilterProps): ReactElement {
  const { onFilterCleared, onChangedTypes, onChangedLocations, onChangedReceivedDates, onChangedOutOfStock, onChangedNoLocation } =
    useInventorySearchFilter(props);
  return (
    <div className={classnames("inventory-search-filter responsive-search-filter", { "filter-shown": props.isShowingFilter })}>
      <div className="inventory-search-filter-head">
        <span className="inventory-search-filter-head-title">
          <FormattedMessage id="filters" />
        </span>
        <Button onClick={() => onFilterCleared()} className="inventory-search-filter-head-clear" size="sm" color="link">
          <FormattedMessage id="clear.filters" />
        </Button>
        <Button color="link" className="responsive-search-filter-head-close" onClick={props.onFilterToggle}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Button>
      </div>
      <fieldset className="inventory-filter-criteria" id="inventory-filter-criteria-type">
        <legend className="inventory-filter-criteria-title">
          <FormattedMessage id="types" />
        </legend>
        <InventorySearchTypeFilter
          inventoryTypeViews={props.inventoryTypeViews}
          selectedTypeIds={props.filters.typeIds}
          onTypesChanged={onChangedTypes}
        />
      </fieldset>
      <fieldset className="inventory-filter-criteria" id="inventory-filter-criteria-location">
        <legend className="inventory-filter-criteria-title">
          <FormattedMessage id="locations" />
        </legend>
        <InventorySearchStorageLocationFilter
          selectedLocationIds={props.filters.locationIds}
          locations={props.storageLocations}
          onLocationsChanged={onChangedLocations}
          isNoLocationSelected={props.filters.includeMissingLocationId === true}
          onNoLocationChanged={onChangedNoLocation}
        />
      </fieldset>
      <fieldset className="inventory-filter-criteria" id="inventory-filter-order-dates">
        <legend className="inventory-filter-criteria-title">
          <FormattedMessage id="received.date.range" />
        </legend>
        <SearchDates
          selectedStart={props.filters.receivedStartDate}
          selectedEnd={props.filters.receivedEndDate}
          onDatesChanged={onChangedReceivedDates}
        />
      </fieldset>
      <fieldset className="inventory-filter-criteria" id="inventory-filter-include-out-of-stock">
        <legend className="inventory-filter-criteria-title">
          <FormattedMessage id="availability" />
        </legend>
        <div>
          <CheckboxLabel>
            <CheckboxInput
              id="inventory-filter-include-out-of-stock-option"
              onChange={() => onChangedOutOfStock(!props.filters.includeOutOfStock)}
              uncheckedValue="0"
              checked={props.filters.includeOutOfStock === true}
            />
            <span>
              <FormattedMessage id="include.out.of.stock" />
            </span>
          </CheckboxLabel>
        </div>
      </fieldset>
    </div>
  );
}
