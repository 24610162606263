import { Input, FormGroup } from "reactstrap";
import React, { ReactElement } from "react";
import { CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { InventoryAdvanceSearchMatchDropdown } from "./InventoryAdvanceSearchMatchDropdown";
import { InventoryAdvancedSearchCheckboxProps } from "./InventoryAdvancedSearchTypeComponentProps";

export function InventoryAdvancedSearchRadio(props: InventoryAdvancedSearchCheckboxProps): ReactElement {
  return (
    <>
      <InventoryAdvanceSearchMatchDropdown
        index={props.index}
        matchType={props.matchType}
        onMatchTypeChanged={props.onMatchTypeChanged}
        attributeType={CustomAttributeType.Radio}
      />
      <div className={"criteria-value"}>
        <FormGroup>
          <label className={"hidden"} id={`${props.selectedAttribute.inputId}label`} htmlFor={`chk${props.selectedAttribute.inputId}`}>
            {props.selectedAttribute.label}
          </label>
          <div>
            {props.selectedAttribute.possibleValues.map((v, index) => {
              return (
                <Input
                  name={`radio${props.selectedAttribute.inputId}`}
                  id={props.selectedAttribute.inputId + index}
                  inline
                  key={props.selectedAttribute.inputId + v}
                  label={v}
                  type="radio"
                  value={v}
                  defaultChecked={props.isChecked(v)}
                  onChange={(e) => props.onValueChanged(e.target.value)}
                />
              );
            })}
          </div>
        </FormGroup>
      </div>
    </>
  );
}
