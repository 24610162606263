import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Form, FormFeedback, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faTrash } from "@fortawesome/pro-light-svg-icons";
import { AlertWithIcon, Button, Input, Modal } from "@labarchives/ui-design";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { SiteLocationFloor } from "@labarchives/inventory-shared/build/inventory";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { DeleteButton } from "../../components/DeleteButton/CancelButton";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { AdminViewLocationRoom, AdminViewLocationRoomProps } from "./AdminViewLocationRoom";

export interface AdminViewLocationFloorProps extends AdminViewLocationRoomProps {
  canAddFloor(name: string, buildingId: number): boolean;

  canRenameFloor(id: number, name: string): boolean;

  onFloorUpdated(floorId: number, updatedName: string, newRoomName: string | undefined): void;
}

export function AdminViewLocationFloor(props: AdminViewLocationFloorProps & { floor: SiteLocationFloor }): ReactElement {
  const { floor, locations, onEditToggle, canRenameFloor, currentlyEditedId, onFloorUpdated, onLocationDeleted, canAddRoom } = props;
  const intl = useIntl();

  const isEditing = useMemo<boolean>(() => currentlyEditedId === floor.id, [currentlyEditedId]);
  const [isAddingRoom, setIsAddingRoom] = useState(false);
  const [floorName, setFloorName] = useState("");
  const [roomName, setRoomName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setIsAddingRoom(false);
    setFloorName(floor.name || "");
  }, [isEditing]);

  function onSave(): void {
    if (floorName.trim() !== "") {
      onFloorUpdated(floor.id, floorName.trim(), roomName.trim() || undefined);
    }
  }

  function onDelete(): void {
    setIsDeleting(false);
    onLocationDeleted(floor.id);
  }

  function getRooms(): ReactElement {
    return (
      <div>
        {locations.rooms
          .filter((r) => r.floorId === floor.id)
          .map((r) => (
            <AdminViewLocationRoom key={r.id} {...props} room={r} />
          ))}
      </div>
    );
  }

  function getEditRoom(): ReactElement {
    if (!isAddingRoom) {
      return <></>;
    }

    return (
      <FormGroup>
        <Label for={`add-room-${floor.id}`}>
          <FormattedMessage id={"room.name"} />
          <RequiredLabelIndicator required={true} />
        </Label>
        <Input
          bsSize="sm"
          type={"text"}
          required
          id={`add-room-${floor.id}`}
          onChange={(e) => setRoomName(e.target.value)}
          invalid={!canAddRoom(roomName, floor.buildingId, floor.id)}
          maxLength={255}
          autoFocus
          className={"location-management-room-name-input"}
        />
        <FormFeedback>
          <FormattedMessage id={"room.names.must.be.unique"} />
        </FormFeedback>
      </FormGroup>
    );
  }

  function getButtons(): ReactElement {
    if (isAddingRoom) {
      return <></>;
    }

    return (
      <>
        <Button size={"sm"} color={"primary"} className={"me-1 location-management-add-room-button"} onClick={() => setIsAddingRoom(true)}>
          <FontAwesomeIcon icon={faPlus} /> <FormattedHTMLMessage id={"add.room.within"} values={{ within: floor.name }} />
        </Button>
      </>
    );
  }

  if (isEditing) {
    return (
      <>
        <div key={floor.id} className={"location-management-floor"}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSave();
            }}
          >
            <div className={"manage-site-locations-edit-container"}>
              <FormGroup>
                <Label for={`floor-${floor.id}`}>
                  <FormattedMessage id={"floor.name"} />
                  <RequiredLabelIndicator required={true} />
                </Label>
                <Input
                  bsSize="sm"
                  type={"text"}
                  required
                  defaultValue={floor.name}
                  id={`floor-${floor.id}`}
                  onChange={(e) => setFloorName(e.target.value)}
                  invalid={!canRenameFloor(floor.id, floorName)}
                  maxLength={255}
                  autoFocus
                  className={"location-management-floor-name-input"}
                />
                <FormFeedback>
                  <FormattedMessage id={"floor.names.must.be.unique"} />
                </FormFeedback>
              </FormGroup>

              <div className={"mb-2 ps-2"}>
                {getEditRoom()}
                {getButtons()}
              </div>
              <div className={"manage-site-locations-buttons"}>
                <div>
                  <Button color={"primary"} className={"me-1 location-management-update-floor-save"}>
                    <FormattedMessage id={"save"} />
                  </Button>
                  <CancelButton onClick={() => onEditToggle(null)} />
                </div>
                <div>
                  <Button
                    color={"link"}
                    title={intl.formatMessage({ id: "delete.floor" })}
                    onClick={() => setIsDeleting(true)}
                    className={"location-management-delete-button"}
                  >
                    <FontAwesomeIcon icon={faTrash} size={"lg"} />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          {getRooms()}
        </div>

        <Modal isOpen={isDeleting} toggle={() => setIsDeleting(false)}>
          <ModalHeader toggle={() => setIsDeleting(false)}>
            <FormattedMessage id={"delete.site.location"} />
          </ModalHeader>
          <ModalBody>
            <AlertWithIcon color={"danger"}>
              <FormattedHTMLMessage id={"delete.floor.warning"} values={{ name: floor.name }} />
            </AlertWithIcon>
          </ModalBody>
          <ModalFooter>
            <DeleteButton onClick={onDelete} id={"location-management-delete-confirm"} />
            <CancelButton onClick={() => setIsDeleting(false)} />
          </ModalFooter>
        </Modal>
      </>
    );
  }

  return (
    <div key={floor.id} className={"location-management-floor"}>
      <div data-floorname={floor.name}>
        <span className={"location-management-floor-name"}>{floor.name}</span>
        {currentlyEditedId === null && (
          <Button
            color={"link"}
            onClick={() => onEditToggle(floor.id)}
            className={"ms-2 location-management-edit-button"}
            title={intl.formatMessage({ id: "edit" })}
          >
            <FontAwesomeIcon icon={faPencilAlt} size={"lg"} />
          </Button>
        )}
        {getRooms()}
      </div>
    </div>
  );
}
