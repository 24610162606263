import React, { ReactElement, useContext } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { OrderSearchCriteria } from "@labarchives/inventory-shared/build/inventory";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { Loading } from "../../components";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import * as utils from "../../utils";
import { UsersContext } from "../../user/UsersContext";
import { RolesContext } from "../../roles/RolesContext";
import { InventoryApiClient } from "../../api";
import { useOrderExport } from "./OrderExportModalHooks";

export interface OrderExportModalProps {
  searchCriteria: OrderSearchCriteria;
  isOpen: boolean;

  onCancel(): void;
  onExportComplete(): void;
}

export function OrderExportModal(props: OrderExportModalProps): ReactElement {
  const { isLoading, onSearch } = useOrderExport(useContext(UsersContext), useContext(RolesContext), new InventoryApiClient());

  async function onExportCurrentResults(): Promise<void> {
    await onExport(props.searchCriteria);
  }

  async function onExportAll(): Promise<void> {
    await onExport({ typeIds: [], term: "", statusIds: [], resultSetOptions: undefined });
  }

  async function onExport(criteria: OrderSearchCriteria): Promise<void> {
    const excel = await onSearch(criteria);
    const blob = await excel.AsBlob();
    utils.saveAs(blob, "OrderExport.xlsx");
    props.onExportComplete();
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id="export.orders" />
      </ModalHeader>
      <ModalBody>
        {isLoading && <Loading />}
        {!isLoading && (
          <div className="inventory-export-modal-buttons">
            <div>
              <Button outline size="sm" onClick={onExportCurrentResults} color="none">
                <FormattedMessage id="export.current.results" />
              </Button>
            </div>
            <div>
              <Button outline size="sm" onClick={onExportAll} color="none">
                <FormattedMessage id="export.all.orders" />
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div>
          <CancelButton onClick={props.onCancel} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
