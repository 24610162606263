import React, { ReactElement, useContext } from "react";
import { Col, Row } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { Loading } from "../../components";
import { UserState } from "../types/state";
import { UsersContext } from "../UsersContext";
import { RoleState } from "../../roles/types/state";
import { RolesContext } from "../../roles/RolesContext";
import { FlashAlertMessage } from "../../components/FlashAlertMessage/FlashAlertMessage";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import { ManageUsersList } from "./ManageUsersList";
import { InviteUsers } from "./invite/InviteUsers";
import { ManageUsersPills } from "./ManageUsersPills";
import "./ManageUsers.scss";
import { useManageUsers } from "./ManageUsersHooks";
import { InventoryApiClient } from "../../api";

export function ManageUsers(): ReactElement {
  const {
    isInviting,
    isInvitationFailed,
    activeStatus,
    setIsInviting,
    onUsersInvited,
    onUserInviteCancelled,
    onFilterUsers,
    onUserRoleUpdated,
    isSuccessAlertOpen,
    userViews,
    userRoleViews,
    onUserDeleted,
    isLoading,
  } = useManageUsers(useContext<RoleState>(RolesContext), useContext<UserState>(UsersContext));

  const api = new InventoryApiClient();
  const authState = useContext(AuthenticationContext);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="management-header justify-content-between">
        <h1 className="inventory-heading">
          <FormattedMessage id="users" />
        </h1>

        <ManageUsersPills onFilterUsers={onFilterUsers} activeStatus={activeStatus} userViews={userViews} />

        <Button color="link" onClick={() => setIsInviting(true)} data-tour="invite-users">
          <FontAwesomeIcon icon={faPlusCircle} /> <FormattedMessage id="invite.users" />
        </Button>
      </div>

      <Row>
        <Col>
          <FlashAlertMessage isOpen={isSuccessAlertOpen} />
          <FlashAlertMessage isOpen={isInvitationFailed} alertColor="danger" messageId="invitation.error" />
          {!isInviting && (
            <ManageUsersList
              userViews={userViews.filter((userView) => !activeStatus || activeStatus === userView.status)}
              userRoleViews={userRoleViews}
              onUserDeleted={onUserDeleted}
              onUserRoleUpdated={onUserRoleUpdated}
              currentUser={authState.getUser()}
            />
          )}
          {isInviting && <InviteUsers onUsersInvited={onUsersInvited} onCancelInvite={onUserInviteCancelled} userViews={userViews} api={api} />}
        </Col>
      </Row>
    </div>
  );
}
