import { FreezerBoxDisplayFormat, StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";

export interface AddNewLocationHooks {
  isNameValid: boolean;
  isFreezerBox: boolean;
  isLocationNameDuplicated: boolean;
  onFreezerBoxChanged(rows: number, columns: number, freezerBoxFormat: FreezerBoxDisplayFormat): void;
  onNamedChanged(locationName: string): void;
  onToggleFreezerBox(isFreezerBox: boolean): void;
  onAddLocation(): void;
}

export function useAddNewLocation(props: AddNewLocationProps): AddNewLocationHooks {
  const [isNameValid, setIsNameValid] = useState(true);
  const [isFreezerBox, setIsFreezerBox] = useState(false);
  const [isLocationNameDuplicated, setIsLocationNameDuplicated] = useState(false);
  const [rows, setRows] = useState<number | null>(9);
  const [columns, setColumns] = useState<number | null>(9);
  const [freezerBoxFormat, setFreezerBoxFormat] = useState<FreezerBoxDisplayFormat | null>(null);
  const [locationName, setLocationName] = useState("");

  function onFreezerBoxChanged(rowsUpdated: number, columnsUpdated: number, freezerBoxFormatUpdated: FreezerBoxDisplayFormat): void {
    setRows(rowsUpdated);
    setColumns(columnsUpdated);
    setFreezerBoxFormat(freezerBoxFormatUpdated);
  }

  function onNamedChanged(locationNameUpdated: string): void {
    setLocationName(locationNameUpdated);
    setIsLocationNameDuplicated(props.isLocationNameDuplicated(locationNameUpdated, 0, null));
  }

  function onToggleFreezerBox(isFreezerBoxUpdated: boolean): void {
    setIsFreezerBox(isFreezerBoxUpdated);
    setColumns(isFreezerBoxUpdated ? 9 : null);
    setRows(isFreezerBoxUpdated ? 9 : null);
    setFreezerBoxFormat(isFreezerBoxUpdated ? FreezerBoxDisplayFormat.Default : null);
  }

  function onAddLocation(): void {
    const name = locationName.trim();
    if (name !== "" && !isLocationNameDuplicated) {
      props.onAddLocation({
        numberOfColumns: isFreezerBox ? columns : null,
        numberOfRows: isFreezerBox ? rows : null,
        id: 0,
        name,
        freezerBoxDisplayFormat: isFreezerBox ? freezerBoxFormat : null,
        parentId: null,
      });
    } else {
      setIsNameValid(false);
    }
  }

  return {
    isNameValid,
    isFreezerBox,
    isLocationNameDuplicated,
    onFreezerBoxChanged,
    onNamedChanged,
    onToggleFreezerBox,
    onAddLocation,
  };
}

export interface AddNewLocationProps {
  onAddLocation(storageLocation: StorageLocation): void;

  onCancel(): void;

  isLocationNameDuplicated(name: string, id: number, parentId: number | null): boolean;
}
