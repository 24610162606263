import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Badge, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, Card, CardBody, CheckboxInput, CheckboxLabel, Input } from "@labarchives/ui-design";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faFilter, faTimes } from "@fortawesome/pro-light-svg-icons";
import { ChemicalSafety } from "@labarchives/inventory-shared/build/inventory/chemicalSafety";
import { AuthenticationContext } from "../../../components/Authentication/AuthenticationContext";
import { UsersContext } from "../../../user/UsersContext";
import { Loading } from "../../../components";
import "./InventorySearch.scss";
import { InventoryAdminExportModal } from "../export/InventoryAdminExportModal";
import { SiteLocationBuilding, SiteLocationFloor } from "../../../../../shared/inventory";
import { CheckboxTree } from "../../../components/CheckboxTree/CheckboxTree";
import { onPageLoad } from "../../../utils/onPageLoad";
import { TreeData } from "../../../components/CheckboxTree/TreeData";
import { InventoryAdminSearchResults } from "./InventoryAdminSearchResults";
import { useInventoryAdminSearchHooks } from "./InventorySearchHooks";
import { InventoryApiClient } from "../../../api";

export function InventorySiteAdminSearch(): ReactElement {
  const api = new InventoryApiClient();

  const {
    accounts,
    allAccounts,
    criteria,
    searchResults,
    isAllowedToSearch,
    isLoading,
    siteLocations,
    checkedLocations,
    onLocationsChanged,
    onResultSetOptionsChanged,
    onAccountsChanged,
    onOutOfStockChanged,
    onSearchTermChanged,
    onCriteriaCleared,
    onHazardsChanged,
  } = useInventoryAdminSearchHooks(useContext(UsersContext), useContext(AuthenticationContext), api);

  const [term, setTerm] = useState(criteria.term);
  const [isShowingFilter, setIsShowingFilter] = useState(true);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [labsExpanded, setLabsExpanded] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.inventory.search" }));
  }, []);

  function onSelectAccount(checked: boolean, accountId: number): Promise<void> {
    if (checked) {
      return onAccountsChanged([...criteria.accountIds, accountId]);
    }
    return onAccountsChanged(criteria.accountIds.filter((id) => id !== accountId));
  }

  function onSelectHazards(checked: boolean, hazard: string): Promise<void> {
    if (checked) {
      return onHazardsChanged([...criteria.safetyCriteria.hazards, hazard]);
    }
    return onHazardsChanged(criteria.safetyCriteria.hazards.filter((h) => h !== hazard));
  }

  function onTermChanged(newTerm: string): void {
    setTerm(newTerm);
  }

  function onSearch(): Promise<void> {
    return onSearchTermChanged(term.trim());
  }

  function floorAsNode(floor: SiteLocationFloor): TreeData {
    return {
      name: `${floor.id}`,
      value: `${floor.id}`,
      label: <span>{floor.name}</span>,
      children: undefined,
      data: floor,
    };
  }

  function buildingAsNode(building: SiteLocationBuilding): TreeData {
    return {
      name: `${building.id}`,
      value: `${building.id}`,
      label: <span>{building.name}</span>,
      children: [
        { name: "No Floor", value: `${building.id * -1}`, label: <span>No Floor</span>, children: undefined },
        ...siteLocations.floors.filter((f) => f.buildingId === building.id).map((f) => floorAsNode(f)),
      ],
      data: building,
    };
  }

  function getLocationTreeSpanProps(e: TreeData): Record<string, string> {
    if (!e.data) {
      return { "data-location-type": "2", "data-name": "" };
    }
    if (e.data.hasOwnProperty("buildingId")) {
      return { "data-location-type": "2", "data-id": e.data.id, "data-building-id": e.data.buildingId, "data-name": e.data.name };
    }

    return { "data-location-type": "1", "data-id": e.data.id, "data-name": e.data.name };
  }

  if (!isAllowedToSearch) {
    return <div />;
  }

  return (
    <Row>
      <Col xl={2} role={"region"} aria-label={intl.formatMessage({ id: "filters" })}>
        <div className={classnames("inventory-search-filter responsive-search-filter", { "filter-shown": isShowingFilter })}>
          <div className="inventory-search-filter-head">
            <span className="inventory-search-filter-head-title">
              <FormattedMessage id="filters" />
            </span>
            <Button
              className="inventory-search-filter-head-clear"
              size="sm"
              color="link"
              id={"inventory-search-filter-head-clear"}
              onClick={() => {
                onCriteriaCleared();
                onTermChanged("");
              }}
            >
              <FormattedMessage id="clear.filters" />
            </Button>
            <Button color="link" className="responsive-search-filter-head-close">
              <FontAwesomeIcon icon={faTimes} size="lg" onClick={() => setIsShowingFilter(!isShowingFilter)} />
            </Button>
          </div>

          {siteLocations.isPublished && (
            <fieldset className="inventory-filter-criteria" id={"inventory-filter-locations"}>
              <legend className="inventory-filter-criteria-title">
                <FormattedMessage id={"locations"} />
              </legend>
              <CheckboxTree
                data={siteLocations.buildings.map((l) => buildingAsNode(l))}
                onChecked={(values) => onLocationsChanged(values.map((v) => Number.parseInt(v)))}
                defaultExpanded={[]}
                defaultChecked={checkedLocations.map((l) => l.toString())}
                getSpanProps={getLocationTreeSpanProps}
              />
            </fieldset>
          )}

          <fieldset className="inventory-filter-criteria" id="inventory-filter-criteria-labs">
            <legend className="inventory-filter-criteria-title d-inline-block w-auto">
              <FormattedMessage id={"labs"} />{" "}
              <Badge role={"status"} aria-label={intl.formatMessage({ id: "lab.count.badge" }, { count: criteria.accountIds.length })}>
                {criteria.accountIds.length}
              </Badge>
            </legend>

            <div className={"d-inline-block ms-2"}>
              {labsExpanded && (
                <Button
                  color={"link"}
                  onClick={() => setLabsExpanded(false)}
                  aria-label={intl.formatMessage({ id: "collapse" })}
                  aria-expanded={labsExpanded}
                >
                  <FontAwesomeIcon icon={faAngleDown} />
                </Button>
              )}
              {!labsExpanded && (
                <Button
                  color={"link"}
                  onClick={() => setLabsExpanded(true)}
                  aria-label={intl.formatMessage({ id: "expand" })}
                  aria-expanded={labsExpanded}
                >
                  <FontAwesomeIcon icon={faAngleUp} />
                </Button>
              )}
            </div>

            {labsExpanded && (
              <div className={"clearfix"}>
                {accounts.map((a) => (
                  <div key={`inventory-filter-type${a.id}`} className="inventory-filter-type-item">
                    <CheckboxLabel>
                      <CheckboxInput
                        id={`inventory-filter-type-check${a.id}`}
                        uncheckedValue="0"
                        checked={criteria.accountIds.includes(a.id)}
                        onChange={(e) => onSelectAccount(e.target.checked, a.id)}
                      />
                      <span>
                        {a.name || intl.formatMessage({ id: "no.lab.name" })} ({a.room || intl.formatMessage({ id: "no.room.name" })})
                      </span>
                    </CheckboxLabel>
                  </div>
                ))}
              </div>
            )}
          </fieldset>

          <fieldset className="inventory-filter-criteria" id="inventory-filter-criteria-hazards">
            <legend className="inventory-filter-criteria-title">
              <FormattedMessage id={"hazards"} />
            </legend>
            {ChemicalSafety.Hazards.map((h) => (
              <div key={`inventory-filter-type${h}`} className="inventory-filter-type-item">
                <CheckboxLabel>
                  <CheckboxInput
                    id={`inventory-filter-type-check${h}`}
                    uncheckedValue="0"
                    checked={criteria.safetyCriteria.hazards.includes(h)}
                    onChange={(e) => onSelectHazards(e.target.checked, h)}
                    value={h}
                  />
                  <span>{h}</span>
                </CheckboxLabel>
              </div>
            ))}
          </fieldset>
          <fieldset className="inventory-filter-criteria" id="inventory-filter-criteria-labs">
            <legend className="inventory-filter-criteria-title">
              <FormattedMessage id={"availability"} />
            </legend>
            <div className="inventory-filter-type-item">
              <CheckboxLabel>
                <CheckboxInput
                  id={"out-of-stock"}
                  uncheckedValue="0"
                  checked={criteria.includeOutOfStock}
                  onChange={(e) => onOutOfStockChanged(e.target.checked)}
                />
                <FormattedMessage id="include.out.of.stock" />
              </CheckboxLabel>
            </div>
          </fieldset>
        </div>
      </Col>
      <Col role={"region"} aria-labelledby={"inventory-search-heading"}>
        <Card className="inventory-search-primary-content">
          <CardBody>
            <Row>
              <Col xs={12} md={4}>
                <h1 className="inventory-heading" id={"inventory-search-heading"}>
                  <FormattedMessage id="inventory.search" />
                </h1>
              </Col>
              <Col xs={12} md={8}>
                <div>
                  <div>
                    <div id="inventory-search-text-container">
                      <Form
                        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                          e.preventDefault();
                        }}
                        role={"search"}
                      >
                        <FormGroup className="inventory-search-text-input">
                          <Label for="inventory-search-text" className="visually-hidden">
                            <FormattedMessage id="search.inventory" />
                          </Label>
                          <Input
                            id={"inventory-search-text"}
                            aria-label={intl.formatMessage({ id: "search.inventory" })}
                            placeholder={intl.formatMessage({ id: "search.inventory.placeholder" })}
                            type={"text"}
                            value={term}
                            onChange={(e) => onTermChanged(e.target.value)}
                          />
                        </FormGroup>
                        <div>
                          <Button type="submit" color="primary" id="inventory-search-submit" onClick={() => onSearch()}>
                            <FormattedMessage id="search" />
                          </Button>
                        </div>
                      </Form>
                      <Button
                        color="link"
                        title={intl.formatMessage({ id: "filter" })}
                        className="ms-1 d-block d-xl-none"
                        onClick={() => setIsShowingFilter(!isShowingFilter)}
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="inventory-search-actions">
                <Button color="link" className="d-none d-sm-block" onClick={() => setIsExportOpen(true)}>
                  <img
                    src="/images/Inventory_Export.svg"
                    className="link button-icon"
                    alt={intl.formatMessage({ id: "export.items" })}
                    aria-hidden={true}
                  />
                  <FormattedMessage id="export.items" />
                </Button>
              </Col>
            </Row>
            <div>
              {isLoading && <Loading messageId="searching" />}
              {!isLoading && (
                <InventoryAdminSearchResults onResultSetOptionsChanged={onResultSetOptionsChanged} results={searchResults} accounts={allAccounts} />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      <InventoryAdminExportModal
        isOpen={isExportOpen}
        onCancel={() => setIsExportOpen(false)}
        accounts={accounts}
        onExportComplete={() => setIsExportOpen(false)}
        searchCriteria={criteria}
        api={api}
      />
    </Row>
  );
}
