export enum Hazards {
  Explosive = "Explosive",
  Flammable = "Flammable",
  Oxidizer = "Oxidizer",
  CompressedGas = "Compressed Gas",
  Corrosive = "Corrosive",
  AcuteToxic = "Acute Toxic",
  Irritant = "Irritant",
  HealthHazard = "Health Hazard",
  EnvironmentalHazard = "Environmental Hazard",
}
