import React, { ReactElement } from "react";
import { DropdownItem, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal } from "@labarchives/ui-design";
import { Settings } from "../../utils/Settings";

interface WarningRestrictedDropdownItemProps {
  textKey: string;
  href: string;
  target: string;

  onWarningModalShown(href: string, target: string): void;
}

export function WarningRestrictedDropdownItem(props: WarningRestrictedDropdownItemProps): ReactElement {
  const intl = useIntl();
  const { href, textKey, target, onWarningModalShown } = props;
  const warningEnabled = Settings.isExternalLinkWarningEnabled();

  if (warningEnabled) {
    return (
      <DropdownItem tag={"button"} className={"nav-dropdown-link"} onClick={() => onWarningModalShown(href, target)}>
        <span className="nav-dropdown-link nav-link">{intl.formatMessage({ id: textKey })}</span>
      </DropdownItem>
    );
  }

  return (
    <DropdownItem tag={"a"} href={href} target={target} className={"nav-dropdown-link"}>
      <span className="nav-dropdown-link nav-link">{intl.formatMessage({ id: textKey })}</span>
    </DropdownItem>
  );
}

interface WarningRestrictedNavigationModalProps {
  isModalOpen: boolean;
  onModalClosed(): void;
  href: string;
  target: string;
}
export function WarningRestrictedNavigationModal(props: WarningRestrictedNavigationModalProps): ReactElement {
  const { isModalOpen, onModalClosed, href, target } = props;

  function onContinue(): void {
    window.open(href, target);
    onModalClosed();
  }

  return (
    <Modal isOpen={isModalOpen} toggle={onModalClosed}>
      <ModalHeader toggle={onModalClosed}>
        <FormattedMessage id={"alert"} />
      </ModalHeader>

      <ModalBody>
        <FormattedMessage id={"external.site.warning"} />
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={onContinue}>
          <FormattedMessage id={"continue.to.external.site"} />
        </Button>

        <Button onClick={onModalClosed} outline>
          <FormattedMessage id={"cancel"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
