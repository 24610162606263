import React, { ReactElement, useRef } from "react";
import { InventorySearchResultSetItem } from "@labarchives/inventory-shared/build/inventory";
import { useIntl } from "react-intl";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { FreezerBoxCellBaseProps } from "./FreezerBoxCellBaseProps";

export interface FreezerBoxCellItemProps extends FreezerBoxCellBaseProps {
  item: InventorySearchResultSetItem;
  onClick(): void;
  onDragStart(item: InventorySearchResultSetItem, cell: string): void;
}

export function FreezerBoxCellItem(props: FreezerBoxCellItemProps): ReactElement {
  const { label, item, tabIndex, onClick, cell, activeCell } = props;
  const intl = useIntl();

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={`inventory-freezer-box-cell occupied ${activeCell === cell ? "active" : ""}`}
      aria-label={intl.formatMessage({ id: "freezerbox.cell.title" }, { label })}
      tabIndex={tabIndex}
      role={"button"}
      onClick={onClick}
      onKeyPress={(e) => {
        e.preventDefault();
        if (e.key === "Enter" || e.key === " ") {
          window.location.href = ApplicationPaths.Inventory.Item(item.id);
        }
      }}
      data-cell-name={label}
      onDragStart={(e) => {
        ref.current!.blur();
        e.dataTransfer.dropEffect = "move";
        props.onDragStart(item, cell);
      }}
      draggable={true}
      ref={ref}
    >
      <div className={"inventory-freezer-box-cell-header"}>
        <div className={"inventory-freezer-box-cell-label"}>{label}</div>
        <div>
          {item && item.typeColor && (
            <div className={"inventory-type-color"} style={{ backgroundColor: item.typeColor }} title={item.typeName}>
              &nbsp;
            </div>
          )}
        </div>
      </div>

      <div>
        <div className={"inventory-freezer-box-cell-name"} title={item.name}>
          {item.name}
        </div>
        <div className={"inventory-freezer-box-cell-type"} title={item.typeName}>
          {item.typeName}
        </div>
        <div className={"inventory-freezer-box-cell-quantity"}>
          {item.quantityAvailable} {item.unit}
        </div>
      </div>
    </div>
  );
}
