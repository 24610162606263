import React, { ReactElement, useContext, useState } from "react";
import { InventorySearchResultSetItem, StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { Link } from "react-router-dom";
import { Button } from "@labarchives/ui-design";
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "reactstrap";
import { getFormattedFreezerBoxCells } from "../selectors";
import { FormattedFreezerBoxCells } from "../FormattedFreezerBoxCells";
import { StorageContext } from "../StorageContext";
import { getInventoryItemViewFromSearch, getItemAsText } from "../../inventory/selectors";
import { InventoryTypesContext } from "../../inventorytypes/InventoryTypesContext";
import { VendorsContext } from "../../vendors/VendorsContext";
import copyToClipboard from "../../utils/copyToClipboard";

export interface FreezerBoxCellDetailsProps {
  activeCell: string;
  location: StorageLocation;
  activeItem: InventorySearchResultSetItem;
  isMoveActive: boolean;
  isCopyActive: boolean;
  currency: string;

  onDetailsClosed(): void;

  onMoveItemActivated(): void;

  onCopyItemActivated(): void;

  onMoveItemCancelled(): void;

  onCopyItemCancelled(): void;

  onUseNowOpened(open: boolean): void;
}

export function FreezerBoxCellDetails(props: FreezerBoxCellDetailsProps): ReactElement {
  const typeState = useContext(InventoryTypesContext);
  const storageState = useContext(StorageContext);
  const vendorState = useContext(VendorsContext);

  const [wasCopied, setWasCopied] = useState(false);

  const {
    activeCell,
    location,
    activeItem,
    isMoveActive,
    isCopyActive,
    currency,
    onDetailsClosed,
    onUseNowOpened,
    onMoveItemActivated,
    onCopyItemActivated,
    onMoveItemCancelled,
    onCopyItemCancelled,
  } = props;

  const intl = useIntl();

  function onCopiedToClipboard(): void {
    const text = getItemAsText(getInventoryItemViewFromSearch(activeItem, typeState, storageState, vendorState), intl, currency);
    copyToClipboard(text);

    setWasCopied(true);
    setTimeout(() => {
      setWasCopied(false);
    }, 5000);
  }

  function truncate(description: string): string {
    if (description.length <= 200) {
      return description;
    }

    return `${description.slice(0, 200)}...`;
  }

  return (
    <div className={"col inventory-freezer-box-details-col"}>
      <div id={"inventory-freezer-box-details"}>
        <div className={"d-flex justify-content-between"}>
          <div className={"inventory-freezer-box-details-cell-label"}>
            {getFormattedFreezerBoxCells([activeCell], location.freezerBoxDisplayFormat, location.numberOfRows, location.numberOfColumns)}
          </div>
          <button type={"button"} className={"close btn-close"} onClick={() => onDetailsClosed()}></button>
        </div>

        <div className={"inventory-freezer-box-details-item-name"}>
          <Link to={`/inventory/${activeItem.id}`}>{activeItem.name}</Link>
        </div>

        {!isMoveActive && !isCopyActive && (
          <div className={"d-flex justify-content-between mt-2 mb-2"}>
            <Button size={"sm"} outline onClick={() => onUseNowOpened(true)} id={"inventory-freezer-box-details-use-now"}>
              <FormattedMessage id={"use"} />
            </Button>
            <Button size={"sm"} outline onClick={onMoveItemActivated} id={"inventory-freezer-box-details-move"}>
              <FormattedMessage id={"move"} />
            </Button>
            <Button size={"sm"} outline onClick={onCopyItemActivated} id={"inventory-freezer-box-details-copy"}>
              <FormattedMessage id={"copy"} />
            </Button>
            <Button size={"sm"} outline onClick={onCopiedToClipboard} id={"inventory-freezer-box-details-copy-link"}>
              <FormattedMessage id={"copy.link"} />
            </Button>
            <Tooltip placement="bottom" isOpen={wasCopied} target="inventory-freezer-box-details-copy-link">
              <FormattedMessage id="inventory.item.copied.to.clipboard" />
            </Tooltip>
          </div>
        )}

        {isMoveActive && (
          <div className={"inventory-freezer-box-move-message"}>
            <div>
              <FormattedMessage id={"freezer.box.move.message"} />
            </div>
            <Button outline size={"sm"} onClick={onMoveItemCancelled}>
              <FormattedMessage id={"cancel"} />
            </Button>
          </div>
        )}

        {isCopyActive && (
          <div className={"inventory-freezer-box-copy-message"}>
            <div>
              <FormattedMessage id={"freezer.box.copy.message"} />
            </div>
            <Button outline size={"sm"} onClick={onCopyItemCancelled}>
              <FormattedMessage id={"cancel"} />
            </Button>
          </div>
        )}

        <div className={"inventory-freezer-box-details-item"}>
          <div className={"inventory-freezer-box-details-header"}>
            <FormattedMessage id={"type"} />
          </div>

          <div className={"inventory-freezer-box-details-value"}>
            {activeItem.typeColor && (
              <div className={"inventory-type-color"} style={{ backgroundColor: activeItem.typeColor }}>
                &nbsp;
              </div>
            )}
            <div className={"inventory-freezer-box-details-type"}>{activeItem.typeName}</div>
          </div>
        </div>

        <div className={"inventory-freezer-box-details-item"}>
          <div className={"inventory-freezer-box-details-header"}>
            <FormattedMessage id={"quantity"} />
          </div>
          <div className={"inventory-freezer-box-details-value inventory-freezer-box-details-quantity"}>
            {activeItem.quantityAvailable} {activeItem.unit}
          </div>
        </div>

        {activeItem.storageCells.length > 1 && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"location"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>
              <FormattedFreezerBoxCells
                cells={activeItem.storageCells}
                format={activeItem.storageCellFormat}
                rows={activeItem.storageCellRows}
                cols={activeItem.storageCellCols}
              />
            </div>
          </div>
        )}

        {activeItem.description && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"description"} />
            </div>
            <div className={"inventory-freezer-box-details-value"} title={activeItem.description}>
              {truncate(activeItem.description)}
            </div>
          </div>
        )}

        {activeItem.vendorName && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"vendor"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>{activeItem.vendorName}</div>
          </div>
        )}

        {activeItem.catalogNumber && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"catalog.number"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>{activeItem.catalogNumber}</div>
          </div>
        )}

        {activeItem.lotNumber && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"lot.number"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>{activeItem.lotNumber}</div>
          </div>
        )}

        {activeItem.price && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"price"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>
              <FormattedNumber value={activeItem.price} style="currency" currency={currency} />
            </div>
          </div>
        )}

        {activeItem.dateReceived && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"date.received"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>
              <FormattedDate value={activeItem.dateReceived} />
            </div>
          </div>
        )}

        {activeItem.grantNumber && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"grant.number"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>{activeItem.grantNumber}</div>
          </div>
        )}

        {activeItem.poNumber && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"po.number"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>{activeItem.poNumber}</div>
          </div>
        )}

        {activeItem.expiration && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"expiration"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>
              {" "}
              <FormattedDate value={activeItem.expiration} />
            </div>
          </div>
        )}

        {activeItem.safetySheetUrl && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"safety.sheet"} />
            </div>
            <div className={"inventory-freezer-box-details-value"}>
              <a href={activeItem.safetySheetUrl} target="_new">
                {activeItem.safetySheetUrl} <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </div>
          </div>
        )}

        {activeItem.notes && (
          <div className={"inventory-freezer-box-details-item"}>
            <div className={"inventory-freezer-box-details-header"}>
              <FormattedMessage id={"notes"} />
            </div>
            <div className={"inventory-freezer-box-details-value"} title={activeItem.notes}>
              {truncate(activeItem.notes)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
