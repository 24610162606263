import React, { ReactElement } from "react";
import { Role } from "@labarchives/inventory-shared/build/inventory";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Button, Modal, AlertWithIcon } from "@labarchives/ui-design";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { RoleView } from "../types/views";

interface DeleteRoleModalProps {
  isOpen: boolean;
  role: RoleView | undefined;

  isDeleteAllowed: boolean;
  showFailureMessage: boolean;

  onToggle(roleView: RoleView): void;

  onDelete(role: Role): Promise<void>;
}

export function DeleteRoleModal(props: DeleteRoleModalProps): ReactElement {
  if (!props.role) {
    return <></>;
  }
  const { role, isDeleteAllowed, showFailureMessage, isOpen } = props;

  function onToggle(): void {
    props.onToggle(role);
  }

  function getBody(): ReactElement {
    if (showFailureMessage) {
      return (
        <AlertWithIcon color={"error"} isOpen={true}>
          <FormattedMessage id={"error.deleting.role"} />
        </AlertWithIcon>
      );
    }

    if (!isDeleteAllowed) {
      return <FormattedHTMLMessage id="role.cannot.be.deleted" />;
    }

    return <FormattedHTMLMessage id="delete.role.warning" values={{ name: role.name }} />;
  }

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>
        <FormattedMessage id="delete.role" />
      </ModalHeader>
      <ModalBody>{getBody()}</ModalBody>
      <ModalFooter>
        {isDeleteAllowed && (
          <Button color="danger" onClick={() => props.onDelete({ id: role.id, name: role.name, permissions: [] })}>
            <FormattedMessage id="delete" />
          </Button>
        )}
        <CancelButton onClick={onToggle} />
      </ModalFooter>
    </Modal>
  );
}
