import { FormGroup, Label } from "reactstrap";
import { Input } from "@labarchives/ui-design";
import React, { ReactElement } from "react";
import { CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { InventoryAdvanceSearchMatchDropdown } from "./InventoryAdvanceSearchMatchDropdown";
import { InventoryAdvancedSearchDropdownProps } from "./InventoryAdvancedSearchTypeComponentProps";

export function InventoryAdvancedSearchDropdown(props: InventoryAdvancedSearchDropdownProps): ReactElement {
  return (
    <>
      <InventoryAdvanceSearchMatchDropdown
        matchType={props.matchType}
        index={props.index}
        onMatchTypeChanged={props.onMatchTypeChanged}
        attributeType={CustomAttributeType.Dropdown}
      />
      <div className={"criteria-value"}>
        <FormGroup>
          <Label className="visually-hidden" for={`value${props.index}`}>
            Search Value
          </Label>
          <Input bsSize="sm" defaultValue={props.defaultValue} type="select" onChange={(e) => props.onValueChanged(e.target.value)}>
            {props.selectedAttribute.possibleValues.map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </Input>
        </FormGroup>
      </div>
    </>
  );
}
