import { useState } from "react";
import { InventoryItemView } from "../types/views";

export interface EditableQuantityProps {
  onQuantityChanged(id: number, amount: number, unitOfMeasure: string): void;

  current: InventoryItemView;
  units: string[];
}

export interface EditableQuantityHooks {
  isEditingQuantity: boolean;
  onCancelChangeAmount(): void;
  onSaveChangedAmount(amount: string, uom: string): void;
  onEditQuantity(): void;
}

export function useEditableQuantity(props: EditableQuantityProps): EditableQuantityHooks {
  const [isEditingQuantity, setIsEditingQuantity] = useState(false);

  function onEditQuantity(): void {
    setIsEditingQuantity(true);
  }

  function onCancelChangeAmount(): void {
    setIsEditingQuantity(false);
  }

  function saveChangedAmount(amount: string, uom: string): void {
    if (props.onQuantityChanged) {
      props.onQuantityChanged(props.current.id, Number.parseFloat(amount), uom);
      onCancelChangeAmount();
    }
  }

  return {
    isEditingQuantity,
    onEditQuantity,
    onCancelChangeAmount,
    onSaveChangedAmount: saveChangedAmount,
  };
}
