// eslint-disable-next-line max-classes-per-file
import { StorageLocation } from "@labarchives/inventory-shared/build/inventory";

export interface FreezerBoxLocation {
  row: number;
  col: number;
  normalizedLabel: string;
  label: string;
}

export interface StorageLocationTreeView {
  location: StorageLocation;
  children: StorageLocationTreeView[];
}

export class StorageLocationView {
  public locationList: StorageLocation[];

  private readonly cachedLocationNames: Map<number, string> = new Map<number, string>();

  private readonly byId: { [id: number]: StorageLocation };

  public constructor(storageLocations: StorageLocation[]) {
    this.byId = {};
    storageLocations.forEach((l) => {
      this.byId[l.id] = l;
    });
    this.locationList = [...storageLocations].sort((s1, s2) => s1.name.localeCompare(s2.name));
  }

  public getSelectedLocation(locationId: number | null): StorageLocation | null {
    if (!locationId) {
      return null;
    }
    return this.byId[locationId];
  }

  private getStorageLocationName(storageLocationId: number): string {
    if (!storageLocationId) {
      return "";
    }

    if (!(storageLocationId in this.byId)) {
      return "";
    }

    const storageLocation = this.byId[storageLocationId];
    let locationName = storageLocation.name;
    if (storageLocation.parentId) {
      locationName = `${this.getStorageLocationName(storageLocation.parentId)} > ${locationName}`;
    }

    return locationName;
  }

  public getFormattedDescription(locationId: number | null): string {
    if (!locationId) {
      return "(No Location Set)";
    }

    if (!this.cachedLocationNames.has(locationId)) {
      this.cachedLocationNames.set(locationId, this.getStorageLocationName(locationId));
    }

    return `${this.cachedLocationNames.get(locationId)}`;
  }

  public getLocation(id: number): StorageLocation | undefined {
    return this.byId[id];
  }

  public getTopParent(parentId: number): StorageLocation {
    const parent = this.byId[parentId];
    if (parent.parentId === null) {
      return parent;
    }
    return this.getTopParent(parent.parentId);
  }

  public getAllLocationsInTree(parentId: number): StorageLocation[] {
    const getChildren = (parentLocation: StorageLocation, allLocations: StorageLocation[]): void => {
      allLocations.push(parentLocation);
      const children = this.locationList.filter((l) => l.parentId === parentLocation.id);
      children.forEach((c) => getChildren(c, allLocations));
    };

    const parent = this.getTopParent(parentId);
    const locations: StorageLocation[] = [];
    getChildren(parent, locations);

    return locations;
  }

  public getChildrenAsFlatList(id: number): StorageLocation[] {
    const findChildren = (parentLocation: StorageLocation, allLocations: StorageLocation[]): void => {
      allLocations.push(parentLocation);
      const children = this.locationList.filter((l) => l.parentId === parentLocation.id);
      children.forEach((c) => findChildren(c, allLocations));
    };

    const parent = this.locationList.find((l) => l.id === id)!;
    const locations: StorageLocation[] = [];
    findChildren(parent, locations);

    return locations;
  }

  private static asStorageLocationTreeView(location: StorageLocation): StorageLocationTreeView {
    return {
      location,
      children: [],
    };
  }

  public getChildren(item: StorageLocationTreeView): StorageLocationTreeView[] {
    return this.locationList
      .filter((l) => l.parentId === item.location.id)
      .map((l) => {
        return { location: l, children: this.getChildren(StorageLocationView.asStorageLocationTreeView(l)) };
      });
  }

  public asTree(): StorageLocationTreeView[] {
    return this.locationList
      .filter((l) => !l.parentId)
      .map((l) => {
        return { location: l, children: this.getChildren(StorageLocationView.asStorageLocationTreeView(l)) };
      });
  }

  public getKeysInTree(leafNodeId: string | number | null): string[] {
    if (!leafNodeId) {
      return [];
    }

    let keys: string[] = [leafNodeId.toString()];

    const locationAsInt = Number.parseInt(leafNodeId.toString());
    if (locationAsInt in this.byId) {
      const { parentId } = this.byId[locationAsInt];
      if (parentId) {
        keys = [...keys, ...this.getKeysInTree(parentId.toString())];
      }
    }

    return keys;
  }

  public getTopLevelLocation(name: string): StorageLocation | undefined {
    return this.locationList.find((l) => l.name.trim().toLowerCase() === name.trim().toLowerCase() && l.parentId === null);
  }
}

export class NullStorageLocationView extends StorageLocationView {
  public constructor() {
    super([]);
  }
}
