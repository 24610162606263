import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { faPencilAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { ManageInventoryTypeNameProps, useManageInventoryTypeName } from "./ManageInventoryTypeNameHooks";

export function ManageInventoryTypeName(props: ManageInventoryTypeNameProps): ReactElement {
  const { isEditing, name, invalid, onSave, onEdit, onCancelEdit, onNameChange } = useManageInventoryTypeName(props);
  const intl = useIntl();

  if (isEditing) {
    return (
      <div className="management-edit-name-container">
        <FormGroup className="management-edit-name">
          <Label for="management-edit-name" className="visually-hidden">
            <FormattedMessage id="name" />
          </Label>
          <Input
            id="management-edit-name"
            type="text"
            defaultValue={name}
            onChange={(e) => onNameChange(e.target.value)}
            maxLength={255}
            invalid={invalid}
          />
          <FormFeedback>
            <FormattedMessage id="inventory.type.name.invalid.error" />
          </FormFeedback>
        </FormGroup>
        <Button onClick={() => onSave()} className="me-1" color="primary">
          <FormattedMessage id="save" />
        </Button>
        <CancelButton onClick={() => onCancelEdit()} />
      </div>
    );
  }

  return (
    <div className="management-name-container">
      <div className="management-item-name">{props.type.name}</div>
      {!props.type.isDefault && !props.type.isLocked && (
        <div>
          <Button color="link" title={intl.formatMessage({ id: "edit" })} onClick={onEdit}>
            <FontAwesomeIcon icon={faPencilAlt} size="lg" />
          </Button>
        </div>
      )}
    </div>
  );
}
