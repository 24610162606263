import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { Button, Card, CardBody } from "@labarchives/ui-design";
import { Link } from "react-router-dom";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faListCheck, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { ModalApproval } from "../modals/ModalApproval";
import { ModalOrder } from "../modals/ModalOrder";
import { ModalReceive } from "../modals/ModalReceive";
import { UsersContext } from "../../user/UsersContext";
import { InventoryTypesState } from "../../inventorytypes/types/state";
import { InventoryTypesContext } from "../../inventorytypes/InventoryTypesContext";
import { StorageContext } from "../../storage/StorageContext";
import { AuthorizedComponent } from "../../components/Authentication/AuthorizedComponent";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import { OrderExportModal } from "../export/OrderExportModal";
import { OrderActionModals } from "../modals/OrderActionModals";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import "./OrderSearch.scss";
import { OrderBulkUpdateModal } from "../modals/OrderBulkUpdateModal";
import { onPageLoad } from "../../utils/onPageLoad";
import { InventoryApiClient } from "../../api";
import { useOrderSearch } from "./OrderSearchHooks";
import { OrderSearchResults } from "./results/OrderSearchResults";
import { OrderSearchFilter } from "./filter/OrderSearchFilter";

export function OrderSearch(): ReactElement {
  const userState = useContext(UsersContext);
  const storageState = useContext(StorageContext);
  const authState = useContext(AuthenticationContext);
  const inventoryTypesState = useContext<InventoryTypesState>(InventoryTypesContext);
  const intl = useIntl();

  const [isShowingFilter, setIsShowingFilter] = useState(false);
  const [isShowingExport, setIsShowingExport] = useState(false);

  function onExportModalToggled(): void {
    setIsShowingExport(!isShowingExport);
  }

  const api = new InventoryApiClient();
  const {
    filterView,
    isLoading,
    isStorageLocationRequired,
    isProcessingOrder,
    actionErrorMessage,
    searchResults,
    openOrderRequest,
    units,
    searchCriteria,
    storageLocations,
    inventoryTypeViews,
    searchTerm,
    isBulkActionEnabled,
    bulkActionStatus,
    selectedBulkUpdateOrderIds,
    isBulkSelectAllChecked,
    isBulkUpdateModalOpen,
    isModalOpen,
    onFilterChanged,
    onFilterCleared,
    changeSearchTerm,
    onResultsOptionsChanged,
    onSearchTermChange,
    onOrderAction,
    toggleModal,
    onOrderApproval,
    onOrderPlaced,
    onOrderReceived,
    onBulkUpdateSelectAll,
    onBulkUpdateOrderChecked,
    onBulkOrderApproved,
    onBulkOrderPlaced,
    onBulkUpdateModalToggled,
  } = useOrderSearch(userState, authState, inventoryTypesState, storageState, api);

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.order.search" }));
  }, []);

  function getUserName(requestedById: number): string {
    const user = userState.byId[requestedById];

    if (user) {
      return user.fullName;
    }

    return "";
  }

  return (
    <AuthorizedComponent requiredPermissions={[RolePermissions.OrdersViewAll]}>
      <Row>
        <Col xl={2} role={"region"} aria-label={intl.formatMessage({ id: "filters" })}>
          <OrderSearchFilter
            inventoryTypeViews={inventoryTypeViews}
            filters={filterView}
            onFilterChanged={onFilterChanged}
            onFilterCleared={onFilterCleared}
            isShowingFilter={isShowingFilter}
            onFilterToggle={() => setIsShowingFilter(!isShowingFilter)}
          />
        </Col>
        <Col xl={10} role={"region"} aria-labelledby={"order-search-heading"}>
          <Card className="order-search-primary-content">
            <CardBody>
              <Row>
                <Col xs={12} md={4}>
                  <h1 className="inventory-heading" id={"order-search-heading"}>
                    <FormattedMessage id="order.search" />
                  </h1>
                </Col>
                <Col xs={12} md={8}>
                  <div>
                    <Form
                      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        changeSearchTerm();
                      }}
                      role={"search"}
                    >
                      <div id="order-search-text-container">
                        <Label for="order-search-text" className="visually-hidden">
                          <FormattedMessage id="search.orders" />
                        </Label>
                        <Input
                          id="order-search-text"
                          type="search"
                          aria-label={intl.formatMessage({ id: "search.orders" })}
                          placeholder={intl.formatMessage({ id: "search.orders.placeholder" })}
                          value={searchTerm}
                          onChange={(e) => onSearchTermChange(e.target.value)}
                        />
                        <Button type="submit" color="primary" id="order-search-search-button">
                          <FormattedMessage id="search" />
                        </Button>
                        <Button
                          color="link"
                          onClick={() => setIsShowingFilter(!isShowingFilter)}
                          title={intl.formatMessage({ id: "filter" })}
                          className="ms-1 d-block d-xl-none"
                        >
                          <FontAwesomeIcon icon={faFilter} />
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="order-search-actions">
                  <AuthorizedComponent requiredPermissions={[RolePermissions.OrdersRequestItem]}>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    <Button color="link" tag={Link as any} {...{ to: ApplicationPaths.Orders.Request }} className="me-2" data-tour="add-order">
                      <FontAwesomeIcon icon={faPlusCircle} className="button-icon" />
                      <FormattedMessage id="request.item" />
                    </Button>
                  </AuthorizedComponent>
                  <Button color="link" onClick={onExportModalToggled} className="d-none d-sm-inline">
                    <img
                      src="/images/Inventory_Export.svg"
                      className="link button-icon"
                      alt={intl.formatMessage({ id: "export.orders" })}
                      aria-hidden={true}
                    />
                    <FormattedMessage id="export.orders" />
                  </Button>
                  {isBulkActionEnabled && selectedBulkUpdateOrderIds.length > 0 && (
                    <Button color="link" onClick={onBulkUpdateModalToggled} className="d-none d-sm-inline ms-2">
                      <FontAwesomeIcon icon={faListCheck} className="button-icon" />
                      <FormattedMessage id="update.orders" />
                    </Button>
                  )}
                </Col>
              </Row>
              <div>
                <OrderSearchResults
                  isLoading={isLoading}
                  searchResults={searchResults}
                  onResultsOptionsChanged={onResultsOptionsChanged}
                  onOrderAction={onOrderAction}
                  currency={authState.getCurrency()}
                  isBulkActionEnabled={isBulkActionEnabled}
                  selectedBulkUpdateOrderIds={selectedBulkUpdateOrderIds}
                  isBulkSelectAllChecked={isBulkSelectAllChecked}
                  onBulkUpdateSelectAll={onBulkUpdateSelectAll}
                  onBulkUpdateOrderChecked={onBulkUpdateOrderChecked}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ModalApproval
        modalId={OrderActionModals.Approval}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        isProcessing={isProcessingOrder}
        onOrderApproval={onOrderApproval}
        errorMessage={actionErrorMessage}
        orderId={openOrderRequest ? openOrderRequest.id : 0}
        dateLastOrdered={openOrderRequest ? openOrderRequest.dateLastOrdered : null}
        inventoryName={openOrderRequest ? openOrderRequest.inventoryName : ""}
        notes={openOrderRequest ? openOrderRequest.notes : ""}
        price={openOrderRequest ? openOrderRequest.price : 0}
        quantity={openOrderRequest ? openOrderRequest.quantity : 0}
        quantityOnHand={openOrderRequest ? openOrderRequest.quantityOnHand : 0}
        total={openOrderRequest ? openOrderRequest.total : 0}
        currency={authState.getCurrency()}
        requestedByName={openOrderRequest ? getUserName(openOrderRequest.requestedById) : ""}
      />

      <ModalOrder
        modalId={OrderActionModals.Order}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        isProcessing={isProcessingOrder}
        onOrderPlaced={onOrderPlaced}
        errorMessage={actionErrorMessage}
        orderId={openOrderRequest ? openOrderRequest.id : 0}
        inventoryName={openOrderRequest ? openOrderRequest.inventoryName : ""}
        price={openOrderRequest ? openOrderRequest.price : 0}
        quantity={openOrderRequest ? openOrderRequest.quantity : 0}
      />

      <ModalReceive
        modalId={OrderActionModals.Receive}
        isModalOpen={isModalOpen}
        toggleModal={() => toggleModal(OrderActionModals.Receive)}
        isProcessing={isProcessingOrder}
        onOrderReceived={onOrderReceived}
        storage={storageLocations}
        units={units}
        errorMessage={actionErrorMessage}
        orderId={openOrderRequest ? openOrderRequest.id : 0}
        inventoryName={openOrderRequest ? openOrderRequest.inventoryName : ""}
        price={openOrderRequest ? openOrderRequest.price : 0}
        quantity={openOrderRequest ? openOrderRequest.quantity : 0}
        unitOfMeasure={openOrderRequest ? openOrderRequest.unitOfMeasure : ""}
        requestedByName={openOrderRequest ? getUserName(openOrderRequest.requestedById) : ""}
        requestedById={openOrderRequest ? openOrderRequest.requestedById : 0}
        api={api}
        isStorageLocationRequired={isStorageLocationRequired}
      />

      <OrderExportModal
        isOpen={isShowingExport}
        onCancel={onExportModalToggled}
        searchCriteria={searchCriteria}
        onExportComplete={onExportModalToggled}
      />

      <OrderBulkUpdateModal
        isOpen={isBulkUpdateModalOpen}
        actionStatus={bulkActionStatus}
        onCancel={onBulkUpdateModalToggled}
        orderIds={selectedBulkUpdateOrderIds}
        onBulkOrderApproved={onBulkOrderApproved}
        onBulkOrderOrdered={onBulkOrderPlaced}
      />
    </AuthorizedComponent>
  );
}
