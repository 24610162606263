import React, { ReactElement } from "react";
import { Button } from "@labarchives/ui-design/dist";
import "./GettingStarted.scss";
import { useIntl } from "react-intl";

export interface FirstVisitGettingStartedProps {
  isLabManager: boolean;

  startTour(): void;
}

export function FirstVisitGettingStarted(props: FirstVisitGettingStartedProps): ReactElement {
  const intl = useIntl();

  if (props.isLabManager) {
    return (
      <div className="getting-started-container">
        <div className="getting-started-header">Set up your lab</div>
        <div className="getting-started-icons">
          <div className="getting-started-icon">
            <img src="/images/tour/Inventory_Customize.svg" alt="Customize" />
            <span>Customize</span>
          </div>
          <div className="getting-started-icon">
            <img src="/images/tour/Inventory_Import.svg" alt="Import" />
            <span>Import</span>
          </div>
          <div className="getting-started-icon">
            <img src="/images/tour/Inventory_Invite.svg" alt="Invite" />
            <span>Invite</span>
          </div>
        </div>
        <div className="getting-started-start-tour">
          <Button onClick={props.startTour} color="link" aria-label={intl.formatMessage({ id: "open.getting.started.tour" })}>
            {intl.formatMessage({ id: "get.started" })}
          </Button>
        </div>
        <div className="getting-started-general-footer">
          <a
            href="https://www.labarchives.com/labarchives-knowledge-base/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={intl.formatMessage({ id: "open.knowledge.base" })}
          >
            {intl.formatMessage({ id: "learn.more" })}
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="getting-started-container">
      <div className="getting-started-header">Welcome to your lab’s inventory!</div>
      <div className="getting-started-icons">
        <div className="getting-started-icon">
          <img src="/images/tour/Inventory_Find.svg" alt="Find" />
          <span>Find</span>
        </div>
        <div className="getting-started-icon">
          <img src="/images/tour/Inventory_Add.svg" alt="Add" />
          <span>Add</span>
        </div>
        <div className="getting-started-icon">
          <img src="/images/tour/Inventory_Request.svg" alt="Request" />
          <span>Request</span>
        </div>
      </div>
      <div className="getting-started-start-tour">
        <Button onClick={props.startTour} color="link" aria-label={intl.formatMessage({ id: "open.getting.started.tour" })}>
          {intl.formatMessage({ id: "check.it.out" })}
        </Button>
      </div>
      <div className="getting-started-general-footer">
        <a
          href="https://www.labarchives.com/labarchives-knowledge-base/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label={intl.formatMessage({ id: "open.knowledge.base" })}
        >
          {intl.formatMessage({ id: "learn.more" })}
        </a>
      </div>
    </div>
  );
}
