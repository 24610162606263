import React, { ReactElement, useState } from "react";
// @ts-ignore
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import "./SearchDates.scss";
import { getDefaultLocale } from "react-datepicker";

interface SearchDatesProps {
  selectedStart?: Date;
  selectedEnd?: Date;

  onDatesChanged(start?: Date, end?: Date): void;
}

export function SearchDates(props: SearchDatesProps): ReactElement {
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>(null);
  const [dateStart, setDateStart] = useState<moment.Moment | null>(props.selectedStart ? moment(props.selectedStart) : null);
  const [dateEnd, setDateEnd] = useState<moment.Moment | null>(props.selectedEnd ? moment(props.selectedEnd) : null);

  const onDatesChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }): void => {
    setDateStart(startDate);
    setDateEnd(endDate);

    props.onDatesChanged(startDate ? startDate.toDate() : undefined, endDate ? endDate.toDate() : undefined);
  };

  const onFocusChange = (updatedFocusedInput: "startDate" | "endDate" | null): void => {
    setFocusedInput(updatedFocusedInput);
  };

  return (
    <div className="search-dates-container">
      <DateRangePicker
        startDate={dateStart}
        endDate={dateEnd}
        focusedInput={focusedInput}
        startDateId="search-dates-start-date"
        endDateId="search-dates-end-date"
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        showClearDates
        numberOfMonths={1}
        orientation="horizontal"
        displayFormat={moment.localeData(getDefaultLocale()).longDateFormat("L")}
        hideKeyboardShortcutsPanel
        noBorder
        isOutsideRange={() => {
          return false;
        }}
      />
    </div>
  );
}
