import { InventorySearchResultSetItem } from "@labarchives/inventory-shared/build/inventory";
import { ExcelFile } from "../../../components/Excel/ExcelFile";
import { SiteAccountView } from "../../types/views";
import { ExcelSheet } from "../../../components/Excel/ExcelSheet";

export class InventoryAdminExcel extends ExcelFile {
  public static CreateExport(items: InventorySearchResultSetItem[], accounts: SiteAccountView[]): InventoryAdminExcel {
    const excel = new InventoryAdminExcel();
    const inventorySheet = new ExcelSheet("Inventory");
    inventorySheet.AddColumns(["Name", "Lab Name", "Lab Building", "Lab Floor", "Lab Room", "Inventory Type", "Quantity", "Unit"]);
    items.forEach((item) => {
      const lab = accounts.find((a) => a.id === item.accountId) || { id: 0, name: "", building: "", room: "", floor: "" };
      inventorySheet.AppendRow([item.name, lab.name, lab.building, lab.room, lab.floor, item.typeName, item.quantityAvailable.toString(), item.unit]);
    });
    excel.sheets.push(inventorySheet);
    return excel;
  }
}
