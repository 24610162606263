import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, ReactElement, useContext } from "react";
import { Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPencilAlt, faPlus, faTrash } from "@fortawesome/pro-light-svg-icons";
import { StorageLocationTreeView } from "../types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { getIsFreezerBox, getStorageLocationView } from "../selectors";
import { StorageContext } from "../StorageContext";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { ManageFreezerBox } from "./ManageFreezerBox";
import { DeleteStorageLocationPrompt } from "./DeleteStorageLocationPrompt";
import { AddStorageLocation } from "./AddStorageLocation";
import "./ManageStorageLocation.scss";
import { ManageStorageLocationProps, useManageStorageLocation } from "./ManageStorageLocationHooks";
import { DuplicateStorageLocationPrompt } from "./DuplicateStorageLocationPrompt";
import { MoveStorageLocationPrompt } from "./MoveStorageLocationPrompt";
import { useMoveStorageLocation } from "./MoveStorageLocationHooks";
import { useManageStorageLocationTree } from "./ManageStorageLocationTreeHooks";
import { ManageStorageExpandCollapse } from "./ManageStorageExpandCollapse";

export function ManageStorageLocation(props: ManageStorageLocationProps): ReactElement {
  const {
    currentEditingLocation,
    addingChild,
    isFreezerBox,
    isLocationNameValid,
    isSubLocationNameValid,
    isDeleting,
    locationNameDuplicated,
    storageLocationBeingDuplicated,
    isDuplicateError,
    onLocationNameChanged,
    onSublocationNameChanged,
    onLocationFreezerBoxChanged,
    onSubLocationFreezerBoxChanged,
    onSave,
    onItemMoved,
    onDelete,
    onCancelEdit,
    onDeletePrompt,
    onCancelDeletePrompt,
    onToggleFreezerBox,
    onToggleAddChild,
    onToggleEdit,
    onShowDuplicatePrompt,
    onDuplicatePromptCancel,
    onDuplicateStorageLocation,
  } = useManageStorageLocation(props);

  const { storageLocation, storageLocations } = props;

  const intl = useIntl();

  const { storageLocationBeingMoved, onToggleMove, onMoveCompleted, onCancelMove, canChooseAsParent } = useMoveStorageLocation({
    storageLocations: getStorageLocationView(useContext(StorageContext)),
    onItemMoved,
  });

  const { isCollapsed, isHidden, onExpand, onCollapse } = useManageStorageLocationTree({ fullLocationTree: storageLocation });

  function getItemView(view: StorageLocationTreeView): ReactElement {
    if (currentEditingLocation === view) {
      return (
        <div className="manage-storage-location-edit-container">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSave();
            }}
          >
            <div className="manage-storage-location-edit-current">
              <FormGroup>
                <Label className="visually-hidden">
                  <FormattedMessage id="location.name" />
                  <RequiredLabelIndicator required={true} />
                </Label>
                <Input
                  id="manage-storage-location-name"
                  type="text"
                  bsSize="sm"
                  defaultValue={view.location.name}
                  onChange={(e) => onLocationNameChanged(e.target.value)}
                  required
                  invalid={!isLocationNameValid}
                  maxLength={255}
                />
                <FormFeedback>
                  <FormattedMessage id="manage.location.name.error" />
                </FormFeedback>
              </FormGroup>
              {locationNameDuplicated && (
                <div className="storage-location-duplicate-warning">
                  <FormattedMessage id="duplicate.storage.location.name" />
                </div>
              )}
            </div>
            <div className="manage-storage-location-new-child-container">
              {addingChild && (
                <AddStorageLocation
                  isNameValid={isSubLocationNameValid}
                  isFreezerBox={isFreezerBox}
                  onFreezerBoxChanged={onSubLocationFreezerBoxChanged}
                  onNameChanged={onSublocationNameChanged}
                  onToggleFreezerBox={onToggleFreezerBox}
                  isNameDuplicated={false}
                  invalidNameErrorMessageId="manage.location.name.error"
                />
              )}
              {!addingChild && !getIsFreezerBox(currentEditingLocation.location) && (
                <Button className="manage-storage-location-add-child-action" size="sm" onClick={() => onToggleAddChild()} color="primary">
                  <FontAwesomeIcon icon={faPlus} className="button-icon" />
                  <FormattedHTMLMessage id="add.child.location" values={{ location: view.location.name }} />
                </Button>
              )}
              {getIsFreezerBox(currentEditingLocation.location) && (
                <ManageFreezerBox
                  columns={currentEditingLocation.location.numberOfColumns}
                  rows={currentEditingLocation.location.numberOfRows}
                  format={currentEditingLocation.location.freezerBoxDisplayFormat}
                  onFreezerBoxChanged={onLocationFreezerBoxChanged}
                />
              )}
            </div>
            <div>
              <Button type="submit" id="manage-storage-location-save" color="primary">
                <FormattedMessage id="save" />
              </Button>
              <Button
                className={"ms-2 manage-storage-location-move-action"}
                color="none"
                outline
                onClick={() => onToggleMove(view)}
                title={intl.formatMessage({ id: "move.storage.location" }, { name: view.location.name })}
              >
                <FormattedMessage id={"move"} />
              </Button>
              <CancelButton id="manage-storage-location-cancel" className="ms-1" onClick={onCancelEdit} />
              <Button
                id="manage-storage-location-delete"
                className="float-end"
                color="link"
                onClick={onDeletePrompt}
                title={intl.formatMessage({ id: "delete.location" })}
              >
                <FontAwesomeIcon icon={faTrash} size="lg" />
              </Button>
            </div>
          </Form>
        </div>
      );
    }

    if (isHidden(view)) {
      return <></>;
    }

    return (
      <div className={"manage-storage-location-tree"} data-treename={view.location.name} data-treeid={view.location.id}>
        <ManageStorageExpandCollapse isCollapsed={isCollapsed} location={view} onExpand={onExpand} onCollapse={onCollapse} />
        <span className="manage-storage-location-name">{view.location.name}</span>
        {getIsFreezerBox(view.location) && (
          <span className="manage-storage-location-grid">
            ({view.location.numberOfRows} x {view.location.numberOfColumns})
          </span>
        )}
        <span className="manage-storage-location-edit-action">
          <Button
            color="link"
            onClick={() => onToggleEdit(view)}
            title={intl.formatMessage({ id: "edit.storage.location" })}
            className="manage-storage-location-edit-trigger ms-1"
          >
            <FontAwesomeIcon icon={faPencilAlt} size="lg" />
          </Button>
        </span>
        {!view.location.parentId && (
          <span className="manage-storage-location-duplicate-action">
            <Button
              className={"ms-2"}
              color="link"
              onClick={() => onShowDuplicatePrompt(view)}
              title={intl.formatMessage({ id: "duplicate.storage.location" })}
            >
              <FontAwesomeIcon icon={faCopy} className="button-icon" size="lg" />
            </Button>
          </span>
        )}
      </div>
    );
  }

  function displayChildren(tree: StorageLocationTreeView[]): ReactElement | ReactElement[] {
    return tree.map((view) => {
      const children: ReactElement[] = [];
      if (view.children.length > 0) {
        children.push(<ul key={view.location.id} id={`manage-location-section-${view.location.id}`}>{displayChildren(view.children)}</ul>);
      }
      return (
        <Fragment key={view.location.id}>
          <li title={view.location.name}>
            <div className="manage-storage-location-child">{getItemView(view)}</div>
            {children}
          </li>
        </Fragment>
      );
    });
  }

  if (!storageLocation) {
    return <div />;
  }

  return (
    <div>
      {getItemView(storageLocation)}
      <ul className="manage-storage-location-children">{displayChildren(storageLocation.children)}</ul>
      <DeleteStorageLocationPrompt isOpen={isDeleting} location={currentEditingLocation} onDelete={onDelete} onCancel={onCancelDeletePrompt} />
      <DuplicateStorageLocationPrompt
        isOpen={storageLocationBeingDuplicated !== undefined}
        location={storageLocationBeingDuplicated}
        onDuplicateStorageLocation={onDuplicateStorageLocation}
        onCancel={onDuplicatePromptCancel}
        isError={isDuplicateError}
      />
      <MoveStorageLocationPrompt
        isOpen={storageLocationBeingMoved !== undefined}
        location={storageLocationBeingMoved}
        onCancel={onCancelMove}
        locations={storageLocations}
        onMoved={onMoveCompleted}
        canChooseAsParent={canChooseAsParent}
      />
    </div>
  );
}
