import { FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { Button } from "@labarchives/ui-design/dist";
import { ButtonProps } from "@labarchives/ui-design/dist/components/Button";

export function CancelButton(props: ButtonProps): ReactElement {
  return (
    <Button color="none" outline {...props}>
      <FormattedMessage id="cancel" />
    </Button>
  );
}
