import React, { ReactElement, useEffect } from "react";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AlertWithIcon, Button, Modal } from "@labarchives/ui-design/dist";
import { useDropzone } from "react-dropzone";
import { Loading } from "../../components";
import "./InventoryImportModal.css";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { InventoryImportModalProps, useInventoryImportModal } from "./InventoryImportModalHooks";

export function InventoryImportModal(props: InventoryImportModalProps): ReactElement {
  const {
    onTemplateDownloaded,
    onImportRestarted,
    onImportStarted,
    onImportUploaded,
    importResult,
    isParsing,
    wasParsed,
    step,
    setStep,
    isUploading,
  } = useInventoryImportModal(props);

  const intl = useIntl();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/vnd.ms-excel": [".xls", ".xlsx"] },
    multiple: false,
    onDropAccepted: onImportUploaded,
  });

  useEffect(() => {
    onImportRestarted();
  }, [props.isOpen]);

  // @ts-ignore
  const divProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = { ...getRootProps() };

  return (
    <Modal isOpen={props.isOpen} className="inventory-import-modal" toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id="import.inventory.items" />
      </ModalHeader>
      <ModalBody>
        {step === 0 && (
          <div>
            <div>
              <FormattedHTMLMessage id="import.inventory.instructions.0" />
            </div>
            <div className="inventory-import-modal-download-template">
              <Button onClick={() => setStep(1)} size="sm" color="primary">
                <FormattedMessage id="next" />
              </Button>
            </div>
          </div>
        )}
        {step === 1 && (
          <div>
            <div>
              <FormattedHTMLMessage id="import.inventory.instructions.1" />
            </div>
            <div className="inventory-import-modal-download-template">
              <Button onClick={onTemplateDownloaded} size="sm" color="primary">
                <FormattedMessage id="download.template" />
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div>
          {wasParsed && importResult.items.length === 0 && importResult.isValid && (
            <AlertWithIcon color="danger" className="inventory-import-modal-parse-errors">
              <FormattedMessage id="import.inventory.empty.file" />
            </AlertWithIcon>
          )}
          {importResult.items.length > 0 && importResult.isValid && (
            <div className="inventory-import-modal-parse-success">
              <div>
                <FormattedMessage id="inventory.upload.parse.success" values={{ rowCount: importResult.items.length }} />
                <br />
                <FormattedMessage id="inventory.upload.parse.success.refresh.notice" />
              </div>
              <div className="inventory-import-modal-parse-success-buttons">
                {!isUploading && (
                  <>
                    <Button onClick={onImportStarted} size="sm" color="primary">
                      <FormattedMessage id="start.import" />
                    </Button>
                    <Button color="link" size="sm" onClick={onImportRestarted}>
                      <FormattedMessage id="choose.another.file" />
                    </Button>
                  </>
                )}
                {isUploading && <Loading />}
              </div>
            </div>
          )}
          {!importResult.isValid && (
            <AlertWithIcon color="danger" className="inventory-import-modal-parse-errors">
              {importResult.errors.map((e, index) => (
                <ul key={`${e.id}${index}`}>
                  <li>
                    <FormattedHTMLMessage id={e.id} values={e.values} />
                  </li>
                </ul>
              ))}
              <div className="inventory-import-modal-parse-error-retry">
                <FormattedMessage id="inventory.upload.error.retry" />
              </div>
            </AlertWithIcon>
          )}
          {isParsing && <Loading messageId="parsing.upload" />}
          {importResult.items.length === 0 && (
            <section>
              <div className="dropzone" {...divProps}>
                <input id="item-attachment" {...getInputProps()} />
                <div>
                  <FormattedMessage id="upload.import.prompt" />
                </div>
                <div>
                  <img src="/images/Inventory_Import.svg" className="svg-inline--fa fa-2x" alt={intl.formatMessage({ id: "import.items" })} />
                </div>
              </div>
            </section>
          )}
        </div>
        <div>
          <CancelButton onClick={props.onCancel} className="mt-1" />
        </div>
      </ModalFooter>
    </Modal>
  );
}
