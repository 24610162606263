import { useState } from "react";
import { InventoryStandardField } from "@labarchives/inventory-shared/build/inventory";
import { isStandardFieldEnabled, isStandardFieldRequired } from "../../inventory/selectors";
import { getInventoryTypeFromView } from "../selectors";
import { ManageInventoryTypeProps } from "./ManageInventoryTypeHooks";

export interface ManageInventoryStandardFieldsHooks {
  isEditing: boolean;

  isFieldVisible(field: InventoryStandardField): boolean;
  isFieldRequired(field: InventoryStandardField): boolean;
  isFieldVisibleCheckboxDisabled(field: InventoryStandardField): boolean;
  isFieldRequiredCheckboxDisabled(field: InventoryStandardField): boolean;
  onFieldVisibleChanged(field: InventoryStandardField, isVisible: boolean): void;
  onFieldRequiredChanged(field: InventoryStandardField, isRequired: boolean): void;
  onEnterEdit(): void;
  onCancelEdit(): void;
  onSave(): void;
}

export function useManageInventoryStandardFieldsHooks(props: ManageInventoryTypeProps): ManageInventoryStandardFieldsHooks {
  const initialConfig = props.inventoryTypeView!.standardFieldConfiguration;
  const unchangeableFields = new Set([InventoryStandardField.Name, InventoryStandardField.Quantity]);
  const allInventoryFields = Object.values(InventoryStandardField); // turns enum into array of values

  function getInitialVisibleConfig(): InventoryStandardField[] {
    return allInventoryFields.filter((f) => isStandardFieldEnabled(f, initialConfig)).map((f) => f);
  }
  function getInitialRequiredConfig(): InventoryStandardField[] {
    return allInventoryFields.filter((f) => isStandardFieldRequired(f, initialConfig)).map((f) => f);
  }

  const [isEditing, setIsEditing] = useState(false);
  const [visibleFields, setVisibleFields] = useState<InventoryStandardField[]>(getInitialVisibleConfig());
  const [requiredFields, setRequiredFields] = useState<InventoryStandardField[]>(getInitialRequiredConfig());

  function onEnterEdit(): void {
    setIsEditing(true);
  }

  function onCancelEdit(): void {
    setVisibleFields(getInitialVisibleConfig());
    setRequiredFields(getInitialRequiredConfig());
    setIsEditing(false);
  }

  function onSave(): void {
    const type = getInventoryTypeFromView(props.inventoryTypeView!);
    type.standardFieldConfiguration = [];
    allInventoryFields.forEach((f) => {
      if (!unchangeableFields.has(f)) {
        type.standardFieldConfiguration!.push({ field: f, displayed: visibleFields.includes(f), required: requiredFields.includes(f) });
      }
    });
    props.onUpdated(type);
    setIsEditing(false);
  }

  function isFieldVisibleCheckboxDisabled(field: InventoryStandardField): boolean {
    return [InventoryStandardField.Name, InventoryStandardField.Quantity, InventoryStandardField.Location].includes(field);
  }

  function isFieldVisible(field: InventoryStandardField): boolean {
    return visibleFields.includes(field);
  }

  function onFieldVisibleChanged(field: InventoryStandardField, isVisible: boolean): void {
    if (isVisible) {
      setVisibleFields([...visibleFields, field]);
    } else {
      setVisibleFields(visibleFields.filter((f) => f !== field));
      setRequiredFields(requiredFields.filter((f) => f !== field));
    }
  }

  function isFieldRequiredCheckboxDisabled(field: InventoryStandardField): boolean {
    if (unchangeableFields.has(field)) {
      return true;
    }
    return !visibleFields.includes(field);
  }

  function isFieldRequired(field: InventoryStandardField): boolean {
    return requiredFields.includes(field);
  }

  function onFieldRequiredChanged(field: InventoryStandardField, isRequired: boolean): void {
    if (isRequired) {
      setRequiredFields([...requiredFields, field]);
    } else {
      setRequiredFields(requiredFields.filter((f) => f !== field));
    }
  }

  return {
    isEditing,
    isFieldVisible,
    isFieldVisibleCheckboxDisabled,
    onFieldVisibleChanged,
    isFieldRequired,
    isFieldRequiredCheckboxDisabled,
    onFieldRequiredChanged,
    onEnterEdit,
    onCancelEdit,
    onSave,
  };
}
