import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useContext, useMemo, useState } from "react";
import { AlertWithIcon, Button, Input } from "@labarchives/ui-design/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, FormFeedback, FormGroup, Label } from "reactstrap";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { AppContext, AppState } from "../../app/AppContext";
import { Loading } from "../../components";
import "./ManageSiteLocations.scss";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { InventoryApiClient } from "../../api";
import { AdminViewLocationBuilding } from "./AdminViewLocationBuilding";
import { useAdminLocationManagement } from "./InventoryAdminLocationManagementHooks";
import { ImportLocationsModal } from "./ImportLocationsModal";

export function AdminViewAddBuilding(props: {
  onCancel(): void;
  canAddBuilding(name: string): boolean;
  onBuildingAdded(name: string): void;
}): ReactElement {
  const [buildingName, setBuildingName] = useState("");

  function onNameChanged(name: string): void {
    setBuildingName(name);
  }

  function onSave(): void {
    if (buildingName.trim() !== "") {
      props.onBuildingAdded(buildingName.trim());
    }
  }

  return (
    <div className={"manage-site-locations-edit-container"}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        <FormGroup>
          <Label for={`new-building-name`}>
            <FormattedMessage id={"building.name"} />
            <RequiredLabelIndicator required={true} />
          </Label>
          <Input
            bsSize="sm"
            type={"text"}
            required
            id={"new-building-name"}
            onChange={(e) => onNameChanged(e.target.value)}
            invalid={!props.canAddBuilding(buildingName)}
            maxLength={255}
            autoFocus
          />
          <FormFeedback>
            <FormattedMessage id={"building.names.must.be.unique"} />
          </FormFeedback>
        </FormGroup>
        <div>
          <Button color={"primary"} className={"me-1"} onClick={onSave} id={"location-management-add-building-save"}>
            <FormattedMessage id={"save"} />
          </Button>
          <CancelButton onClick={() => props.onCancel()} />
        </div>
      </Form>
    </div>
  );
}

export function AdminViewManageLocations(): ReactElement {
  const {
    isLoadingLocations,
    locations,
    isImportOpen,
    isImportAllowed,
    setIsImportOpen,
    isPublishAllowed,
    onLocationsImported,
    canAddRoom,
    canRenameRoom,
    canRenameFloor,
    canAddFloor,
    canAddBuilding,
    canRenameBuilding,
    onRoomUpdated,
    onFloorUpdated,
    onBuildingAdded,
    onBuildingUpdated,
    onLocationDeleted,
    onEditToggle,
    currentlyEditedId,
    expandedIds,
    onExpandToggled,
    onLocationsPublished,
  } = useAdminLocationManagement(new InventoryApiClient());

  const intl = useIntl();

  const isAddingBuilding = useMemo(() => currentlyEditedId === 0, [currentlyEditedId]);

  const appState = useContext<AppState>(AppContext);
  if (!appState.isInventoryAdminMode) {
    return (
      <div>
        <FormattedMessage id="unauthorized.error" />
      </div>
    );
  }

  if (isLoadingLocations) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <div className="management-header justify-content-between">
          <h1 className="inventory-heading">
            <FormattedMessage id="admin.manage.locations" />
          </h1>
          {isImportAllowed && (
            <>
              <Button
                className="management-header-action"
                color="link"
                onClick={() => setIsImportOpen(true)}
                id={"location-management-upload-prompt-button"}
              >
                <img src="/images/Inventory_Import.svg" className="link button-icon" alt={intl.formatMessage({ id: "import.locations" })} />
                <FormattedMessage id="import.locations" />
              </Button>
            </>
          )}
        </div>
        <div>
          {locations.buildings.length === 0 && (
            <AlertWithIcon color={"info"}>
              <FormattedMessage id={"no.locations.inventory.admin"} />
            </AlertWithIcon>
          )}
          <div className={"building-list"} id={"location-management-building-list"}>
            {!isAddingBuilding && (
              <Button color={"link"} onClick={() => onEditToggle(0)} className={"mb-3"} id={"location-management-add-building-button"}>
                <FontAwesomeIcon icon={faPlusCircle} /> <FormattedMessage id={"add.new.building"} />
              </Button>
            )}
            {isAddingBuilding && (
              <AdminViewAddBuilding onBuildingAdded={onBuildingAdded} onCancel={() => onEditToggle(null)} canAddBuilding={canAddBuilding} />
            )}
            {locations.buildings.map((b) => (
              <AdminViewLocationBuilding
                key={b.id}
                building={b}
                locations={locations}
                onLocationDeleted={onLocationDeleted}
                onBuildingUpdated={onBuildingUpdated}
                onFloorUpdated={onFloorUpdated}
                onRoomUpdated={onRoomUpdated}
                canAddRoom={canAddRoom}
                canRenameRoom={canRenameRoom}
                canRenameFloor={canRenameFloor}
                canAddFloor={canAddFloor}
                canAddBuilding={canAddBuilding}
                canRenameBuilding={canRenameBuilding}
                onEditToggle={onEditToggle}
                currentlyEditedId={currentlyEditedId}
                expandedIds={expandedIds}
                onExpandToggled={onExpandToggled}
              />
            ))}
          </div>
        </div>
        <div className="management-edit-footer">
          {isPublishAllowed && (
            <div>
              <div>
                <FormattedMessage id={"locations.not.yet.published"} />
              </div>
              <Button id={"location-management-publish-button"} onClick={onLocationsPublished}>
                <FormattedMessage id={"make.locations.available"} />
              </Button>
            </div>
          )}
        </div>
      </div>

      <ImportLocationsModal
        isOpen={isImportOpen}
        isOverride={locations.buildings.length > 0}
        onCancel={() => setIsImportOpen(false)}
        onLocationsImported={onLocationsImported}
      />
    </>
  );
}
