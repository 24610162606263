import { FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { User } from "@labarchives/inventory-shared/build/inventory";
import { Modal } from "@labarchives/ui-design/dist";
import { CancelButton } from "../CancelButton/CancelButton";
import { getUserAccountViews } from "../../user/selectors";

export interface SwitchLabsModalProps {
  isOpen: boolean;
  user: User;

  onClose(): void;

  onSwitchLabs(accountId: number): void;
}

export function SwitchLabsModal(props: SwitchLabsModalProps): ReactElement {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onClose} id={"switch-labs-modal"}>
      <ModalHeader toggle={props.onClose}>
        <FormattedMessage id="my.labs" />
      </ModalHeader>
      <ModalBody>
        {getUserAccountViews(props.user).map((account) => {
          if (account.id === props.user.activeAccountId) {
            return (
              <div key={account.id} className={"switch-labs-current-lab"}>
                <span className={"lab-name"}>{account.name}</span> <FormattedMessage id="current.lab.note" />
              </div>
            );
          }
          return (
            <div key={account.id} className={"switch-labs-other-lab"}>
              <Button color="link" onClick={() => props.onSwitchLabs(account.id)}>
                {account.name}
              </Button>
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={props.onClose} />
      </ModalFooter>
    </Modal>
  );
}
