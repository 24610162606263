import { OrganizationBillingType } from "@labarchives/inventory-shared/build/inventory";
import { SubscriptionRenewalDetails } from "@labarchives/inventory-shared/build/subscription";
import { useEffect, useState } from "react";
import { InventoryApi } from "../../api/InventoryApi";

export interface BundleRenewalHooks {
  isLoading: boolean;
  currentBundlePricing: number;
  isBundleAllowed: boolean;
  bundleMinimumUsers: number;
  numberOfUsers: number;
  perYearTotal: number;

  onBundleCheckout(): Promise<void>;
}

export interface BundleRenewalHooksProps {
  numberOfUsers: number;
  subscriptionId: string;
  api: InventoryApi;
}

export function useBundleRenewal(props: BundleRenewalHooksProps): BundleRenewalHooks {
  const defaultSubscriptionDetails: SubscriptionRenewalDetails = {
    isSubscriptionAllowed: false,
    billingType: OrganizationBillingType.Academic,
    clientReferenceNumber: "",
    perSeatCost: 0,
    purchaseUrl: "",
    seatCount: 0,
    productCode: "",
    subtotal: 0,
    subscriptionId: "",
    description: "",
  };
  const { numberOfUsers, subscriptionId, api } = props;
  const [accountServiceSubscription, setAccountServiceSubscription] = useState<SubscriptionRenewalDetails>(defaultSubscriptionDetails);
  const [isLoading, setIsLoading] = useState(true);

  function calculateTotal(): number {
    return accountServiceSubscription.perSeatCost * numberOfUsers;
  }

  async function onBundleCheckout(): Promise<void> {
    try {
      const details = await api.getAccountServiceSubscriptionRenewalDetails(subscriptionId, numberOfUsers);
      window.location.href = details.purchaseUrl;
    } catch (error) {
      api.logError(error, "Getting checking out bundle renewal");
      throw error;
    }
  }

  async function onLoad(): Promise<void> {
    try {
      const subDetails = await api.getAccountServiceSubscriptionRenewalDetails(subscriptionId, numberOfUsers);
      setAccountServiceSubscription(subDetails);
    } catch {
      setAccountServiceSubscription({ ...defaultSubscriptionDetails, isSubscriptionAllowed: false });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  return {
    isLoading,
    numberOfUsers,
    currentBundlePricing: accountServiceSubscription.perSeatCost,
    isBundleAllowed: accountServiceSubscription.isSubscriptionAllowed,
    bundleMinimumUsers: accountServiceSubscription.seatCount,
    onBundleCheckout,
    perYearTotal: calculateTotal(),
  };
}
