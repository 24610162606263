import { Table } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPencilAlt, faTrash } from "@fortawesome/pro-light-svg-icons";
import { InventoryTypeView } from "../../inventory/types/views";
import { isEnterKey } from "../../utils/isEnterKey";
import { DeleteTypePrompt } from "./DeleteTypePrompt";
import { DuplicateTypePrompt } from "./DuplicateTypePrompt";
import { InventoryTypeColorPrompt } from "./InventoryTypeColorPrompt";

interface ManageInventoryTypesProps {
  inventoryTypeViews: InventoryTypeView[];

  onShowType(type: InventoryTypeView): void;

  onDuplicateType(type: InventoryTypeView): Promise<boolean>;

  onDeleted(type: InventoryTypeView): Promise<boolean>;

  onSaveInventoryType(type: InventoryTypeView): void;

  isColorDuplicated(color: string | undefined, type: InventoryTypeView): boolean;
}

export function ManageInventoryTypesList(props: ManageInventoryTypesProps): ReactElement {
  const [typeBeingDeleted, setTypeBeingDeleted] = useState<InventoryTypeView | undefined>(undefined);
  const [typeBeingDuplicated, setTypeBeingDuplicated] = useState<InventoryTypeView | undefined>(undefined);
  const [typeBeingColored, setTypeBeingColored] = useState<InventoryTypeView | undefined>(undefined);
  const [isDeleteError, setIsDeleteError] = useState(false);
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const intl = useIntl();

  function onShowDeletePrompt(type: InventoryTypeView): void {
    setIsDeleteError(false);
    setTypeBeingDeleted(type);
  }

  function onShowDuplicatePrompt(type: InventoryTypeView): void {
    setIsDuplicateError(false);
    setTypeBeingDuplicated(type);
  }

  function onDeletePromptCancel(): void {
    setTypeBeingDeleted(undefined);
  }

  function onDuplicatePromptCancel(): void {
    setTypeBeingDuplicated(undefined);
  }

  async function onDelete(type: InventoryTypeView): Promise<void> {
    const isDeleted = await props.onDeleted(type);
    if (isDeleted) {
      setTypeBeingDeleted(undefined);
    } else {
      setIsDeleteError(true);
    }
  }

  async function onDuplicateType(type: InventoryTypeView): Promise<void> {
    const isDuplicated = await props.onDuplicateType(type);
    if (isDuplicated) {
      setTypeBeingDuplicated(undefined);
    } else {
      setIsDuplicateError(true);
    }
  }

  async function onTypeColorSaved(type: InventoryTypeView): Promise<void> {
    await props.onSaveInventoryType(type);
    setTypeBeingColored(undefined);
  }

  function onEditTypeColorCancel(): void {
    setTypeBeingColored(undefined);
  }

  function isColorDuplicated(color: string | undefined, type: InventoryTypeView): boolean {
    return props.isColorDuplicated(color, type);
  }

  return (
    <div data-tour="review-inventory-types">
      <Table striped id={"inventory-types-list"}>
        <caption className={"visually-hidden"}>
          <FormattedMessage id="types" />
        </caption>
        <thead>
          <tr>
            <th scope={"col"}>
              <FormattedMessage id="name" />
            </th>
            <th scope={"col"} className={"inventory-type-row-small-col"}>
              <FormattedMessage id="color" />
            </th>
            <th scope={"col"} className={"inventory-type-row-small-col"}>
              <FormattedMessage id="custom.field.count" />
            </th>
            <th scope={"col"} className={"inventory-type-row-action-col"}>
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          {props.inventoryTypeViews.map((type) => (
            <tr key={type.id} className={"inventory-types-list-item"}>
              <td>
                <Button
                  className={"text-left"}
                  color="link"
                  onClick={() => props.onShowType(type)}
                  title={intl.formatMessage({ id: "edit.inventory.type" })}
                >
                  {type.name}
                </Button>
              </td>
              <td className={"inventory-type-row-small-col"}>
                <div
                  role={"button"}
                  className={`inventory-type-color ${type.color ? "" : "inventory-type-color-none"} `}
                  data-color={type.color || ""}
                  title={intl.formatMessage({ id: "change.color" })}
                  onClick={() => setTypeBeingColored(type)}
                  onKeyDown={(e) => {
                    if (isEnterKey(e.key)) {
                      setTypeBeingColored(type);
                    }
                  }}
                  style={{ backgroundColor: type.color || "#ffffff" }}
                  tabIndex={0}
                >
                  &nbsp;
                </div>
              </td>
              <td className={"inventory-type-row-small-col"}>{type.attributes.length}</td>
              <td className="management-item-delete">
                <Button
                  color="link"
                  onClick={() => props.onShowType(type)}
                  style={{ marginRight: type.isDefault || type.isLocked ? "26px" : "" }}
                  title={intl.formatMessage({ id: "edit.inventory.type" })}
                >
                  <FontAwesomeIcon icon={faPencilAlt} className="button-icon" size="lg" />
                </Button>
                {!type.isDefault && !type.isLocked && (
                  <Button onClick={() => onShowDeletePrompt(type)} color="link" title={intl.formatMessage({ id: "delete.inventory.type" })}>
                    <FontAwesomeIcon icon={faTrash} className="button-icon" size="lg" />
                  </Button>
                )}
                <Button onClick={() => onShowDuplicatePrompt(type)} color="link" title={intl.formatMessage({ id: "duplicate.inventory.type" })}>
                  <FontAwesomeIcon icon={faCopy} className="button-icon" size="lg" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <DeleteTypePrompt
        isOpen={typeBeingDeleted !== undefined}
        type={typeBeingDeleted}
        onDelete={onDelete}
        onCancel={onDeletePromptCancel}
        isError={isDeleteError}
      />
      <DuplicateTypePrompt
        isOpen={typeBeingDuplicated !== undefined}
        type={typeBeingDuplicated}
        onDuplicateType={onDuplicateType}
        onCancel={onDuplicatePromptCancel}
        isError={isDuplicateError}
      />
      <InventoryTypeColorPrompt
        isOpen={typeBeingColored !== undefined}
        type={typeBeingColored}
        onSave={onTypeColorSaved}
        onCancel={onEditTypeColorCancel}
        isColorDuplicated={isColorDuplicated}
      />
    </div>
  );
}
