import React, { ReactElement } from "react";
import { FormGroup, Label } from "reactstrap";
import { Input } from "@labarchives/ui-design";
import { AdvancedSearchCriteriaMatchType, CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { useIntl } from "react-intl";

interface InventoryAdvanceSearchMatchDropdownProps {
  matchType: AdvancedSearchCriteriaMatchType;
  index: number;
  attributeType: CustomAttributeType;
  onMatchTypeChanged: (selectedMatchType: AdvancedSearchCriteriaMatchType) => void;
}

export function InventoryAdvanceSearchMatchDropdown(props: InventoryAdvanceSearchMatchDropdownProps): ReactElement {
  const intl = useIntl();

  return (
    <div>
      <FormGroup>
        <Label className="visually-hidden" for={`matchtype${props.index}`}>
          Search Type
        </Label>
        <Input
          id={`matchtype${props.index}`}
          bsSize="sm"
          type={"select"}
          defaultValue={props.matchType.toString()}
          onChange={(e) => props.onMatchTypeChanged(Number.parseInt(e.target.value))}
        >
          {(props.attributeType === CustomAttributeType.TextArea ||
            props.attributeType === CustomAttributeType.Textbox ||
            props.attributeType === CustomAttributeType.CASNumber) && (
            <>
              <option value={AdvancedSearchCriteriaMatchType.Matches}>{intl.formatMessage({ id: "search.matches" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.DoesNotMatch}>{intl.formatMessage({ id: "search.does.not.match" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.IsExactly}>{intl.formatMessage({ id: "search.is.exactly" })}</option>
            </>
          )}

          {props.attributeType === CustomAttributeType.Dropdown && (
            <>
              <option value={AdvancedSearchCriteriaMatchType.Is}>{intl.formatMessage({ id: "search.is" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.IsNot}>{intl.formatMessage({ id: "search.is.not" })}</option>
            </>
          )}

          {props.attributeType === CustomAttributeType.Number && (
            <>
              <option value={AdvancedSearchCriteriaMatchType.Is}>{intl.formatMessage({ id: "search.is" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.GreaterThan}>{intl.formatMessage({ id: "search.greater.than" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.LessThan}>{intl.formatMessage({ id: "search.less.than" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.Between}>{intl.formatMessage({ id: "search.between" })}</option>
            </>
          )}

          {props.attributeType === CustomAttributeType.Date && (
            <>
              <option value={AdvancedSearchCriteriaMatchType.On}>{intl.formatMessage({ id: "search.on" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.After}>{intl.formatMessage({ id: "search.after" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.Before}>{intl.formatMessage({ id: "search.before" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.Between}>{intl.formatMessage({ id: "search.between" })}</option>
            </>
          )}

          {props.attributeType === CustomAttributeType.Checkbox && (
            <>
              <option value={AdvancedSearchCriteriaMatchType.AnyOf}>{intl.formatMessage({ id: "search.any.of" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.AllOf}>{intl.formatMessage({ id: "search.all.of" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.NoneOf}>{intl.formatMessage({ id: "search.none.of" })}</option>
            </>
          )}
          {props.attributeType === CustomAttributeType.Radio && (
            <>
              <option value={AdvancedSearchCriteriaMatchType.Is}>{intl.formatMessage({ id: "search.is" })}</option>
              <option value={AdvancedSearchCriteriaMatchType.IsNot}>{intl.formatMessage({ id: "search.is.not" })}</option>
            </>
          )}
        </Input>
      </FormGroup>
    </div>
  );
}
