import { FormattedDate, FormattedMessage } from "react-intl";
import React, { ReactElement, useContext, useState } from "react";
import { Col, Row, Table } from "reactstrap";
import { Button, Card, CardBody, CardHeader } from "@labarchives/ui-design/dist";
import { OrganizationBillingType, SubscriptionTransactionAction, User } from "@labarchives/inventory-shared/build/inventory";
import { Loading } from "../components";
import { UsersContext } from "../user/UsersContext";
import { InventoryApiClient } from "../api";
import { AddSubscriptionUsersModal } from "./modals/AddSubscriptionUsersModal";
import { useSubscriptionManagement } from "./SubscriptionManagementHooks";
import { AddSubscriptionUsersAccountSubscriptionModal } from "./modals/AddSubscriptionUsersAccountSubscriptionModal";
import "./PaidSubscriptionManagement.scss";
import "./SubscriptionManagement.scss";
import { RenewSubscriptionModal } from "./modals/RenewSubscriptionModal";

export interface PaidSubscriptionManagementProps {
  user: User;
}

export const PaidSubscriptionManagement = (props: PaidSubscriptionManagementProps): ReactElement => {
  const userState = useContext(UsersContext);

  const api = new InventoryApiClient();

  const {
    isLoading,
    currentPricing,
    subscriptionSummary,
    numberOfAccountUsers,
    calculateTotal,
    onCheckout,
    checkoutErrorMessage,
    numberOfUsers,
    onNumberOfUsersChanged,
  } = useSubscriptionManagement(props.user, userState, api);
  const [isAddingUsers, setIsAddingUsers] = useState(false);
  const [isAddingUsersAccountSubscription, setIsAddingUsersAccountSubscription] = useState(false);
  const [isRenewing, setIsRenewing] = useState(false);

  if (isLoading) {
    return <Loading />;
  }

  function getTypeId(action: SubscriptionTransactionAction): string {
    switch (action) {
      case SubscriptionTransactionAction.AdditionalUsers: {
        return "subscription.added.users";
      }
      case SubscriptionTransactionAction.Renewal: {
        return "subscription.renewal";
      }
      default: {
        return "subscription.creation";
      }
    }
  }

  function getBillingType(billingType: OrganizationBillingType): string {
    if (billingType === OrganizationBillingType.Commercial) {
      return "commercial.fon.profit";
    }
    return "academic.non.profit";
  }

  return (
    <div>
      <h1>
        <FormattedMessage id={"manage.your.subscription"} />
      </h1>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <FormattedMessage id="my.subscription" />
            </CardHeader>
            <CardBody>
              <div className={"subscription-product-name"}>
                {subscriptionSummary.productName} - <FormattedMessage id={getBillingType(subscriptionSummary.organizationBillingType)} />
              </div>
              {subscriptionSummary.isExpired && (
                <div className={"subscription-expired"}>
                  <FormattedMessage id="your.subscription.expired.on" />
                  <span className="subscription-end-date">
                    <FormattedDate value={subscriptionSummary.upcomingSubscriptionEndDate || subscriptionSummary.endDate} timeZone={"UTC"} />
                  </span>
                </div>
              )}

              <div className="subscription-dates">
                <FormattedDate value={subscriptionSummary.beginDate} timeZone={"UTC"} /> -{" "}
                <FormattedDate value={subscriptionSummary.upcomingSubscriptionEndDate || subscriptionSummary.endDate} timeZone={"UTC"} />
              </div>
              <div className={"subscription-seat-count"}>
                {subscriptionSummary.seatCount} <FormattedMessage id="users.pluralized" values={{ users: subscriptionSummary.seatCount }} />
              </div>

              <div className="subscription-management-actions">
                <div>
                  {!subscriptionSummary.isExpired && subscriptionSummary.labArchivesSubscriptionId && (
                    <Button id={"add-seats-managed-button"} onClick={() => setIsAddingUsersAccountSubscription(true)}>
                      <FormattedMessage id="add.users" />
                    </Button>
                  )}
                  {!subscriptionSummary.isExpired && !subscriptionSummary.labArchivesSubscriptionId && (
                    <Button id={"add-seats-inventory-button"} onClick={() => setIsAddingUsers(true)}>
                      <FormattedMessage id="add.users" />
                    </Button>
                  )}
                </div>
                {subscriptionSummary.canBeRenewed && (
                  <div>
                    <Button id={"renew-subscription-button"} onClick={() => setIsRenewing(true)}>
                      <FormattedMessage id={"renew.subscription"} />
                    </Button>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <FormattedMessage id="billing.history" />
            </CardHeader>
            <CardBody>
              <Table>
                <caption className={"visually-hidden"}>
                  <FormattedMessage id="billing.history" />
                </caption>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="date" />
                    </th>
                    <th>
                      <FormattedMessage id="price" />
                    </th>
                    <th>
                      <FormattedMessage id="subscription.billing.type" />
                    </th>
                    <th>
                      <FormattedMessage id="description" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionSummary.billingHistory.map((h) => (
                    <tr key={h.transactionDate.getTime()}>
                      <td>
                        <FormattedDate value={h.transactionDate} />
                      </td>
                      <td>
                        {h.seatCount} <FormattedMessage id="users.pluralized" values={{ users: h.seatCount }} /> @ ${h.perSeatPrice} (${h.total})
                      </td>
                      <td>
                        <FormattedMessage id={getTypeId(h.action)} />
                      </td>
                      <td>{h.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <AddSubscriptionUsersModal isOpen={isAddingUsers} toggleModal={() => setIsAddingUsers(false)} api={api} />

      <AddSubscriptionUsersAccountSubscriptionModal
        isOpen={isAddingUsersAccountSubscription}
        toggleModal={() => setIsAddingUsersAccountSubscription(false)}
        subscriptionId={subscriptionSummary.labArchivesSubscriptionId || ""}
        api={api}
      />

      <RenewSubscriptionModal
        isOpen={isRenewing}
        onCheckout={onCheckout}
        subscriptionSummary={subscriptionSummary}
        minimumNumberOfUsers={numberOfAccountUsers}
        toggleModal={() => setIsRenewing(false)}
        numberOfUsers={numberOfUsers}
        totalPrice={calculateTotal(numberOfUsers)}
        onNumberOfUsersChanged={onNumberOfUsersChanged}
        perUserPrice={currentPricing.oneYearCostPerUser}
        errorMessage={checkoutErrorMessage}
        api={api}
      />
    </div>
  );
};
