import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";

interface BundleCardProps {
  numberOfUsers: number;
  minimumNumberOfUsers: number;
  callToActionTextId: string;
  perUserPrice: number;
  totalPrice: number;
  className?: string;
  onNumberOfUsersChanged(users: string): void;
  onCheckout(): Promise<void>;
}

export function BundleCard(props: BundleCardProps): ReactElement {
  const { perUserPrice, numberOfUsers, totalPrice, minimumNumberOfUsers, callToActionTextId, onCheckout, onNumberOfUsersChanged, className } = props;

  // NK 2023-11-02 temporary hack for https://trello.com/c/vHkTt93D/674-666-674-ui-verbiage-to-be-updated-on-bundle-pricing-on-purchase-page
  // this should be removed 2024-01-05
  const currentDate = new Date();
  const nov162023 = new Date(2023, 10, 16);
  const jan042024 = new Date(2024, 0, 4);
  const showSpecialPriceMessage = currentDate >= nov162023 && currentDate <= jan042024;

  return (
    <div className={`subscription-pricing ${className || ""}`}>
      <div className="subscription-pricing-term">LabArchives ELN + Inventory</div>
      <div className={"subscription-pricing-details"}>
        <span className={"subscription-pricing-price-dollar"}>$</span>
        <span className="subscription-pricing-price">{perUserPrice}</span>
        {showSpecialPriceMessage && <span className={"subscription-special-price-message"}>Special price through Jan 3, 2024</span>}
        <span className="subscription-pricing-expected-cost">
          ${totalPrice} <FormattedMessage id="per.year.for.users" values={{ users: numberOfUsers }} />
        </span>
        <div className="subscription-subscribe-now">
          <FormGroup className={"mb-4"}>
            <Input
              className={"me-2"}
              type={"number"}
              value={numberOfUsers}
              min={minimumNumberOfUsers}
              onChange={(e) => onNumberOfUsersChanged(e.target.value)}
              id={"subscription-bundle-user-count"}
            />
            <Label className={"align-self-end"} htmlFor={"subscription-bundle-user-count"}>
              {numberOfUsers === 1 && <FormattedMessage id={"user"} />}
              {numberOfUsers > 1 && <FormattedMessage id={"users"} />}
            </Label>
          </FormGroup>

          <Button id={"subscription-bundle-purchase-btn"} color={"primary"} onClick={() => onCheckout()}>
            <FormattedMessage id={callToActionTextId} />
          </Button>
        </div>
        <div className={"subscription-features"}>
          <div>
            <FormattedMessage id={"includes"} />:
          </div>
          <ul>
            <li>
              <FormattedMessage id={"all.inventory.features"} />
            </li>
            <li>
              <FormattedMessage id={"link.reagents.to.experiment.protocols"} />
            </li>
            <li>
              <FormattedMessage id={"automatically.debit.inventory.quantities"} />
            </li>
          </ul>
          <div>
            <FormattedMessage id={"plus.eln.specific.features"} />:
          </div>
          <ul>
            <li>
              <FormattedMessage id={"100gb.per.user.data.storage"} />
            </li>
            <li>
              <FormattedMessage id={"expanded.files.size.limit.250mb"} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
