/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from "react";
import { FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design";
import { FormattedMessage, useIntl } from "react-intl";
import Menu, { MenuItem, SubMenu } from "rc-menu";
import { FreezerBoxDisplayFormat, StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faPencilAlt } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { EditableStorageCells } from "./EditableStorageCells";
import { StorageLocationTreeView } from "./types/views";
import { FormattedFreezerBoxCells } from "./FormattedFreezerBoxCells";
import { EditableStorageProps, useEditableStorage } from "./EditableStorageHooks";
import { getIsFreezerBox } from "./selectors";
import "rc-menu/assets/index.css";
import "./EditableStorage.scss";

export function EditableStorage(props: EditableStorageProps): ReactElement {
  const intl = useIntl();
  const idPrefix = props.idPrefix ? `${props.idPrefix}-` : "";
  const {
    isUpdating,
    selectedLocation,
    matrixRows,
    matrixCols,
    occupiedFreezerBoxCells,
    onStorageLocationSelected,
    onSelectedCellsChanged,
    onNotesChanged,
    onClearLocation,
    onUpdateStorageLocation,
    onCancelStorageUpdate,
    hideStorageUpdate,
    showStorageUpdate,
    isCollapsed,
    onExpand,
  } = useEditableStorage(props);

  function buildSubMenu(tree: StorageLocationTreeView[]): ReactElement | ReactElement[] {
    return tree.map((view) => {
      if (view.children.length > 0) {
        return (
          <SubMenu key={view.location.id} title={view.location.name}>
            {buildSubMenu(view.children)}
          </SubMenu>
        );
      }
      return (
        <MenuItem key={view.location.id} title={view.location.name}>
          {view.location.name}
        </MenuItem>
      );
    });
  }

  function buildStorageMenu(): ReactElement {
    const selected: string = selectedLocation.id ? selectedLocation.id.toString() : "";
    return (
      <Menu
        mode="inline"
        onSelect={(menuObject: any) => onStorageLocationSelected(Number.parseInt(menuObject.key))}
        defaultSelectedKeys={[selected]}
        selectedKeys={[selected]}
        defaultOpenKeys={props.storageLocations.getKeysInTree(selectedLocation.id)}
      >
        {buildSubMenu(props.storageLocations.asTree())}
      </Menu>
    );
  }

  function showFreezerBoxLink(location: StorageLocation | null): ReactElement {
    if (getIsFreezerBox(location)) {
      return (
        <Link
          to={ApplicationPaths.Storage.Browse(location!.id)}
          title={intl.formatMessage({ id: "browse.freezer.box" })}
          className={"freezer-box-link"}
        >
          {" "}
          <FontAwesomeIcon icon={faExternalLink} />
        </Link>
      );
    }

    return <></>;
  }

  const currentLocation = props.storageLocations.getSelectedLocation(selectedLocation.id);
  const displayFormat = currentLocation ? currentLocation.freezerBoxDisplayFormat : FreezerBoxDisplayFormat.Default;
  const rows = currentLocation ? currentLocation.numberOfRows : 0;
  const cols = currentLocation ? currentLocation.numberOfColumns : 0;

  let currentStorageCells = <></>;
  if (selectedLocation.cells.length > 0) {
    currentStorageCells = (
      <div id={`${idPrefix}storage-location-cells`} className={"storage-location-cells"}>
        <FormattedFreezerBoxCells cells={selectedLocation.cells} format={displayFormat} rows={rows} cols={cols} />
      </div>
    );
  }

  if (!isUpdating) {
    const storageLocation = (
      <>
        {showFreezerBoxLink(currentLocation)}{" "}
        <Button
          color="link"
          id={`${idPrefix}show-storage-update-trigger`}
          onClick={() => showStorageUpdate()}
          title={intl.formatMessage({ id: "choose.storage.location" })}
          className={"show-storage-update-trigger"}
        >
          {props.storageLocations.getFormattedDescription(selectedLocation.id)}
        </Button>
      </>
    );

    return (
      <div id={`${idPrefix}storage-location-description`} className={"storage-location-description"}>
        {storageLocation} {currentStorageCells}
        <div id={`${idPrefix}storage-location-notes-display`} className={"storage-location-notes-display"}>
          {selectedLocation.notes}
        </div>
      </div>
    );
  }

  let cellMatrix = <></>;
  if (matrixCols > 0 && matrixRows > 0) {
    cellMatrix = (
      <EditableStorageCells
        matrixCols={matrixCols}
        matrixRows={matrixRows}
        selectedCells={selectedLocation.cells}
        onSelectedCellsChange={onSelectedCellsChanged}
        displayFormat={displayFormat}
        occupiedCells={occupiedFreezerBoxCells}
        idPrefix={idPrefix}
      />
    );
  }

  return (
    <div>
      <div id={`${idPrefix}storage-location-description`} className={"storage-location-description"}>
        {props.storageLocations.getFormattedDescription(selectedLocation.id)} {currentStorageCells}
        <div id={`${idPrefix}storage-location-notes-display`} className={"storage-location-notes-display"}>
          {selectedLocation.notes}
        </div>
      </div>

      <Modal isOpen={isUpdating} toggle={() => hideStorageUpdate()} scrollable id={`${idPrefix}storage-location-modal`}>
        <ModalHeader toggle={() => hideStorageUpdate()}>
          <FormattedMessage id="location" />
        </ModalHeader>
        <ModalBody className="horizontal-scrollable-modal">
          <div>
            <div>
              {isCollapsed && (
                <Button
                  color="link"
                  onClick={onExpand}
                  id={`${idPrefix}storage-location-expand-button`}
                  title={intl.formatMessage({ id: "choose.storage.location" })}
                >
                  {props.storageLocations.getFormattedDescription(selectedLocation.id)} <FontAwesomeIcon icon={faPencilAlt} size={"lg"} />
                </Button>
              )}
              {!isCollapsed && props.storageLocations.getFormattedDescription(selectedLocation.id)}
            </div>
            <div id={`${idPrefix}storage-location-dropdowns`}>
              {!isCollapsed && buildStorageMenu()}
              {cellMatrix}
              <FormGroup>
                <Label for="storage-location-notes" className="label-secondary">
                  <FormattedMessage id="storage.notes" />
                </Label>
                <Input
                  type="textarea"
                  id={`${idPrefix}storage-location-notes`}
                  value={selectedLocation.notes}
                  onChange={(e) => onNotesChanged(e.target.value)}
                />
              </FormGroup>
              <Button
                color="link"
                id={`${idPrefix}update-storage-clear-trigger`}
                className={"update-storage-clear-trigger"}
                title={intl.formatMessage({ id: "clear.location" })}
                onClick={onClearLocation}
                disabled={props.isRequired}
              >
                <FormattedMessage id="clear.location" />
              </Button>
              <div className={"required-field-indicator"}>
                <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "location" }) }} />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            id={`${idPrefix}update-storage-location-trigger`}
            className={"update-storage-location-trigger"}
            onClick={onUpdateStorageLocation}
            color="primary"
          >
            <FormattedMessage id="ok" />
          </Button>
          <CancelButton
            id={`${idPrefix}cancel-storage-location-update-trigger`}
            className={"cancel-storage-location-update-trigger"}
            onClick={onCancelStorageUpdate}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
