import { useState } from "react";
import { RolePermissions, SubscriptionType, User } from "@labarchives/inventory-shared/build/inventory";
import { AuthenticationState } from "../Authentication/AuthenticationState";
import { AppState } from "../../app/AppContext";

export interface NavBarHooksProps {
  authState: AuthenticationState;
  appState: AppState;
}

export interface NavBarHooks {
  canViewSignup: boolean;
  canViewInventoryAdmin: boolean;
  canSwitchAccounts: boolean;
  isLabSwitchShown: boolean;
  isDrawerOpen: boolean;
  isAuthenticated: boolean;
  canViewSubscriptionManagement: boolean;
  canViewSuperUser: boolean;
  canAddLabs: boolean;
  isAddLabShown: boolean;
  isWarningModalShown: boolean;
  warningModalHref: string;
  warningModalTarget: string;
  userFullName: string;
  user: User | undefined;

  hideDrawer(): void;

  onDrawerToggled(): void;

  onLabSwitchShown(): void;

  onLabSwitchHidden(): void;

  onLabSwitched(accountId: number): void;

  onAdminViewActivated(): void;

  onInventoryViewActivated(): void;

  onAddLabShown(): void;

  onAddLabHidden(): void;

  onLabAdded(newLabName: string): Promise<void>;
  onWarningModalShown(href: string, target: string): void;
  onWarningModalClosed(): void;
}

export function useNavBar(props: NavBarHooksProps): NavBarHooks {
  const user = props.authState.getUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLabSwitchShown, setIsLabSwitchShown] = useState(false);
  const [isAddLabShown, setIsAddLabShown] = useState(false);
  const [isWarningModalShown, setIsWarningModalShown] = useState(false);
  const [warningModalHref, setWarningModalHref] = useState<string>("");
  const [warningModalTarget, setWarningModalTarget] = useState<string>("");

  function canViewSignup(): boolean {
    return (
      user !== undefined &&
      user.isAccountOwner &&
      user.activeAccount.subscriptionManagementAllowed &&
      user.activeAccount.subscriptionType === SubscriptionType.Free
    );
  }

  function canViewSubscriptionManagement(): boolean {
    return user !== undefined && user.isAccountOwner && user.activeAccount.subscriptionManagementAllowed;
  }

  function canViewInventoryAdmin(): boolean {
    return user !== undefined && user.isSiteAdmin;
  }

  function canViewSuperUser(): boolean {
    return user !== undefined && user.isSuperUser;
  }

  function canSwitchAccounts(): boolean {
    return user !== undefined && user.accounts.length > 1;
  }

  function canAddLabs(): boolean {
    return user !== undefined && user.permissions.some((p) => p.permissionId === RolePermissions.LabManagementAllowed) && user.isSite;
  }

  function onDrawerToggled(): void {
    setIsDrawerOpen(!isDrawerOpen);
  }

  function hideDrawer(): void {
    setIsDrawerOpen(false);
  }

  function onLabSwitchShown(): void {
    setIsLabSwitchShown(true);
    setIsDrawerOpen(false);
  }

  function onLabSwitchHidden(): void {
    setIsLabSwitchShown(false);
  }

  function onLabSwitched(accountId: number): void {
    props.authState.switchAccount(accountId).then(() => setIsLabSwitchShown(false)); // let the global error handler catch it
  }

  async function onLabAdded(newLabName: string): Promise<void> {
    await props.authState.addAccount(newLabName.trim());
    onLabSwitchHidden();
  }

  function onAdminViewActivated(): void {
    return props.appState.toggleAdminView(true);
  }

  function onInventoryViewActivated(): void {
    return props.appState.toggleAdminView(false);
  }

  function onAddLabShown(): void {
    setIsAddLabShown(true);
  }

  function onAddLabHidden(): void {
    setIsAddLabShown(false);
  }

  function onWarningModalShown(href: string, target: string): void {
    setWarningModalHref(href);
    setWarningModalTarget(target);
    setIsWarningModalShown(true);
  }

  function onWarningModalClosed(): void {
    setWarningModalHref("");
    setWarningModalTarget("");
    setIsWarningModalShown(false);
  }

  return {
    canViewSignup: canViewSignup(),
    canViewInventoryAdmin: canViewInventoryAdmin(),
    canSwitchAccounts: canSwitchAccounts(),
    canViewSubscriptionManagement: canViewSubscriptionManagement(),
    canViewSuperUser: canViewSuperUser(),
    userFullName: user?.fullName || "",
    isDrawerOpen,
    isLabSwitchShown,
    isAddLabShown,
    isWarningModalShown,
    warningModalHref,
    warningModalTarget,
    isAuthenticated: props.authState.isAuthenticated(),
    canAddLabs: canAddLabs(),
    user,
    hideDrawer,
    onDrawerToggled,
    onLabSwitchShown,
    onLabSwitchHidden,
    onLabSwitched,
    onAdminViewActivated,
    onInventoryViewActivated,
    onAddLabShown,
    onAddLabHidden,
    onLabAdded,
    onWarningModalClosed,
    onWarningModalShown,
  };
}
