import { useEffect, useState } from "react";
import { SubscriptionUpgradeDetails } from "@labarchives/inventory-shared/build/subscription";
import { OrganizationBillingType } from "@labarchives/inventory-shared/build/inventory";
import { Settings } from "../../utils/Settings";
import { InventoryApi } from "../../api/InventoryApi";

export interface BundleUpgradeRenewalHooks {
  isLoading: boolean;
  currentBundlePricing: number;
  isBundleAllowed: boolean;
  bundleMinimumUsers: number;
  numberOfUsers: number;
  perYearTotal: number;
  productName: string;

  onBundleCheckout(): Promise<void>;
}

export interface BundleUpgradeRenewalHooksProps {
  numberOfUsers: number;
  api: InventoryApi;
}

export function useBundleUpgradeRenewal(props: BundleUpgradeRenewalHooksProps): BundleUpgradeRenewalHooks {
  const { numberOfUsers, api } = props;
  const bundleProductCode = Settings.getBundleProductCode();

  const defaultDetails: SubscriptionUpgradeDetails = {
    billingType: OrganizationBillingType.Academic,
    clientReferenceNumber: "",
    description: "",
    isSubscriptionAllowed: false,
    minimumSeatCount: 0,
    perSeatCost: 0,
    productCode: "",
    productName: "",
    purchaseUrl: "",
    seatCount: 0,
    subtotal: 0,
  };

  const [details, setDetails] = useState(defaultDetails);
  const [isLoading, setIsLoading] = useState(true);

  async function onBundleCheckout(): Promise<void> {
    try {
      const upgradeDetails = await api.getAccountServiceSubscriptionUpgradeDetails(numberOfUsers, bundleProductCode);
      window.location.href = upgradeDetails.purchaseUrl;
    } catch (error) {
      api.logError(error, "Getting checking out bundle upgrade");
      throw error;
    }
  }

  async function loadDetails(): Promise<void> {
    try {
      const upgradeDetails = await api.getAccountServiceSubscriptionUpgradeDetails(numberOfUsers, bundleProductCode);
      setDetails(upgradeDetails);
    } catch {
      setDetails({ ...defaultDetails, isSubscriptionAllowed: false });
    }

    setIsLoading(false);
  }

  useEffect(() => {
    loadDetails();
  }, []);

  return {
    isLoading,
    currentBundlePricing: details.perSeatCost,
    bundleMinimumUsers: details.minimumSeatCount,
    isBundleAllowed: details.isSubscriptionAllowed,
    numberOfUsers,
    onBundleCheckout,
    perYearTotal: details.perSeatCost * numberOfUsers,
    productName: details.productName,
  };
}
