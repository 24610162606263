import React, { Fragment, ReactElement, useContext } from "react";
import { Input, Label, Table } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { InventorySearchResultSetItem, InventorySortableColumn, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { Loading, ResultSetPagination } from "../../../components";
import { AuthorizedComponent } from "../../../components/Authentication/AuthorizedComponent";
import { SortableTableHeader } from "../../../components/SortableTableHeader/SortableTableHeader";
import { InventoryStorageChangedFunction } from "../../../storage/EditableStorageHooks";
import { isInventoryStorageLocationRequired } from "../../../inventorytypes/selectors";
import { InventoryTypesContext } from "../../../inventorytypes/InventoryTypesContext";
import { InventorySearchResultsRow } from "./InventorySearchResultsRow";
import { InventorySearchResultsProps, useInventorySearchResults } from "./InventorySearchResultsHooks";
import "./InventorySearchResults.scss";

export function InventorySearchResults(props: InventorySearchResultsProps): ReactElement {
  const { onPageChanged, onSortChanged } = useInventorySearchResults(props);
  const inventoryTypeState = useContext(InventoryTypesContext);
  const {
    columnOrder,
    isLoading,
    searchResults,
    isReorderDisabled,
    currency,
    storageLocations,
    isFreezerBoxDisabled,
    api,
    itemLink,
    isUsed,
    onUseNow,
    onInventoryStorageChanged,
  } = props;
  const intl = useIntl();

  function isStorageLocationRequired(item: InventorySearchResultSetItem): boolean {
    return isInventoryStorageLocationRequired(inventoryTypeState, item.typeId);
  }

  function getInventoryStorageChangedCallback(item: InventorySearchResultSetItem): undefined | InventoryStorageChangedFunction {
    if (!onInventoryStorageChanged) {
      return undefined;
    }

    return (locationId?: number | null, storageCells?: string[] | null, notes?: string | null) =>
      onInventoryStorageChanged(item, locationId, storageCells, notes);
  }

  const textMapping: { [col: number]: string } = {
    [InventorySortableColumn.Quantity]: "quantity",
    [InventorySortableColumn.Vendor]: "vendor",
    [InventorySortableColumn.Price]: "price",
    [InventorySortableColumn.LotNumber]: "lot.number",
    [InventorySortableColumn.GrantNumber]: "grant.number",
    [InventorySortableColumn.Expiration]: "expiration",
    [InventorySortableColumn.Description]: "description",
    [InventorySortableColumn.CatalogNumber]: "catalog.number",
    [InventorySortableColumn.PONumber]: "po.number",
    [InventorySortableColumn.DateReceived]: "date.received",
    [InventorySortableColumn.Type]: "type",
    [InventorySortableColumn.UseNow]: "use.now",
  };

  const sortFieldMapping: { [col: number]: string } = {
    [InventorySortableColumn.Quantity]: "quantity",
    [InventorySortableColumn.Vendor]: "vendor",
    [InventorySortableColumn.Price]: "price",
    [InventorySortableColumn.LotNumber]: "lotNumber",
    [InventorySortableColumn.GrantNumber]: "grantNumber",
    [InventorySortableColumn.Expiration]: "expiration",
    [InventorySortableColumn.Description]: "description",
    [InventorySortableColumn.CatalogNumber]: "catalogNumber",
    [InventorySortableColumn.PONumber]: "poNumber",
    [InventorySortableColumn.DateReceived]: "receivedDate",
    [InventorySortableColumn.Type]: "typeId",
  };

  if (isLoading) {
    return <Loading messageId="searching" />;
  }

  if (searchResults.items.length === 0) {
    return (
      <div id="inventory-search-results-no-results" aria-live="assertive" role="alert">
        <div>
          <FormattedMessage id="no.inventory.search.results" />
        </div>
        <div>
          <FormattedMessage id="clear.search.and.try.again" />
        </div>
      </div>
    );
  }

  function getHeaders(): ReactElement {
    return (
      <tr>
        <SortableTableHeader
          currentlySortedFieldName={searchResults.sortField}
          currentlySortedDirection={searchResults.sortDirection}
          onSortChanged={onSortChanged}
          fieldName={"name"}
          displayTextMessageId={"name"}
        />
        {columnOrder.map((col) => {
          if (col === InventorySortableColumn.Reorder) {
            if (isReorderDisabled === true) {
              return <Fragment key={col} />;
            }
            return (
              <AuthorizedComponent key={col} requiredPermissions={[RolePermissions.OrdersRequestItem]}>
                <th scope={"col"} aria-label={intl.formatMessage({ id: "request.reorder" })}>
                  &nbsp;
                </th>
              </AuthorizedComponent>
            );
          }

          if (col === InventorySortableColumn.UseNow) {
            return (
              <th scope={"col"} key={col} aria-label={intl.formatMessage({ id: "use.now" })}>
                &nbsp;
              </th>
            );
          }

          if (col === InventorySortableColumn.Location) {
            return (
              <th scope={"col"} key={col}>
                <FormattedMessage id="location" />
              </th>
            );
          }

          return (
            <SortableTableHeader
              currentlySortedFieldName={searchResults.sortField}
              currentlySortedDirection={searchResults.sortDirection}
              onSortChanged={onSortChanged}
              fieldName={sortFieldMapping[col]}
              displayTextMessageId={textMapping[col]}
              key={col}
            />
          );
        })}
      </tr>
    );
  }

  const sortInput = React.createRef<HTMLInputElement>();

  return (
    <>
      <span aria-live="assertive" role="alert" className="visually-hidden">
        <FormattedMessage id="result.count" values={{ resultCount: searchResults.totalResultCount }} />
      </span>
      <div id="inventory-search-result-container" className="table-to-card" key={"inventory-search-result-container"}>
        <div className="sort-results-mobile">
          <Label for="sort-result-select" key={"sort.by"}>
            {intl.formatMessage({ id: "sort.by" })}
          </Label>
          <Input
            id="sort-result-select"
            type="select"
            bsSize="sm"
            innerRef={sortInput}
            onChange={() => onSortChanged(sortInput.current ? sortInput.current.value : "")}
            value={searchResults.sortField}
            key={"sort-result-select"}
          >
            <option value="name" key={"name"}>
              {intl.formatMessage({ id: "name" })}
            </option>
            {columnOrder.map((col) => {
              if (col !== InventorySortableColumn.Reorder && col !== InventorySortableColumn.Location) {
                return (
                  <option key={`column-${col}`} value={sortFieldMapping[col]}>
                    {intl.formatMessage({ id: textMapping[col] })}
                  </option>
                );
              }
              return <React.Fragment key={`col-undefined-${col}`} />;
            })}
          </Input>
        </div>

        <Table striped id={"inventory-search-results-table"}>
          <caption className={"visually-hidden"}>
            <FormattedMessage id="inventory.search.results" />
          </caption>
          <thead>{getHeaders()}</thead>
          <tbody>
            {searchResults.items.map((item) => {
              return (
                <InventorySearchResultsRow
                  key={item.id}
                  item={item}
                  storageLocations={storageLocations}
                  currency={currency}
                  columnOrder={columnOrder}
                  isReorderDisabled={isReorderDisabled}
                  isFreezerBoxDisabled={isFreezerBoxDisabled}
                  onUseNow={onUseNow}
                  itemLink={itemLink}
                  isUsed={isUsed(item)}
                  onInventoryStorageChanged={getInventoryStorageChangedCallback(item)}
                  api={api}
                  isStorageLocationRequired={isStorageLocationRequired(item)}
                />
              );
            })}
          </tbody>
        </Table>

        <ResultSetPagination
          totalResultCount={searchResults.totalResultCount}
          totalPageCount={searchResults.totalPageCount}
          currentPageNumber={searchResults.currentPageNumber}
          pageSize={searchResults.pageSize}
          onPageChanged={onPageChanged}
        />
      </div>
    </>
  );
}
