import { OrganizationBillingType } from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useState } from "react";
import { SubscriptionProduct, SubscriptionSuperUserAddSubscriptionDetailsResponseDto } from "@labarchives/inventory-shared/build/subscription";
import { Settings } from "../utils/Settings";
import { InventoryApi } from "../api/InventoryApi";
import { SuperUserAccountDetailsView } from "./views";

export interface SuperUserBundleSubscriptionHooks {
  isBundleAllowed: boolean;
  perSeatCost: number;
  minimumSeatCount: number;
  seatCount: number;
  isLoading: boolean;
  totalCharged: number;
  billingTypeId: OrganizationBillingType;
  expirationDate: Date;

  onSeatCountChanged(newSeatCount: number): void;
  onTotalChargeChanged(newTotalCharged: number): void;
  onSubscriptionUpdated(): Promise<void>;
  onOrganizationTypeChanged(newBillingType: OrganizationBillingType): void;
  onExpirationDateChanged(newExpiration: Date): void;
}

export function useSuperUserBundleSubscription(props: SuperUserBundleSubscriptionModalProps): SuperUserBundleSubscriptionHooks {
  const { accountDetails, isOpen, api } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [seatCount, setSeatCount] = useState(accountDetails.subscriptionSeatCount);
  const [totalCharged, setTotalCharged] = useState(0);
  const [billingTypeId, setBillingTypeId] = useState(accountDetails.billingType);
  const [expirationDate, setExpirationDate] = useState(accountDetails.subscriptionEnd);
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionSuperUserAddSubscriptionDetailsResponseDto | undefined>();
  const [productPricing, setProductPricing] = useState<SubscriptionProduct | undefined>();

  function getIsBundleAllowed(): boolean {
    return subscriptionDetails !== undefined;
  }

  function getMinimumSeatCount(): number {
    return subscriptionDetails?.minimumSeatCount || seatCount;
  }

  function getPerSeatCost(): number {
    let price = subscriptionDetails?.perSeatCost || 0;
    if (productPricing) {
      price = productPricing.pricing.find((p) => p.billingTypeId === billingTypeId)?.perSeatCost || 0;
    }

    return price;
  }

  function onSeatCountChanged(newSeatCount: number): void {
    if (subscriptionDetails && newSeatCount >= subscriptionDetails.minimumSeatCount) {
      setSeatCount(newSeatCount);
    }
  }

  function onTotalChargeChanged(newTotalCharged: number): void {
    setTotalCharged(newTotalCharged);
  }

  function onExpirationDateChanged(newExpiration: Date): void {
    setExpirationDate(newExpiration);
  }

  function onOrganizationTypeChanged(newBillingType: OrganizationBillingType): void {
    setBillingTypeId(newBillingType);
  }

  async function onSubscriptionUpdated(): Promise<void> {
    return props.onSubscriptionUpdated(seatCount, totalCharged, billingTypeId, expirationDate);
  }

  async function loadPricing(): Promise<void> {
    api.getAccountServiceSubscriptionProducts().then((response) => {
      setProductPricing(response.products.find((p) => p.productCode === Settings.getBundleProductCode()));
      return response;
    });
  }

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setIsLoading(true);
    api
      .getAccountServiceSuperUserAddSubscriptionDetails({
        billingType: accountDetails.billingType,
        ownerId: accountDetails.ownerId,
        productCode: Settings.getBundleProductCode(),
        seatCount,
      })
      .then((details) => {
        loadPricing();
        setTotalCharged(details.subtotal);
        setSeatCount(details.seatCount);
        setSubscriptionDetails(details);
        return details;
      })
      .catch((error) => {
        console.error(error);
        setSubscriptionDetails(undefined);
      });

    setIsLoading(false);
  }, [isOpen, accountDetails]);

  return {
    seatCount,
    isLoading,
    isBundleAllowed: getIsBundleAllowed(),
    minimumSeatCount: getMinimumSeatCount(),
    perSeatCost: getPerSeatCost(),
    totalCharged,
    billingTypeId,
    expirationDate,
    onSeatCountChanged,
    onSubscriptionUpdated,
    onTotalChargeChanged,
    onExpirationDateChanged,
    onOrganizationTypeChanged,
  };
}

export interface SuperUserBundleSubscriptionModalProps {
  isOpen: boolean;
  accountDetails: SuperUserAccountDetailsView;
  api: InventoryApi;

  onToggle(): void;

  onSubscriptionUpdated(seatCount: number, totalAmountCharged: number, billingType: OrganizationBillingType, expirationDate: Date): Promise<void>;
}
