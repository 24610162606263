import { useEffect, useState } from "react";
import { SubscriptionAdditionalUserDetails } from "@labarchives/inventory-shared/build/subscription";
import { SubscriptionTotalView } from "../views";
import { getSubscriptionTotalViewFromAdditionalUsers } from "../selectors";
import { InventoryApi } from "../../api/InventoryApi";

export interface AddAccountServiceSubscriptionUsersHooks {
  numberOfUsers: number;
  subscriptionTotal: SubscriptionTotalView;
  isPurchasing: boolean;

  setUsers(users: number): void;

  onCheckout(): Promise<void>;
}

interface AddAccountServiceSubscriptionUsersHooksProps {
  isOpen: boolean;
  subscriptionId: string;
  api: InventoryApi;
}

export function useAddSubscriptionUsersAccountService(props: AddAccountServiceSubscriptionUsersHooksProps): AddAccountServiceSubscriptionUsersHooks {
  const { subscriptionId, isOpen, api } = props;
  const [users, setUsers] = useState(1);
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionAdditionalUserDetails | undefined>();
  const [isPurchasing, setIsPurchasing] = useState(false);

  async function onCheckout(): Promise<void> {
    try {
      setIsPurchasing(true);
      const details = await api.getAccountServiceSubscriptionAdditionalUsersDetails(users, subscriptionId);

      window.location.href = details.purchaseUrl;
    } catch (error) {
      api.logError(error, "Getting checking out bundle additional seats");
      throw error;
    }
  }

  useEffect(() => {
    if (isOpen) {
      api.getAccountServiceSubscriptionAdditionalUsersDetails(users, subscriptionId).then((tot) => setSubscriptionDetails(tot));
    }
  }, [users, isOpen]);

  return {
    subscriptionTotal: getSubscriptionTotalViewFromAdditionalUsers(subscriptionDetails),
    numberOfUsers: users,
    isPurchasing,
    setUsers,
    onCheckout,
  };
}
