import React, { ReactElement, useContext, useEffect } from "react";
import { Alert, Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Settings } from "../../utils/Settings";
import { AuthenticationContext } from "./AuthenticationContext";
import { AuthenticationState } from "./AuthenticationState";

export function useLogout(authenticationState: AuthenticationState): unknown {
  useEffect(() => {
    if (authenticationState.isAuthenticated()) {
      authenticationState.logout();
    }
  }, [authenticationState]);

  return {};
}

export function Logout(): ReactElement {
  useLogout(useContext(AuthenticationContext));

  return (
    <Row>
      <Col xs={12} sm={{ size: 4, offset: 4 }}>
        <Alert>
          <div>
            <FormattedMessage id="you.have.been.signed.out" />
          </div>
          <div>
            <a href={`${Settings.getAccountUrlBase()}/login`}>
              <FormattedMessage id="sign.in" />
            </a>
          </div>
        </Alert>
      </Col>
    </Row>
  );
}
