import React, { ReactElement } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Button } from "@labarchives/ui-design/dist/components/Button";
import { AlertWithIcon } from "@labarchives/ui-design";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { Loading } from "../../components";
import { isEnterKey } from "../../utils/isEnterKey";
import "./CasSearchInput.scss";
import { CasSearchInputProps, useCasSearchInput } from "./CasSearchInputHooks";

export const CasSearchInput = (props: CasSearchInputProps): ReactElement => {
  const { definition, inputId, isValid, fieldName, innerRef, isSearchEnabled } = props;
  const { defaultValue, searchStatus, casOnChanged, onCasSearched } = useCasSearchInput(props);

  return (
    <FormGroup id={`inventory-item-display-custom${definition.id}`} className="custom-field">
      <Label for={inputId}>{definition.label}</Label>
      <RequiredLabelIndicator required={definition.required} />
      <div className="casNumberLookup">
        <Input
          name={fieldName}
          type="text"
          id={inputId}
          value={defaultValue}
          required={definition.required}
          invalid={!isValid}
          onChange={(e) => casOnChanged(fieldName, e)}
          innerRef={innerRef}
          maxLength={255}
          autoComplete="off"
          onKeyDown={(e) => {
            if (isEnterKey(e.key)) {
              e.preventDefault();
              onCasSearched();
            }
          }}
        />
        {isSearchEnabled && (
          <Button type="button" outline className="ms-1" onClick={() => onCasSearched()}>
            <FormattedMessage id="cas.lookup" />
          </Button>
        )}
      </div>
      <AlertWithIcon color="error" className="mt-1" isOpen={searchStatus.failed}>
        <FormattedMessage id="cas.lookup.failed" />
      </AlertWithIcon>
      {searchStatus.searching && <Loading messageId={"searching"} />}
      <FormFeedback>
        <FormattedMessage id="generic.is.required" values={{ subject: definition.label }} />
      </FormFeedback>
    </FormGroup>
  );
};
