export enum InventorySortableColumn {
  Location,
  Type,
  Price,
  Quantity,
  DateReceived,
  Reorder,
  Description,
  Vendor,
  CatalogNumber,
  LotNumber,
  GrantNumber,
  PONumber,
  Expiration,
  UseNow = 13,
}

export const InventorySortableColumnDefault = [
  InventorySortableColumn.Location,
  InventorySortableColumn.Type,
  InventorySortableColumn.Price,
  InventorySortableColumn.Quantity,
  InventorySortableColumn.DateReceived,
  InventorySortableColumn.UseNow,
  InventorySortableColumn.Reorder,
];
