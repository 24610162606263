import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Input, Modal } from "@labarchives/ui-design";
import { OrderStatus } from "@labarchives/inventory-shared/build/inventory";
import { CancelButton } from "../../components/CancelButton/CancelButton";

export interface OrderBulkUpdateModalProps {
  isOpen: boolean;
  actionStatus: OrderStatus | undefined;
  orderIds: number[];

  onCancel(): void;
  onBulkOrderApproved(orderIds: number[], notes: string): Promise<void>;
  onBulkOrderOrdered(orderIds: number[], notes: string): Promise<void>;
}

export function OrderBulkUpdateModal(props: OrderBulkUpdateModalProps): ReactElement {
  const { isOpen, orderIds, actionStatus, onCancel, onBulkOrderApproved, onBulkOrderOrdered } = props;
  const [notes, setNotes] = useState("");

  useEffect(() => {
    setNotes("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={onCancel} className="order-modal-modal">
      <ModalHeader toggle={onCancel}>
        <FormattedMessage id="update.orders" /> ({orderIds.length})
      </ModalHeader>
      <ModalBody>
        <Label for="order-bulk-modal-notes">
          <FormattedMessage id="add.notes" />
        </Label>
        <Input id={"order-bulk-modal-notes"} type={"textarea"} onChange={(e) => setNotes(e.target.value)} value={notes} autoFocus />
      </ModalBody>
      <ModalFooter>
        {actionStatus === OrderStatus.Requested && (
          <Button color="primary" onClick={() => onBulkOrderApproved(orderIds, notes.trim())} className="me-1" id="order-bulk-modal-approve-button">
            <FormattedMessage id={"approve.all"} />
          </Button>
        )}
        {actionStatus === OrderStatus.Approved && (
          <Button color="primary" onClick={() => onBulkOrderOrdered(orderIds, notes.trim())} className="me-1" id="order-bulk-modal-order-button">
            <FormattedMessage id={"mark.all.as.ordered"} />
          </Button>
        )}
        <CancelButton onClick={onCancel} />
      </ModalFooter>
    </Modal>
  );
}
