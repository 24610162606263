import { useState } from "react";
import { Role, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { getRequiredPermissions } from "../selectors";
import { arrayUnique } from "../../utils";

export interface AddRoleHooks {
  permissions: RolePermissions[];
  isInvalid: boolean;

  onRoleAdded(): void;

  onNameUpdated(name: string): void;

  onPermissionChanged(permission: RolePermissions, checked: boolean): void;
}

export interface AddRoleProps {
  onRoleAdded(role: Role): void;

  isRoleValid(role: Role): boolean;

  onCancel(): void;
}

export function useAddRole(props: AddRoleProps): AddRoleHooks {
  const [roleName, setRoleName] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [permissions, setPermissions] = useState([RolePermissions.SearchViewAllItems]);

  function getAllPermissions(): RolePermissions[] {
    return arrayUnique([...permissions, ...getRequiredPermissions(permissions)]);
  }

  function getRole(): Role {
    return {
      id: 0,
      name: roleName.trim(),
      permissions: getAllPermissions().map((p) => {
        return {
          permissionId: p,
        };
      }),
    };
  }

  const onRoleAdded = (): void => {
    setIsInvalid(false);
    const role = getRole();
    if (props.isRoleValid(role)) {
      props.onRoleAdded(role);
    } else {
      setIsInvalid(true);
    }
  };

  const onNameUpdated = (name: string): void => {
    const newName = name.trim();
    setRoleName(newName);

    const role = getRole();
    role.name = newName;
    setIsInvalid(!props.isRoleValid(role));
  };

  const onPermissionChanged = (permission: RolePermissions, checked: boolean): void => {
    if (checked) {
      setPermissions([...permissions, permission]);
    } else {
      setPermissions(permissions.filter((p) => p !== permission));
    }
  };

  return {
    isInvalid,
    permissions: getAllPermissions(),
    onRoleAdded,
    onNameUpdated,
    onPermissionChanged,
  };
}
