import React, { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faCopy, faPlus } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Loading } from "../../components";
import { isEnterKey } from "../../utils/isEnterKey";
import { FreezerBoxCellBaseProps } from "./FreezerBoxCellBaseProps";

export interface FreezerBoxCellEmptyProps extends FreezerBoxCellBaseProps {
  canAddItems: boolean;
  cell: string;
  locationId: number;
  isMoveActive: boolean;
  isCopyActive: boolean;

  onDropped(cell: string): Promise<void>;

  onMoveConfirmed(cell: string): Promise<void>;

  onCopyConfirmed(cell: string): Promise<void>;
}

export function FreezerBoxCellEmpty(props: FreezerBoxCellEmptyProps): ReactElement {
  const { label, canAddItems, locationId, cell, tabIndex, isMoveActive, isCopyActive, onDropped, onMoveConfirmed, onCopyConfirmed } = props;
  const intl = useIntl();

  const [dropState, setDropState] = useState<"none" | "droppable" | "dropping">("none");

  function getHeader(): ReactElement {
    return (
      <div className={"inventory-freezer-box-cell-header"}>
        <div className={"inventory-freezer-box-cell-label"}>{label}</div>
      </div>
    );
  }

  if (isMoveActive) {
    return (
      <div
        className={`inventory-freezer-box-cell empty`}
        aria-label={intl.formatMessage({ id: "freezerbox.cell.title" }, { label })}
        tabIndex={tabIndex}
        role="button"
        data-cell-name={label}
        onClick={() => {
          setDropState("dropping");
          onMoveConfirmed(cell);
        }}
        onKeyDown={(e) => {
          if (isEnterKey(e.key)) {
            setDropState("dropping");
            onMoveConfirmed(cell);
          }
        }}
        title={intl.formatMessage({ id: "freezerbox.cell.move" }, { label })}
      >
        {getHeader()}
        <div className={"inventory-freezer-box-cell-empty"}>
          {dropState === "dropping" && <Loading hideMessage={true} messageId={""} />}
          {dropState !== "dropping" && <FontAwesomeIcon icon={faArrowCircleDown} />}
        </div>
      </div>
    );
  }

  if (isCopyActive) {
    return (
      <div
        className={`inventory-freezer-box-cell empty`}
        aria-label={intl.formatMessage({ id: "freezerbox.cell.title" }, { label })}
        tabIndex={tabIndex}
        role="button"
        data-cell-name={label}
        onClick={() => {
          setDropState("dropping");
          onCopyConfirmed(cell);
        }}
        onKeyDown={(e) => {
          if (isEnterKey(e.key)) {
            setDropState("dropping");
            onCopyConfirmed(cell);
          }
        }}
        title={intl.formatMessage({ id: "freezerbox.cell.copy" }, { label })}
      >
        {getHeader()}
        <div className={"inventory-freezer-box-cell-empty"}>
          {dropState === "dropping" && <Loading hideMessage={true} messageId={""} />}
          {dropState !== "dropping" && <FontAwesomeIcon icon={faCopy} />}
        </div>
      </div>
    );
  }

  if (canAddItems) {
    return (
      <Link
        to={`/inventory/add?location=${locationId}&cell=${cell}`}
        className={`inventory-freezer-box-cell empty ${dropState === "none" ? "" : "drag-over"}`}
        aria-label={intl.formatMessage({ id: "freezerbox.cell.title" }, { label })}
        tabIndex={tabIndex}
        title={intl.formatMessage({ id: "add.to.freezerbox.cell" }, { label })}
        data-cell-name={label}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.dataTransfer.dropEffect = "move";
          setDropState("droppable");
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDropState("none");
        }}
        onDrop={(e) => {
          e.preventDefault();
          setDropState("dropping");
          onDropped(cell);
        }}
        draggable={false}
      >
        <>
          {getHeader()}
          <div className={"inventory-freezer-box-cell-empty"}>
            {dropState === "dropping" && <Loading hideMessage={true} messageId={""} />}
            {dropState === "droppable" && <FontAwesomeIcon icon={faArrowCircleDown} />}
            {dropState === "none" && <FontAwesomeIcon icon={faPlus} />}
          </div>
        </>
      </Link>
    );
  }

  return (
    <div
      className={`inventory-freezer-box-cell empty no-add`}
      aria-label={intl.formatMessage({ id: "freezerbox.cell.title" }, { label })}
      tabIndex={tabIndex}
      role="cell"
      data-cell-name={label}
    >
      {getHeader()}
    </div>
  );
}
