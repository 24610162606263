import { useState, useEffect, createContext } from "react";
import { InventoryType, CustomAttribute } from "@labarchives/inventory-shared/build/inventory";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { createReduced, addIfNotExists } from "../utils";
import { NormalizedState } from "../utils/types";
import { Settings } from "../utils/Settings";
import { isValidationError } from "../utils/errorHelpers";
import { InventoryApi } from "../api/InventoryApi";
import { InventoryTypesState } from "./types/state";

const normalizedState: NormalizedState<InventoryType> = {
  isLoading: true,
  byId: createReduced<InventoryType>([]),
  allIds: [],
};

const defaultInventoryTypesState: InventoryTypesState = {
  ...normalizedState,
  onTypeAdded: (): Promise<InventoryType> =>
    Promise.resolve<InventoryType>({ id: 0, name: "", customAttributes: [], isDefault: false, isLocked: false, color: null }),
  onTypeUpdated: (): Promise<InventoryType> =>
    Promise.resolve<InventoryType>({ id: 0, name: "", customAttributes: [], isDefault: false, isLocked: false, color: null }),
  onTypeDeleted: (): Promise<boolean> => Promise.resolve(true),
  onAttributeAdded: (): Promise<InventoryType> =>
    Promise.resolve<InventoryType>({ id: 0, name: "", customAttributes: [], isDefault: false, isLocked: false, color: null }),
  refresh: () => Promise.resolve(),
};

export const InventoryTypesContext = createContext<InventoryTypesState>(defaultInventoryTypesState);

export function useInventoryTypesContext(api: InventoryApi): InventoryTypesState {
  const [localState, setLocalState] = useState<NormalizedState<InventoryType>>(normalizedState);
  const [lastDataRefreshTime, setLastDataRefreshTime] = useState<Date>(new Date(0));

  const getNewState = (inventoryType: InventoryType): NormalizedState<InventoryType> => {
    return {
      allIds: addIfNotExists(localState.allIds, inventoryType.id),
      byId: { ...localState.byId, [inventoryType.id]: inventoryType },
      isLoading: false,
    };
  };

  const loadAllTypes = async (): Promise<void> => {
    setLocalState({ ...normalizedState, isLoading: true });
    await api.getInventoryTypes().then((invTypes) => {
      const newState: NormalizedState<InventoryType> = {
        allIds: invTypes.map((invType) => invType.id),
        byId: createReduced<InventoryType>(invTypes),
        isLoading: false,
      };
      setLocalState(newState);
      setLastDataRefreshTime(clock.getNow());
      return invTypes;
    });
  };

  const onTypeAdded = (invType: InventoryType): Promise<InventoryType> => {
    return api
      .addInventoryType(invType)
      .then((addedInvType) => {
        const newState = getNewState(addedInvType);
        setLocalState(newState);
        return addedInvType;
      })
      .catch((error) => {
        loadAllTypes();
        throw error;
      });
  };

  const onTypeUpdated = (invType: InventoryType): Promise<InventoryType> => {
    return api
      .updateInventoryType(invType)
      .then((updatedInvType) => {
        const newState = getNewState(updatedInvType);
        setLocalState(newState);
        return updatedInvType;
      })
      .catch((error) => {
        loadAllTypes();
        throw error;
      });
  };

  const onTypeDeleted = async (invType: InventoryType): Promise<boolean> => {
    return api
      .deleteInventoryType(invType)
      .then(() => {
        const newById = { ...localState.byId };
        delete newById[invType.id];
        const newState: NormalizedState<InventoryType> = {
          allIds: localState.allIds.filter((id) => id !== invType.id),
          byId: newById,
          isLoading: false,
        };
        setLocalState(newState);
        return true;
      })
      .catch((error) => {
        if (isValidationError(error)) {
          return false;
        }

        loadAllTypes();
        throw error;
      });
  };

  const onAttributeAdded = (inventoryTypeId: number, attribute: CustomAttribute): Promise<InventoryType> => {
    return api
      .addInventoryTypeAttribute(inventoryTypeId, attribute)
      .then((addedInvType) => {
        const newState = getNewState(addedInvType);
        setLocalState(newState);
        return addedInvType;
      })
      .catch((error) => {
        loadAllTypes();
        throw error;
      });
  };

  const refresh = async (force = false): Promise<void> => {
    if (force || (!localState.isLoading && clock.addMinutes(lastDataRefreshTime, Settings.getDataCacheTimeout()) < clock.getNow())) {
      await loadAllTypes();
    }
  };

  useEffect(() => {
    loadAllTypes();
  }, []);

  return {
    isLoading: localState.isLoading,
    byId: localState.byId,
    allIds: localState.allIds,
    onTypeAdded,
    onTypeUpdated,
    onTypeDeleted,
    onAttributeAdded,
    refresh,
  };
}
