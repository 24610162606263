import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "@labarchives/ui-design";
import { Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CancelButton } from "../CancelButton/CancelButton";
import { RequiredLabelIndicator } from "../RequiredLabelIndicator/RequiredLabelIndicator";

interface AddLabModalProps {
  isOpen: boolean;
  defaultLabName: string;

  onClose(): void;

  onLabAdded(labName: string): Promise<void>;
}

export function AddLabModal(props: AddLabModalProps): ReactElement {
  const [newLabName, setNewLabName] = useState(props.defaultLabName);

  function onLabAdded(): void {
    props.onLabAdded(newLabName.trim());
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onClose} id={"add-lab-modal"}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onLabAdded();
        }}
      >
        <ModalHeader toggle={props.onClose}>
          <FormattedMessage id="add.lab.modal.header" />
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for={"new-lab-name"}>
              <FormattedMessage id={"lab.name"} />
              <RequiredLabelIndicator required={true} />
            </Label>
            <Input id={"new-lab-name"} type={"text"} autoFocus required value={newLabName} onChange={(e) => setNewLabName(e.target.value)} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CancelButton onClick={props.onClose} />
          <Button type={"submit"} color={"primary"} id={"create-lab-button"}>
            <FormattedMessage id={"confirm"} />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
