import { FormattedMessage } from "react-intl";
import React, { Fragment, ReactElement } from "react";
import { Badge, Button, Nav, NavItem } from "reactstrap";
import classnames from "classnames";
import { UserStatus } from "@labarchives/inventory-shared/build/inventory";
import { UserView } from "../types/views";

export interface ManageUsersPillsProps {
  activeStatus?: UserStatus;
  onFilterUsers(status: UserStatus | undefined): void;
  userViews: UserView[];
}

export function ManageUsersPills(props: ManageUsersPillsProps): ReactElement {
  if (props.userViews.length === props.userViews.filter((u) => u.status === UserStatus.Active).length) {
    return <Fragment />;
  }

  return (
    <Nav pills>
      <NavItem>
        <Button
          onClick={() => props.onFilterUsers(UserStatus.Active)}
          className={classnames({ active: props.activeStatus === UserStatus.Active }, "nav-link")}
        >
          <FormattedMessage id="active" /> <Badge>{props.userViews.filter((u) => u.status === UserStatus.Active).length}</Badge>
        </Button>
      </NavItem>
      <NavItem>
        <Button onClick={() => props.onFilterUsers(undefined)} className={classnames({ active: !props.activeStatus }, "nav-link")}>
          <FormattedMessage id="all.users" /> <Badge>{props.userViews.length}</Badge>
        </Button>
      </NavItem>
    </Nav>
  );
}
