import React, { ReactElement, useState } from "react";
import { BrowserQRCodeReader } from "@zxing/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import { faCamera } from "@fortawesome/pro-light-svg-icons";
import { Button } from "reactstrap";
import isUrl from "is-url";
import { AlertWithIcon } from "@labarchives/ui-design/dist";
import { history } from "../../app/history";
import { CancelButton } from "../CancelButton/CancelButton";
import "./BarcodeReader.scss";
import { Settings } from "../../utils/Settings";

// eslint-disable-next-line unicorn/prefer-module
require("webrtc-adapter"); // trying to maximize support for older devices

export interface BarcodeReaderProps {
  onItemRouted(itemId: number | string): void;
  logError(error: Error): Promise<void>;
}

export interface BarcodeReaderHooksProps extends BarcodeReaderProps {
  stopCodeReader(): void;
  readBarcode(): Promise<string>;
  logError(error: Error): Promise<void>;
}

export interface BarcodeReaderHooks {
  isCapturing: boolean;
  isError: boolean;

  onCapture(): Promise<void>;
  onCancelCapture(): void;
}

export function useBarcodeReader(props: BarcodeReaderHooksProps): BarcodeReaderHooks {
  const [isCapturing, setIsCapturing] = useState(false);
  const [isError, setIsError] = useState(false);

  function parseItemId(text: string): number {
    return Number.parseInt(text.split("item ")[1]);
  }

  function isRouting(text: string): boolean {
    return text.includes("item");
  }

  function isPossibleItemId(text: string): boolean {
    return new RegExp(/^[\dA-Z]{8,}$/).test(text);
  }

  function stopCodeReader(): void {
    try {
      props.stopCodeReader();
    } catch (error: any) {
      props.logError(error);
    }
  }

  function isInventoryUrl(text: string): boolean {
    return isUrl(text) && text.startsWith(Settings.getBaseInventoryWebUrl());
  }

  function onCancelCapture(): void {
    setIsCapturing(false);
    setIsError(false);

    stopCodeReader();
  }

  async function onCapture(): Promise<void> {
    setIsCapturing(true);
    setIsError(false);

    try {
      const result = await props.readBarcode();
      onCancelCapture();

      if (isInventoryUrl(result)) {
        history.push(result.replace(Settings.getBaseInventoryWebUrl(), ""));
      } else if (isRouting(result)) {
        const itemId = parseItemId(result);
        props.onItemRouted(itemId);
      } else if (isPossibleItemId(result)) {
        props.onItemRouted(result);
      }
    } catch (error: any) {
      if (isCapturing) {
        stopCodeReader();
        setIsCapturing(false);
        setIsError(true);
        props.logError(error);
      }
    }
  }

  return {
    isCapturing,
    isError,
    onCapture,
    onCancelCapture,
  };
}

export function BarcodeReader(props: BarcodeReaderProps): ReactElement {
  const codeReader = new BrowserQRCodeReader();
  const intl = useIntl();

  function stopCodeReader(): void {
    codeReader.stopAsyncDecode();
    codeReader.stopContinuousDecode();
    codeReader.reset();
  }

  async function readBarcode(): Promise<string> {
    const result = await codeReader.decodeFromInputVideoDevice(undefined, "video");

    return result.getText();
  }

  const { isCapturing, isError, onCapture, onCancelCapture } = useBarcodeReader({ ...props, readBarcode, stopCodeReader, logError: props.logError });

  return (
    <div>
      {!isCapturing && (
        <Button onClick={onCapture} color="link" title={intl.formatMessage({ id: "scan.qr.code" })}>
          <FontAwesomeIcon icon={faCamera} size="2x" />
        </Button>
      )}

      {isCapturing && (
        <div className="dimScreen">
          <video id="video">
            <track kind="captions" />
          </video>
          <div className="close-capture">
            <div>
              <FormattedMessage id="having.trouble.scanning" />
            </div>
            <CancelButton onClick={onCancelCapture} />
          </div>
        </div>
      )}

      {isError && (
        <div className="dimScreen">
          <div className="capture-error">
            <AlertWithIcon isOpen={isError} color="error">
              <FormattedMessage id="error.scanning" />
            </AlertWithIcon>
            <CancelButton onClick={onCancelCapture} />
          </div>
        </div>
      )}
    </div>
  );
}
