import { FormattedMessage } from "react-intl";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design";
import React, { ReactElement } from "react";
import { Loading } from "../../components";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import "./InventoryExportModal.css";

interface InventoryExportModalComponentProps {
  isOpen: boolean;
  isExporting: boolean;
  onExportCurrentResults(): Promise<void>;
  onExportAll(): Promise<void>;
  onCancel(): void;
}

export function InventoryExportModalComponent(props: InventoryExportModalComponentProps): ReactElement {
  return (
    <Modal isOpen={props.isOpen} className="inventory-export-modal" toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id="export.inventory.items" />
      </ModalHeader>
      <ModalBody>
        {props.isExporting && <Loading />}
        {!props.isExporting && (
          <div className="inventory-export-modal-buttons">
            <div>
              <Button outline size="sm" onClick={props.onExportCurrentResults} color="none" id={"inventory-export-modal-current"}>
                <FormattedMessage id="export.current.results" />
              </Button>
            </div>
            <div>
              <Button outline size="sm" onClick={props.onExportAll} color="none" id={"inventory-export-modal-all"}>
                <FormattedMessage id="export.all.inventory" />
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div>
          <CancelButton onClick={props.onCancel} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
