import React, { Fragment, ReactElement } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useIntl } from "react-intl";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { Modal } from "@labarchives/ui-design/dist";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { SuperUserUserAccountView } from "./views";

export interface SuperUserPermissionModalProps {
  isOpen: boolean;
  account: SuperUserUserAccountView | undefined;
  onToggle(): void;
}

export function SuperUserPermissionModal(props: SuperUserPermissionModalProps): ReactElement {
  const intl = useIntl();

  const permissionToString: Map<RolePermissions, string> = new Map([
    [RolePermissions.InventoryAddCreatedItem, "permission.inventory.add.created.item"],
    [RolePermissions.InventoryEditAllItems, "permission.inventory.edit.all.items"],
    [RolePermissions.SearchViewAllItems, "permission.search.view.all.items"],
    [RolePermissions.LabManagementAllowed, "permission.lab.management.allowed"],
    [RolePermissions.OrdersRequestItem, "permission.orders.request.item"],
    [RolePermissions.OrdersViewAll, "permission.orders.request.item"],
    [RolePermissions.OrdersUpdateAll, "permission.orders.update.all"],
    [RolePermissions.OrdersApproveAll, "permission.orders.approve.all"],
    [RolePermissions.OrdersCancelAll, "permission.orders.cancel.all"],
    [RolePermissions.OrdersCancelOwn, "permission.orders.cancel.own"],
    [RolePermissions.OrdersReceiveOwn, "permission.orders.receive.own"],
    [RolePermissions.OrdersReceiveAll, "permission.orders.receive.all"],
  ]);

  function getPermissionName(permission: RolePermissions): ReactElement {
    const id = permissionToString.get(permission);
    if (id) {
      return <li key={permission}>{intl.formatMessage({ id })}</li>;
    }

    return <Fragment key={permission} />;
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle}>
      <ModalHeader toggle={props.onToggle}>{props.account?.name} permissions</ModalHeader>
      <ModalBody>
        <ul>{props.account && props.account.permissions.map((p) => getPermissionName(p))}</ul>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={props.onToggle} />
      </ModalFooter>
    </Modal>
  );
}
