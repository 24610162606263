import { SuggestionsFetchRequestedParams } from "react-autosuggest";
import { useState } from "react";
import { InventorySearchAutoSuggestItem } from "@labarchives/inventory-shared/build/inventory";
import { InventoryApi } from "../../../api/InventoryApi";

export interface InventorySearchAutoSuggestHooks {
  options: InventorySearchAutoSuggestItem[];
  onChange(event: never, { newValue }: { newValue: string }): void;
  onSuggestionsFetchRequested({ value }: SuggestionsFetchRequestedParams): void;
  onSuggestionsClearRequested(): void;
}

export interface InventorySearchAutoSuggestProps {
  searchTerm: string;
  api: InventoryApi;

  onClear(): void;
  onSearchTermChange(searchTerm: string): void;
}

export function useInventorySearchAutoSuggest(props: InventorySearchAutoSuggestProps): InventorySearchAutoSuggestHooks {
  const { api } = props;
  const [options, setOptions] = useState<InventorySearchAutoSuggestItem[]>([]);
  function onChange(event: never, { newValue }: { newValue: string }): void {
    props.onSearchTermChange(newValue);
  }

  function onSuggestionsFetchRequested({ value }: SuggestionsFetchRequestedParams): void {
    api
      .getInventorySuggestions(value.trim(), ["name"])
      .then((items) => {
        setOptions(items);

        return items;
      })
      .catch((error) => {
        // eslint-disable-next-line promise/no-nesting
        api.logError(error, "getInventorySuggestions");
        return [];
      });
  }

  function onSuggestionsClearRequested(): void {
    setOptions([]);
  }

  return {
    options,
    onChange,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
  };
}
