/* eslint-disable unicorn/no-document-cookie */
import { Settings } from "./Settings";

export const CSRF_COOKIE_NAME = "lasess-csrf";
export const ACCOUNT_ID_COOKIE_NAME = "lasess-acct";

export function setCookie(name: string, val: string, daysToLive?: number): void {
  const value = val;
  let expires = "";
  if (daysToLive) {
    const date = new Date();
    date.setTime(date.getTime() + daysToLive * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()};`;
  }
  const domain = Settings.getCookieDomain();

  const secure = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "" : "secure;";

  document.cookie = `${name}=${value};${expires} domain=${domain}; path=/;${secure}`;
}

export function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) {
    return parts.pop()!.split(";").shift();
  }

  return undefined;
}

export function deleteCookie(name: string): void {
  const date = new Date();
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
  const domain = Settings.getCookieDomain();
  document.cookie = `${name}=; expires=${date.toUTCString()}; domain=${domain}; path=/`;
}
