import { useEffect, useRef, useState } from "react";

interface UseVisibleHooks {
  ref: React.RefObject<HTMLDivElement> | null | undefined;
  isVisible: boolean;
  setIsVisible(isVisible: boolean): void;
}

function useVisible(initialIsVisible: boolean): UseVisibleHooks {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event: MouseEvent): void => {
    // @ts-ignore
    if (ref && ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
}

export default useVisible;
