import { useState } from "react";
import * as FileSaver from "file-saver";
import { InventoryImportRequest } from "@labarchives/inventory-shared/build/inventory";
import { InventoryTypeView } from "../types/views";
import { StorageLocationView } from "../../storage/types/views";
import { InventoryExcel } from "./InventoryExcel";
import { InventoryImportParseResult } from "./InventoryImportParseResult";

export interface InventoryImportModalHooks {
  onTemplateDownloaded(): void;

  onImportUploaded(files: File[]): void;

  onImportRestarted(): void;

  onImportStarted(): void;
  setStep(step: number): void;

  importResult: InventoryImportParseResult;
  isParsing: boolean;
  wasParsed: boolean;
  step: number;
  isUploading: boolean;
}

export interface InventoryImportProps {
  inventoryTypeViews: InventoryTypeView[];
  storageLocations: StorageLocationView;

  onItemsImported(request: InventoryImportRequest): void;
}

export interface InventoryImportModalProps extends InventoryImportProps{
  isOpen: boolean;
  onCancel(): void;
}

export function useInventoryImportModal(props: InventoryImportProps): InventoryImportModalHooks {
  const [importResult, setImportResult] = useState<InventoryImportParseResult>({ items: [], isValid: true, errors: [], fileDate: new Date() });
  const [isParsing, setIsParsing] = useState(false);
  const [wasParsed, setWasParsed] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState(0);

  function onTemplateDownloaded(): void {
    InventoryExcel.GetTemplate(props.inventoryTypeViews)
      .AsBlob()
      .then((blob) => FileSaver.saveAs(blob, "InventoryImport.xlsx", { autoBom: false }));
  }

  function onImportUploaded(files: File[]): void {
    setIsParsing(true);
    const excel = InventoryExcel.Create(props.inventoryTypeViews, props.storageLocations);
    excel.Parse(files[0]).then((result) => {
      setImportResult(result);
      setIsParsing(false);
      setWasParsed(true);

      return result;
    });
  }

  function onImportRestarted(): void {
    setImportResult({ items: [], isValid: true, errors: [], fileDate: new Date() });
    setIsParsing(false);
    setWasParsed(false);
    setIsUploading(false);
    setStep(0);
  }

  function onImportStarted(): void {
    setIsUploading(true);
    props.onItemsImported({ items: importResult.items });
  }

  return {
    onTemplateDownloaded,
    onImportUploaded,
    onImportRestarted,
    onImportStarted,
    isParsing,
    wasParsed,
    isUploading,
    step,
    importResult,
    setStep,
  };
}
