import React, { ReactElement, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AlertWithIcon, Button, Modal } from "@labarchives/ui-design";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import * as FileSaver from "file-saver";
import { LocationsImportRequest } from "@labarchives/inventory-shared/build/inventory";
import { Loading } from "../../components";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { LocationsExcel } from "./LocationsExcel";
import { LocationImportParseResult } from "./LocationImportParseResult";

export interface InventoryAdminLocationImportHooks {
  isParsing: boolean;
  isUploading: boolean;
  wasParsed: boolean;
  importResult: LocationImportParseResult;

  onTemplateDownloaded(): Promise<void>;
  onImportUploaded(file: File): Promise<void>;
  onImportRestarted(): void;
  onImportStarted(): void;
}

export interface AdminLocationImportProps {
  isOpen: boolean;
  isOverride: boolean;

  onCancel(): void;
  onLocationsImported(locations: LocationsImportRequest): void;
}

export function useAdminLocationImport(props: AdminLocationImportProps): InventoryAdminLocationImportHooks {
  const [isParsing, setIsParsing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [wasParsed, setWasParsed] = useState(false);
  const [importResult, setImportResult] = useState<LocationImportParseResult>({ items: [], errors: [], isValid: true });

  async function onTemplateDownloaded(): Promise<void> {
    const blob = await LocationsExcel.GetTemplate().AsBlob();
    FileSaver.saveAs(blob, "LocationsImport.xlsx", { autoBom: false });
  }

  async function onImportUploaded(file: File): Promise<void> {
    setIsParsing(true);
    const excel = LocationsExcel.Create();
    excel
      .Parse(file)
      .then((result) => {
        setImportResult(result);
        setIsParsing(false);
        setWasParsed(true);

        return result;
      })
      .catch(() => {
        setIsParsing(false);
        setImportResult({ items: [], isValid: false, errors: [] });
      });
  }

  async function onImportStarted(): Promise<void> {
    setIsUploading(true);
    props.onLocationsImported({ locations: importResult.items });
  }

  function onImportRestarted(): void {
    setImportResult({ items: [], isValid: true, errors: [] });
    setIsParsing(false);
    setWasParsed(false);
    setIsUploading(false);
  }

  useEffect(() => {
    onImportRestarted();
  }, [props.isOpen]);

  return {
    isParsing,
    isUploading,
    wasParsed,
    importResult,
    onTemplateDownloaded,
    onImportUploaded,
    onImportStarted,
    onImportRestarted,
  };
}

export function ImportLocationsModal(props: AdminLocationImportProps): ReactElement {
  const { wasParsed, isUploading, isParsing, importResult, onImportRestarted, onImportUploaded, onTemplateDownloaded, onImportStarted } =
    useAdminLocationImport(props);

  const intl = useIntl();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/vnd.ms-excel": [".xls", ".xlsx"] },
    multiple: false,
    onDropAccepted: (files: File[]) => onImportUploaded(files[0]),
  });

  // @ts-ignore
  const divProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = { ...getRootProps() };

  return (
    <Modal isOpen={props.isOpen} className="inventory-import-modal" toggle={props.onCancel} id={"location-management-upload-dialog"}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id="import.locations" />
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <FormattedHTMLMessage id="import.locations.instructions" />
            {props.isOverride && (
              <div>
                <FormattedMessage id={"import.locations.warning"} />
              </div>
            )}
          </div>
          <div className="mt-3 text-center">
            <Button onClick={onTemplateDownloaded} size="sm" color="primary" id={"location-management-download-template-button"}>
              <FormattedMessage id="download.template" />
            </Button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div>
          {wasParsed && importResult.items.length === 0 && importResult.isValid && (
            <AlertWithIcon color="danger" className="inventory-import-modal-parse-errors" id={"location-management-empty-upload"}>
              <FormattedMessage id="import.locations.empty.file" />
            </AlertWithIcon>
          )}
          {importResult.items.length > 0 && importResult.isValid && (
            <div id="inventory-import-modal-parse-success">
              <div id={"inventory-import-modal-parse-success-message"}>
                <FormattedMessage id="location.upload.parse.success" values={{ rowCount: importResult.items.length }} />
              </div>
              <div className="inventory-import-modal-parse-success-buttons">
                {!isUploading && (
                  <>
                    <Button onClick={onImportStarted} size="sm" color="primary" id={"location-management-begin-import"}>
                      <FormattedMessage id="start.import" />
                    </Button>
                    <Button color="link" size="sm" onClick={onImportRestarted}>
                      <FormattedMessage id="choose.another.file" />
                    </Button>
                  </>
                )}
                {isUploading && <Loading />}
              </div>
            </div>
          )}
          {!importResult.isValid && (
            <AlertWithIcon color="danger" className="inventory-import-modal-parse-errors" id={"location-management-upload-error"}>
              {importResult.errors.map((e, index) => (
                <ul key={`${e.id}${index}`}>
                  <li>
                    <FormattedHTMLMessage id={e.id} values={e.values} />
                  </li>
                </ul>
              ))}
              <div className="inventory-import-modal-parse-error-retry">
                <FormattedMessage id="location.upload.error.retry" />
              </div>
            </AlertWithIcon>
          )}
          {isParsing && <Loading messageId="parsing.upload" />}
          {importResult.items.length === 0 && (
            <section>
              <div className="dropzone" {...divProps}>
                <input id="location-management-upload-file" {...getInputProps()} />
                <div>
                  <FormattedMessage id="upload.import.prompt" />
                </div>
                <div>
                  <img src="/images/Inventory_Import.svg" className="svg-inline--fa fa-2x" alt={intl.formatMessage({ id: "import.locations" })} />
                </div>
              </div>
            </section>
          )}
        </div>
        <div>
          <CancelButton onClick={props.onCancel} className="mt-1" />
        </div>
      </ModalFooter>
    </Modal>
  );
}
