import React, { ReactElement, useContext } from "react";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { AppContext, useAppContext } from "./AppContext";

export function AppProvider(props: React.PropsWithChildren<unknown>): ReactElement {
  const authState = useContext<AuthenticationState>(AuthenticationContext);
  const value = useAppContext({ user: authState.getUser()! });
  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
}
