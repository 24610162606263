import React, { ReactElement } from "react";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserStatus } from "@labarchives/inventory-shared/build/inventory";
import { UserView } from "../types/views";
import { getStatusName } from "../utils/userStatus";
import "./ManageUsersList.scss";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { ManageUsersListProps, useManageUsersList } from "./ManageUsersListHooks";

export function ManageUsersList(props: ManageUsersListProps): ReactElement {
  const { deletingUser, isModalOpen, onShowDeletePrompt, onHideDeletePrompt, onDeleteUser, onRoleUpdated } = useManageUsersList(props);
  const intl = useIntl();

  const canBeUpdated = (userView: UserView): boolean => {
    return props.currentUser !== undefined && props.currentUser.id !== userView.id && userView.status !== UserStatus.Inactive && !userView.isOwner;
  };

  const getRoleContent = (userView: UserView): ReactElement => {
    if (userView.status === UserStatus.Inactive || !canBeUpdated(userView)) {
      return <span>{userView.roleName}</span>;
    }

    const id = `manage-users-list-role-options}${userView.id}`;
    return (
      <div>
        <FormGroup>
          <Label for={id} className="visually-hidden">
            <FormattedMessage id="role" />
          </Label>
          <Input
            type="select"
            bsSize="sm"
            className="manage-users-list-role-options"
            defaultValue={userView.roleId.toString()}
            onChange={(e) => onRoleUpdated(userView, e.target.value)}
          >
            {props.userRoleViews.map((userRoleView) => (
              <option key={userRoleView.id} value={userRoleView.id}>
                {userRoleView.name}
              </option>
            ))}
          </Input>
        </FormGroup>
      </div>
    );
  };

  return (
    <div className="manage-users-list-container">
      <Table striped tabIndex={0}>
        <caption className={"visually-hidden"}>
          <FormattedMessage id="users" />
        </caption>
        <thead>
          <tr>
            <th scope={"col"}>
              <FormattedMessage id="user" />
            </th>
            <th scope={"col"}>
              <FormattedMessage id="email.address" />
            </th>
            <th scope={"col"}>
              <FormattedMessage id="role" />
            </th>
            <th scope={"col"}>
              <FormattedMessage id="status" />
            </th>
            <th scope={"col"}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {props.userViews.map((userView) => (
            <tr key={userView.id}>
              <td>{userView.fullName}</td>
              <td>{userView.email}</td>
              <td className="manage-users-list-role">{getRoleContent(userView)}</td>
              <td>
                <FormattedMessage id={getStatusName(userView.status)} />
              </td>
              <td className="management-item-delete">
                {canBeUpdated(userView) && (
                  <Button
                    color="link"
                    title={intl.formatMessage({ id: "delete.user" })}
                    onClick={() => onShowDeletePrompt(userView)}
                    data-testid="delete.user"
                  >
                    <FontAwesomeIcon icon={faTrash} className="button-icon" size="lg" />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={isModalOpen && deletingUser} toggle={onHideDeletePrompt}>
        <ModalHeader toggle={onHideDeletePrompt}>
          <FormattedMessage id="delete.user" />
        </ModalHeader>
        <ModalBody>
          <FormattedHTMLMessage id="delete.user.warning" values={{ name: deletingUser ? deletingUser.fullName : "" }} />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => onDeleteUser()}>
            <FormattedMessage id="delete" />
          </Button>
          <CancelButton className="delete-user-cancel-button" onClick={onHideDeletePrompt} />
        </ModalFooter>
      </Modal>
    </div>
  );
}
