import { FormGroup, Label } from "reactstrap";
import { Input } from "@labarchives/ui-design";
import React, { ReactElement } from "react";
import { AdvancedSearchCriteriaMatchType, CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { InventoryAdvancedSearchTypeRangeComponentProps } from "./InventoryAdvancedSearchTypeComponentProps";
import { InventoryAdvanceSearchMatchDropdown } from "./InventoryAdvanceSearchMatchDropdown";

export function InventoryAdvancedSearchNumber(props: InventoryAdvancedSearchTypeRangeComponentProps): ReactElement {
  return (
    <>
      <InventoryAdvanceSearchMatchDropdown
        index={props.index}
        matchType={props.matchType}
        onMatchTypeChanged={props.onMatchTypeChanged}
        attributeType={CustomAttributeType.Number}
      />
      <div className={"criteria-value"}>
        <FormGroup className={"range-option"}>
          <Label className="visually-hidden" for={`number1${props.index}`}>
            Date Value
          </Label>
          <Input
            id={`number1${props.index}`}
            bsSize="sm"
            defaultValue={props.defaultValue}
            className="range-option"
            type="number"
            onChange={(e) => props.onValueChanged(e.target.value)}
          />
        </FormGroup>
        {props.matchType === AdvancedSearchCriteriaMatchType.Between && (
          <>
            <span className="range-option"> - </span>
            <FormGroup className={"range-option"}>
              <Label className="visually-hidden" for={`number2${props.index}`}>
                Date Value
              </Label>
              <Input
                id={`number2${props.index}`}
                bsSize="sm"
                defaultValue={props.defaultEndValue}
                className="range-option"
                type="number"
                onChange={(e) => props.onValueChanged(e.target.value, true)}
              />
            </FormGroup>
          </>
        )}
      </div>
    </>
  );
}
