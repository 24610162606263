/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { RefObject, useEffect, useState } from "react";
import moment from "moment";
import isUrl from "is-url";
import { useLastLocation } from "react-router-last-location";
import {
  CasSearchResults,
  CustomAttributeType,
  FreezerBoxDisplayFormat,
  InventoryAttachment,
  InventoryCustomAttributeValue,
  InventoryItem,
  InventoryStandardField,
  InventoryStandardFieldConfiguration,
  User,
} from "@labarchives/inventory-shared/build/inventory";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { StorageState } from "../storage/types/state";
import { VendorState } from "../vendors/types/state";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { getCustomAttributeDefinitionView, getInventoryTypeViews } from "../inventorytypes/selectors";
import { history } from "../app/history";
import { AuthorizedComponentCheck } from "../components/Authentication/AuthorizedComponent";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { isNotFoundError } from "../utils/errorHelpers";
import { getFreezerBoxLocationFromLabel } from "../storage/selectors";
import { InventoryStorageChangedFunction } from "../storage/EditableStorageHooks";
import { InventoryApi } from "../api/InventoryApi";
import { getDefaultInventoryItemView, isStandardFieldEnabled } from "./selectors";
import { CustomAttributeDefinitionView, InventoryAttachmentView, InventoryItemView, InventoryTypeView } from "./types/views";

export interface InputState {
  isValid: boolean;
  fieldName: string;
  value?: string;
  values?: string[];
  required: boolean;
  enabled: boolean;
  isUrl: boolean;
  ref: RefObject<HTMLInputElement>;
}

export interface InventoryItemFormInputs {
  dynamic: { [id: string]: InputState };
  name: InputState;
  quantityAvailable: InputState;
  unitOfMeasure: InputState;
  storageLocation: InputState;
  expirationDate: InputState;
  description: InputState;
  notes: InputState;
  vendor: InputState;
  typeId: InputState;
  catalogNumber: InputState;
  lotNumber: InputState;
  grantNumber: InputState;
  poNumber: InputState;
  price: InputState;
  receivedDate: InputState;
  safetySheet: InputState;
}

export interface SelectedStorage {
  locationId: number;
  cell: string;
}

export interface EditableInventoryProps {
  units: string[];
  buttonTextId: string;
  allowDelete: boolean;
  titleTextId: string;

  loadInventoryItemView(
    inventoryId: string | undefined,
    inventoryTypesState: InventoryTypesState,
    vendorState: VendorState,
    storageState: StorageState,
    api: InventoryApi,
  ): Promise<InventoryItemView>;

  onSaveItem(item: InventoryItem, attachments: File[], user: User | undefined, api: InventoryApi): Promise<InventoryItem>;

  authorizationCheck(inventoryOwnerId: number | null): AuthorizedComponentCheck;

  getSelectedStorage(qs: URLSearchParams): SelectedStorage | undefined;
}

export class InventoryItemEditableFormInputBuilder {
  public buildInputs(itemView: InventoryItemView, attributes: CustomAttributeDefinitionView[] = [], defaultUnits = ""): InventoryItemFormInputs {
    const inputState: InputState = {
      value: "",
      values: [],
      isValid: true,
      fieldName: "",
      required: false,
      enabled: true,
      isUrl: false,
      ref: React.createRef(),
    };

    const inputs = {
      name: {
        ...inputState,
        value: itemView.name,
        fieldName: "name",
        required: true,
        ref: React.createRef<HTMLInputElement>(),
      },
      quantityAvailable: {
        ...inputState,
        value: itemView.quantityAvailable.toString(),
        fieldName: "quantityAvailable",
        required: true,
        ref: React.createRef<HTMLInputElement>(),
      },
      unitOfMeasure: {
        ...inputState,
        value: itemView.unitOfMeasure.toString() || defaultUnits,
        fieldName: "unitOfMeasure",
        required: true,
        ref: React.createRef<HTMLInputElement>(),
      },
      storageLocation: {
        ...inputState,
        value: itemView.storageLocationId?.toString() || undefined,
        fieldName: "storageLocation",
        ref: React.createRef<HTMLInputElement>(),
      },
      expirationDate: {
        ...inputState,
        value: itemView.expirationDate ? moment(itemView.expirationDate).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "",
        fieldName: "expirationDate",
        ref: React.createRef<HTMLInputElement>(),
      },
      description: {
        ...inputState,
        value: itemView.description,
        fieldName: "description",
        ref: React.createRef<HTMLInputElement>(),
      },
      vendor: {
        ...inputState,
        value: itemView.vendorId ? itemView.vendorId.toString() : "",
        fieldName: "vendor",
        ref: React.createRef<HTMLInputElement>(),
      },
      notes: {
        ...inputState,
        value: itemView.notes,
        fieldName: "notes",
        ref: React.createRef<HTMLInputElement>(),
      },
      typeId: {
        ...inputState,
        value: itemView.typeId.toString(),
        fieldName: "typeId",
        required: true,
        ref: React.createRef<HTMLInputElement>(),
      },
      catalogNumber: {
        ...inputState,
        value: itemView.catalogNumber,
        fieldName: "catalogNumber",
        ref: React.createRef<HTMLInputElement>(),
      },
      lotNumber: {
        ...inputState,
        value: itemView.lotNumber,
        fieldName: "lotNumber",
        ref: React.createRef<HTMLInputElement>(),
      },
      grantNumber: {
        ...inputState,
        value: itemView.grantNumber,
        fieldName: "grantNumber",
        ref: React.createRef<HTMLInputElement>(),
      },
      poNumber: {
        ...inputState,
        value: itemView.poNumber,
        fieldName: "poNumber",
        ref: React.createRef<HTMLInputElement>(),
      },
      price: {
        ...inputState,
        value: itemView.price ? itemView.price.toString() : "",
        fieldName: "price",
        ref: React.createRef<HTMLInputElement>(),
      },
      receivedDate: {
        ...inputState,
        value: itemView.dateReceived ? moment(itemView.dateReceived).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "",
        fieldName: "receivedDate",
        ref: React.createRef<HTMLInputElement>(),
      },
      safetySheet: {
        ...inputState,
        value: itemView.safetySheetUrl,
        fieldName: "safetySheet",
        isUrl: true,
        ref: React.createRef<HTMLInputElement>(),
      },

      dynamic: this.getDynamicValues(attributes, itemView),
    };

    return this.bindStandardFieldConfiguration(itemView.inventoryStandardFieldConfiguration, inputs);
  }

  private bindStandardFieldConfiguration(
    inventoryStandardFieldConfiguration: InventoryStandardFieldConfiguration[],
    inputs: InventoryItemFormInputs,
  ): InventoryItemFormInputs {
    function isEnabled(field: InventoryStandardField): boolean {
      return isStandardFieldEnabled(field, inventoryStandardFieldConfiguration);
    }

    function isRequired(field: InventoryStandardField): boolean {
      return isEnabled(field) && inventoryStandardFieldConfiguration.find((c) => c.field === field)?.required === true;
    }

    const copy = { ...inputs };
    copy.storageLocation.required = isRequired(InventoryStandardField.Location);
    copy.storageLocation.enabled = isEnabled(InventoryStandardField.Location);

    copy.expirationDate.required = isRequired(InventoryStandardField.Expiration);
    copy.expirationDate.enabled = isEnabled(InventoryStandardField.Expiration);

    copy.description.required = isRequired(InventoryStandardField.Description);
    copy.description.enabled = isEnabled(InventoryStandardField.Description);

    copy.vendor.required = isRequired(InventoryStandardField.Vendor);
    copy.vendor.enabled = isEnabled(InventoryStandardField.Vendor);

    copy.notes.required = isRequired(InventoryStandardField.Notes);
    copy.notes.enabled = isEnabled(InventoryStandardField.Notes);

    copy.catalogNumber.required = isRequired(InventoryStandardField.CatalogNumber);
    copy.catalogNumber.enabled = isEnabled(InventoryStandardField.CatalogNumber);

    copy.lotNumber.required = isRequired(InventoryStandardField.LotNumber);
    copy.lotNumber.enabled = isEnabled(InventoryStandardField.LotNumber);

    copy.grantNumber.required = isRequired(InventoryStandardField.GrantNumber);
    copy.grantNumber.enabled = isEnabled(InventoryStandardField.GrantNumber);

    copy.poNumber.required = isRequired(InventoryStandardField.PONumber);
    copy.poNumber.enabled = isEnabled(InventoryStandardField.PONumber);

    copy.price.required = isRequired(InventoryStandardField.Price);
    copy.price.enabled = isEnabled(InventoryStandardField.Price);

    copy.receivedDate.required = isRequired(InventoryStandardField.DateReceived);
    copy.receivedDate.enabled = isEnabled(InventoryStandardField.DateReceived);

    copy.safetySheet.required = isRequired(InventoryStandardField.SafetySheet);
    copy.safetySheet.enabled = isEnabled(InventoryStandardField.SafetySheet);

    return copy;
  }

  // eslint-disable-next-line class-methods-use-this
  public getDynamicValues(attributes: CustomAttributeDefinitionView[], view: InventoryItemView): { [id: string]: InputState } {
    const dynamic: { [id: string]: InputState } = {};

    attributes.forEach((att: CustomAttributeDefinitionView) => {
      const attributeValue = view.attributes.find((attribute) => attribute.id === att.id);
      dynamic[att.inputId] = {
        value: undefined,
        values: attributeValue ? attributeValue.values : [],
        fieldName: att.inputId,
        required: att.required,
        isValid: true,
        isUrl: false,
        enabled: true,
        ref: React.createRef(),
      };
    });

    return dynamic;
  }

  public isValid(input: InputState, value: string | string[]): boolean {
    if (!input.enabled) {
      return true;
    }

    if (Array.isArray(value)) {
      return !input.required || value.some((v) => v.trim() !== "");
    }

    if (input.required) {
      return value.trim() !== "";
    }

    if (input.isUrl && value.trim() !== "") {
      return isUrl(value.trim());
    }

    return true;
  }

  public getValue(input: InputState): string {
    return input.value ?? "";
  }

  public getValues(input: InputState): string[] {
    if (input.values && input.values.length > 0) {
      return input.values;
    }
    return [];
  }

  public getUpdatedInputState(prevField: InputState, value: string): InputState {
    return {
      ...prevField,
      value,
      isValid: this.isValid(prevField, value),
    };
  }

  public getUpdatedInputStateMultiple(prevField: InputState, values: string | string[]): InputState {
    return {
      ...prevField,
      values: Array.isArray(values) ? values : [values],
      isValid: this.isValid(prevField, values),
    };
  }

  public updateStandardFieldConfiguration(
    inputs: InventoryItemFormInputs,
    updatedFormConfig: InventoryStandardFieldConfiguration[],
  ): InventoryItemFormInputs {
    return this.bindStandardFieldConfiguration(updatedFormConfig, inputs);
  }
}

export interface InventoryItemNotificationInputs {
  sendReorderNotification: boolean;
  sendExpirationNotification: boolean;
  reorderNotificationUnit: string | null;
  reorderNotificationQuantity: number | null;
  expirationNotificationDays: number | null;
}

export function useInventoryItemEditable(
  inventoryId: string | undefined,
  props: EditableInventoryProps,
  selectedStorage: SelectedStorage | undefined,
  inventoryTypesState: InventoryTypesState,
  storageState: StorageState,
  vendorState: VendorState,
  authState: AuthenticationState,
  inputBuilder: InventoryItemEditableFormInputBuilder,
  api: InventoryApi,
): InventoryItemEditableHooks {
  function getNotifications(itemView: InventoryItemView): InventoryItemNotificationInputs {
    return {
      expirationNotificationDays: itemView.expirationNotificationDays,
      reorderNotificationQuantity: itemView.reorderNotificationQuantity,
      reorderNotificationUnit: itemView.reorderNotificationUnit,
      sendExpirationNotification: itemView.sendExpirationNotification,
      sendReorderNotification: itemView.sendReorderNotification,
    };
  }

  const [currentItemView, setCurrentItemView] = useState<InventoryItemView>(getDefaultInventoryItemView(inventoryTypesState));
  const [formInputs, setFormInputs] = useState(inputBuilder.buildInputs(currentItemView, [], props.units[0]));
  const [storageLocationId, setStorageLocationId] = useState(currentItemView.storageLocationId);
  const [storageCells, setStorageCells] = useState(currentItemView.storageCells);
  const [storageLocationNotes, setStorageLocationNotes] = useState(currentItemView.storageLocationNotes);
  const [dynamicMap, setDynamicMap] = useState(new Map<string, number>());
  const [customAttributes, setCustomAttributes] = useState<CustomAttributeDefinitionView[]>([]);
  const [filesToAttach, setFilesToAttach] = useState<File[]>([]);
  const [filesAttached, setFilesAttached] = useState(currentItemView.attachments);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isInventoryLoading, setIsInventoryLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [notifications, setNotifications] = useState<InventoryItemNotificationInputs>(getNotifications(currentItemView));
  const [inventoryTypeViews, setInventoryTypeViews] = useState<InventoryTypeView[]>([]);
  const [showSaveError, setShowSaveError] = useState(false);
  const [showSaveAttachmentError, setShowSaveAttachmentError] = useState(false);

  const lastLocation = useLastLocation();

  const getCustomAttributes = (typeId: number): CustomAttributeDefinitionView[] => {
    const invType = inventoryTypesState.byId[typeId];
    return invType ? getCustomAttributeDefinitionView(invType) : [];
  };

  const getAttachments = (): InventoryAttachment[] => {
    return filesAttached.map((f) => {
      return { id: f.id, name: f.name, sizeInBytes: f.sizeInBytes };
    });
  };

  const buildInventoryItem = (): InventoryItem => {
    const localCustomAttributes: InventoryCustomAttributeValue[] = [];
    dynamicMap.forEach((id, inputId) => {
      localCustomAttributes.push({
        customAttributeId: id,
        values: inputBuilder.getValues(formInputs.dynamic[inputId]),
      });
    });

    const vendorId = inputBuilder.getValue(formInputs.vendor);
    const price = inputBuilder.getValue(formInputs.price);
    const expiration = inputBuilder.getValue(formInputs.expirationDate);
    const dateReceived = inputBuilder.getValue(formInputs.receivedDate);

    return {
      dateOrdered: null,
      id: currentItemView.id || 0,
      name: inputBuilder.getValue(formInputs.name),
      typeId: Number.parseInt(inputBuilder.getValue(formInputs.typeId)),
      storageCells,
      storageLocationId,
      storageLocationNotes: storageLocationNotes || "",
      quantityAvailable: Number.parseFloat(inputBuilder.getValue(formInputs.quantityAvailable)),
      unit: inputBuilder.getValue(formInputs.unitOfMeasure),
      expiration: expiration === "" ? null : clock.midDay(moment(expiration, clock.STANDARD_MOMENT_DATE_FORMAT).toDate()),
      description: inputBuilder.getValue(formInputs.description),
      notes: inputBuilder.getValue(formInputs.notes),
      customAttributes: localCustomAttributes,
      catalogNumber: inputBuilder.getValue(formInputs.catalogNumber),
      history: currentItemView.history.map((h) => {
        return { activityDate: h.activityDate, description: h.description, userName: h.userName, userId: h.userId, id: h.id };
      }),
      vendorId: vendorId === "" ? null : Number.parseInt(vendorId),
      price: price === "" ? null : Number.parseFloat(price),
      grantNumber: inputBuilder.getValue(formInputs.grantNumber),
      poNumber: inputBuilder.getValue(formInputs.poNumber),
      dateReceived: dateReceived === "" ? null : clock.midDay(moment(dateReceived, clock.STANDARD_MOMENT_DATE_FORMAT).toDate()),
      lotNumber: inputBuilder.getValue(formInputs.lotNumber),
      attachments: getAttachments(),
      safetySheetUrl: inputBuilder.getValue(formInputs.safetySheet),
      expirationNotificationDays: notifications.sendExpirationNotification ? notifications.expirationNotificationDays : null,
      reorderNotificationQuantity: notifications.sendReorderNotification ? notifications.reorderNotificationQuantity : null,
      reorderNotificationUnit: notifications.sendReorderNotification ? notifications.reorderNotificationUnit : null,
      sendExpirationNotification: notifications.sendExpirationNotification,
      sendReorderNotification: notifications.sendReorderNotification,
      ownerId: null,
      links: [], // 2021-12-06 NK, just leave this empty until we do the feature to add/edit links. right now only use now from eln will add links
      itemId: currentItemView.itemId,
    };
  };

  const onValidateForm = (): boolean => {
    let isFormValid = true;
    let firstInvalid: InputState | undefined;

    const properties = Object.keys(formInputs);
    const newFormInputs = { ...formInputs };

    properties.forEach((prop) => {
      if (prop === "dynamic") {
        const dynamicProperties = Object.keys(formInputs.dynamic);
        dynamicProperties.forEach((dynamic) => {
          const field: InputState = formInputs.dynamic[dynamic];
          if (!inputBuilder.isValid(field, inputBuilder.getValues(field))) {
            isFormValid = false;
            newFormInputs.dynamic[dynamic] = { ...field, isValid: false };
            if (!firstInvalid) {
              firstInvalid = field;
            }
          }
        });
      }

      const field: InputState = (formInputs as any)[prop];
      if (!inputBuilder.isValid(field, inputBuilder.getValue(field))) {
        isFormValid = false;
        (newFormInputs as any)[prop] = { ...field, isValid: false };
        if (!firstInvalid) {
          firstInvalid = field;
        }
      }
    });

    if (!isFormValid) {
      setFormInputs(newFormInputs);
      if (firstInvalid && firstInvalid.ref.current) {
        window.scrollTo({ top: firstInvalid.ref.current.offsetTop - 30 });
        firstInvalid.ref.current.focus();
      }
    }

    return isFormValid;
  };

  const onRemoveUpload = (filename: string): void => {
    const prevFiles = filesToAttach.filter((f) => f.name !== filename);
    setFilesToAttach(prevFiles);
  };

  const onRemoveAttachment = (filename: string): void => {
    const prevFiles = filesAttached.filter((f) => f.name !== filename);
    setFilesAttached(prevFiles);
  };

  const onCheckboxChange = (fieldName: string, value: string): void => {
    const dynamicField: InputState = formInputs.dynamic[fieldName];
    let values = inputBuilder.getValues(dynamicField);
    values = values.includes(value) ? values.filter((v) => v !== value) : [...values, value];
    const dynamic = {
      ...formInputs.dynamic,
      [fieldName]: inputBuilder.getUpdatedInputStateMultiple(dynamicField, values),
    };
    const updatedFormInputs = {
      ...formInputs,
      dynamic,
    };
    setFormInputs(updatedFormInputs);
  };

  const onElementChange = (fieldName: string, value: string): void => {
    function updateDynamic(): void {
      const dynamicField: InputState = formInputs.dynamic[fieldName];
      const dynamic = {
        ...formInputs.dynamic,
        [fieldName]: inputBuilder.getUpdatedInputStateMultiple(dynamicField, value),
      };
      const updatedFormInputs = {
        ...formInputs,
        dynamic,
      };
      setFormInputs(updatedFormInputs);
    }

    function updateStatic(): void {
      const prevField: InputState = (formInputs as any)[fieldName];
      const updatedFormInputs = {
        ...formInputs,
        [fieldName]: inputBuilder.getUpdatedInputState(prevField, value),
      };
      setFormInputs(updatedFormInputs);
    }

    if (fieldName in formInputs.dynamic) {
      updateDynamic();
    } else {
      updateStatic();
    }
  };

  const onInventoryStorageChanged: InventoryStorageChangedFunction = (
    locationId?: number | null,
    changedStorageCells?: string[] | null,
    notes?: string | null,
  ): void => {
    setStorageCells(changedStorageCells || []);
    setStorageLocationId(locationId || null);
    onElementChange("storageLocation", locationId?.toString() || "");
    setStorageLocationNotes(notes || null);
  };

  const onFilesDropped = (files: File[]): void => {
    const prevFiles: File[] = [...filesToAttach];
    files.forEach((f) => prevFiles.push(f));
    setFilesToAttach(prevFiles);
  };

  const onDeleteItemPromptToggle = (): void => {
    setIsDeleting(!isDeleting);
  };

  const setDynamicAttributeMap = (attributes: CustomAttributeDefinitionView[]): void => {
    const map: Map<string, number> = new Map<string, number>();
    attributes.forEach((att) => {
      map.set(att.inputId, att.id);
    });
    setDynamicMap(map);
  };

  const onChangeInventoryType = (typeId: number): void => {
    const attributes = getCustomAttributes(typeId);

    // each type has different enabled/required options
    const updatedFormConfig = inventoryTypesState.byId[typeId]?.standardFieldConfiguration || [];

    const dynamic = inputBuilder.getDynamicValues(attributes, currentItemView);

    setCustomAttributes(attributes);
    setDynamicAttributeMap(attributes);

    const input = inputBuilder.updateStandardFieldConfiguration(formInputs, updatedFormConfig);
    input.typeId = inputBuilder.getUpdatedInputState(formInputs.typeId, typeId.toString());
    input.dynamic = dynamic;
    setFormInputs(input);
  };

  const onClose = (): void => {
    history.goBack();
  };

  const onSubmitAddForm = (): void => {
    const isFormValid = onValidateForm();

    if (isFormValid) {
      setShowSaveError(false);
      setShowSaveAttachmentError(false);

      props
        .onSaveItem(buildInventoryItem(), filesToAttach, authState.getUser(), api)
        .then((inventory) => {
          if (lastLocation && lastLocation.pathname && lastLocation.pathname.includes(ApplicationPaths.Orders.Search)) {
            history.push(ApplicationPaths.Orders.Search);
          } else {
            history.push(ApplicationPaths.Inventory.Item(inventory.itemId || inventory.id));
          }
          return inventory;
        })
        .catch((error) => {
          if (error.request?.responseText && error.request.responseText.includes("virus")) {
            setShowSaveAttachmentError(true);
          } else {
            setShowSaveError(true);
          }
          api.logError(error);
        });
    } else {
      setShowSaveError(true);
    }
  };

  const onItemDeleted = (id: string): void => {
    // eslint-disable-next-line promise/always-return
    api.deleteInventory(id).then(() => {
      history.push(ApplicationPaths.Inventory.Search);
    });
  };

  const onExpirationNotificationChanged = (enabled: boolean, numberOfDays: number): void => {
    setNotifications({ ...notifications, sendExpirationNotification: enabled, expirationNotificationDays: numberOfDays });
  };

  const onQuantityNotificationChanged = (enabled: boolean, amount: number | null, unit: string | null): void => {
    setNotifications({ ...notifications, sendReorderNotification: enabled, reorderNotificationQuantity: amount, reorderNotificationUnit: unit });
  };

  const onCasSearched = (casSearchResults: CasSearchResults | null): void => {
    const allLockedAttributes = customAttributes.filter((i) => i.isLocked);

    if (!casSearchResults) {
      return;
    }

    const updatedInputs = { ...formInputs };

    const formula = allLockedAttributes.find((a) => a.label === "Formula");
    if (formula) {
      updatedInputs.dynamic[formula.inputId].values = [casSearchResults.formula];
    }

    const weight = allLockedAttributes.find((a) => a.label === "Molecular Weight");
    if (weight) {
      updatedInputs.dynamic[weight.inputId].values = [casSearchResults.molecularWeight];
    }

    const smiles = allLockedAttributes.find((a) => a.label === "SMILES Structure");
    if (smiles) {
      updatedInputs.dynamic[smiles.inputId].values = [casSearchResults.smilesStructure];
    }

    const safety = allLockedAttributes.find((a) => a.label === "Chemical Safety");
    if (safety) {
      const attribute = customAttributes.find((a) => a.type === CustomAttributeType.ChemicalSafety)!;
      const toBeChecked = casSearchResults.chemicalSafety.filter((s) => attribute.possibleValues.includes(s));

      updatedInputs.dynamic[safety.inputId].values = toBeChecked;
    }

    if (!formInputs.name.value || formInputs.name.value === "") {
      updatedInputs.name.value = casSearchResults.itemName;
      updatedInputs.name.isValid = true;
    }

    // this forces attributes to rebind
    setFormInputs(updatedInputs);
    setCustomAttributes(customAttributes);
  };

  const refreshDependencies = async (): Promise<void> => {
    await storageState.refresh();
    await inventoryTypesState.refresh();
    await vendorState.refresh();
  };

  useEffect(() => {
    refreshDependencies();

    function areDependenciesLoading(): boolean {
      return inventoryTypesState.isLoading || vendorState.isLoading || storageState.isLoading;
    }

    if (!areDependenciesLoading()) {
      props
        .loadInventoryItemView(inventoryId, inventoryTypesState, vendorState, storageState, api)
        .then((view) => {
          const attributes = getCustomAttributes(view.typeId);

          setCurrentItemView(view);
          setFormInputs(inputBuilder.buildInputs(view, attributes, props.units[0]));
          setNotifications(getNotifications(view));
          setCustomAttributes(attributes);
          setStorageLocationId(view.storageLocationId);
          setStorageCells(view.storageCells);
          setStorageLocationNotes(view.storageLocationNotes);
          setDynamicAttributeMap(attributes);
          setIsInventoryLoading(inventoryTypesState.isLoading || vendorState.isLoading || storageState.isLoading);
          setFilesAttached(view.attachments);

          if (selectedStorage) {
            const selectedLocation = storageState.byId[selectedStorage.locationId];
            if (selectedLocation && selectedLocation.numberOfColumns !== null && selectedLocation.numberOfRows !== null) {
              setStorageLocationId(selectedStorage.locationId);
              const location = getFreezerBoxLocationFromLabel(
                selectedStorage.cell,
                selectedLocation.numberOfRows,
                selectedLocation.numberOfColumns,
                FreezerBoxDisplayFormat.Default,
              );
              if (location.row <= selectedLocation.numberOfRows && location.col <= selectedLocation.numberOfColumns) {
                setStorageCells([selectedStorage.cell]);
              }
            }
          }

          return view;
        })
        .catch((error) => {
          if (isNotFoundError(error)) {
            setIsInventoryLoading(false);
            setIsNotFound(true);
          } else {
            throw error;
          }
        });
    }
  }, [inventoryTypesState.isLoading, vendorState.isLoading, storageState.isLoading, inventoryId]);

  useEffect(() => {
    setInventoryTypeViews(getInventoryTypeViews(inventoryTypesState));
  }, [inventoryTypesState]);

  return {
    currentItemView,
    isDeleting,
    formInputs,
    storageCells,
    storageLocationId,
    storageLocationNotes,
    filesAttached,
    setFilesAttached,
    filesToAttach,
    customAttributes,
    notifications,
    inventoryTypeViews,
    showSaveError,
    showSaveAttachmentError,
    isCasSearchEnabled: !(authState.getUser()?.activeAccount.displaySettings.disablePubChem || false),
    getValues: inputBuilder.getValues,
    onCheckboxChange,
    onElementChange,
    onInventoryStorageChanged,
    onDeleteItemPromptToggle,
    onChangeInventoryType,
    onClose,
    onSubmitAddForm,
    onFilesDropped,
    onRemoveAttachment,
    onRemoveUpload,
    onItemDeleted,
    onExpirationNotificationChanged,
    onQuantityNotificationChanged,
    onCasSearched,
    isLoading: isInventoryLoading || inventoryTypesState.isLoading || storageState.isLoading,
    isNotFound,
    setShowSaveError,
    setShowSaveAttachmentError,
  };
}

export interface InventoryItemEditableHooks {
  isDeleting: boolean;
  formInputs: InventoryItemFormInputs;
  storageCells: string[];
  storageLocationId: number | null;
  storageLocationNotes: string | null;
  filesAttached: InventoryAttachmentView[];
  filesToAttach: File[];
  customAttributes: CustomAttributeDefinitionView[];
  isLoading: boolean;
  isNotFound: boolean;
  notifications: InventoryItemNotificationInputs;
  currentItemView: InventoryItemView;
  inventoryTypeViews: InventoryTypeView[];
  showSaveError: boolean;
  showSaveAttachmentError: boolean;
  isCasSearchEnabled: boolean;

  setShowSaveError(show: boolean): void;

  setShowSaveAttachmentError(show: boolean): void;

  setFilesAttached(filesAttached: InventoryAttachmentView[]): void;

  getValues(input: InputState): string[];

  onCheckboxChange(fieldName: string, value: string): void;

  onElementChange(fieldName: string, value: string): void;

  onInventoryStorageChanged: InventoryStorageChangedFunction;

  onDeleteItemPromptToggle(): void;

  onChangeInventoryType(typeId: number): void;

  onClose(): void;

  onSubmitAddForm(): void;

  onFilesDropped(files: File[]): void;

  onRemoveAttachment(filename: string): void;

  onRemoveUpload(filename: string): void;

  onItemDeleted(itemId: string): void;

  onExpirationNotificationChanged(enabled: boolean, numberOfDays: number | null): void;

  onQuantityNotificationChanged(enabled: boolean, amount: number | null, unit: string | null): void;

  onCasSearched(casSearchResults: CasSearchResults | null): void;
}
