import { FreezerBoxExtended } from "@labarchives/inventory-shared/build/inventory";
import { ExcelFile } from "../components/Excel/ExcelFile";
import { ExcelSheet } from "../components/Excel/ExcelSheet";
import { StorageLocationView } from "../storage/types/views";

export class FreezerCapacityReportExcel extends ExcelFile {
  public static CreateExport(items: FreezerBoxExtended[], storage: StorageLocationView): FreezerCapacityReportExcel {
    const excel = new FreezerCapacityReportExcel();
    excel.sheets.push(FreezerCapacityReportExcel.BuildExportSheet(items, storage));
    return excel;
  }

  public static BuildExportSheet(items: FreezerBoxExtended[], storage: StorageLocationView): ExcelSheet {
    const sheet = new ExcelSheet("FreezerCapacityReport");

    const allColumns = ["Name", "Location", "Columns", "Rows", "Total", "Occupied", "Available", "Message"];
    sheet.AddColumns(allColumns);

    items.forEach((item) => {
      const total = item.numberOfColumns * item.numberOfRows;
      let message = "";
      if (item.contents.length !== item.contentsExtended.length) {
        message = "This freezerbox includes multiple items in the same cell.";
      }
      if (item.contentsExtended.includes("")) {
        message += "  This freezerbox includes items that have not been assigned to cells.";
      }
      const row: string[] = [
        item.name,
        storage.getFormattedDescription(item.id),
        item.numberOfColumns.toString(),
        item.numberOfRows.toString(),
        total.toString(),
        item.contents.length.toString(),
        (total - item.contents.length).toString(),
        message,
      ];
      sheet.AppendRow(row);
    });

    return sheet;
  }
}
