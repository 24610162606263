import { useEffect, useMemo, useState } from "react";
import { StorageLocationTreeView } from "../../../storage/types/views";
import { arrayEquals } from "../../../utils";
import { getIsFreezerBox } from "../../../storage/selectors";

export interface InventorySearchStorageLocationFilterHooks {
  selectedLocationIds: string[];
  expandedLocationIds: string[];
  isStorageLinkShown: boolean;
  isNoLocationSelected: boolean;

  onNoLocationChanged(isChecked: boolean): void;
  onCheck(checkedIds: string[]): void;
  onExpand(expandedIds: string[]): void;
}

export interface InventorySearchStorageLocationFilterProps {
  locations: StorageLocationTreeView[];
  selectedLocationIds: number[];
  isNoLocationSelected: boolean;

  onNoLocationChanged(isChecked: boolean): void;
  onLocationsChanged(selectedLocationIds: number[]): void;
}

export function useInventorySearchStorageFilter(props: InventorySearchStorageLocationFilterProps): InventorySearchStorageLocationFilterHooks {
  const [selectedLocationIds, setSelectedLocationIds] = useState(props.selectedLocationIds.map((id) => id.toString()));
  const [expandedLocationIds, setExpandedLocationIds] = useState(props.selectedLocationIds.map((id) => id.toString()));
  const [isNoLocationSelected, setIsNoLocationSelected] = useState(props.isNoLocationSelected === true);

  function onCheck(checkedIds: string[]): void {
    setSelectedLocationIds(checkedIds);

    props.onLocationsChanged(checkedIds.map((id) => Number.parseInt(id)));
  }

  function onExpand(expandedIds: string[]): void {
    setExpandedLocationIds(expandedIds);
  }

  function isFreezerBox(locations: StorageLocationTreeView[]): boolean {
    if (locations.some((l) => getIsFreezerBox(l.location))) {
      return true;
    }

    return locations.some((l) => isFreezerBox(l.children));
  }

  function onNoLocationChanged(isChecked: boolean): void {
    setIsNoLocationSelected(isChecked);
    props.onNoLocationChanged(isChecked);
  }

  useEffect(() => {
    const selected = props.selectedLocationIds.map((id) => id.toString());
    if (!arrayEquals(selected, selectedLocationIds)) {
      setSelectedLocationIds(selected);
      setExpandedLocationIds(selected);
    }
  }, [props.selectedLocationIds]);

  return {
    selectedLocationIds,
    expandedLocationIds,
    isNoLocationSelected,
    onNoLocationChanged,
    onCheck,
    onExpand,
    isStorageLinkShown: useMemo(() => isFreezerBox(props.locations), [props.locations]),
  };
}
