import React, { ReactElement } from "react";
import "./UserSpecificNotice.scss";
import { Button } from "reactstrap";
import { InventoryApiClient } from "../../api";
import { useUserSpecificNotice } from "./UserSpecificNoticeHooks";

export const UserSpecificNotice = (): ReactElement => {
  const { message, onDismiss } = useUserSpecificNotice(new InventoryApiClient());

  if (!message || message === "") {
    return <></>;
  }

  function getMarkup(): { __html: string } {
    return { __html: message! };
  }

  return (
    <div className="user-notice">
      <div className="user-notice-text" dangerouslySetInnerHTML={getMarkup()} />
      <div className="user-notice-close">
        <Button color="link" onClick={onDismiss}>
          &times;
        </Button>
      </div>
    </div>
  );
};
