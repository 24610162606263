import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, ReactElement } from "react";
import { Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { mbToBytes } from "../../utils";
import { InventoryAttachmentView } from "../types/views";
import "./InventoryAttachmentUpload.scss";

export interface InventoryAttachmentUploadProps {
  filesAttached: InventoryAttachmentView[];
  filesToAttach: File[];

  onFilesDropped(files: File[]): void;

  onRemoveAttachment(filename: string): void;

  onRemoveUpload(filename: string): void;
}

export function InventoryAttachmentUpload(props: InventoryAttachmentUploadProps): ReactElement {
  const intl = useIntl();
  const { getRootProps, fileRejections, getInputProps } = useDropzone({
    accept: {
      "text/plain": [],
      "application/pdf": [],
      "image/*": [],
      "application/octet-stream": [
        ".cdx",
        ".cdxml",
        ".mol",
        ".sdf",
        ".skc",
        ".dna",
        ".seq",
        ".xdna",
        ".clc",
        ".pdw",
        ".cx5",
        ".cm5",
        ".nucl",
        ".gcproj",
        ".cow",
        ".sbd",
        ".embl",
        ".gcc",
        ".fasta",
        ".gb",
        ".geneious",
      ],
    },
    minSize: 1,
    maxSize: mbToBytes(25),
    onDropAccepted: props.onFilesDropped,
  });

  // @ts-ignore
  const divProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = { ...getRootProps() };

  return (
    <Fragment>
      <Label for="item-attachment" className="inventory-heading">
        <FormattedMessage id="attachments" /> <span className="badge bg-secondary">{props.filesToAttach.length + props.filesAttached.length}</span>
      </Label>
      <div className="item-attachment-upload-notes">
        <FormattedMessage id="item.attachment.notes" />
      </div>
      <section>
        <div className="dropzone" {...divProps}>
          <input id="item-attachment" {...getInputProps()} />
          {fileRejections.length > 0 && (
            <div className="item-attachment-upload-error">
              <FormattedMessage id="item.attachment.error" />
            </div>
          )}
          <div>
            <FormattedMessage id="upload.files.prompt" />
          </div>
          <div>
            <img src="/images/Inventory_Import.svg" className="svg-inline--fa fa-2x" alt={intl.formatMessage({ id: "import.items" })} />
          </div>
        </div>
      </section>
      <div id="item-existing-attachments">
        {props.filesAttached.map((f) => {
          return (
            <div className="item-file-attachment" key={f.name}>
              {f.name}{" "}
              <Button color="link" title={intl.formatMessage({ id: "remove" })} onClick={() => props.onRemoveAttachment(f.name)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          );
        })}
      </div>
      <div id="item-new-attachments">
        {props.filesToAttach.map((f) => {
          return (
            <div className="item-file-attachment" key={f.name}>
              {f.name}{" "}
              <Button color="link" title={intl.formatMessage({ id: "remove" })} onClick={() => props.onRemoveUpload(f.name)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
}
