import { OrderStatus, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { AuthorizedComponentCheck } from "../AuthorizedComponent";

export function userCanCancelOrder(orderRequestedById: number | null, status: OrderStatus): AuthorizedComponentCheck {
  return (auth) => {
    if (auth.hasPermissions([RolePermissions.OrdersUpdateAll, RolePermissions.OrdersCancelAll])) {
      return true;
    }

    const session = auth.getUser();
    return (
      session !== undefined &&
      session.id === orderRequestedById &&
      status === OrderStatus.Requested &&
      auth.hasPermissions([RolePermissions.OrdersCancelOwn])
    );
  };
}
