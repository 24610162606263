import moment from "moment";
import * as clock from "@labarchives/inventory-shared/build/util/clock";

export interface DatePickerHooksProps {
  onChange(date: Date | null, dateAsString: string | null): void;
}

export interface DatePickerHooks {
  onChange(date: Date | null): void;
}

export function useDatePicker(props: DatePickerHooksProps): DatePickerHooks {
  function onChange(date: Date | null): void {
    if (date) {
      props.onChange(date, moment(date).format(clock.STANDARD_MOMENT_DATE_FORMAT));
    } else {
      props.onChange(null, null);
    }
  }

  return {
    onChange,
  };
}
