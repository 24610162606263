import {
  InventorySearchResultSet,
  InventorySortableColumn,
  InventorySortableColumnDefault,
  SortDirection,
} from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useState } from "react";
import { StorageLocationView } from "../../storage/types/views";
import { StorageState } from "../../storage/types/state";
import { AuthenticationState } from "../../components/Authentication/AuthenticationState";
import { InventoryApi } from "../../api/InventoryApi";
import { getStorageLocationView } from "../../storage/selectors";

export interface DisplaySettingsHooks {
  readonly availableId: string;
  readonly selectedId: string;
  sortedColumns: InventorySortableColumn[];
  availableColumns: InventorySortableColumn[];
  sampleItems: InventorySearchResultSet;
  storageLocations: StorageLocationView;
  currency: string;
  isSavePromptOpen: boolean;
  isUpdatedMessageShown: boolean;
  isUpdateFailedMessageShown: boolean;

  onDropColumn(sourceId: string, destinationId: string, sourceIndex: number, destinationIndex: number): void;

  onMoveColumnLeft(index: number): void;

  onMoveColumnRight(index: number): void;

  onAddSelected(column: InventorySortableColumn): void;

  onRemoveSelected(column: InventorySortableColumn): void;

  onResetToDefault(): void;

  onCancelSave(): void;

  onSave(): void;

  onSavePrompt(): void;

  onUpdateMessageDismissed(): void;

  onFailedMessageDismissed(): void;
}

export function useDisplaySettings(props: { storageState: StorageState; authState: AuthenticationState; api: InventoryApi }): DisplaySettingsHooks {
  const { api, storageState, authState } = props;
  const allColumns: InventorySortableColumn[] = Object.keys(InventorySortableColumn)
    .filter((i) => !Number.isNaN(Number.parseInt(i)))
    .map((i) => Number.parseInt(i));
  const [sortedColumns, setSortedColumns] = useState<InventorySortableColumn[]>(InventorySortableColumnDefault);
  const [availableColumns, setAvailableColumns] = useState<InventorySortableColumn[]>([]);
  const [sampleItems, setSampleItems] = useState<InventorySearchResultSet>({
    items: [],
    sortDirection: SortDirection.DEFAULT,
    sortField: "",
    pageSize: 5,
    currentPageNumber: 1,
    totalPageCount: 1,
    totalResultCount: 5,
  });
  const [isSavePromptOpen, setIsSavePromptOpen] = useState(false);
  const [isUpdatedMessageShown, setIsUpdatedMessageShown] = useState(false);
  const [isUpdateFailedMessageShown, setIsUpdateFailedMessageShown] = useState(false);

  const availableId = "available";
  const selectedId = "selected";

  const move = (
    source: InventorySortableColumn[],
    destination: InventorySortableColumn[],
    sourceIndex: number,
    destinationIndex: number,
  ): { source: InventorySortableColumn[]; destination: InventorySortableColumn[] } => {
    const sourceClone = [...source];
    const destClone = [...destination];
    const [removed] = sourceClone.splice(sourceIndex, 1);

    destClone.splice(destinationIndex, 0, removed);

    return {
      source: sourceClone,
      destination: destClone,
    };
  };

  const reorder = (list: InventorySortableColumn[], startIndex: number, endIndex: number): InventorySortableColumn[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDropColumn(sourceId: string, destinationId: string, sourceIndex: number, destinationIndex: number): void {
    if (sourceId === destinationId && sourceId === selectedId) {
      const reordered = reorder(sortedColumns, sourceIndex, destinationIndex);
      setSortedColumns(reordered);
    } else if (destinationId === selectedId) {
      const moveResult = move(availableColumns, sortedColumns, sourceIndex, destinationIndex);

      setAvailableColumns(moveResult.source);
      setSortedColumns(moveResult.destination);
    } else {
      const moveResult = move(sortedColumns, availableColumns, sourceIndex, destinationIndex);

      setAvailableColumns(moveResult.destination);
      setSortedColumns(moveResult.source);
    }
  }

  function addSelected(column: InventorySortableColumn): void {
    setAvailableColumns(availableColumns.filter((s) => s !== column));
    const cloneSorted = [...sortedColumns];
    cloneSorted.push(column);
    setSortedColumns(cloneSorted);
  }

  function removeSelected(column: InventorySortableColumn): void {
    setSortedColumns(sortedColumns.filter((s) => s !== column));
    const cloneAvailable = [...availableColumns];
    cloneAvailable.push(column);
    setAvailableColumns(cloneAvailable);
  }

  function onMoveColumnLeft(index: number): void {
    if (index !== 0) {
      const result = reorder(sortedColumns, index, index - 1);
      setSortedColumns(result);
    }
  }

  function onMoveColumnRight(index: number): void {
    if (index !== sortedColumns.length - 1) {
      const result = reorder(sortedColumns, index, index + 1);
      setSortedColumns(result);
    }
  }

  function onResetToDefault(): void {
    setSortedColumns(InventorySortableColumnDefault);
    setAvailableColumns(allColumns.filter((c) => !InventorySortableColumnDefault.includes(c)));
  }

  function onSave(): void {
    setIsSavePromptOpen(false);
    setIsUpdatedMessageShown(false);
    setIsUpdateFailedMessageShown(false);

    const toUpdate = { ...authState.getUser()!.activeAccount };
    toUpdate.displaySettings.inventorySearchOrder = sortedColumns;

    api
      .updateAccountSettings(toUpdate)
      .then((account) => {
        setIsUpdatedMessageShown(true);
        return account;
      })
      .catch(() => {
        setIsUpdateFailedMessageShown(true);
      });
  }

  useEffect(() => {
    api.getAccountSettings().then((a) => {
      setSortedColumns(a.displaySettings.inventorySearchOrder);
      setAvailableColumns(allColumns.filter((c) => !a.displaySettings.inventorySearchOrder.includes(c)));
      return a;
    });

    api
      .searchInventory({
        resultSetOptions: { pageNumber: 1, pageSize: 5, sortDirection: SortDirection.DEFAULT, sortField: "" },
        term: "",
        locationIds: [],
        typeIds: [],
      })
      .then((results) => {
        setSampleItems(results);
        return results;
      });
  }, []);

  return {
    availableId,
    selectedId,
    sortedColumns,
    availableColumns,
    sampleItems,
    isSavePromptOpen,
    isUpdatedMessageShown,
    isUpdateFailedMessageShown,
    currency: authState.getCurrency(),
    storageLocations: getStorageLocationView(storageState),
    onDropColumn,
    onAddSelected: addSelected,
    onRemoveSelected: removeSelected,
    onMoveColumnLeft,
    onMoveColumnRight,
    onResetToDefault,
    onCancelSave: () => setIsSavePromptOpen(false),
    onSavePrompt: () => setIsSavePromptOpen(true),
    onSave,
    onUpdateMessageDismissed: () => setIsUpdatedMessageShown(false),
    onFailedMessageDismissed: () => setIsUpdateFailedMessageShown(false),
  };
}
