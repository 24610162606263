import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { OrderStatus } from "@labarchives/inventory-shared/build/inventory";

interface OrderStatusLabelProps {
  statusId: OrderStatus | number | null;
  className?: string;
}

export const OrderStatusLabel: React.FC<OrderStatusLabelProps> = (props: OrderStatusLabelProps) => {
  const getStatusId = (statusId: OrderStatus | number): string => {
    switch (statusId) {
      case OrderStatus.Approved: {
        return "order.approved";
      }
      case OrderStatus.Cancelled: {
        return "order.cancelled";
      }
      case OrderStatus.Requested: {
        return "order.requested";
      }
      case OrderStatus.Ordered: {
        return "order.ordered";
      }
      case OrderStatus.Received: {
        return "order.received";
      }
      default: {
        return "order.requested";
      }
    }
  };

  if (!props.statusId) {
    return <Fragment />;
  }

  return (
    <span className={props.className}>
      <FormattedMessage id={getStatusId(props.statusId)} />
    </span>
  );
};
