export const getNow = (): Date => {
  return new Date();
};

export const dateOnly = (date: Date): Date => {
  const copy = new Date(date);
  copy.setHours(0);
  copy.setMinutes(0);
  copy.setSeconds(0);
  copy.setMilliseconds(0);

  return copy;
};

export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const addYears = (date: Date, years: number): Date => {
  const newDate = new Date(date.valueOf());
  newDate.setFullYear(date.getFullYear() + years);
  return newDate;
};

export const addMinutes = (date: Date, minutes: number): Date => {
  return new Date(date.getTime() + minutes * 60000);
};

export const addSeconds = (date: Date, seconds: number): Date => {
  return new Date(date.getTime() + seconds * 1000);
};

export const midDay = (date: Date): Date => {
  const newDate = new Date(date.valueOf());
  newDate.setHours(12);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

export const STANDARD_MOMENT_DATE_FORMAT = "YYYY-MM-DD";
