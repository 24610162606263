import React, { ReactElement } from "react";
import { SortDirection } from "@labarchives/inventory-shared/build/inventory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@labarchives/ui-design";
import { FormattedMessage } from "react-intl";

interface SortableTableHeaderProps {
  currentlySortedFieldName: string;
  currentlySortedDirection: SortDirection;

  onSortChanged(sortField: string): void;

  fieldName: string;

  displayTextMessageId: string;

  buttonId?: string;

  buttonClassName?: string;

  thKey?: React.Key | null;
}

export function SortableTableHeader(props: SortableTableHeaderProps): ReactElement {
  const { currentlySortedFieldName, currentlySortedDirection, onSortChanged, fieldName, displayTextMessageId, buttonId, thKey } = props;
  let sortIcon = <></>;
  let direction: "none" | "ascending" | "descending" | "other" | undefined;
  if (fieldName === currentlySortedFieldName) {
    sortIcon =
      currentlySortedDirection === SortDirection.DESCENDING ? (
        <FontAwesomeIcon icon={faChevronCircleDown} />
      ) : (
        <FontAwesomeIcon icon={faChevronCircleUp} />
      );

    direction = currentlySortedDirection === SortDirection.DESCENDING ? "descending" : "ascending";
  }

  return (
    <th key={thKey} scope={"col"} aria-sort={direction}>
      <Button color="link" onClick={() => onSortChanged(fieldName)} id={buttonId}>
        <FormattedMessage id={displayTextMessageId} /> {sortIcon}
      </Button>
    </th>
  );
}
