import React, { ReactElement, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { Button, Card, CardBody, CardHeader } from "@labarchives/ui-design";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { Loading } from "../../components";
import { CurrencySymbol } from "../../components/CurrencySymbol/CurrencySymbol";
import { FlashAlertMessage } from "../../components/FlashAlertMessage/FlashAlertMessage";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import "./SettingsComponent.scss";
import { InventoryApiClient } from "../../api";
import { useLabSettings } from "./LabSettingsHooks";

export function SettingsComponent(): ReactElement {
  const authState = useContext(AuthenticationContext);

  const {
    isLoading,
    isAutoApproveEnabled,
    autoApprovalThreshold,
    onAutoApprovalThresholdChanged,
    onAutoApprovalChanged,
    onSettingsSaved,
    showUpdate,
    currency,
    onCurrencyChanged,
    name,
    isNameValid,
    building,
    floor,
    room,
    floorId,
    roomId,
    buildingId,
    isLocationValid,
    roomOptions,
    floorOptions,
    buildingOptions,
    onNameChanged,
    onBuildingChanged,
    onFloorChanged,
    onRoomChanged,
    onFloorIdChanged,
    onRoomIdChanged,
    onBuildingIdChanged,
  } = useLabSettings(authState, new InventoryApiClient());

  if (isLoading) {
    return <Loading />;
  }

  function getLocationId(id: string): number | undefined {
    if (id === "") {
      return undefined;
    }

    return Number.parseInt(id) || undefined;
  }

  return (
    <div>
      <div className="management-header justify-content-between">
        <h1 className="inventory-heading">
          <FormattedMessage id="settings" />
        </h1>
      </div>
      <div>
        <FlashAlertMessage isOpen={showUpdate} />
        <Card className={"mb-2"}>
          <CardHeader>
            <FormattedMessage id={"lab.information"} />
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="organization-name">
                <FormattedMessage id="lab.name" />
              </Label>
              <Input
                type="text"
                id="organization-name"
                value={name}
                onChange={(e) => onNameChanged(e.target.value)}
                maxLength={255}
                invalid={!isNameValid}
              />
              <FormFeedback>
                <FormattedMessage id="lab.name.is.required" />
              </FormFeedback>
            </FormGroup>
            {buildingOptions.length === 0 && (
              <>
                <FormGroup>
                  <Label for="lab-building">
                    <FormattedMessage id="lab.building" />
                  </Label>
                  <Input type="text" id="lab-building" value={building} onChange={(e) => onBuildingChanged(e.target.value)} maxLength={255} />
                </FormGroup>
                <FormGroup>
                  <Label for="lab-floor">
                    <FormattedMessage id="lab.floor" />
                  </Label>
                  <Input type="text" id="lab-floor" value={floor} onChange={(e) => onFloorChanged(e.target.value)} maxLength={255} />
                </FormGroup>
                <FormGroup>
                  <Label for="lab-room">
                    <FormattedMessage id="lab.room" />
                  </Label>
                  <Input type="text" id="lab-room" value={room} onChange={(e) => onRoomChanged(e.target.value)} maxLength={255} />
                </FormGroup>
              </>
            )}
            {buildingOptions.length > 0 && (
              <>
                {!isLocationValid && (
                  <div className={"error-note"}>
                    <FormattedMessage id={"location.information.required"} />
                  </div>
                )}
                <FormGroup>
                  <Label for="settings-building-names">
                    <FormattedMessage id="lab.building" />
                  </Label>
                  <Input
                    type="select"
                    id="settings-building-names"
                    value={buildingId}
                    onChange={(e) => onBuildingIdChanged(getLocationId(e.target.value))}
                  >
                    <option value={""}>---</option>
                    {buildingOptions.map((o) => (
                      <option value={o.id} key={o.id}>
                        {o.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="settings-floor-names">
                    <FormattedMessage id="lab.floor" />
                  </Label>
                  <Input type="select" id="settings-floor-names" value={floorId} onChange={(e) => onFloorIdChanged(getLocationId(e.target.value))}>
                    <option value={""}>---</option>
                    {floorOptions.map((o) => (
                      <option value={o.id} key={o.id}>
                        {o.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="settings-room-names">
                    <FormattedMessage id="lab.room" />
                  </Label>
                  <Input type="select" id="settings-room-names" value={roomId} onChange={(e) => onRoomIdChanged(getLocationId(e.target.value))}>
                    <option value={""}>---</option>
                    {roomOptions.map((o) => (
                      <option value={o.id} key={o.id}>
                        {o.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </>
            )}
          </CardBody>
        </Card>

        <Card className={"mb-2"}>
          <CardHeader>
            <FormattedMessage id={"orders"} />
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="currency">
                <FormattedMessage id="currency" />
              </Label>
              <Input type="select" id="currency" value={currency} onChange={(e) => onCurrencyChanged(e.target.value)}>
                <option value="AUD">AUD</option>
                <option value="BRL">BRL</option>
                <option value="CAD">CAD</option>
                <option value="CNY">CNY</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="ILS">ILS</option>
                <option value="JPY">JPY</option>
                <option value="KRW">KRW</option>
                <option value="NZD">NZD</option>
                <option value="SGD">SGD</option>
                <option value="USD">USD</option>
                <option value="ZAR">ZAR</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <CheckboxLabel>
                <CheckboxInput
                  id="automatically-approve-orders"
                  onChange={(e) => onAutoApprovalChanged(e.target.checked)}
                  uncheckedValue="0"
                  checked={isAutoApproveEnabled}
                />
                <span>
                  <FormattedMessage id="automatically.approve.orders" />
                </span>
              </CheckboxLabel>
            </FormGroup>
            {isAutoApproveEnabled && (
              <FormGroup>
                <Label for="approval-threshold">
                  <FormattedMessage id="automatically.approve.orders.below.amount" />
                </Label>
                <InputGroup>
                  <InputGroupText>
                    <CurrencySymbol />
                  </InputGroupText>
                  <Input
                    id="approval-threshold"
                    type="number"
                    min={1}
                    step={0.01}
                    value={autoApprovalThreshold || ""}
                    onChange={(e) => onAutoApprovalThresholdChanged(e.target.value)}
                    className="approval-threshold-amount"
                  />
                </InputGroup>
              </FormGroup>
            )}
          </CardBody>
        </Card>

        <div className="management-edit-footer">
          <Button type="submit" onClick={onSettingsSaved} color="primary" id={"settings-save-button"}>
            <FormattedMessage id="update.settings" />
          </Button>
        </div>
      </div>
    </div>
  );
}
