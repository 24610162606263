import { Vendor } from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useState } from "react";
import { VendorAssignmentsView, VendorView } from "../../orders/types/views";
import { VendorState } from "../types/state";
import { getVendorAssignmentsView, getVendorViews } from "../selectors";
import { InventoryApi } from "../../api/InventoryApi";

export interface ManageVendorsHooks {
  vendorViews: VendorView[];
  activeVendorView?: VendorView;

  setActiveVendorView(vendorView: VendorView | undefined): void;

  isAdding: boolean;

  setIsAdding(isAdding: boolean): void;

  onShowVendor(vendorView: VendorView): void;

  onShowAddVendor(): void;

  onAddVendor(vendor: Vendor): void;

  onUpdateVendor(vendor: Vendor): void;

  onDeleteVendor(vendorId: number): void;

  onShowDeleteVendor(vendorId: number): Promise<VendorAssignmentsView>;
}

export function useManageVendors(vendorState: VendorState, api: InventoryApi): ManageVendorsHooks {
  const [activeVendorView, setActiveVendorView] = useState<VendorView | undefined>();
  const [isAdding, setIsAdding] = useState(false);
  const [vendorViews, setVendorViews] = useState(getVendorViews(vendorState));

  useEffect(() => {
    setVendorViews(getVendorViews(vendorState));
  }, [vendorState]);

  const onAddVendor = (vendor: Vendor): void => {
    vendorState.onVendorAdded(vendor).then(() => setIsAdding(false));
  };

  const onUpdateVendor = (vendor: Vendor): void => {
    vendorState.onVendorUpdated(vendor).then(() => setActiveVendorView(undefined));
  };

  const onDeleteVendor = (vendorId: number): void => {
    vendorState.onVendorDeleted(vendorId);
  };

  const onShowVendor = (vendorView: VendorView): void => {
    setActiveVendorView(vendorView);
    setIsAdding(false);
  };

  const onShowAddVendor = (): void => {
    setActiveVendorView(undefined);
    setIsAdding(true);
  };

  const onShowDeleteVendor = async (vendorId: number): Promise<VendorAssignmentsView> => {
    const assignments = await api.getVendorAssignments(vendorId);
    return getVendorAssignmentsView(assignments);
  };

  return {
    activeVendorView,
    setActiveVendorView,
    isAdding,
    setIsAdding,
    onAddVendor,
    onUpdateVendor,
    onDeleteVendor,
    onShowVendor,
    onShowAddVendor,
    onShowDeleteVendor,
    vendorViews,
  };
}
