export enum LabArchivesRegions {
  US = "US",
  UK = "UK",
  AU = "AU",
  EU = "EU",
  GOV = "GOV",
  QA = "QA",
  OH = "OH",
  DR = "DR",
}
