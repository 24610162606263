import React, { useEffect, useState } from "react";
import { VendorView } from "../../orders/types/views";
import { VendorState } from "../types/state";
import { getVendorFromView, getVendorViews } from "../selectors";

export interface CreatableVendorsDropdownHooks {
  vendors: VendorView[];
  selectedVendor?: VendorView;

  onChanged(vendorView: VendorView | undefined): Promise<void>;
}

export interface CreatableVendorsDropdownProps {
  id: string;
  isValid: boolean;
  selectedVendorId?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;

  onChange(selectedVendorId: number | null): void;
}

export function useCreatableVendorsDropdown(props: CreatableVendorsDropdownProps, vendorState: VendorState): CreatableVendorsDropdownHooks {
  const [vendors, setVendors] = useState(getVendorViews(vendorState));

  const getSelected = (): VendorView | undefined => {
    if (!props.selectedVendorId || props.selectedVendorId.length === 0) {
      return undefined;
    }

    const id = props.selectedVendorId || "";

    const selected = vendors.find((v) => v.id === Number.parseInt(id));

    if (selected) {
      return selected;
    }

    return undefined;
  };

  const onChanged = async (vendorView: VendorView | undefined): Promise<void> => {
    if (vendorView === undefined) {
      props.onChange(null);
      return;
    }

    if (vendorView.id === 0) {
      const newVendor = await vendorState.onVendorAdded(getVendorFromView(vendorView));
      props.onChange(newVendor.id);
    } else {
      props.onChange(vendorView.id);
    }
  };

  useEffect(() => {
    vendorState.refresh().then(() => setVendors(getVendorViews(vendorState)));
  }, []);

  return {
    vendors,
    selectedVendor: getSelected(),
    onChanged,
  };
}
