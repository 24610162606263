import { OrganizationBillingType } from "@labarchives/inventory-shared/build/inventory";
import { SubscriptionDetails } from "@labarchives/inventory-shared/build/subscription";
import { useEffect, useState } from "react";
import { BundlePricingView } from "../views";
import { Settings } from "../../utils/Settings";
import { getBundlePricingView } from "../selectors";
import { InventoryApi } from "../../api/InventoryApi";

export interface BundlePurchaseHooks {
  currentBundlePricing: number;
  isBundleAllowed: boolean;
  bundleMinimumUsers: number;
  numberOfUsers: number;
  isLoading: boolean;

  calculateTotal(): number;
  onBundleCheckout(): Promise<void>;
}

export interface BundlePurchaseHooksProps {
  billingType: OrganizationBillingType;
  numberOfUsers: number;
  api: InventoryApi;
}

export function useBundlePurchase(props: BundlePurchaseHooksProps): BundlePurchaseHooks {
  const defaultSubscriptionDetails: SubscriptionDetails = {
    isSubscriptionAllowed: false,
    billingType: OrganizationBillingType.Academic,
    clientReferenceNumber: "",
    perSeatCost: 0,
    purchaseUrl: "",
    seatCount: 0,
    productCode: "",
    subtotal: 0,
  };
  const { billingType, numberOfUsers, api } = props;
  const [bundlePricing, setBundlePricing] = useState<BundlePricingView>({ academic: 0, commercial: 0 });
  const [accountServiceSubscription, setAccountServiceSubscription] = useState<SubscriptionDetails>(defaultSubscriptionDetails);
  const [isLoading, setIsLoading] = useState(true);

  function getBundlePricing(): number {
    if (billingType === OrganizationBillingType.Academic) {
      return Math.trunc(bundlePricing.academic);
    }

    return Math.trunc(bundlePricing.commercial);
  }

  function calculateTotal(): number {
    if (!bundlePricing) {
      return 0;
    }

    const perSeat = billingType === OrganizationBillingType.Academic ? bundlePricing.academic : bundlePricing.commercial;
    return perSeat * numberOfUsers;
  }

  async function onBundleCheckout(): Promise<void> {
    try {
      const details = await api.getAccountServiceSubscriptionDetails(numberOfUsers, billingType, Settings.getBundleProductCode());
      window.location.href = details.purchaseUrl;
    } catch (error) {
      api.logError(error, "Getting checking out bundle purchase");
      throw error;
    }
  }

  async function onLoad(): Promise<void> {
    const products = await api.getAccountServiceSubscriptionProducts();
    try {
      const subDetails = await api.getAccountServiceSubscriptionDetails(numberOfUsers, billingType, Settings.getBundleProductCode());
      setAccountServiceSubscription(subDetails);
      if (subDetails.isSubscriptionAllowed) {
        setBundlePricing(getBundlePricingView(products, Settings.getBundleProductCode()));
      }
    } catch {
      setAccountServiceSubscription({ ...defaultSubscriptionDetails, isSubscriptionAllowed: false });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  return {
    isLoading,
    numberOfUsers: Math.max(numberOfUsers, accountServiceSubscription.seatCount),
    currentBundlePricing: getBundlePricing(),
    isBundleAllowed: accountServiceSubscription.isSubscriptionAllowed,
    bundleMinimumUsers: accountServiceSubscription.seatCount,
    onBundleCheckout,
    calculateTotal,
  };
}
