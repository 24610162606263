import { InventoryImportRequest } from "@labarchives/inventory-shared/build/inventory";
import { InventoryTypeView } from "../../inventory/types/views";
import { StorageLocationView } from "../../storage/types/views";
import { InventoryTypesState } from "../../inventorytypes/types/state";
import { StorageState } from "../../storage/types/state";
import { getInventoryTypeViews } from "../../inventorytypes/selectors";
import { getStorageLocationView } from "../../storage/selectors";
import { VendorState } from "../../vendors/types/state";
import { InventoryApi } from "../../api/InventoryApi";

export interface BulkInventoryImportHooks {
  inventoryTypeViews: InventoryTypeView[];
  storageLocations: StorageLocationView;
  onItemsImported(request: InventoryImportRequest): void;
}

interface BulkInventoryImportHooksProps {
  inventoryTypesContext: InventoryTypesState;
  storageLocationsContext: StorageState;
  vendorsContext: VendorState;
  api: InventoryApi;
}

export function useBulkInventoryImport(props: BulkInventoryImportHooksProps): BulkInventoryImportHooks {
  function onItemsImported(request: InventoryImportRequest): void {
    props.api.importInventoryItems(request).then(() => {
      props.vendorsContext.refresh(true);
      props.inventoryTypesContext.refresh(true);
    });
  }

  return {
    inventoryTypeViews: getInventoryTypeViews(props.inventoryTypesContext),
    storageLocations: getStorageLocationView(props.storageLocationsContext),
    onItemsImported,
  };
}
