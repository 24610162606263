import { FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { Button } from "@labarchives/ui-design";
import { Form } from "reactstrap";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { AddStorageLocation } from "./AddStorageLocation";
import { AddNewLocationProps, useAddNewLocation } from "./AddNewLocationHooks";

export function AddNewLocation(props: AddNewLocationProps): ReactElement {
  const { isNameValid, isFreezerBox, isLocationNameDuplicated, onFreezerBoxChanged, onAddLocation, onNamedChanged, onToggleFreezerBox } =
    useAddNewLocation(props);

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onAddLocation();
        }}
      >
        <AddStorageLocation
          isNameValid={isNameValid}
          isFreezerBox={isFreezerBox}
          onFreezerBoxChanged={onFreezerBoxChanged}
          onNameChanged={onNamedChanged}
          onToggleFreezerBox={onToggleFreezerBox}
          isNameDuplicated={isLocationNameDuplicated}
          invalidNameErrorMessageId="manage.location.top.level.name.error"
        />
        <div>
          <Button type="submit" color="primary">
            <FormattedMessage id="add.location" />
          </Button>
          <CancelButton onClick={props.onCancel} className="ms-1" />
        </div>
      </Form>
    </div>
  );
}
