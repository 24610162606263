import React, { ReactElement, useContext } from "react";
import { AuthenticationContext } from "../Authentication/AuthenticationContext";

export function CurrencySymbol(): ReactElement {
  const context = useContext(AuthenticationContext);
  switch (context.getCurrency()) {
    case "AUD": {
      return <span>A$</span>;
    }
    case "CAD": {
      return <span>CA$</span>;
    }
    case "CNY": {
      return <span>CN¥</span>;
    }
    case "EUR": {
      return <span>€</span>;
    }
    case "GBP": {
      return <span>£</span>;
    }
    case "JPY": {
      return <span>¥</span>;
    }
    case "ILS": {
      return <span>₪</span>;
    }
    case "KRW": {
      return <span>₩</span>;
    }
    case "NZD": {
      return <span>$</span>;
    }
    case "ZAR": {
      return <span>R</span>;
    }
    case "SGD": {
      return <span>S$</span>;
    }
    case "BRL": {
      return <span>R$</span>;
    }
    default: {
      return <span>$</span>;
    }
  }
}
