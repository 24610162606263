import React, { ReactElement, useContext } from "react";
import { Route, Router, Switch } from "react-router";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Navigation } from "../components";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { SubscriptionManagement } from "../subscription/SubscriptionManagement";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import { UsersProvider } from "../user/UsersProvider";
import { Logout } from "../components/Authentication/Logout";
import { AppProvider } from "./AppProvider";
import { ApplicationPaths } from "./ApplicationPaths";
import { history } from "./history";

export function SubscriptionExpiredApp(): ReactElement {
  const auth = useContext(AuthenticationContext);
  const user = auth.getUser();

  const SubscriptionExpiredNotification = (): ReactElement => {
    if (user === undefined) {
      return <div />;
    }

    return (
      <div>
        <div>
          <FormattedMessage id="your.subscription.expired.on" /> <FormattedDate value={user.activeAccount.subscriptionEnd} />
        </div>
        {user.isAccountOwner && (
          <div>
            <Link to={ApplicationPaths.Subscription.Home}>
              <FormattedMessage id="manage.your.subscription" />
            </Link>
          </div>
        )}
      </div>
    );
  };

  return (
    <Router history={history}>
      <ErrorBoundary>
        <AppProvider>
          <UsersProvider>
            <div>
              <Navigation />
              <div className="container-fluid">
                <Switch>
                  <Route exact path={ApplicationPaths.Routes.Logout} component={Logout} />
                  <Route exact path={ApplicationPaths.Subscription.Home} component={SubscriptionManagement} />
                  <Route component={SubscriptionExpiredNotification} />
                </Switch>
              </div>
            </div>
          </UsersProvider>
        </AppProvider>
      </ErrorBoundary>
    </Router>
  );
}
