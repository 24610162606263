import { useEffect, useState } from "react";
import { StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { InventoryApi } from "../api/InventoryApi";
import { StorageLocationView } from "./types/views";

export type InventoryStorageChangedFunction = (locationId?: number | null, storageCells?: string[] | null, notes?: string | null) => void;

export interface EditableStorageProps {
  onInventoryStorageChanged: InventoryStorageChangedFunction;
  storageLocations: StorageLocationView;
  selectedStorageLocationId: number | null;
  selectedStorageCells: string[];
  storageNotes: string | null;
  api: InventoryApi;
  isRequired: boolean;
  idPrefix?: string;
}

export interface SelectedLocation {
  id: number | null;
  cells: string[];
  notes: string;
}

export function useEditableStorage(props: EditableStorageProps): EditableStorageHooks {
  const { api, selectedStorageLocationId, selectedStorageCells, storageNotes, storageLocations } = props;
  const [selectedLocation, setSelectedLocation] = useState<SelectedLocation>({
    id: selectedStorageLocationId,
    cells: selectedStorageCells,
    notes: storageNotes || "",
  });

  const [originalLocation, setOriginalLocation] = useState<SelectedLocation>({
    id: selectedStorageLocationId,
    cells: selectedStorageCells,
    notes: storageNotes || "",
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [occupiedFreezerBoxCells, setOccupiedFreezerBoxCells] = useState<string[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  function getLocation(locationId: number | null): StorageLocation | null {
    return storageLocations.getSelectedLocation(locationId);
  }

  function getMatrixRows(locationId: number | null): number {
    const location = getLocation(locationId);
    if (location && location.numberOfRows) {
      return location.numberOfRows;
    }

    return 0;
  }

  function getMatrixCols(locationId: number | null): number {
    const location = getLocation(locationId);
    if (location && location.numberOfColumns) {
      return location.numberOfColumns;
    }

    return 0;
  }

  function onStorageLocationSelected(locationId: number | null): void {
    setSelectedLocation({ id: locationId, cells: [], notes: selectedLocation.notes });
    const isLeaf = !storageLocations.locationList.some((l) => l.parentId === locationId);
    setIsCollapsed(isLeaf);
  }

  function onSelectedCellsChanged(cells: string[]): void {
    setSelectedLocation({ ...selectedLocation, cells });
  }

  function onNotesChanged(notes: string): void {
    setSelectedLocation({ ...selectedLocation, notes });
  }

  function onClearLocation(): void {
    setSelectedLocation({ id: null, cells: [], notes: "" });
  }

  function onUpdateStorageLocation(): void {
    const id = selectedLocation.id || undefined;
    props.onInventoryStorageChanged(id, selectedLocation.cells, selectedLocation.notes);
    setOriginalLocation(selectedLocation);
    hideStorageUpdate();
  }

  function onCancelStorageUpdate(): void {
    setSelectedLocation(originalLocation);
    setIsUpdating(false);
    setIsCollapsed(false);
  }

  function hideStorageUpdate(): void {
    setIsUpdating(false);
    setIsCollapsed(false);
  }

  function showStorageUpdate(): void {
    setIsCollapsed(false);
    setIsUpdating(true);
  }

  function onExpand(): void {
    setIsCollapsed(false);
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (
      selectedLocation.notes === storageNotes &&
      selectedLocation.id === selectedStorageLocationId &&
      selectedLocation.cells === selectedStorageCells
    ) {
      return;
    }
    const location: SelectedLocation = { id: selectedStorageLocationId, cells: selectedStorageCells, notes: storageNotes || "" };

    setSelectedLocation(location);
    setOriginalLocation(location);
  }, [props]);

  // eslint-disable-next-line
  useEffect(() => {
    const location = getLocation(selectedLocation.id);
    if (isUpdating && selectedLocation.id && location && location.numberOfColumns && location.numberOfRows) {
      api
        .getFreezerBox(selectedLocation.id)
        .then((freezerBox) => {
          setOccupiedFreezerBoxCells(freezerBox.contents);
          return freezerBox;
        })
        .catch((error) => {
          setOccupiedFreezerBoxCells([]);
          api.logError(error);
        });
    } else {
      setOccupiedFreezerBoxCells([]);
    }
  }, [selectedLocation.id, isUpdating]);

  return {
    isUpdating,
    selectedLocation,
    matrixRows: getMatrixRows(selectedLocation.id),
    matrixCols: getMatrixCols(selectedLocation.id),
    occupiedFreezerBoxCells,
    isCollapsed,
    onStorageLocationSelected,
    onSelectedCellsChanged,
    onNotesChanged,
    onClearLocation,
    onUpdateStorageLocation,
    onCancelStorageUpdate,
    hideStorageUpdate,
    showStorageUpdate,
    onExpand,
  };
}

export interface EditableStorageHooks {
  occupiedFreezerBoxCells: string[];
  selectedLocation: SelectedLocation;
  matrixRows: number;
  matrixCols: number;
  isUpdating: boolean;
  isCollapsed: boolean;

  onStorageLocationSelected(locationId: number | null): void;

  onSelectedCellsChanged(selectedCells: string[]): void;

  onNotesChanged(storageNotes: string): void;

  onClearLocation(): void;

  onUpdateStorageLocation(): void;

  onCancelStorageUpdate(): void;

  hideStorageUpdate(): void;

  showStorageUpdate(): void;

  onExpand(): void;
}
