import { OrderSearchResultSetItem } from "@labarchives/inventory-shared/build/inventory";
import { ExcelFile } from "../../components/Excel/ExcelFile";
import { ExcelSheet } from "../../components/Excel/ExcelSheet";
import { getOrderStatusName } from "../selectors";
import { UserView } from "../../user/types/views";

export class OrderExcel extends ExcelFile {
  public static CreateExport(items: OrderSearchResultSetItem[], users: UserView[]): OrderExcel {
    const excel = new OrderExcel();
    excel.sheets.push(OrderExcel.BuildExportSheet(items, users));
    return excel;
  }

  public static BuildExportSheet(items: OrderSearchResultSetItem[], users: UserView[]): ExcelSheet {
    const sheet = new ExcelSheet("Orders");

    function getUserName(userId: number): string {
      const user = users.find((u) => u.id === userId);

      if (user) {
        return user.fullName;
      }

      return "";
    }

    const allColumns = [
      "Name",
      "Inventory Type",
      "Catalog #",
      "Grant #",
      "PO #",
      "Vendor",
      "Quantity",
      "Price",
      "Status",
      "Date Requested",
      "Requested By",
      "Date Approved",
      "Date Ordered",
      "Date Received",
      "Date Cancelled",
    ];
    sheet.AddColumns(allColumns);

    items.forEach((item) => {
      const row: string[] = [
        item.inventoryName,
        item.typeName,
        item.catalogNumber,
        item.grantNumber,
        item.poNumber,
        item.vendorName,
        item.quantity.toString(),
        item.price.toString(),
        getOrderStatusName(item.status),
        ExcelFile.formatDate(item.dateRequested),
        getUserName(item.requestedById),
        ExcelFile.formatDate(item.dateApproved),
        ExcelFile.formatDate(item.dateOrdered),
        ExcelFile.formatDate(item.dateReceived),
        ExcelFile.formatDate(item.dateCancelled),
      ];
      sheet.AppendRow(row);
    });

    return sheet;
  }
}
