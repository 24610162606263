import React, { ReactElement, useState } from "react";
import { SiteAdminInventorySearchCriteria } from "@labarchives/inventory-shared/build/inventory";
import * as utils from "../../../utils";
import { SiteAccountView } from "../../types/views";
import { InventoryExportModalComponent } from "../../../inventory/import/InventoryExportModalComponent";
import { InventoryApi } from "../../../api/InventoryApi";
import { InventoryAdminExcel } from "./InventoryAdminExcel";

export interface InventoryAdminExportModalProps {
  isOpen: boolean;
  accounts: SiteAccountView[];
  searchCriteria: SiteAdminInventorySearchCriteria;
  api: InventoryApi;

  onCancel(): void;

  onExportComplete(): void;
}

export interface InventoryAdminExportHooks {
  isExporting: boolean;

  onExportCurrentResults(): Promise<void>;

  onExportAll(): Promise<void>;
}

export function useInventoryAdminExport(props: InventoryAdminExportModalProps): InventoryAdminExportHooks {
  const [isExporting, setIsExporting] = useState(false);
  const { api } = props;

  async function exportItems(criteria: SiteAdminInventorySearchCriteria): Promise<void> {
    setIsExporting(true);

    const exportedItems = await api.adminExportInventory(criteria);
    const excel = InventoryAdminExcel.CreateExport(exportedItems, props.accounts);

    const blob = await excel.AsBlob();
    utils.saveAs(blob, "InventoryExport.xlsx");

    setIsExporting(false);

    props.onExportComplete();
  }

  async function onExportCurrentResults(): Promise<void> {
    await exportItems(props.searchCriteria);
  }

  async function onExportAll(): Promise<void> {
    await exportItems({ term: "", safetyCriteria: { hazards: [] }, accountIds: [] });
  }

  return {
    isExporting,
    onExportCurrentResults,
    onExportAll,
  };
}

export function InventoryAdminExportModal(props: InventoryAdminExportModalProps): ReactElement {
  const { isExporting, onExportCurrentResults, onExportAll } = useInventoryAdminExport(props);

  return (
    <InventoryExportModalComponent
      onCancel={props.onCancel}
      isOpen={props.isOpen}
      isExporting={isExporting}
      onExportAll={onExportAll}
      onExportCurrentResults={onExportCurrentResults}
    />
  );
}
