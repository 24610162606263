import React, { ReactElement, useContext, useEffect } from "react";
import { SubscriptionType } from "@labarchives/inventory-shared/build/inventory";
import { useIntl } from "react-intl";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { onPageLoad } from "../utils/onPageLoad";
import { FreeTrialSubscriptionManagement } from "./FreeTrialSubscriptionManagement";
import { PaidSubscriptionManagement } from "./PaidSubscriptionManagement";

export const SubscriptionManagement = (): ReactElement => {
  const auth = useContext(AuthenticationContext);
  const user = auth.getUser();
  const intl = useIntl();

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.subscription" }));
  }, []);

  if (!user || !user.isAccountOwner || !user.activeAccount.subscriptionManagementAllowed) {
    return <div>You cannot access this section</div>;
  }

  if (user.activeAccount.subscriptionType === SubscriptionType.Free) {
    return <FreeTrialSubscriptionManagement user={user} />;
  }

  return <PaidSubscriptionManagement user={user} />;
};
