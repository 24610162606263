import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FormFeedback, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Input, Modal } from "@labarchives/ui-design";
import { CancelButton } from "../../../components/CancelButton/CancelButton";

interface SaveAdvancedSearchModalProps {
  isOpen: boolean;
  name: string;
  onSaved(name: string): void;
  onCancel(): void;
}

export function SaveAdvancedSearchModal(props: SaveAdvancedSearchModalProps): ReactElement {
  const [searchName, setSearchName] = useState(props.name);
  const [isError, setIsError] = useState<boolean>(false);

  function onNameChanged(name: string): void {
    if (name.trim() === "") {
      setIsError(true);
    } else {
      setIsError(false);
      setSearchName(name);
    }
  }

  useEffect(() => {
    setIsError(false);
    setSearchName(props.name);
  }, [props.name, props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel} id={"save-search-modal"}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id={"save.this.search"} />
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor={"saved-search-name"}>
            <FormattedMessage id={"search.name"} />
          </Label>
          <Input id={"saved-search-name"} type="text" defaultValue={props.name} onChange={(e) => onNameChanged(e.target.value)} invalid={isError} />
          <FormFeedback>
            <FormattedMessage id="name.is.required" />
          </FormFeedback>
        </FormGroup>
        {props.name !== "" && (
          <div className={"saved-search-update-note"}>
            <FormattedMessage id={"save.search.as"} />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div>
          <Button id={"saved-search-save-button"} className={"me-1"} color={"primary"} onClick={() => props.onSaved(searchName)}>
            <FormattedMessage id={"save"} />
          </Button>
          <CancelButton onClick={props.onCancel} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
