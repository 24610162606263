import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleMinus } from "@fortawesome/pro-light-svg-icons";
import { faCircleDot } from "@fortawesome/pro-solid-svg-icons";

export const RadioIcon = (checkboxIconProps: { variant: "all" | "none" | "some" }): ReactElement => {
  switch (checkboxIconProps.variant) {
    case "all": {
      return <FontAwesomeIcon icon={faCircleDot} />;
    }
    case "none": {
      return <FontAwesomeIcon icon={faCircle} />;
    }
    case "some": {
      return <FontAwesomeIcon icon={faCircleMinus} />;
    }
    default: {
      return <></>;
    }
  }
};
