import { useEffect, useState } from "react";
import { AdvancedSearchCriteria, AdvancedSearchCriteriaMatchType, CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { CustomAttributeDefinitionView } from "../../types/views";

export interface InventoryAdvancedSearchCriteriaRowHooks {
  selectedFieldName: string;
  selectedAttribute: CustomAttributeDefinitionView | undefined;
  selectedType: CustomAttributeType;
  matchType: AdvancedSearchCriteriaMatchType;
  values: string[];
  standardFields: StandardAdvancedSearchField[];

  onSelectedFieldChanged(fieldName: string): void;
  onMatchTypeChanged(matchType: AdvancedSearchCriteriaMatchType): void;
  onValueChanged(value: string, isEndRangeValue?: boolean): void;
  onCheckboxChanged(value: string): void;
}

interface StandardAdvancedSearchField {
  name: string;
  label: string;
  type: CustomAttributeType;
}

interface InventoryAdvancedSearchCriteriaRowProps {
  criteria: AdvancedSearchCriteria;
  attributes: CustomAttributeDefinitionView[];

  onCriteriaChanged(index: number, fieldName: string, matchType: AdvancedSearchCriteriaMatchType, values: string[], attributeId?: number): void;
}

export function useInventoryAdvancedSearchCriteriaRow(props: InventoryAdvancedSearchCriteriaRowProps): InventoryAdvancedSearchCriteriaRowHooks {
  const [matchType, setMatchType] = useState<AdvancedSearchCriteriaMatchType>(props.criteria.matchType);
  const [values, setCurrentValues] = useState<string[]>(props.criteria.values);
  const [selectedFieldName, setSelectedField] = useState(props.criteria.fieldName);
  const [selectedAttribute, setSelectedAttribute] = useState<CustomAttributeDefinitionView | undefined>(
    props.attributes.find((a) => a.inputId === props.criteria.fieldName),
  );

  useEffect(() => {
    setMatchType(props.criteria.matchType);
    setCurrentValues(props.criteria.values);
    setSelectedField(props.criteria.fieldName);
    setSelectedAttribute(props.attributes.find((a) => a.inputId === props.criteria.fieldName));
  }, [props.criteria]);

  const standardFields: StandardAdvancedSearchField[] = [
    {
      name: "name",
      label: "name",
      type: CustomAttributeType.Textbox,
    },
    {
      name: "quantity",
      label: "quantity",
      type: CustomAttributeType.Number,
    },
    {
      name: "description",
      label: "description",
      type: CustomAttributeType.Textbox,
    },
    {
      name: "vendor",
      label: "vendor",
      type: CustomAttributeType.Textbox,
    },
    {
      name: "catalog",
      label: "catalog.number",
      type: CustomAttributeType.Textbox,
    },
    {
      name: "lot",
      label: "lot.number",
      type: CustomAttributeType.Textbox,
    },
    {
      name: "price",
      label: "price",
      type: CustomAttributeType.Number,
    },
    // {
    //   name: "received",
    //   label: "date.received",
    //   type: CustomAttributeType.Date,
    // },
    {
      name: "grant",
      label: "grant.number",
      type: CustomAttributeType.Textbox,
    },
    {
      name: "po",
      label: "po.number",
      type: CustomAttributeType.Textbox,
    },
    {
      name: "expiration",
      label: "expiration",
      type: CustomAttributeType.Date,
    },
    {
      name: "notes",
      label: "notes",
      type: CustomAttributeType.Textbox,
    },
  ];

  function onValueChanged(value: string, isEndRangeValue?: boolean): void {
    let newValues = [value];
    if (isEndRangeValue) {
      newValues = [values[0], value];
    }
    setCurrentValues(newValues);
    props.onCriteriaChanged(props.criteria.index, selectedFieldName, matchType, newValues, selectedAttribute ? selectedAttribute.id : undefined);
  }

  function onCheckboxChanged(value: string): void {
    let newValue = [...values];
    if (newValue.includes(value)) {
      newValue = newValue.filter((p) => p !== value);
    } else {
      newValue.push(value);
    }
    setCurrentValues(newValue);
    props.onCriteriaChanged(props.criteria.index, selectedFieldName, matchType, newValue, selectedAttribute ? selectedAttribute.id : undefined);
  }

  function onMatchTypeChanged(newMatchType: AdvancedSearchCriteriaMatchType): void {
    setMatchType(newMatchType);
    props.onCriteriaChanged(props.criteria.index, selectedFieldName, newMatchType, values, selectedAttribute ? selectedAttribute.id : undefined);
  }

  function getDefaultMatchType(selectedType: CustomAttributeType): AdvancedSearchCriteriaMatchType {
    if (selectedType === CustomAttributeType.Textbox) {
      return AdvancedSearchCriteriaMatchType.Matches;
    }
    if (selectedType === CustomAttributeType.Dropdown) {
      return AdvancedSearchCriteriaMatchType.Is;
    }
    if (selectedType === CustomAttributeType.Number) {
      return AdvancedSearchCriteriaMatchType.Is;
    }
    if (selectedType === CustomAttributeType.Date) {
      return AdvancedSearchCriteriaMatchType.On;
    }
    if (selectedType === CustomAttributeType.Checkbox) {
      return AdvancedSearchCriteriaMatchType.AnyOf;
    }
    if (selectedType === CustomAttributeType.Radio) {
      return AdvancedSearchCriteriaMatchType.Is;
    }

    return AdvancedSearchCriteriaMatchType.Matches;
  }

  function getSelectedType(att: CustomAttributeDefinitionView | undefined, name: string | undefined): CustomAttributeType {
    if (att) {
      if (att.type === CustomAttributeType.Textbox || att.type === CustomAttributeType.TextArea || att.type === CustomAttributeType.CASNumber) {
        return CustomAttributeType.Textbox;
      }

      if (att.type === CustomAttributeType.Checkbox || att.type === CustomAttributeType.ChemicalSafety) {
        return CustomAttributeType.Checkbox;
      }

      return att.type;
    }

    const standardField = standardFields.find((f) => f.name === name);
    if (standardField) {
      return standardField.type;
    }

    return CustomAttributeType.Textbox;
  }

  function onSelectedFieldChanged(newSelectedFieldName: string): void {
    const selected = props.attributes.find((a) => a.inputId === newSelectedFieldName);

    setSelectedAttribute(selected);
    setSelectedField(newSelectedFieldName);
    setCurrentValues([]);

    const selectedType = getSelectedType(selected, newSelectedFieldName);
    const defaultMatchType = getDefaultMatchType(selectedType);

    setMatchType(defaultMatchType);

    props.onCriteriaChanged(props.criteria.index, newSelectedFieldName, defaultMatchType, [], selected ? selected.id : undefined);
  }

  return {
    selectedFieldName,
    selectedAttribute,
    selectedType: getSelectedType(selectedAttribute, selectedFieldName),
    matchType,
    values,
    standardFields,
    onMatchTypeChanged,
    onValueChanged,
    onCheckboxChanged,
    onSelectedFieldChanged,
  };
}
