import React, { ErrorInfo, PropsWithChildren, ReactElement } from "react";
import { Col, Navbar, NavbarBrand, Row } from "reactstrap";
import { AlertWithIcon, Button, LaLogoBrand } from "@labarchives/ui-design/dist";
import { v4 as uuidv4 } from "uuid";
import { InventoryApiClient } from "../../api";

export interface ErrorBoundaryProps {
  hasError?: boolean;
  errorReportId?: string;
  children?: ReactElement;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  errorReportId: string;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public constructor(props: PropsWithChildren<ErrorBoundaryProps>) {
    super(props);
    this.state = { hasError: props.hasError || false, errorReportId: props.errorReportId || "" };
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    const api = new InventoryApiClient();
    console.error("Caught an error", error);
    const errorReportId = uuidv4();
    this.setState({ hasError: true, errorReportId });
    // eslint-disable-next-line promise/no-promise-in-callback
    api.logError(error, info.componentStack, errorReportId).catch((error_) => {
      console.error("Error logging the error!", error_);
    });
  }

  public render(): ReactElement {
    if (this.state.hasError) {
      return (
        <>
          <header>
            <Navbar dark expand="md" fixed="top">
              <NavbarBrand href="/">
                <LaLogoBrand className="logo">Inventory</LaLogoBrand>
              </NavbarBrand>
            </Navbar>
          </header>
          <div className="container-fluid">
            <Row>
              <Col>
                <AlertWithIcon color="danger">
                  Something went wrong. Try{" "}
                  <Button color="link" onClick={() => window.location.reload()}>
                    reloading
                  </Button>{" "}
                  the application.
                  <br />
                  <br />
                  If the problem persists please contact support and include error report number <em>{this.state.errorReportId}</em>.
                </AlertWithIcon>
              </Col>
            </Row>
          </div>
        </>
      );
    }
    return <>{this.props.children}</>;
  }
}
