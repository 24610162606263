import { InventoryItem, RolePermissions, User } from "@labarchives/inventory-shared/build/inventory";
import { getUnits } from "@labarchives/inventory-shared/build/util/units";
import { RouteComponentProps } from "react-router";
import { ReactElement } from "react";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { VendorState } from "../vendors/types/state";
import { StorageState } from "../storage/types/state";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { InventoryApi } from "../api/InventoryApi";
import { getDefaultInventoryItemView, getInventoryItemViewCopy } from "./selectors";
import { InventoryItemView } from "./types/views";
import { InventoryItemEditableComponent } from "./InventoryItemEditableComponent";
import { EditableInventoryProps, SelectedStorage } from "./InventoryItemEditableHooks";

async function loadInventoryItemView(
  inventoryId: string | undefined,
  inventoryTypesState: InventoryTypesState,
  vendorState: VendorState,
  storageState: StorageState,
  api: InventoryApi,
): Promise<InventoryItemView> {
  if (inventoryId) {
    return api.getInventoryItem(inventoryId).then((inventory) => {
      return getInventoryItemViewCopy(inventory, inventoryTypesState, storageState, vendorState);
    });
  }
  return getDefaultInventoryItemView(inventoryTypesState);
}

async function onSaveItem(item: InventoryItem, attachments: File[], user: User | undefined, api: InventoryApi): Promise<InventoryItem> {
  return api.addInventoryItem(item, attachments);
}

const defaultProps: EditableInventoryProps = {
  units: getUnits(),
  buttonTextId: "add.item",
  allowDelete: false,
  titleTextId: "duplicate.inventory.item",
  loadInventoryItemView,
  onSaveItem,
  authorizationCheck(): (authenticationState: AuthenticationState) => boolean {
    return (auth) => auth.hasPermissions([RolePermissions.InventoryAddCreatedItem]);
  },
  getSelectedStorage: (): SelectedStorage | undefined => {
    return undefined;
  },
};

interface RouteParams {
  id: string;
}

export function DuplicateInventory(props: RouteComponentProps<RouteParams>): ReactElement {
  return InventoryItemEditableComponent({ ...props, ...defaultProps });
}
