import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { AlertWithIcon } from "@labarchives/ui-design/dist";

export interface FlashAlertMessageProps {
  isOpen: boolean;
  messageId?: string;
  isHtml?: boolean;
  alertColor?: "info" | "danger" | "success" | "error";
  onDismiss?(): void;
}

export function FlashAlertMessage(props: FlashAlertMessageProps): ReactElement {
  return (
    <AlertWithIcon color={props.alertColor || "info"} isOpen={props.isOpen} toggle={props.onDismiss}>
      {props.isHtml && <FormattedHTMLMessage id={props.messageId} />}
      {!props.isHtml && <FormattedMessage id={props.messageId || "updates.saved"} />}
    </AlertWithIcon>
  );
}
