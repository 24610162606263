import { useState } from "react";

export interface AddInventoryTypeHooks {
  isInvalid: boolean;

  onTypeAdded(): void;

  onCancelTypeAdded(): void;

  updateName(name: string): void;
}

export interface AddInventoryTypeProps {
  onTypeAdded(name: string): void;

  onCancelTypeAdded(): void;

  isNameValid(name: string, id: number | undefined): boolean;
}

export function useAddInventoryType(props: AddInventoryTypeProps): AddInventoryTypeHooks {
  const [name, setName] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  const onTypeAdded = (): void => {
    if (props.isNameValid(name, undefined)) {
      props.onTypeAdded(name);
      return;
    }
    setIsInvalid(true);
  };

  const onCancelTypeAdded = (): void => {
    props.onCancelTypeAdded();
  };

  const updateName = (updatedName: string): void => {
    setName(updatedName.trim());
    setIsInvalid(!props.isNameValid(updatedName.trim(), undefined));
  };

  return { isInvalid, onTypeAdded, updateName, onCancelTypeAdded };
}
