import { Hazards } from "./hazards";

export class ChemicalSafety {
  public static Hazards: string[] = [
    Hazards.AcuteToxic,
    Hazards.CompressedGas,
    Hazards.Corrosive,
    Hazards.EnvironmentalHazard,
    Hazards.Explosive,
    Hazards.Flammable,
    Hazards.HealthHazard,
    Hazards.Irritant,
    Hazards.Oxidizer,
  ];
}
