import { OrderSearchResultSet, OrderSearchResultSetItem, ResultSetOptions, SortDirection } from "@labarchives/inventory-shared/build/inventory";

export interface OrderSearchResultsHooks {
  onPageChanged(pageNumber: number): void;
  onSortChanged(sortField: string): void;
}

export interface OrderSearchResultsProps {
  isLoading: boolean;
  searchResults: OrderSearchResultSet;
  currency: string;
  isBulkActionEnabled: boolean;
  selectedBulkUpdateOrderIds: number[];
  isBulkSelectAllChecked: boolean;

  onBulkUpdateSelectAll(): void;
  onBulkUpdateOrderChecked(orderId: number, isChecked: boolean): void;
  onResultsOptionsChanged(options: ResultSetOptions): void;
  onOrderAction(item: OrderSearchResultSetItem): void;
}

export function useOrderSearchResults(props: OrderSearchResultsProps): OrderSearchResultsHooks {
  function onPageChanged(page: number): void {
    const { searchResults } = props;
    props.onResultsOptionsChanged({
      pageNumber: page,
      pageSize: searchResults.pageSize,
      sortDirection: searchResults.sortDirection,
      sortField: searchResults.sortField,
    });
  }

  function onSortChanged(field: string): void {
    const { searchResults } = props;
    props.onResultsOptionsChanged({
      pageNumber: searchResults.currentPageNumber,
      pageSize: searchResults.pageSize,
      sortDirection: getSortDirection(field, searchResults),
      sortField: field,
    });
  }

  function getSortDirection(field: string, searchResults: OrderSearchResultSet): SortDirection {
    if (field !== searchResults.sortField) {
      return SortDirection.DEFAULT;
    }

    return searchResults.sortDirection === SortDirection.DESCENDING ? SortDirection.ASCENDING : SortDirection.DESCENDING;
  }

  return {
    onPageChanged,
    onSortChanged,
  };
}
