import { OrderSearchCriteria } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { UserState } from "../../user/types/state";
import { RoleState } from "../../roles/types/state";
import { InventoryApi } from "../../api/InventoryApi";
import { getUserViews } from "../../user/selectors";
import { OrderExcel } from "./OrderExcel";

export interface OrderExportHooks {
  isLoading: boolean;

  onSearch(criteria: OrderSearchCriteria): Promise<OrderExcel>;
}

export function useOrderExport(userState: UserState, roleState: RoleState, api: InventoryApi): OrderExportHooks {
  const [isLoading, setIsLoading] = useState(false);

  async function onSearch(criteria: OrderSearchCriteria): Promise<OrderExcel> {
    setIsLoading(true);
    const results = await api.exportOrders(criteria);
    setIsLoading(false);
    return OrderExcel.CreateExport(results, getUserViews(userState, roleState));
  }

  return {
    isLoading,
    onSearch,
  };
}
