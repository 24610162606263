import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { StorageLocationTreeView } from "../types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";

interface DuplicateStorageLocationPromptProps {
  isOpen: boolean;
  location?: StorageLocationTreeView;
  isError: boolean;
  onDuplicateStorageLocation(location: StorageLocationTreeView): void;
  onCancel(): void;
}

export const DuplicateStorageLocationPrompt = (props: DuplicateStorageLocationPromptProps): ReactElement => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id="duplicate.storage.location" />
      </ModalHeader>
      <ModalBody>
        {!props.isError && (
          <FormattedHTMLMessage id="duplicate.storage.location.warning" values={{ name: props.location ? props.location.location.name : "" }} />
        )}
        {props.isError && (
          <FormattedHTMLMessage id="duplicate.storage.location.error" values={{ name: props.location ? props.location.location.name : "" }} />
        )}
      </ModalBody>
      <ModalFooter>
        {!props.isError && (
          <Button color="primary" onClick={() => (props.location ? props.onDuplicateStorageLocation(props.location) : {})}>
            <FormattedMessage id="duplicate" />
          </Button>
        )}
        <CancelButton onClick={props.onCancel} />
      </ModalFooter>
    </Modal>
  );
};
