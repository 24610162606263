import { OrderSearchResultSetItem } from "@labarchives/inventory-shared/build/inventory";
import * as utils from "../utils";
import { OrderExcel } from "../orders/export/OrderExcel";
import { getUserViews } from "../user/selectors";
import { UserState } from "../user/types/state";
import { RoleState } from "../roles/types/state";

export interface OrdersReportDownloadHooks {
  onReportDownloaded(items: OrderSearchResultSetItem[], fileName: string): Promise<void>;
}

export interface OrdersReportDownloadHooksProps {
  userState: UserState;
  roleState: RoleState;
}

export function useOrdersReportDownload(props: OrdersReportDownloadHooksProps): OrdersReportDownloadHooks {
  async function onReportDownloaded(items: OrderSearchResultSetItem[], fileName: string): Promise<void> {
    const excel = OrderExcel.CreateExport(items, getUserViews(props.userState, props.roleState));
    const blob = await excel.AsBlob();
    utils.saveAs(blob, fileName);
  }

  return {
    onReportDownloaded,
  };
}
