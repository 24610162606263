import { FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AlertWithIcon } from "@labarchives/ui-design/dist";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { AccountSubscriptionSummaryView } from "../views";

import "./SubscribeModal.scss";
import { InventoryPurchaseCard } from "../inventory-standalone/InventoryPurchaseCard";
import { BundleUpgradeRenewalCard } from "../bundle/BundleUpgradeRenewalCard";
import { BundleRenewCard } from "../bundle/BundleRenewCard";
import { Settings } from "../../utils/Settings";
import { InventoryApi } from "../../api/InventoryApi";

export interface RenewSubscriptionModalProps {
  isOpen: boolean;
  numberOfUsers: number;
  minimumNumberOfUsers: number;
  subscriptionSummary: AccountSubscriptionSummaryView;
  totalPrice: number;
  perUserPrice: number;
  errorMessage: string;
  api: InventoryApi;

  onCheckout(users: number): Promise<void>;
  onNumberOfUsersChanged(users: string): void;
  toggleModal(): void;
}

export function RenewSubscriptionModal(props: RenewSubscriptionModalProps): ReactElement {
  const {
    toggleModal,
    perUserPrice,
    errorMessage,
    isOpen,
    totalPrice,
    subscriptionSummary,
    numberOfUsers,
    minimumNumberOfUsers,
    api,
    onNumberOfUsersChanged,
    onCheckout,
  } = props;

  const isBundleEnabled = Settings.getBundleProductCode() !== "";

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={"xl"}>
      <ModalHeader toggle={toggleModal}>
        <FormattedMessage id={"renew.subscription"} />
      </ModalHeader>
      <ModalBody>
        <AlertWithIcon color="danger" isOpen={errorMessage !== ""}>
          {errorMessage}
        </AlertWithIcon>
        <div>
          {subscriptionSummary.canBeRenewed && !subscriptionSummary.labArchivesSubscriptionId && (
            <div className={"subscription-pricing-box d-flex flex-sm-wrap justify-content-center"}>
              <InventoryPurchaseCard
                numberOfUsers={numberOfUsers}
                minimumNumberOfUsers={minimumNumberOfUsers}
                callToActionTextId={"renew.subscription"}
                perUserPrice={perUserPrice}
                totalPrice={totalPrice}
                onNumberOfUsersChanged={onNumberOfUsersChanged}
                onCheckout={() => onCheckout(numberOfUsers)}
              />

              {isBundleEnabled && (
                <BundleUpgradeRenewalCard
                  numberOfUsers={Math.max(numberOfUsers, subscriptionSummary.seatCount)}
                  onNumberOfUsersChanged={onNumberOfUsersChanged}
                  className={"ms-md-3"}
                  api={api}
                />
              )}
            </div>
          )}
          {subscriptionSummary.canBeRenewed && subscriptionSummary.labArchivesSubscriptionId && (
            <div className={"subscription-pricing-box d-flex justify-content-center"}>
              <BundleRenewCard
                labArchivesSubscriptionId={subscriptionSummary.labArchivesSubscriptionId}
                numberOfUsers={Math.max(numberOfUsers, subscriptionSummary.seatCount)}
                onNumberOfUsersChanged={onNumberOfUsersChanged}
                api={api}
              />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <CancelButton onClick={toggleModal} />
      </ModalFooter>
    </Modal>
  );
}
