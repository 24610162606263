import { SubscriptionCart, SubscriptionTotal } from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useState } from "react";
import { SubscriptionTotalView } from "../views";
import { getSubscriptionTotalView } from "../selectors";
import { InventoryApi } from "../../api/InventoryApi";

export interface AddSubscriptionUsersHooks {
  numberOfUsers: number;
  subscriptionTotal: SubscriptionTotalView;
  isPurchasing: boolean;

  setUsers(users: number): void;

  onCheckout(): Promise<SubscriptionCart>;
}

export function useAddSubscriptionUsers(props: { isOpen: boolean; api: InventoryApi }): AddSubscriptionUsersHooks {
  const { isOpen, api } = props;
  const [users, setUsers] = useState(1);
  const [subscriptionTotal, setSubscriptionTotal] = useState<SubscriptionTotal | undefined>();
  const [isPurchasing, setIsPurchasing] = useState(false);

  async function onCheckout(): Promise<SubscriptionCart> {
    setIsPurchasing(true);
    const cart = await api.addUsersToSubscription(users);

    window.location.href = cart.paymentUrl;
    return cart;
  }

  useEffect(() => {
    if (isOpen) {
      api.calculateSubscriptionAdditionalUsersTotal(users).then((tot) => setSubscriptionTotal(tot));
    }
  }, [users, isOpen]);

  return {
    subscriptionTotal: getSubscriptionTotalView(subscriptionTotal),
    numberOfUsers: users,
    isPurchasing,
    setUsers,
    onCheckout,
  };
}
