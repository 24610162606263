import React, { ReactElement } from "react";
import "./InventorySearchTypeFilter.scss";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { InventorySearchTypeFilterProps, useInventorySearchTypeFilter } from "./InventorySearchTypeFilterHooks";

export function InventorySearchTypeFilter(props: InventorySearchTypeFilterProps): ReactElement {
  const { onChange, isFilterChecked } = useInventorySearchTypeFilter(props);
  return (
    <div>
      {props.inventoryTypeViews.map((type) => {
        const checked = isFilterChecked(type.id);
        return (
          <div key={`inventory-filter-type${type.id}`} className="inventory-filter-type-item">
            <CheckboxLabel>
              <CheckboxInput id={`inventory-filter-type-check${type.id}`} onChange={() => onChange(type.id)} uncheckedValue="0" checked={checked} />
              {type.color && (
                <div className={"inventory-type-color"} data-color={type.color} style={{ backgroundColor: type.color }}>
                  &nbsp;
                </div>
              )}
              <span> {type.name}</span>
            </CheckboxLabel>
          </div>
        );
      })}
    </div>
  );
}
