import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { OrderStatus } from "@labarchives/inventory-shared/build/inventory";

import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";

interface OrderSearchStatusFilterProps {
  selectedStatusIds: number[];

  onChangedStatuses(selectedStatusIds: number[]): void;
}

export interface OrderSearchStatusFilterHooks {
  onChange(id: number): void;

  isFilterChecked(id: number): boolean;
}

export function useOrderSearchStatusFilter(props: OrderSearchStatusFilterProps): OrderSearchStatusFilterHooks {
  const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>(props.selectedStatusIds);

  useEffect(() => {
    setSelectedStatusIds(props.selectedStatusIds);
  }, [props.selectedStatusIds]);

  function isFilterChecked(id: number): boolean {
    return selectedStatusIds.includes(id);
  }

  function onChange(id: number): void {
    let updatedIds = [...selectedStatusIds] || [];

    if (updatedIds.includes(id)) {
      updatedIds = updatedIds.filter((typeId) => typeId !== id);
    } else {
      updatedIds.push(id);
    }

    setSelectedStatusIds(updatedIds);
    props.onChangedStatuses(updatedIds);
  }

  return {
    onChange,
    isFilterChecked,
  };
}

export function OrderSearchStatusFilter(props: OrderSearchStatusFilterProps): ReactElement {
  const { onChange, isFilterChecked } = useOrderSearchStatusFilter(props);

  const statusList: { id: number; nameid: string }[] = [
    { id: OrderStatus.Requested, nameid: "order.requested" },
    { id: OrderStatus.Approved, nameid: "order.approved" },
    { id: OrderStatus.Cancelled, nameid: "order.cancelled" },
    { id: OrderStatus.Ordered, nameid: "order.ordered" },
    { id: OrderStatus.Received, nameid: "order.received" },
  ];

  return (
    <div>
      {statusList.map((type) => {
        const checked = isFilterChecked(type.id);
        return (
          <div key={`order-filter-status${type.id}`}>
            <CheckboxLabel>
              <CheckboxInput id={`order-filter-status-check${type.id}`} onChange={() => onChange(type.id)} uncheckedValue="0" checked={checked} />
              <span>
                <FormattedMessage id={type.nameid} />
              </span>
            </CheckboxLabel>
          </div>
        );
      })}
    </div>
  );
}
