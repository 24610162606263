import React, { ReactElement } from "react";
import { OrganizationBillingType } from "@labarchives/inventory-shared/build/inventory";
import { Loading } from "../../components";
import { InventoryApi } from "../../api/InventoryApi";
import { useBundlePurchase } from "./BundePurchaseHooks";
import { BundleCard } from "./BundleCard";

interface BundlePurchaseCardProps {
  numberOfUsers: number;
  billingType: OrganizationBillingType;
  className?: string;
  api: InventoryApi;

  onNumberOfUsersChanged(users: string): void;
}

export function BundlePurchaseCard(props: BundlePurchaseCardProps): ReactElement {
  const { isLoading, isBundleAllowed, numberOfUsers, bundleMinimumUsers, currentBundlePricing, calculateTotal, onBundleCheckout } = useBundlePurchase(
    {
      numberOfUsers: props.numberOfUsers,
      billingType: props.billingType,
      api: props.api,
    },
  );

  if (!isBundleAllowed) {
    return <></>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BundleCard
      numberOfUsers={numberOfUsers}
      callToActionTextId={"bundle.up"}
      minimumNumberOfUsers={bundleMinimumUsers}
      onNumberOfUsersChanged={props.onNumberOfUsersChanged}
      perUserPrice={currentBundlePricing}
      totalPrice={calculateTotal()}
      onCheckout={onBundleCheckout}
      className={props.className}
    />
  );
}
