import { useState } from "react";
import { User } from "@labarchives/inventory-shared/build/inventory";
import { UserRoleView, UserView } from "../types/views";

export interface ManageUsersListHooks {
  deletingUser?: UserView;
  isModalOpen: boolean;

  onShowDeletePrompt(userView: UserView): void;

  onHideDeletePrompt(): void;

  onDeleteUser(): void;

  onRoleUpdated(userView: UserView, roleId: string): void;
}

export interface ManageUsersListProps {
  userViews: UserView[];
  userRoleViews: UserRoleView[];
  currentUser?: User;

  onUserDeleted(userId: number): void;

  onUserRoleUpdated(userId: number, roleId: number): void;
}

export function useManageUsersList(props: ManageUsersListProps): ManageUsersListHooks {
  const [deletingUser, setDeletingUser] = useState<UserView | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onShowDeletePrompt = (userView: UserView): void => {
    setDeletingUser(userView);
    setIsModalOpen(true);
  };

  const onHideDeletePrompt = (): void => {
    setDeletingUser(undefined);
    setIsModalOpen(false);
  };

  const onDeleteUser = (): void => {
    if (deletingUser !== undefined) {
      // todo: change this to just delete by id then get rid of getUserFromView
      props.onUserDeleted(deletingUser.id);
      onHideDeletePrompt();
    }
  };

  const onRoleUpdated = (userView: UserView, roleId: string): void => {
    props.onUserRoleUpdated(userView.id, Number.parseInt(roleId));
  };

  return {
    deletingUser,
    isModalOpen,
    onShowDeletePrompt,
    onHideDeletePrompt,
    onDeleteUser,
    onRoleUpdated,
  };
}
