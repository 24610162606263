/* eslint-disable unicorn/prefer-set-has */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  InventorySearchResultSet,
  ResultSetOptions,
  SiteAdminInventorySearchCriteria,
  SiteLocationFloor,
  SiteLocations,
  SortDirection,
} from "@labarchives/inventory-shared/build/inventory";
import { SiteAccountView } from "../../types/views";
import { UserState } from "../../../user/types/state";
import { AuthenticationState } from "../../../components/Authentication/AuthenticationState";
import * as utils from "../../../utils";
import { getSiteAccountView } from "../../types/selectors";
import { InventoryApi } from "../../../api/InventoryApi";

export interface InventoryAdminSearchHooks {
  accounts: SiteAccountView[];
  allAccounts: SiteAccountView[];
  searchResults: InventorySearchResultSet;
  criteria: SiteAdminInventorySearchCriteria;
  isAllowedToSearch: boolean;
  isLoading: boolean;
  isShowingExport: boolean;
  siteLocations: SiteLocations;
  checkedLocations: number[];

  onSearchTermChanged(term: string): Promise<void>;

  onAccountsChanged(accountIds: number[]): Promise<void>;

  onLocationsChanged(locationIds: number[]): Promise<void>;

  onResultSetOptionsChanged(resultSetOptions: ResultSetOptions): Promise<void>;

  onHazardsChanged(hazards: string[]): Promise<void>;

  onOutOfStockChanged(includeOutOfStock: boolean): Promise<void>;

  onCriteriaCleared(): Promise<void>;

  onExportModalToggled(): void;
}

const defaultCriteria: SiteAdminInventorySearchCriteria = {
  term: "",
  accountIds: [],
  safetyCriteria: { hazards: [] },
};

export function useInventoryAdminSearchHooks(userState: UserState, authState: AuthenticationState, api: InventoryApi): InventoryAdminSearchHooks {
  const [criteria, setCriteria] = useState<SiteAdminInventorySearchCriteria>({ ...userState.lastAdminSearchCriteria });
  const [siteLocations, setSiteLocations] = useState<SiteLocations>({ isPublished: true, buildings: [], floors: [], rooms: [] });
  const [checkedLocations, setCheckedLocations] = useState<number[]>([]);

  const [searchResults, setSearchResults] = useState<InventorySearchResultSet>({
    items: [],
    sortDirection: SortDirection.DEFAULT,
    sortField: "",
    pageSize: 0,
    currentPageNumber: 0,
    totalPageCount: 0,
    totalResultCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingExport, setIsShowingExport] = useState(false);

  const accounts: SiteAccountView[] = useMemo(() => {
    const user = authState.getUser();
    return user?.siteAccounts.sort((a1, a2) => a1.name.localeCompare(a2.name)).map((a) => getSiteAccountView(a, siteLocations)) || [];
  }, [authState.getUser(), siteLocations]);

  async function apiSearch(searchCriteria: SiteAdminInventorySearchCriteria): Promise<void> {
    userState.updateLastAdminInventorySearchCriteria(searchCriteria);

    const results = await api.adminSearchInventory(searchCriteria);
    setSearchResults(results);
    setIsLoading(false);
  }

  const debouncedSearch = useRef(utils.debounce(apiSearch, 1000)).current;

  async function searchInventory(searchCriteria: SiteAdminInventorySearchCriteria, debounce = true): Promise<void> {
    setIsLoading(true);
    setCriteria(searchCriteria);
    await (debounce ? debouncedSearch(searchCriteria) : apiSearch(searchCriteria));
  }

  async function onSearchTermChanged(term: string): Promise<void> {
    await searchInventory({ ...criteria, term });
  }

  async function onAccountsChanged(accountIds: number[]): Promise<void> {
    await searchInventory({ ...criteria, accountIds });
  }

  async function onResultSetOptionsChanged(resultSetOptions: ResultSetOptions): Promise<void> {
    await searchInventory({ ...criteria, resultSetOptions });
  }

  async function onOutOfStockChanged(includeOutOfStock: boolean): Promise<void> {
    await searchInventory({ ...criteria, includeOutOfStock });
  }

  async function onCriteriaCleared(): Promise<void> {
    setCheckedLocations([]);
    const searchCriteria = { ...defaultCriteria, accountIds: authState.getUser()?.siteAccounts.map((a) => a.id) || [] };
    await searchInventory(searchCriteria);
  }

  async function onHazardsChanged(updatedHazards: string[]): Promise<void> {
    const { safetyCriteria } = { ...criteria };
    safetyCriteria.hazards = updatedHazards;
    await searchInventory({ ...criteria, safetyCriteria });
  }

  async function onLocationsChanged(locationIds: number[]): Promise<void> {
    const newCheckedLocations = [...locationIds];
    let accountIds: number[] = [];

    accountIds = getFilteredAccounts(locationIds).map((a) => a.id);

    setCheckedLocations(newCheckedLocations);

    await searchInventory({ ...criteria, accountIds });
  }

  function onExportModalToggled(): void {
    setIsShowingExport(!isShowingExport);
  }

  function getFilteredAccounts(locationIds: number[]): SiteAccountView[] {
    if (locationIds.length === 0) {
      return accounts;
    }

    const noFloor = locationIds.filter((id) => id < 0);
    const floors: SiteLocationFloor[] = siteLocations.floors.filter((f) => locationIds.includes(f.id));
    const floorIds = [...noFloor, ...floors.map((f) => f.id)];

    return floorIds.length > 0
      ? accounts.filter(
          (a) => !a.roomId || (a.floorId && floorIds.includes(a.floorId)) || (a.buildingId && !a.floorId && floorIds.includes(a.buildingId * -1)),
        )
      : accounts.filter((a) => !a.roomId || (a.buildingId && locationIds.includes(a.buildingId)));
  }

  function filteredAccounts(): SiteAccountView[] {
    return getFilteredAccounts(checkedLocations);
  }

  function getDefaultCheckedLocations(): number[] {
    const searchAccounts = accounts.filter((a) => criteria.accountIds.includes(a.id));
    return [
      ...searchAccounts.filter((a) => a.floorId).map((a) => a.floorId!),
      ...searchAccounts.filter((a) => a.buildingId && !a.floorId).map((a) => a.buildingId! * -1),
    ];
  }

  function sortedSiteLocations(): SiteLocations {
    const sl = { ...siteLocations };
    sl.buildings = sl.buildings.sort((b1, b2) => b1.name.localeCompare(b2.name));
    sl.floors = sl.floors.sort((f1, f2) => f1.name.localeCompare(f2.name));
    return sl;
  }

  useEffect(() => {
    if (!authState.getUser()?.isSiteAdmin) {
      return;
    }

    api.getSiteLocations().then((locations) => {
      setCheckedLocations(getDefaultCheckedLocations());
      setSiteLocations(locations);
      // this order is important because the flaky checkbox tree doesn't handle prop updates
      // so set checked before binding tree data

      const searchCriteria = { ...criteria };
      if (criteria.accountIds.length === 0) {
        searchCriteria.accountIds = authState.getUser()?.siteAccounts.map((a) => a.id) || [];
      }
      searchInventory(searchCriteria, false);
      return locations;
    });
  }, []);

  return {
    isAllowedToSearch: authState.getUser()?.isSiteAdmin || false,
    accounts: filteredAccounts(),
    allAccounts: accounts,
    criteria,
    searchResults,
    isLoading,
    isShowingExport,
    siteLocations: sortedSiteLocations(),
    checkedLocations,
    onSearchTermChanged,
    onAccountsChanged,
    onResultSetOptionsChanged,
    onOutOfStockChanged,
    onExportModalToggled,
    onCriteriaCleared,
    onHazardsChanged,
    onLocationsChanged,
  };
}
