import { default as lodashDebounce } from "lodash/debounce";
import * as FileSaver from "file-saver";

export const addIfNotExists = (arr: number[], item: number): number[] => {
  if (!arr.includes(item)) {
    const copy = [...arr];
    copy.push(item);
    return copy;
  }

  return arr;
};

export const arrayEquals = (array1: unknown[], array2: unknown[]): boolean => {
  if (array1 === array2) {
    return true;
  }
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((e: unknown) => array2.includes(e));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => any>(func: T, wait?: number): T {
  // this really only exists because it is impossible to test a function that uses lodash's debounce
  // @ts-ignore
  return lodashDebounce(func, wait);
}

export function bytesToString(bytes: number): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) {
    return "n/a";
  }
  const i = Number.parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
}

export function mbToBytes(mb: number): number {
  return mb * 1_048_576;
}

export function findFirstInById<T>(byId: { [id: number]: T }, filter: (obj: T) => boolean): T | undefined {
  const keys = Object.keys(byId);
  // eslint-disable-next-line no-restricted-syntax
  for (const element of keys) {
    const obj: T = byId[Number.parseInt(element)];
    if (filter(obj)) {
      return obj;
    }
  }

  return undefined;
}

export function findInById<T>(byId: { [id: number]: T }, filter: (obj: T) => boolean): T[] {
  const matches: T[] = [];
  const keys = Object.keys(byId);
  // eslint-disable-next-line no-restricted-syntax
  for (const element of keys) {
    const obj: T = byId[Number.parseInt(element)];
    if (filter(obj)) {
      matches.push(obj);
    }
  }

  return matches;
}

export function saveAs(blob: Blob, fileName: string): void {
  FileSaver.saveAs(blob, fileName, { autoBom: false });
}

export interface ById {
  id: number;
}

export function reduceById<T extends ById>(): (previousValue: T[], currentValue: T, currentIndex: number, array: T[]) => T[] {
  return (accum: T[], item: T) => {
    // eslint-disable-next-line no-param-reassign
    accum[item.id] = item;
    return accum;
  };
}

export function createReduced<T extends ById>(items: T[]): { [id: number]: T } {
  return items.reduce(reduceById<T>(), []);
}

export function parseIntOrUndefined(toParse: string): number | undefined {
  const num = Number.parseInt(toParse);
  if (Number.isNaN(num)) {
    return undefined;
  }
  return num;
}

export function arrayUnique<T>(array: T[]): T[] {
  return array.filter((item, index) => array.indexOf(item) === index);
}
