import { FreezerBoxExtended } from "@labarchives/inventory-shared/build/inventory";
import * as utils from "../utils";
import { StorageLocationView } from "../storage/types/views";
import { FreezerCapacityReportExcel } from "./FreezerCapacityReportExcel";

export interface FreezerCapacityReportDownloadHooks {
  onReportDownloaded(items: FreezerBoxExtended[], fileName: string): Promise<void>;
}

export interface FreezerCapacityReportDownloadHooksProps {
  storage: StorageLocationView;
}

export function useFreezerCapacityReportDownload(props: FreezerCapacityReportDownloadHooksProps): FreezerCapacityReportDownloadHooks {
  async function onReportDownloaded(items: FreezerBoxExtended[], fileName: string): Promise<void> {
    const excel = FreezerCapacityReportExcel.CreateExport(items, props.storage);
    const blob = await excel.AsBlob();
    utils.saveAs(blob, fileName);
  }

  return {
    onReportDownloaded,
  };
}
