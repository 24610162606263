import React, { ReactElement } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/pro-light-svg-icons";

export const ArrowIcon = (arrowProps: { isOpen: boolean; className?: string }): ReactElement => {
  const { isOpen, className } = arrowProps;
  const baseClass = "arrow";
  const classes = classnames(baseClass, { [`${baseClass}--closed`]: !isOpen }, { [`${baseClass}--open`]: isOpen }, className);
  return (
    <span className={classes}>
      {isOpen && <FontAwesomeIcon icon={faChevronDown}/>}
      {!isOpen && <FontAwesomeIcon icon={faChevronRight}/>}
    </span>
  );
};