import { Button } from "@labarchives/ui-design";
import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InventoryApi } from "../api/InventoryApi";
import { useItemExpirationReport } from "./ItemExpirationReportHooks";
import { ItemReportDownloadHooks } from "./ItemReportDownloadHooks";

export function ItemExpirationReport(props: { downloadHooks: ItemReportDownloadHooks; api: InventoryApi }): ReactElement {
  const { onReportDownloaded } = useItemExpirationReport({
    downloadHooks: props.downloadHooks,
    api: props.api,
  });

  const intl = useIntl();

  return (
    <div>
      <h2 className={"reports-header"}>
        <FormattedMessage id={"expiration.report.nav"} />
      </h2>

      <div className={"mt-3 mb-3"}>
        <FormattedMessage id={"expiration.report.description"} />
      </div>

      <div>
        <Button
          outline={true}
          onClick={() => onReportDownloaded(30)}
          aria-label={intl.formatMessage({ id: "download.report.expiring.within" }, { days: 30 })}
        >
          <img src="/images/Inventory_Export.svg" className="link button-icon" alt={intl.formatMessage({ id: "export.items" })} />
          <FormattedMessage id={"report.expiring.within"} values={{ days: "30" }} />
        </Button>
        <Button
          outline={true}
          className={"ms-3"}
          onClick={() => onReportDownloaded(60)}
          aria-label={intl.formatMessage({ id: "download.report.expiring.within" }, { days: 60 })}
        >
          <img src="/images/Inventory_Export.svg" className="link button-icon" alt={intl.formatMessage({ id: "export.items" })} />
          <FormattedMessage id={"report.expiring.within"} values={{ days: "60" }} />
        </Button>
        <Button
          outline={true}
          className={"ms-3"}
          onClick={() => onReportDownloaded(90)}
          aria-label={intl.formatMessage({ id: "download.report.expiring.within" }, { days: 90 })}
        >
          <img src="/images/Inventory_Export.svg" className="link button-icon" alt={intl.formatMessage({ id: "export.items" })} />
          <FormattedMessage id={"report.expiring.within"} values={{ days: "90" }} />
        </Button>
      </div>
    </div>
  );
}
