import {
  AccountSubscriptionSummary,
  ApplicationPricing,
  OrganizationBillingType,
  SubscriptionCart,
  SubscriptionType,
  User,
  UserStatus,
} from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useMemo, useState } from "react";
import { UserState } from "../user/types/state";
import { findInById } from "../utils";
import { isValidationError } from "../utils/errorHelpers";
import { InventoryApi } from "../api/InventoryApi";
import { getAccountSubscriptionSummaryView, getApplicationPricingView } from "./selectors";
import { AccountSubscriptionSummaryView, ApplicationTypePricingView } from "./views";

export interface SubscriptionManagementHooks {
  subscriptionSummary: AccountSubscriptionSummaryView;
  currentPricing: ApplicationTypePricingView;
  numberOfAccountUsers: number;
  numberOfUsers: number;
  isLoading: boolean;
  checkoutErrorMessage: string;
  billingType: OrganizationBillingType;

  calculateTotal(users: number): number;

  onCheckout(users: number): Promise<void>;

  onOrganizationBillingTypeChange(billingType: OrganizationBillingType): void;

  onNumberOfUsersChanged(users: string): void;
}

export function useSubscriptionManagement(user: User, userState: UserState, api: InventoryApi): SubscriptionManagementHooks {
  const [subscriptionSummary, setSubscriptionSummary] = useState<AccountSubscriptionSummary | undefined>();
  const [pricing, setPricing] = useState<ApplicationPricing | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState("");
  const [currentPricing, setCurrentPricing] = useState<ApplicationTypePricingView>(
    getApplicationPricingView(pricing).forBillingType(OrganizationBillingType.Academic),
  );

  const numberOfAccountUsers = useMemo(() => {
    if (!subscriptionSummary || subscriptionSummary.subscription.type === SubscriptionType.Free) {
      return Math.max(1, findInById(userState.byId, (u) => u.status !== UserStatus.Inactive).length);
    }

    return subscriptionSummary.subscription.seatCount;
  }, [subscriptionSummary]);

  const [billingType, setBillingType] = useState(OrganizationBillingType.Academic);
  const [numberOfUsers, setNumberOfUsers] = useState(numberOfAccountUsers);

  function calculateTotal(users: number): number {
    if (!pricing) {
      return 0;
    }

    if (!currentPricing) {
      return 0;
    }

    return currentPricing.oneYearCostPerUser * users;
  }

  function onOrganizationBillingTypeChange(newBillingType: OrganizationBillingType): void {
    setBillingType(newBillingType);
    if (pricing) {
      setCurrentPricing(getApplicationPricingView(pricing).forBillingType(newBillingType));
    }
  }

  async function onCheckout(users: number): Promise<void> {
    setCheckoutErrorMessage("");
    try {
      const isRenewal = subscriptionSummary && subscriptionSummary.subscription.type === SubscriptionType.Paid;
      const cart: SubscriptionCart = await (isRenewal
        ? api.renewSubscription(users, 1, billingType)
        : api.purchaseSubscription(users, 1, billingType));
      if (cart.paymentUrl === "") {
        setCheckoutErrorMessage("We had an issue processing your payment. Please try again.");
      } else {
        window.location.href = cart.paymentUrl;
      }
    } catch (error) {
      if (isValidationError(error)) {
        // @ts-ignore
        setCheckoutErrorMessage(error.response.data.message);
      } else {
        throw error;
      }
    }
  }

  function onNumberOfUsersChanged(users: string): void {
    const u = Number.parseInt(users);

    if (u >= numberOfAccountUsers) {
      setNumberOfUsers(u);
    }
  }

  useEffect(() => {
    const loadSubscriptionInfo = async (): Promise<void> => {
      setIsLoading(true);
      const p = await api.getApplicationPricing();
      const summary = await api.getAccountSubscriptionSummary();

      setPricing(p);
      setSubscriptionSummary(summary);
      setBillingType(summary.organizationBillingType);
      setCurrentPricing(getApplicationPricingView(p).forBillingType(summary.organizationBillingType));
      setIsLoading(false);
    };

    loadSubscriptionInfo();
  }, []);

  return {
    subscriptionSummary: getAccountSubscriptionSummaryView(subscriptionSummary),
    currentPricing,
    isLoading: isLoading || userState.isLoading,
    numberOfAccountUsers: Math.max(1, numberOfAccountUsers),
    numberOfUsers: Math.max(numberOfAccountUsers, numberOfUsers),
    checkoutErrorMessage,
    billingType,
    calculateTotal,
    onCheckout,
    onOrganizationBillingTypeChange,
    onNumberOfUsersChanged,
  };
}
