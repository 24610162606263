import Excel from "exceljs";
import { LocationsImportItem } from "@labarchives/inventory-shared/build/inventory";
import { ExcelFile } from "../../components/Excel/ExcelFile";
import { ExcelSheet } from "../../components/Excel/ExcelSheet";
import { ParseResultError } from "../../inventory/import/ParseResultError";
import { LocationImportParseResult } from "./LocationImportParseResult";

export class LocationsExcel extends ExcelFile {
  public static GetTemplate(): LocationsExcel {
    const excel = new LocationsExcel();
    const sheet = new ExcelSheet("Locations");
    sheet.AddColumns(["Building", "Floor", "Room"]);
    sheet.SetRequiredColumns(["Building", "Room"]);
    excel.sheets.push(sheet);
    return excel;
  }

  public static Create(): LocationsExcel {
    return new LocationsExcel();
  }

  public async Parse(file: File): Promise<LocationImportParseResult> {
    try {
      const workbook = await new Promise<Excel.Workbook>((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(new Excel.Workbook().xlsx.load(reader.result as Excel.Buffer)));
        reader.readAsArrayBuffer(file);
      });

      if (workbook.worksheets.length === 0) {
        throw new Error("LocationsExcel missing worksheet");
      }

      const sheet = workbook.worksheets[0];

      if (sheet.columns.length !== 3) {
        return {
          isValid: false,
          items: [],
          errors: [{ id: "location.upload.error.missing.required.columns" }],
        };
      }

      const errors: ParseResultError[] = [];
      const items: LocationsImportItem[] = [];
      const allItems: LocationsImportItem[] = [];

      sheet.eachRow((row, rowNumber) => {
        let hasError = false;
        if (rowNumber !== 1 /* header */) {
          const building = row.getCell(1)?.value;
          const floor = row.getCell(2)?.value;
          const room = row.getCell(3)?.value;

          const location: LocationsImportItem = {
            building: (building || "").toString(),
            floor: floor?.toString() || null,
            room: (room || "").toString(),
          };

          if (location.building === "") {
            errors.push({ id: "location.upload.error.missing.building", values: { rowNumber } });
            hasError = true;
          }
          if (location.room === "") {
            errors.push({ id: "location.upload.error.missing.room", values: { rowNumber } });
            hasError = true;
          }

          const duplicates = this.GetDuplicates(location, allItems);
          allItems.push(location);
          if (duplicates) {
            errors.push({ id: "location.upload.error.duplicate.row", values: { rowNumber, duplicates } });
            hasError = true;
          }

          if (!hasError) {
            items.push(location);
          }
        }
      });

      return {
        errors,
        isValid: errors.length === 0,
        items,
      };
    } catch {
      return { isValid: false, items: [], errors: [{ id: "location.upload.error.unable.to.parse" }] };
    }
  }

  private GetDuplicates(location: LocationsImportItem, items: LocationsImportItem[]): string | null {
    const duplicates: number[] = [];
    items.forEach((i, index) => {
      if (location.building === i.building && location.floor === i.floor && location.room === i.room) {
        duplicates.push(index + 2);
      }
    });

    if (duplicates.length === 0) {
      return null;
    }

    return duplicates.join(", ");
  }
}
