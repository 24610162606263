import React, { useEffect, useState } from "react";
import { RolePermissions, User } from "@labarchives/inventory-shared/build/inventory";
import { history } from "../../app/history";
import { AuthenticationService } from "./AuthenticationService";
import { AuthenticationState } from "./AuthenticationState";
import { AccessDeniedError } from "./AccessDeniedError";
import { InventoryApi } from "../../api/InventoryApi";

function noop(): void {
  // no-op
}

function asyncNoop(): Promise<void> {
  return Promise.resolve();
}

const defaultAuthentication: AuthenticationState = {
  hasPermissions(): boolean {
    return false;
  },
  isAuthenticated(): boolean {
    return false;
  },
  isAccessDenied(): boolean {
    return false;
  },
  hasSubscriptionExpired(): boolean {
    return false;
  },
  isAccountOwner(): boolean {
    return false;
  },
  getFullName: () => {
    return "";
  },
  getUser: () => {
    return undefined;
  },
  login: noop,
  logout: noop,
  switchAccount: asyncNoop,
  isLoading: true,
  getCurrency(): string {
    return "USD";
  },
  refreshUser: asyncNoop,
  addAccount: asyncNoop,
  restartTrial: asyncNoop,
};

export const AuthenticationContext = React.createContext<AuthenticationState>(defaultAuthentication);

export function useAuthenticationContext(authenticationService: AuthenticationService, api: InventoryApi): AuthenticationState {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isAccessDenied, setIsAccessDenied] = useState(false);

  function login(authenticatedUser: User): void {
    setUser(authenticatedUser);
  }

  function logout(): void {
    setIsLoading(true);
    setUser(undefined);
    authenticationService.logout();
  }

  function getFullName(): string {
    return user ? user.fullName : "";
  }

  function isAuthenticated(): boolean {
    return authenticationService.isAuthenticated() && user !== undefined && !isLoading;
  }

  function hasPermissions(permissions: RolePermissions[]): boolean {
    if (permissions.length === 0) {
      return true;
    }

    if (!user) {
      return false;
    }

    return permissions.some((p) => user.permissions.some((u) => u.permissionId === p));
  }

  async function switchAccount(accountId: number, returnHome = true): Promise<void> {
    setIsLoading(true);
    const switchedUser = await api.switchUserAccount({ accountId });
    setUser(switchedUser);
    if (returnHome === undefined || returnHome) {
      history.push("/");
    }
    window.location.reload();
    setIsLoading(false);
  }

  async function addAccount(accountName: string): Promise<void> {
    setIsLoading(true);
    const updatedUser = await api.addAccount(accountName);
    setUser(updatedUser);
    history.push("/");
    window.location.reload();
    setIsLoading(false);
  }

  async function refreshUser(): Promise<void> {
    const latest = await api.getUser();
    setUser(latest);
  }

  async function restartTrial(): Promise<void> {
    setIsLoading(true);
    const response = await api.restartTrial();
    const updatedUser = response.user;
    setUser(updatedUser);
    window.location.reload();
  }

  function getCurrency(): string {
    return user ? user.currency : "USD";
  }

  function isAccountOwner(): boolean {
    return user ? user.isAccountOwner : false;
  }

  function hasSubscriptionExpired(): boolean {
    return user ? user.activeAccount.subscriptionHasExpired : true;
  }

  useEffect(() => {
    setIsLoading(true);
    setIsAccessDenied(false);
    authenticationService
      .signIn(api, { locale: navigator.language, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then((authenticatedUser) => {
        setUser(authenticatedUser);
        setIsLoading(false);
        return authenticatedUser;
      })
      .catch((error) => {
        if (error instanceof AccessDeniedError) {
          setIsAccessDenied(true);
        }
        setUser(undefined);
        setIsLoading(false);
      });
  }, []);

  return {
    isAuthenticated,
    getFullName,
    login,
    logout,
    hasPermissions,
    switchAccount,
    getUser: () => {
      return user;
    },
    isLoading,
    getCurrency,
    refreshUser,
    isAccessDenied(): boolean {
      return isAccessDenied;
    },
    isAccountOwner,
    hasSubscriptionExpired,
    addAccount,
    restartTrial,
  };
}
