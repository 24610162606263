import React, { Fragment, ReactElement } from "react";
import { Button, Modal } from "@labarchives/ui-design";
import { Col, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { InventoryApi } from "../../api/InventoryApi";
import { useAddSubscriptionUsersAccountService } from "./AddSubscriptionUsersAccountServiceHooks";

interface AddSubscriptionUsersAccountSubscriptionModalProps {
  isOpen: boolean;
  subscriptionId: string;
  api: InventoryApi;

  toggleModal(): void;
}

export function AddSubscriptionUsersAccountSubscriptionModal(props: AddSubscriptionUsersAccountSubscriptionModalProps): ReactElement {
  const { numberOfUsers, subscriptionTotal, setUsers, isPurchasing, onCheckout } = useAddSubscriptionUsersAccountService(props);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggleModal}>
      <ModalHeader toggle={props.toggleModal}>
        <FormattedMessage id="add.users.to.subscription" />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div>
              <FormGroup>
                <Label htmlFor={"add-subscription-users-txt"}>
                  <FormattedMessage id="users.pluralized" values={{ users: numberOfUsers }} />
                </Label>
                <Input
                  id={"add-subscription-users-txt"}
                  type="number"
                  min={1}
                  step={1}
                  value={numberOfUsers}
                  onChange={(e) => setUsers(Number.parseInt(e.target.value))}
                />
              </FormGroup>
            </div>
          </Col>

          <Col>
            <div className="add-subscription-users-total">${subscriptionTotal.total}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="add-subscription-users-description">{subscriptionTotal.description}</div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {isPurchasing && <div>Redirecting to secure checkout...</div>}
        {!isPurchasing && (
          <Fragment>
            <Button id={"add-subscription-users-checkout-button"} onClick={() => onCheckout()}>
              <FormattedMessage id="checkout" />
            </Button>
            <CancelButton onClick={props.toggleModal} />
          </Fragment>
        )}
      </ModalFooter>
    </Modal>
  );
}
