import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useEffect, useRef } from "react";
import { FormGroup, Label } from "reactstrap";
import { Button, Input } from "@labarchives/ui-design";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { AdvancedSearchCriteria, AdvancedSearchCriteriaMatchType, CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { CustomAttributeDefinitionView, InventoryTypeView } from "../../types/views";
import { isEnterKey } from "../../../utils/isEnterKey";
import { useInventoryAdvancedSearchCriteriaRow } from "./InventoryAdvancedSearchCriteriaRowHooks";
import { InventoryAdvancedSearchDropdown } from "./InventoryAdvancedSearchDropdown";
import { InventoryAdvancedSearchTextbox } from "./InventoryAdvancedSearchTextbox";
import { InventoryAdvancedSearchNumber } from "./InventoryAdvancedSearchNumber";
import { InventoryAdvancedSearchDate } from "./InventoryAdvancedSearchDate";
import { InventoryAdvancedSearchCheckbox } from "./InventoryAdvancedSearchCheckbox";
import { InventoryAdvancedSearchRadio } from "./InventoryAdvancedSearchRadio";

interface InventoryAdvancedSearchCriteriaRowProps {
  criteria: AdvancedSearchCriteria;
  isDuplicated: boolean;
  isExcluded: boolean;
  inventoryTypeViews: InventoryTypeView[];
  allAttributes: CustomAttributeDefinitionView[];

  hasFocus: boolean;

  onCriteriaChanged(index: number, fieldName: string, matchType: AdvancedSearchCriteriaMatchType, values: string[], attributeId?: number): void;

  onCriteriaRemoved(index: number): void;
}

export function InventoryAdvancedSearchCriteriaRow(props: InventoryAdvancedSearchCriteriaRowProps): ReactElement {
  const { inventoryTypeViews, criteria, allAttributes, isDuplicated, isExcluded, hasFocus, onCriteriaChanged, onCriteriaRemoved } = props;

  const intl = useIntl();
  const {
    matchType,
    values,
    selectedFieldName,
    // currentlySelectedField,
    selectedAttribute,
    selectedType,
    standardFields,
    // onFieldSelected,
    onSelectedFieldChanged,
    onMatchTypeChanged,
    onValueChanged,
    onCheckboxChanged,
  } = useInventoryAdvancedSearchCriteriaRow({
    criteria,
    onCriteriaChanged,
    attributes: allAttributes,
  });

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hasFocus && ref.current) {
      ref.current.focus();
    }
  }, [hasFocus]);

  const defaultValue = values.length > 0 ? values[0] : "";
  const defaultEndValue = values.length > 1 ? values[1] : "";

  function isChecked(value: string): boolean {
    return values.includes(value);
  }

  let className = "inventory-search-advanced-search-row";
  if (selectedFieldName === "") {
    className += " empty";
  }
  if (isDuplicated) {
    className += " duplicated";
  }
  if (isExcluded) {
    className += " excluded";
  }

  return (
    <div className={className}>
      <div className="inventory-search-advanced-search-row-criteria">
        <div>
          <FormGroup>
            <Label className="visually-hidden" for={`attribute${criteria.index}`}>
              Search Field
            </Label>
            <Input
              id={`attribute${criteria.index}`}
              bsSize="sm"
              type={"select"}
              value={selectedFieldName}
              onChange={(e) => onSelectedFieldChanged(e.target.value)}
              // onClick={() => onFieldSelected()}
              // onBlur={() => onFieldSelected()}
              // onKeyDown={(e) => {
              //   if (isEnterKey(e.key)) {
              //     onFieldSelected();
              //   }
              // }}
              innerRef={ref}
            >
              {selectedFieldName === "" && <option value="">And...</option>}
              {standardFields.map((f) => (
                <option key={f.name} value={f.name}>
                  {intl.formatMessage({ id: f.label })}
                </option>
              ))}

              {inventoryTypeViews.map((i) => (
                <optgroup label={i.name} key={i.id}>
                  {i.attributes.map((a) => (
                    <option key={a.inputId} value={a.inputId}>
                      {a.label}
                    </option>
                  ))}
                </optgroup>
              ))}
            </Input>
          </FormGroup>
        </div>
        {selectedFieldName !== "" && (
          <>
            {selectedType === CustomAttributeType.Textbox && (
              <InventoryAdvancedSearchTextbox
                onValueChanged={onValueChanged}
                onMatchTypeChanged={onMatchTypeChanged}
                defaultValue={defaultValue}
                matchType={matchType}
                index={criteria.index}
              />
            )}

            {selectedType === CustomAttributeType.Number && (
              <InventoryAdvancedSearchNumber
                onValueChanged={onValueChanged}
                onMatchTypeChanged={onMatchTypeChanged}
                defaultValue={defaultValue}
                defaultEndValue={defaultEndValue}
                matchType={matchType}
                index={criteria.index}
              />
            )}

            {selectedType === CustomAttributeType.Date && (
              <InventoryAdvancedSearchDate
                onValueChanged={onValueChanged}
                onMatchTypeChanged={onMatchTypeChanged}
                defaultValue={defaultValue}
                defaultEndValue={defaultEndValue}
                matchType={matchType}
                index={criteria.index}
              />
            )}
            {selectedAttribute && (
              <>
                {selectedType === CustomAttributeType.Dropdown && (
                  <InventoryAdvancedSearchDropdown
                    index={criteria.index}
                    matchType={matchType}
                    defaultValue={defaultValue}
                    onMatchTypeChanged={onMatchTypeChanged}
                    selectedAttribute={selectedAttribute}
                    onValueChanged={onValueChanged}
                  />
                )}

                {selectedType === CustomAttributeType.Checkbox && (
                  <InventoryAdvancedSearchCheckbox
                    index={criteria.index}
                    matchType={matchType}
                    defaultValue={defaultValue}
                    onMatchTypeChanged={onMatchTypeChanged}
                    selectedAttribute={selectedAttribute}
                    onValueChanged={onCheckboxChanged}
                    isChecked={isChecked}
                  />
                )}

                {selectedType === CustomAttributeType.Radio && (
                  <InventoryAdvancedSearchRadio
                    index={criteria.index}
                    matchType={matchType}
                    defaultValue={defaultValue}
                    onMatchTypeChanged={onMatchTypeChanged}
                    selectedAttribute={selectedAttribute}
                    onValueChanged={onValueChanged}
                    isChecked={isChecked}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>

      <div className={"remove-criteria"}>
        {selectedFieldName !== "" && (
          <Button color={"link"} title={intl.formatMessage({ id: "remove" })} onClick={() => onCriteriaRemoved(criteria.index)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </div>

      {isDuplicated && !isExcluded && (
        <div className={"duplicated-note"}>
          <FormattedMessage id="duplicated.criteria" />
        </div>
      )}
      {isExcluded && (
        <div className={"excluded-note"}>
          <FormattedMessage id="excluded.criteria" />
        </div>
      )}
    </div>
  );
}
