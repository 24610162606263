import React, { ReactElement, useEffect, useState } from "react";
import "./CheckboxTree.scss";
import { ArrowIcon } from "./ArrowIcon";
import { CheckBoxIcon } from "./CheckBoxIcon";
import { TreeData } from "./TreeData";
import { TreeViewNodeRendererProps } from "./TreeViewNodeRendererProps";
import { FlatNode } from "./FlatNode";
import { CheckboxTreeOnSelectProps } from "./CheckboxTreeOnSelectProps";
import TreeView, { flattenTree } from "./index";

interface CheckboxTreeProps {
  data: TreeData[];

  onChecked(checkedIds: string[]): void;

  defaultChecked: string[];
  defaultExpanded: string[];

  getSpanProps?(element: TreeData): Record<string, string>;
}

export function CheckboxTree(props: CheckboxTreeProps): ReactElement {
  const [treeData, setTreeData] = useState<FlatNode[]>(flattenTree({ name: "", children: [] }));
  const [defaultSelected, setDefaultSelected] = useState<number[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  function onSelect(selectProps: CheckboxTreeOnSelectProps): void {
    props.onChecked(treeData.filter((td) => selectProps.treeState.selectedIds.has(td.id)).map((td) => td.value));
  }

  function getData(data: TreeData[]): FlatNode[] {
    if (data.length === 0) {
      return flattenTree({ name: "", children: [] });
    }

    return flattenTree({ name: "", children: data });
  }

  function getDefaultSelected(tree: FlatNode[]): number[] {
    const selected = tree.filter((d) => props.defaultChecked.includes(d.value));

    return selected.map((d) => d.id);
  }

  function getSpanProps(element: TreeData): Record<string, string> {
    if (props.getSpanProps) {
      return props.getSpanProps(element);
    }

    return {};
  }

  useEffect(() => {
    const tree = getData(props.data);
    setTreeData(tree);
    setDefaultSelected(getDefaultSelected(tree));
    setIsLoaded(props.data.length > 0);
  }, [props.data, props.defaultChecked]);

  if (isLoaded) {
    return (
      <TreeView
        // @ts-ignore
        data={treeData}
        multiSelect
        propagateSelect
        propagateSelectUpwards
        togglableSelect
        defaultSelectedIds={defaultSelected}
        onSelect={onSelect}
        nodeRenderer={(nodeRendererProps: TreeViewNodeRendererProps) => {
          const { getNodeProps, handleExpand, isBranch, isExpanded, handleSelect, isHalfSelected, isSelected, element } = nodeRendererProps;
          const selectedVal = isSelected ? "all" : "none";
          return (
            <div {...getNodeProps({ onClick: handleExpand })} style={{}}>
              {isBranch && <ArrowIcon isOpen={isExpanded} />}
              {!isBranch && <span className={"arrow"}>&nbsp;</span>}
              <CheckBoxIcon
                onClick={(e) => {
                  handleSelect(e);
                  e.stopPropagation();
                }}
                variant={isHalfSelected ? "some" : selectedVal}
              />
              <span className="name checkbox-tree-label" {...getSpanProps(element)}>
                {element.label}
              </span>
            </div>
          );
        }}
      />
    );
  }

  return <></>;
}
