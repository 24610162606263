import { FreezerBoxDisplayFormat } from "@labarchives/inventory-shared/build/inventory";
import React, { ReactElement } from "react";
import { getFormattedFreezerBoxCells } from "./selectors";

interface FormattedFreezerBoxCellsProps {
  cells: string[];
  format: FreezerBoxDisplayFormat | null;
  rows: number | null;
  cols: number | null;
}

export const FormattedFreezerBoxCells = (props: FormattedFreezerBoxCellsProps): ReactElement => {
  return <>{getFormattedFreezerBoxCells(props.cells, props.format, props.rows, props.cols)}</>;
};
