import React, { ReactElement } from "react";
import { Loading } from "../../components";
import { InventoryApi } from "../../api/InventoryApi";
import { BundleCard } from "./BundleCard";
import { useBundleUpgradeRenewal } from "./BundleUpgradeRenewalHooks";

interface BundleUpgradeRenewalCardProps {
  numberOfUsers: number;
  onNumberOfUsersChanged(users: string): void;
  className?: string;
  api: InventoryApi;
}

export function BundleUpgradeRenewalCard(props: BundleUpgradeRenewalCardProps): ReactElement {
  const { isLoading, isBundleAllowed, numberOfUsers, bundleMinimumUsers, currentBundlePricing, perYearTotal, onBundleCheckout } =
    useBundleUpgradeRenewal({
      numberOfUsers: props.numberOfUsers,
      api: props.api,
    });

  if (!isBundleAllowed) {
    return <></>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BundleCard
      numberOfUsers={numberOfUsers}
      callToActionTextId={"bundle.up"}
      minimumNumberOfUsers={bundleMinimumUsers}
      onNumberOfUsersChanged={props.onNumberOfUsersChanged}
      perUserPrice={currentBundlePricing}
      totalPrice={perYearTotal}
      onCheckout={onBundleCheckout}
      className={props.className}
    />
  );
}
