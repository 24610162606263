import React, { ReactElement, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { RouteComponentProps } from "react-router";
import { Loading } from "../../components";
import { RoleState } from "../types/state";
import { RolesContext } from "../RolesContext";
import "./ManageRoles.scss";
import { ManageInventoryTypesProps } from "../../inventorytypes/management/ManageInventoryTypesHooks";
import { parseIntOrUndefined } from "../../utils";
import { UsersContext } from "../../user/UsersContext";
import { useManageRoles } from "./ManageRolesHooks";
import { AddRole } from "./AddRole";
import { DeleteRoleModal } from "./DeleteRoleModal";
import { ManageRole } from "./ManageRole";
import { InventoryApiClient } from "../../api";

interface RouteParams {
  id: string;
}

function getManageRolesRouteProps(props: RouteComponentProps<RouteParams>): ManageInventoryTypesProps {
  return {
    isAdding: props.match.params.id === "add",
    id: parseIntOrUndefined(props.match.params.id),
  };
}

export function ManageRoles(props: RouteComponentProps<RouteParams>): ReactElement {
  const routeProps = getManageRolesRouteProps(props);

  const {
    roleViews,
    activeRoleView,
    isAdding,
    isDeleting,
    isDeleteFailed,
    isEditing,
    isDeleteAllowed,
    onAddRoleToggle,
    onRoleUpdated,
    onRoleAdded,
    onEditToggle,
    onRoleDeleted,
    onDeleteToggle,
    isRoleValid,
  } = useManageRoles({
    roleState: useContext(RolesContext),
    userState: useContext(UsersContext),
    isAdding: routeProps.isAdding,
    api: new InventoryApiClient(),
  });

  if (useContext<RoleState>(RolesContext).isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="management-header">
        <h1 className="inventory-heading">
          <FormattedMessage id="roles" />
        </h1>

        <Button className="management-header-action" color="link" onClick={onAddRoleToggle}>
          <FontAwesomeIcon icon={faPlusCircle} /> <FormattedMessage id="add.new.role" />
        </Button>
      </div>
      <Row>
        <Col className="management-container-contents">
          {isAdding && <AddRole onRoleAdded={onRoleAdded} onCancel={onAddRoleToggle} isRoleValid={isRoleValid} />}

          {!isAdding &&
            roleViews.map((roleView, index) => (
              <div key={roleView.id} className={classnames("management-panel", { "manage-roles-row-alt": index % 2 !== 0 })}>
                <ManageRole
                  roleView={roleView}
                  onRoleUpdated={onRoleUpdated}
                  isEditingAnother={isEditing && activeRoleView?.id !== roleView.id}
                  isEditing={isEditing && activeRoleView?.id === roleView.id}
                  onEditToggle={() => onEditToggle(roleView)}
                  onDeletePrompt={() => onDeleteToggle(roleView)}
                  isRoleValid={isRoleValid}
                />
              </div>
            ))}
        </Col>
      </Row>
      <DeleteRoleModal
        isOpen={isDeleting}
        role={activeRoleView}
        onToggle={onDeleteToggle}
        onDelete={onRoleDeleted}
        isDeleteAllowed={isDeleteAllowed}
        showFailureMessage={isDeleteFailed}
      />
    </div>
  );
}
