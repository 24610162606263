import { useEffect, useState } from "react";
import uniq from "lodash/uniq";
import { OrganizationBillingType, SubscriptionType } from "@labarchives/inventory-shared/build/inventory";
import { Settings } from "../utils/Settings";
import { InventoryApi } from "../api/InventoryApi";
import { getDefaultSuperUserAccountDetailsView, getSuperUserAccountDetailsView, getSuperUserSearchResultsView } from "./selectors";
import { SuperUserAccountActivityHistoryView, SuperUserAccountDetailsView, SuperUserSearchResultView } from "./views";

export interface SuperUserAccountDetailsHooks {
  isLoading: boolean;
  accountNotFound: boolean;
  isEditing: boolean;
  isTransferring: boolean;
  isUpdateError: boolean;
  isAddingUser: boolean;
  isSearchingUsers: boolean;
  isBundling: boolean;
  historyUsers: string[];
  filteredActivityHistory: SuperUserAccountActivityHistoryView[];
  accountDetails: SuperUserAccountDetailsView;
  userSearchResults: SuperUserSearchResultView[];
  showSubscriptionDetails: boolean;

  setIsEditing(isEditing: boolean): void;

  setIsTransferring(isTransferring: boolean): void;

  setIsUpdateError(isEditing: boolean): void;

  setIsAddingUser(isAddingUser: boolean): void;

  setIsBundling(isBundling: boolean): void;

  onSubscriptionUpdate(begin: Date, end: Date, type: SubscriptionType, seatCount: number): void;

  onOwnerChanged(ownerId: number): void;

  onFilterActivityHistory(userName: string): void;

  onNotesChanged(notes: string): void;

  onNotesSaved(): void;

  onUserAdded(userId: number, isLabManager: boolean): void;

  onSearchUsers(term: string): void;

  onSubscriptionBundled(seatCount: number, totalAmountCharged: number, billingType: OrganizationBillingType, expirationDate: Date): Promise<void>;
}

export function useSuperUserAccountDetails(accountId: number, api: InventoryApi): SuperUserAccountDetailsHooks {
  const [isLoading, setIsLoading] = useState(true);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const [accountDetails, setAccountDetails] = useState(getDefaultSuperUserAccountDetailsView());
  const [isEditing, setIsEditing] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isUpdateError, setIsUpdateError] = useState(false);
  const [activityUserFilter, setActivityUserFilter] = useState("");
  const [userSearchResults, setUserSearchResults] = useState<SuperUserSearchResultView[]>([]);
  const [isSearchingUsers, setIsSearchingUsers] = useState(false);
  const [isBundling, setIsBundling] = useState(false);

  function onSubscriptionUpdate(begin: Date, end: Date, type: SubscriptionType, seatCount: number): void {
    setIsLoading(true);
    setIsEditing(false);
    api
      .superUserUpdateSubscriptionDetails({ begin, end, type, seatCount, subscriptionId: accountDetails.subscriptionId })
      .then((details) => {
        setAccountDetails(getSuperUserAccountDetailsView(details));
        setIsLoading(false);
        return details;
      })
      .catch((error) => {
        setIsUpdateError(true);
        setIsLoading(false);
        api.logError(error);
      });
  }

  function onOwnerChanged(ownerId: number): void {
    setIsLoading(true);
    setIsTransferring(false);
    api
      .superUserUpdateOwner({ ownerId, accountId: accountDetails.id })
      .then((details) => {
        setAccountDetails(getSuperUserAccountDetailsView(details));
        setIsLoading(false);
        return details;
      })
      .catch((error) => {
        setIsLoading(false);
        api.logError(error);
      });
  }

  function onNotesSaved(): void {
    setIsLoading(true);
    api
      .superUserUpdateNotes({ accountId: accountDetails.id, notes: accountDetails.notes })
      .then((details) => {
        setAccountDetails(getSuperUserAccountDetailsView(details));
        setIsLoading(false);
        return details;
      })
      .catch((error) => {
        setIsLoading(false);
        api.logError(error);
      });
  }

  function onUserAdded(userId: number, isLabManager: boolean): void {
    const isValid = !accountDetails.users.some((u) => u.id === userId);
    if (!isValid) {
      setIsAddingUser(false);
      return;
    }

    setIsLoading(true);
    api
      .superUserAddUser({ userId, isLabManager, accountId: accountDetails.id })
      .then((details) => {
        setUserSearchResults([]);
        setAccountDetails(getSuperUserAccountDetailsView(details));
        setIsAddingUser(false);
        setIsLoading(false);
        return details;
      })
      .catch((error) => {
        setIsAddingUser(false);
        setIsLoading(false);
        api.logError(error);
      });
  }

  function onSearchUsers(term: string): void {
    if (term.trim() === "") {
      return;
    }

    setIsSearchingUsers(true);
    api
      .superUserSearchUser(term.trim(), false)
      .then((results) => {
        setUserSearchResults(getSuperUserSearchResultsView(results.filter((r) => !accountDetails.users.some((u) => u.id === r.id))));
        setIsSearchingUsers(false);
        return results;
      })
      .catch((error) => {
        setIsSearchingUsers(false);
        api.logError(error);
      });
  }

  function onNotesChanged(notes: string): void {
    setAccountDetails({ ...accountDetails, notes });
  }

  function onFilterActivityHistory(userName: string): void {
    setActivityUserFilter(userName);
  }

  function onIsAddingUserChanged(isAddingUserVal: boolean): void {
    setIsAddingUser(isAddingUserVal);
    if (!isAddingUserVal) {
      setUserSearchResults([]);
    }
  }

  async function onSubscriptionBundled(
    seatCount: number,
    totalAmountCharged: number,
    billingType: OrganizationBillingType,
    expirationDate: Date,
  ): Promise<void> {
    setIsBundling(false);
    setIsLoading(true);

    await api.accountServiceSuperUserAddSubscription({
      ownerId: accountDetails.ownerId,
      billingType,
      productCode: Settings.getBundleProductCode(),
      seatCount,
      totalAmountCharged,
      expirationDate,
    });

    const details = await api.superUserGetAccountDetails(accountId);
    setAccountDetails(getSuperUserAccountDetailsView(details));

    setIsLoading(false);
  }

  useEffect(() => {
    api
      .superUserGetAccountDetails(accountId)
      .then((details) => {
        setAccountDetails(getSuperUserAccountDetailsView(details));
        setIsLoading(false);
        return details;
      })
      .catch((error) => {
        setAccountNotFound(true);
        setIsLoading(false);
        api.logError(error);
      });
  }, []);

  return {
    isLoading,
    accountNotFound,
    accountDetails,
    onSubscriptionUpdate,
    isEditing,
    setIsEditing,
    isTransferring,
    setIsTransferring,
    isUpdateError,
    setIsUpdateError,
    isAddingUser,
    setIsAddingUser: onIsAddingUserChanged,
    isSearchingUsers,
    setIsBundling,
    isBundling,
    showSubscriptionDetails: !accountDetails.hasSiteLicense,
    historyUsers: uniq(accountDetails.activityHistory.map((h) => h.userName)).sort(),
    filteredActivityHistory:
      activityUserFilter === "" ? accountDetails.activityHistory : accountDetails.activityHistory.filter((h) => h.userName === activityUserFilter),
    userSearchResults,
    onFilterActivityHistory,
    onOwnerChanged,
    onNotesChanged,
    onNotesSaved,
    onUserAdded,
    onSearchUsers,
    onSubscriptionBundled,
  };
}
