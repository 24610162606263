import { Role } from "@labarchives/inventory-shared/build/inventory";
import { createContext, useState, useEffect } from "react";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { createReduced, addIfNotExists } from "../utils";
import { NormalizedState } from "../utils/types";
import { Settings } from "../utils/Settings";
import { InventoryApi } from "../api/InventoryApi";
import { RoleState } from "./types/state";

const normalizedState: NormalizedState<Role> = {
  isLoading: false,
  byId: createReduced<Role>([]),
  allIds: [],
};

const defaultRoleState: RoleState = {
  ...normalizedState,
  onRoleAdded: (role: Role): Promise<Role> => Promise.resolve(role),
  onRoleUpdated: (role: Role): Promise<Role> => Promise.resolve(role),
  onRoleDeleted: (): Promise<void> => Promise.resolve(),
  refresh: () => Promise.resolve(),
};
export const RolesContext = createContext<RoleState>(defaultRoleState);

export function useRolesContext(api: InventoryApi): RoleState {
  const [localState, setLocalState] = useState(normalizedState);
  const [lastDataRefreshTime, setLastDataRefreshTime] = useState<Date>(new Date(0));

  const loadAllRoles = async (): Promise<void> => {
    setLocalState({ ...normalizedState, isLoading: true });
    api.getRoles().then((roles) => {
      const newState: NormalizedState<Role> = {
        allIds: roles.map((role) => role.id),
        byId: createReduced<Role>(roles),
        isLoading: false,
      };
      setLocalState(newState);
      setLastDataRefreshTime(clock.getNow());
      return roles;
    });
  };

  const addRole = async (role: Role): Promise<Role> => {
    try {
      const addedRole = await api.addRole(role);

      const newState: NormalizedState<Role> = {
        allIds: addIfNotExists(localState.allIds, addedRole.id),
        byId: { ...localState.byId, [addedRole.id]: addedRole },
        isLoading: false,
      };
      setLocalState(newState);

      return addedRole;
    } catch (error) {
      await loadAllRoles();
      throw error;
    }
  };

  const updateRole = async (role: Role): Promise<Role> => {
    try {
      const updatedRole = await api.updateRole(role);

      const newState: NormalizedState<Role> = {
        allIds: addIfNotExists(localState.allIds, updatedRole.id),
        byId: { ...localState.byId, [updatedRole.id]: updatedRole },
        isLoading: false,
      };
      setLocalState(newState);

      return updatedRole;
    } catch (error) {
      await loadAllRoles();
      throw error;
    }
  };

  const deleteRole = async (roleId: number): Promise<void> => {
    try {
      await api.deleteRole(roleId);

      const byId = { ...localState.byId };
      delete byId[roleId];
      const newState: NormalizedState<Role> = {
        allIds: localState.allIds.filter((id) => id !== roleId),
        byId,
        isLoading: false,
      };
      setLocalState(newState);
    } catch (error) {
      await loadAllRoles();
      throw error;
    }
  };

  const refresh = async (): Promise<void> => {
    if (!localState.isLoading && clock.addMinutes(lastDataRefreshTime, Settings.getDataCacheTimeout()) < clock.getNow()) {
      await loadAllRoles();
    }
  };

  useEffect(() => {
    loadAllRoles();
  }, []);

  return {
    isLoading: localState.isLoading,
    byId: localState.byId,
    allIds: localState.allIds,
    onRoleUpdated: updateRole,
    onRoleAdded: addRole,
    onRoleDeleted: deleteRole,
    refresh,
  };
}
