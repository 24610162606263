export function getUnits(): string[] {
  return [
    "units",
    "µg",
    "mg",
    "g",
    "kg",
    // "pmol",
    // "nmol",
    // "µmol",
    // "mmol",
    // "mol",
    // "oz",
    // "lb",
    "µL",
    "mL",
    "L",
    "bottles",
    "boxes",
    "cases",
    "kits",
    "packs",
    "tubes",
    "vials",
    // "fl oz",
    // "gal",
    // "mm",
    // "m",
    // "in",
    // "ft",
    // "yd",
  ];
}

export function getStandardizedUnit(unit: string): string {
  const match = getUnits().find((u) => u.toUpperCase() === unit.toUpperCase());
  if (match) {
    return match;
  }

  return "units";
}

export function getConvertibleUnits(unit: string, bothDirections = false): string[] {
  switch (unit) {
    case "L":
      return ["L", "mL", "µL"];
    case "mL":
      return bothDirections ? ["L", "mL", "µL"] : ["mL", "µL"];
    case "µL":
      return bothDirections ? ["L", "mL", "µL"] : ["µL"];
    case "kg":
      return ["kg", "g", "mg", "µg"];
    case "g":
      return bothDirections ? ["kg", "g", "mg", "µg"] : ["g", "mg", "µg"];
    case "mg":
      return bothDirections ? ["kg", "g", "mg", "µg"] : ["mg", "µg"];
    case "µg":
      return bothDirections ? ["kg", "g", "mg", "µg"] : ["µg"];
    default:
      return [unit];
  }
}

export class UnitQuantity {
  private readonly quantity: number;

  private readonly unit: string;

  public constructor(quantity: number, unit: string) {
    this.quantity = quantity;
    this.unit = getStandardizedUnit(unit);
  }

  public getQuantity(): number {
    return this.quantity;
  }

  public getUnit(): string {
    return this.unit;
  }

  public Subtract(unitQuantity: UnitQuantity): UnitQuantity {
    const conversionFactor = this.getConversionFactor(this.unit, unitQuantity.unit);

    const minuendDecimalPlaces = this.getDecimalPlaces(this.quantity);
    const subtrahendDecimalPlaces = this.getDecimalPlaces(unitQuantity.quantity);
    const conversionFactorDecimalPlaces = this.getDecimalPlaces(conversionFactor);

    const maxDecimal = Math.max(minuendDecimalPlaces, subtrahendDecimalPlaces, conversionFactorDecimalPlaces);
    const shift = Math.pow(10, maxDecimal);

    const intMinuend = this.quantity * shift;
    const intSubtrahend = unitQuantity.quantity * conversionFactor * shift;

    const intResult = intMinuend - intSubtrahend;

    const decimalResult = intResult / shift;

    return new UnitQuantity(decimalResult, this.unit);
  }

  private getDecimalPlaces(num: number): number {
    return (num.toString().split(".")[1] || []).length;
  }

  private getConversionFactor(toUnit: string, fromUnit: string): number {
    if (toUnit === fromUnit) {
      return 1;
    }

    switch (toUnit) {
      case "L": {
        if (fromUnit === "mL") {
          return 1 / 1000;
        }
        if (fromUnit === "µL") {
          return 1 / 1_000_000;
        }
        break;
      }
      case "mL":
        if (fromUnit === "L") {
          return 1000;
        }
        if (fromUnit === "µL") {
          return 1 / 1000;
        }
        break;
      case "µL":
        if (fromUnit === "L") {
          return 1_000_000;
        }
        if (fromUnit === "mL") {
          return 1000;
        }
        break;
      case "kg":
        if (fromUnit === "g") {
          return 1 / 1000;
        }
        if (fromUnit === "mg") {
          return 1 / 1_000_000;
        }
        if (fromUnit === "µg") {
          return 1 / 1_000_000_000;
        }
        break;
      case "g":
        if (fromUnit === "kg") {
          return 1000;
        }
        if (fromUnit === "mg") {
          return 1 / 1000;
        }
        if (fromUnit === "µg") {
          return 1 / 1_000_000;
        }
        break;
      case "mg":
        if (fromUnit === "kg") {
          return 1_000_000;
        }
        if (fromUnit === "g") {
          return 1000;
        }
        if (fromUnit === "µg") {
          return 1 / 1000;
        }
        break;
      case "µg":
        if (fromUnit === "kg") {
          return 1_000_000_000;
        }
        if (fromUnit === "g") {
          return 1_000_000;
        }
        if (fromUnit === "mg") {
          return 1000;
        }
        break;
      default:
        return 1;
    }

    return 1;
  }

  /**
   * @param toCompare
   * @return -1 if less, 0 of equal, 1 if greater
   */
  public Compare(toCompare: UnitQuantity): number {
    const result = this.Subtract(toCompare);
    if (result.quantity > 0) {
      return 1;
    }

    if (result.quantity < 0) {
      return -1;
    }

    return 0;
  }
}
