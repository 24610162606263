import { InventoryApi } from "../api/InventoryApi";
import { ItemReportDownloadHooks } from "./ItemReportDownloadHooks";

export interface ItemLowQuantityReportHooks {
  onReportDownloaded(): Promise<void>;
}

interface ItemLowQuantityReportHooksProps {
  downloadHooks: ItemReportDownloadHooks;
  api: InventoryApi;
}

export function useItemLowQuantityReport(props: ItemLowQuantityReportHooksProps): ItemLowQuantityReportHooks {
  const { downloadHooks, api } = props;

  async function onReportDownloaded(): Promise<void> {
    const items = await api.reportLowQuantity();
    return downloadHooks.onReportDownloaded(items, "low-quantity-report.xlsx");
  }

  return {
    onReportDownloaded,
  };
}
