import { InventoryItem, RequestedOrder } from "@labarchives/inventory-shared/build/inventory";
import React, { RefObject, useEffect, useState } from "react";
import moment from "moment";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { InventoryTypeView } from "../inventory/types/views";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { VendorState } from "../vendors/types/state";
import { history } from "../app/history";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { getInventoryTypeViews } from "../inventorytypes/selectors";
import { InventoryApi } from "../api/InventoryApi";
import { getOrderRequestView, getReorderRequestView } from "./selectors";
import { OrderRequestView } from "./types/views";

interface InputState {
  isValid: boolean;
  fieldName: string;
  value: string;
  required: boolean;
  ref: RefObject<HTMLInputElement>;
}

interface OrderRequestFormInputs {
  [id: string]: InputState;

  inventoryName: InputState;
  inventoryTypeId: InputState;
  quantity: InputState;
  price: InputState;
  vendorId: InputState;
  catalogNumber: InputState;
  poNumber: InputState;
  grantNumber: InputState;
  notes: InputState;
  requiredDate: InputState;
}

export interface OrderRequestHooks {
  formInputs: OrderRequestFormInputs;
  totalPrice: number;
  orderView: OrderRequestView;
  isLoading: boolean;
  enableNotifications: boolean;
  inventoryTypeViews: InventoryTypeView[];

  onNotifyMeChange(isChecked: boolean): void;

  onFormInputChange(fieldName: string, value: string): void;

  onClose(): void;

  onSubmitForm(): void;

  buildRequestedOrder(): RequestedOrder;
}

export function useOrderRequest(
  inventoryId: string | undefined,
  authState: AuthenticationState,
  inventoryTypesState: InventoryTypesState,
  vendorState: VendorState,
  api: InventoryApi,
): OrderRequestHooks {
  const [enableNotifications, setEnableNotifications] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderView, setOrderView] = useState<OrderRequestView>(getOrderRequestView(inventoryTypesState));
  const [formInputs, setFormInputs] = useState(getFormInputs(orderView));
  const [isLoading, setIsLoading] = useState(true);

  function getFormInputs(orderRequest: OrderRequestView): OrderRequestFormInputs {
    return {
      inventoryName: { fieldName: "inventoryName", value: orderRequest.inventoryName, required: true, isValid: true, ref: React.createRef() },
      inventoryTypeId: {
        fieldName: "inventoryTypeId",
        value: orderRequest.inventoryTypeId ? orderRequest.inventoryTypeId.toString() : "",
        required: true,
        isValid: true,
        ref: React.createRef(),
      },
      quantity: { fieldName: "quantity", value: orderRequest.quantity.toString(), required: true, isValid: true, ref: React.createRef() },
      price: { fieldName: "price", value: orderRequest.price.toString(), required: true, isValid: true, ref: React.createRef() },
      vendorId: { fieldName: "vendorId", value: orderRequest.vendorId, required: true, isValid: true, ref: React.createRef() },
      catalogNumber: { fieldName: "catalogNumber", value: orderRequest.catalogNumber, required: true, isValid: true, ref: React.createRef() },
      poNumber: { fieldName: "poNumber", value: orderRequest.poNumber, required: false, isValid: true, ref: React.createRef() },
      grantNumber: { fieldName: "grantNumber", value: orderRequest.grantNumber, required: false, isValid: true, ref: React.createRef() },
      notes: { fieldName: "notes", value: "", required: false, isValid: true, ref: React.createRef() },
      requiredDate: {
        fieldName: "requiredDate",
        value: orderRequest.requiredDate ? moment(orderRequest.requiredDate).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "",
        required: false,
        isValid: true,
        ref: React.createRef(),
      },
    };
  }

  const isValid = (input: InputState, value: string): boolean => {
    if (!input.required) {
      return true;
    }

    return value.trim() !== "";
  };

  const validate = (input: InputState): InputState => {
    if (!input.required) {
      return { ...input, isValid: true };
    }

    return { ...input, isValid: input.value.trim() !== "" };
  };

  const validateForm = (): boolean => {
    let localIsValid = true;
    let firstInvalid: InputState | undefined;
    const newFormInputs = { ...formInputs };

    Object.keys(formInputs).forEach((fieldName: string) => {
      const input = validate(formInputs[fieldName]);

      if (!input.isValid) {
        if (!firstInvalid) {
          firstInvalid = input;
        }
        localIsValid = false;
        newFormInputs[fieldName] = input;
      }
    });

    if (!localIsValid) {
      setFormInputs(newFormInputs);
      if (firstInvalid && firstInvalid.ref.current) {
        window.scrollTo({ top: firstInvalid.ref.current.offsetTop - 30 });
        firstInvalid.ref.current.focus();
      }
    }
    return localIsValid;
  };

  const buildRequestedOrder = (): RequestedOrder => {
    return {
      inventoryName: formInputs.inventoryName.value,
      inventoryId: orderView.inventoryId,
      catalogNumber: formInputs.catalogNumber.value,
      grantNumber: formInputs.grantNumber.value,
      poNumber: formInputs.poNumber.value,
      notes: formInputs.notes.value,
      price: Number.parseFloat(formInputs.price.value),
      quantity: Number.parseFloat(formInputs.quantity.value),
      vendorId: Number.parseInt(formInputs.vendorId.value),
      sendNotifications: enableNotifications,
      inventoryTypeId: Number.parseInt(formInputs.inventoryTypeId.value),
      requiredDate:
        formInputs.requiredDate.value === "" ? null : clock.midDay(moment(formInputs.requiredDate.value, clock.STANDARD_MOMENT_DATE_FORMAT).toDate()),
    };
  };

  const onFormInputChange = (fieldName: string, value: string): void => {
    const prevField = formInputs[fieldName];
    const field: InputState = { ...prevField, value, isValid: isValid(prevField, value) };

    const updatedForm = { ...formInputs, [fieldName]: field };
    setFormInputs(updatedForm);

    if (fieldName === formInputs.quantity.fieldName || fieldName === formInputs.price.fieldName) {
      setTotalPrice(Number.parseFloat(updatedForm.quantity.value) * Number.parseFloat(updatedForm.price.value));
    }
  };

  const onNotifyMeChange = (isChecked: boolean): void => {
    setEnableNotifications(isChecked);
  };

  const onClose = (): void => {
    history.goBack();
  };

  const onSubmitForm = (): void => {
    const isFormValid = validateForm();

    if (isFormValid) {
      api.addOrderRequest(buildRequestedOrder()).then((order) => {
        history.push(ApplicationPaths.Orders.Search);
        return order;
      });
    }
  };

  const refreshDependencies = async (): Promise<void> => {
    await inventoryTypesState.refresh();
  };

  useEffect(() => {
    refreshDependencies();

    function areDependenciesLoading(): boolean {
      return inventoryTypesState.isLoading || vendorState.isLoading;
    }

    async function setInitialData(): Promise<void> {
      let inventory: InventoryItem | undefined;
      let view = getOrderRequestView(inventoryTypesState);
      if (inventoryId) {
        inventory = await api.getInventoryItem(inventoryId);
        view = getReorderRequestView(inventory);
      }
      setOrderView(view);
      setFormInputs(getFormInputs(view));
      setTotalPrice(view.price * view.quantity);
      setIsLoading(false);
    }

    if (!areDependenciesLoading()) {
      setInitialData();
    }
  }, [inventoryId, inventoryTypesState.isLoading, vendorState.isLoading]);

  return {
    formInputs,
    totalPrice,
    enableNotifications,
    onNotifyMeChange,
    onFormInputChange,
    onClose,
    onSubmitForm,
    buildRequestedOrder,
    orderView,
    isLoading,
    inventoryTypeViews: getInventoryTypeViews(inventoryTypesState),
  };
}
