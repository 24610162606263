import { useState } from "react";
import { InventoryApi } from "../api/InventoryApi";
import { SuperUserSearchResultView, SuperUserSummaryView } from "./views";
import { getSuperUserSearchResultsView } from "./selectors";
import "./SuperUserTools.scss";

export interface SuperUserHooks {
  searchResults?: SuperUserSearchResultView[];
  summary: SuperUserSummaryView;
  isLoading: boolean;
  isSearching: boolean;

  onSearchUsers(term: string): void;
}

export function useSuperUser(api: InventoryApi): SuperUserHooks {
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<SuperUserSearchResultView[] | undefined>(undefined);
  const [summary, setSummary] = useState<SuperUserSummaryView>({
    users: 0,
    orders: 0,
    items: 0,
    inventories: 0,
    freeTrials: 0,
    paidSubscriptions: 0,
  });

  function onSearchUsers(term: string): void {
    setIsSearching(true);
    api.superUserSearchUser(term, true).then((results) => {
      setSearchResults(getSuperUserSearchResultsView(results));
      setIsSearching(false);
      return results;
    });
  }

  return {
    isLoading,
    summary,
    onSearchUsers,
    searchResults,
    isSearching,
  };
}
