export enum AdvancedSearchCriteriaMatchType {
  Matches = 0,
  DoesNotMatch = 1,
  AnyOf = 2,
  AllOf = 3,
  NoneOf = 4,
  Is = 5,
  IsNot = 6,
  On = 7,
  After = 8,
  Before = 9,
  Between = 10,
  LessThan = 11,
  GreaterThan = 12,
  IsExactly = 13,
}
