import { createContext, useState } from "react";
import { User } from "@labarchives/inventory-shared/build/inventory";
import * as localStorage from "../api/inventoryLocalStorage";
import { InventoryDisplayMode } from "./InventoryDisplayMode";
import { history } from "./history";

export interface AppState {
  isTourOpen: boolean;

  setIsTourOpen(isOpen: boolean): void;

  isInventoryAdminMode: boolean;

  toggleAdminView(isAdminMode: boolean): void;
}

const noopDefault = (): void => {
  // no-op default
};

const defaultState: AppState = {
  isTourOpen: false,
  setIsTourOpen: noopDefault,
  isInventoryAdminMode: false,
  toggleAdminView: noopDefault,
};

export const AppContext = createContext<AppState>(defaultState);

export function useAppContext(props: { user: User }): AppState {
  function getDefaultValue(): boolean {
    const mode = localStorage.getDisplayMode();
    if (!mode) {
      return props.user.isSiteAdmin;
    }

    return props.user.isSiteAdmin && mode === InventoryDisplayMode.Admin;
  }

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState(getDefaultValue());

  function toggleAdminView(isAdminModeActive: boolean): void {
    const currentMode = localStorage.getDisplayMode();
    const targetMode = isAdminModeActive ? InventoryDisplayMode.Admin : InventoryDisplayMode.Inventory;

    if (currentMode !== targetMode) {
      localStorage.setDisplayMode(targetMode);
      setIsAdminMode(isAdminModeActive);
      history.push("/");
    }
  }

  return {
    isTourOpen,
    setIsTourOpen,
    isInventoryAdminMode: isAdminMode,
    toggleAdminView,
  };
}
