import { InventorySearchResultSetItem } from "@labarchives/inventory-shared/build/inventory";
import { FreezerBoxCellFormatter, InventoryExcel } from "../inventory/import/InventoryExcel";
import * as utils from "../utils";
import { InventoryTypeView } from "../inventory/types/views";
import { UserView } from "../user/types/views";
import { StorageLocationView } from "../storage/types/views";

export interface ItemReportDownloadHooks {
  onReportDownloaded(items: InventorySearchResultSetItem[], fileName: string): Promise<void>;
}

export interface ItemReportDownloadHooksProps {
  types: InventoryTypeView[];
  users: UserView[];
  storage: StorageLocationView;
  cellFormatter: FreezerBoxCellFormatter;
}

export function useItemReportDownload(props: ItemReportDownloadHooksProps): ItemReportDownloadHooks {
  const { types, users, storage, cellFormatter } = props;

  async function onReportDownloaded(items: InventorySearchResultSetItem[], fileName: string): Promise<void> {
    const excel = InventoryExcel.CreateExport(items, types, users, storage, cellFormatter);
    const blob = await excel.AsBlob();
    utils.saveAs(blob, fileName);
  }

  return {
    onReportDownloaded,
  };
}
