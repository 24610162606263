import { useEffect, useState } from "react";
import { InventoryApi } from "../../api/InventoryApi";

export interface MaintenanceNoticeHooks {
  maintenanceMessage: string;
}

export function useMaintenanceNoticeHooks(api: InventoryApi): MaintenanceNoticeHooks {
  const [maintenanceMessage, setMaintenanceMessage] = useState("");

  useEffect(() => {
    api
      .getMaintenanceMessage()
      .then((message) => setMaintenanceMessage(message.messageText))
      .catch((error) => api.logError(error));
  }, []);

  return {
    maintenanceMessage,
  };
}