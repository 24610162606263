import { FormattedMessage } from "react-intl";
import { RouteComponentProps, useHistory } from "react-router";
import React, { ReactElement, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { AlertWithIcon } from "@labarchives/ui-design/dist";
import { InventoryApiClient } from "../api";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { parseIntOrUndefined } from "../utils";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { Loading } from "../components";
import TreeView, { flattenTree } from "../components/CheckboxTree";
import { CheckboxTreeOnSelectProps } from "../components/CheckboxTree/CheckboxTreeOnSelectProps";
import { isEnterKey } from "../utils/isEnterKey";
import { TreeData } from "../components/CheckboxTree/TreeData";
import { FlatNode } from "../components/CheckboxTree/FlatNode";
import { ArrowIcon } from "../components/CheckboxTree/ArrowIcon";
import { RadioIcon } from "../components/CheckboxTree/RadioIcon";
import { TreeViewNodeRendererProps } from "../components/CheckboxTree/TreeViewNodeRendererProps";
import { StorageContext } from "./StorageContext";
import { useStorageBrowser } from "./StorageBrowserHooks";
import { InventoryFreezerBoxView } from "./freezerBoxView/InventoryFreezerBoxView";
import "./StorageBrowser.scss";
import "../components/CheckboxTree/CheckboxTree.scss";
import { StorageLocationTreeView } from "./types/views";

interface RouteParams {
  id: string;
}

export function StorageBrowser(props: RouteComponentProps<RouteParams>): ReactElement {
  const authState = useContext(AuthenticationContext);
  const {
    isInitialLoading,
    isLoadingFreezerBoxDetails,
    isLocationFound,
    selectedBox,
    locations,
    items,
    canAddItems,
    includesItemsNotVisible,
    defaultExpandedNodes,
    onQuantityUsed,
    onItemMoved,
    onItemCopied,
  } = useStorageBrowser(parseIntOrUndefined(props.match.params.id), useContext(StorageContext), authState, new InventoryApiClient());

  const history = useHistory();

  const treeNodes = useMemo(() => getData(locations.asTree().map((l) => locationAsTreeData(l))), [locations]);

  if (isInitialLoading) {
    return <Loading />;
  }

  function locationAsTreeData(location: StorageLocationTreeView): TreeData {
    return {
      name: location.location.name,
      value: location.location.id.toString(),
      label: <span>{location.location.name}</span>,
      children: location.children.map((c) => locationAsTreeData(c)),
      data: location,
    };
  }

  function getData(data: TreeData[]): FlatNode[] {
    if (data.length === 0) {
      return flattenTree({ name: "", children: [] });
    }
    return flattenTree({ name: "", children: data });
  }

  return (
    <div>
      {includesItemsNotVisible && (
        <AlertWithIcon color={"info"}>
          <FormattedMessage id={"freezer.box.includes.not.visible.items"} />
        </AlertWithIcon>
      )}
      <div>
        <div className={"mb-2"}>
          <Link to={ApplicationPaths.Inventory.Search}>
            <FontAwesomeIcon icon={faArrowCircleLeft} /> <FormattedMessage id={"return.to.search.results"} />
          </Link>
        </div>
        <div className={"row flex-nowrap"}>
          <div className={"col-2 storage-browser-filter"}>
            <div className={"font-weight-bold text-uppercase"}>
              <FormattedMessage id={"freezer.boxes"} />
            </div>
            {treeNodes.length > 0 && (
              <div id={"storage-browser-freezer-box-list"}>
                <TreeView
                  // @ts-ignore
                  data={treeNodes}
                  multiSelect={false}
                  propagateSelect={false}
                  propagateSelectUpwards={false}
                  togglableSelect={false}
                  defaultExpandedIds={treeNodes.filter((tn) => defaultExpandedNodes.includes(Number.parseInt(tn.value))).map((tn) => tn.id)}
                  defaultSelectedIds={selectedBox ? treeNodes.filter((tn) => tn.value === selectedBox.id.toString()).map((tn) => tn.id) : []}
                  onSelect={(selectProps: CheckboxTreeOnSelectProps) => {
                    if (selectProps.treeState.selectedIds.size > 0 && selectProps.isSelected && selectProps.element) {
                      const ids = treeNodes.filter((td) => selectProps.treeState.selectedIds.has(td.id)).map((td) => td.value);
                      const path = ApplicationPaths.Storage.Browse(Number.parseInt(ids[0]));
                      if (document.location.pathname !== path) {
                        history.push(path);
                      }
                    }
                  }}
                  nodeRenderer={(nodeRendererProps: TreeViewNodeRendererProps) => {
                    const { getNodeProps, handleExpand, isBranch, isExpanded, handleSelect, isSelected, element } = nodeRendererProps;
                    const isSelectedBox = selectedBox?.id.toString() === element.value;
                    const variant = isSelected || isSelectedBox ? "all" : "none";
                    return (
                      <div {...getNodeProps({ onClick: handleExpand })} style={{}}>
                        {isBranch && (
                          <>
                            <ArrowIcon isOpen={isExpanded} /> <span className="name checkbox-tree-label">{element.label}</span>
                          </>
                        )}
                        {!isBranch && (
                          <>
                            <span className={"arrow"}>&nbsp;</span>
                            <button
                              type={"button"}
                              className={"btn btn-link m-0 p-0 storage-browser-radio-button"}
                              aria-label={element.name}
                              data-id={element.data.id}
                              onClick={(e) => {
                                handleSelect(e);
                                e.stopPropagation();
                              }}
                              onKeyDown={(e) => {
                                if (isEnterKey(e.key)) {
                                  handleSelect(e);
                                  e.stopPropagation();
                                }
                              }}
                            >
                              <RadioIcon variant={variant} />
                              <span className="name checkbox-tree-label">{element.label}</span>
                            </button>
                          </>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
          {isLoadingFreezerBoxDetails && <Loading />}

          {!isLocationFound && (
            <AlertWithIcon color={"error"}>
              <FormattedMessage id={"freezer.box.not.found"} />
            </AlertWithIcon>
          )}
          {isLocationFound && selectedBox && (
            <InventoryFreezerBoxView
              location={selectedBox}
              items={items}
              canAddItems={canAddItems}
              onQuantityUsed={onQuantityUsed}
              onItemMoved={onItemMoved}
              onItemCopied={onItemCopied}
              currency={authState.getCurrency()}
            />
          )}
        </div>
      </div>
    </div>
  );
}
