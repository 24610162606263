import { InventoryApi } from "../api/InventoryApi";
import { OrdersReportDownloadHooks } from "./OrdersReportDownloadHooks";

export interface OrdersReportHooks {
  onReportDownloaded(): Promise<void>;
}

interface OrdersReportHooksProps {
  downloadHooks: OrdersReportDownloadHooks;
  api: InventoryApi;
}

export function useOrdersReport(props: OrdersReportHooksProps): OrdersReportHooks {
  const { downloadHooks, api } = props;

  async function onReportDownloaded(): Promise<void> {
    const items = await api.reportOrders();
    return downloadHooks.onReportDownloaded(items, "orders-report.xlsx");
  }

  return {
    onReportDownloaded,
  };
}
