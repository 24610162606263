import { InventoryItem, Order, OrderStatus, OrderSearchCriteria } from "@labarchives/inventory-shared/build/inventory";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { OrderFilterView, OrderHistoryView, OrderNotesView, OrderRequestView, OrderView } from "./types/views";

export const getOrderRequestView = (inventoryTypesState: InventoryTypesState): OrderRequestView => {
  let defaultInventoryTypeId = null;
  inventoryTypesState.allIds.forEach((id) => {
    if (inventoryTypesState.byId[id].isDefault) {
      defaultInventoryTypeId = id;
    }
  });
  return {
    catalogNumber: "",
    grantNumber: "",
    inventoryId: null,
    inventoryName: "",
    price: 0,
    unit: "",
    vendorId: "",
    itemExists: true,
    quantity: 1,
    poNumber: "",
    quantityOnHand: 0,
    lastOrderedDate: null,
    inventoryTypeId: defaultInventoryTypeId,
    requiredDate: null,
  };
};

export const getReorderRequestView = (item?: InventoryItem): OrderRequestView => {
  const exists = item !== undefined;

  return {
    catalogNumber: item ? item.catalogNumber : "",
    grantNumber: item ? item.grantNumber : "",
    inventoryId: item ? item.id : null,
    inventoryName: item ? item.name : "",
    inventoryTypeId: item ? item.typeId : null,
    price: item && item.price ? item.price : 0,
    unit: item ? item.unit : "",
    vendorId: item && item.vendorId ? `${item.vendorId}` : "",
    itemExists: exists,
    quantity: 1,
    poNumber: item ? item.poNumber : "",
    quantityOnHand: item ? item.quantityAvailable : 0,
    lastOrderedDate: item ? item.dateOrdered : null,
    requiredDate: null,
  };
};

export const getOrderFilterView = (criteria: OrderSearchCriteria): OrderFilterView => {
  return {
    resultSetOptions: criteria.resultSetOptions,
    typeIds: criteria.typeIds,
    statusIds: criteria.statusIds,
    term: criteria.term,
    orderStartDate: criteria.orderStartDate,
    orderEndDate: criteria.orderEndDate,
  };
};

export const getDefaultOrderView = (): OrderView => {
  return {
    id: 0,
    catalogNumber: "",
    grantNumber: "",
    poNumber: "",
    inventoryName: "",
    inventoryTypeId: 1,
    status: OrderStatus.Requested,
    vendorId: 1,
    history: [],
    notes: [],
    price: 0,
    quantity: 0,
    quantityOnHand: 0,
    requestedByName: "",
    unitOfMeasure: "",
    requiredDate: null,
    lastOrderedDate: null,
    inventoryId: null,
    sourceInventoryId: null,
    requestedById: 0,
  };
};

export const getOrderView = (order: Order, sourceInventory?: InventoryItem): OrderView => {
  const sortedHistory = order.history
    .map((h) => {
      return { activityDate: h.activityDate, description: h.description, userName: h.userName };
    })
    .sort((item1: OrderHistoryView, item2: OrderHistoryView) => item2.activityDate.getTime() - item1.activityDate.getTime());

  return {
    id: order.id,
    catalogNumber: order.catalogNumber,
    grantNumber: order.grantNumber,
    inventoryId: order.inventoryItemId,
    poNumber: order.poNumber,
    sourceInventoryId: order.sourceInventoryId,
    inventoryName: order.inventoryName,
    inventoryTypeId: order.inventoryTypeId,
    status: order.status,
    vendorId: order.vendorId,
    history: sortedHistory,
    notes: order.notes
      .map((n) => {
        return { note: n.note, noteDate: n.noteDate, userName: n.userName };
      })
      .sort((item1: OrderNotesView, item2: OrderNotesView) => item2.noteDate.getTime() - item1.noteDate.getTime()),
    price: order.price || 0,
    quantity: order.quantity,
    quantityOnHand: sourceInventory ? sourceInventory.quantityAvailable : 0,
    lastOrderedDate: sourceInventory ? sourceInventory.dateOrdered : null,
    requestedByName: sortedHistory.length > 0 ? sortedHistory[sortedHistory.length - 1].userName : "",
    unitOfMeasure: sourceInventory ? sourceInventory.unit : "",
    requiredDate: order.dateRequired,
    requestedById: order.requestedById,
  };
};

export function getOrderStatusName(status: OrderStatus): string {
  switch (status) {
    case OrderStatus.Requested: {
      return "Requested";
    }
    case OrderStatus.Approved: {
      return "Approved";
    }
    case OrderStatus.Ordered: {
      return "Ordered";
    }
    case OrderStatus.Received: {
      return "Received";
    }
    case OrderStatus.Cancelled: {
      return "Cancelled";
    }
    default: {
      return "";
    }
  }
}
