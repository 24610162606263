import { InventoryItem, InventorySearchResultSetItem } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { UseNowModalProps } from "../../UseNowModal/UseNowModal";
import { InventoryApi } from "../../../api/InventoryApi";

export interface InventorySearchResultsUseNowHooks {
  onUseNowOpened(item: InventorySearchResultSetItem): Promise<void>;

  useNowProps: UseNowModalProps;
}

export interface InventorySearchResultsUseNowHooksProps {
  onUseNow(item: InventorySearchResultSetItem, amount: number, unit: string, selectedCells: string[]): Promise<InventoryItem>;

  api: InventoryApi;
}

type UseNowStateProps = Omit<UseNowModalProps, "onSelectedCellsChanged" | "onUsageSaved" | "onToggle">;

export function useSearchResultsUseNow(props: InventorySearchResultsUseNowHooksProps): InventorySearchResultsUseNowHooks {
  const [useNowProps, setUseNowProps] = useState<UseNowStateProps>({
    defaultUsageAmount: "units",
    defaultUsageUnit: "0",
    displayFormat: null,
    isOpen: false,
    matrixCols: 0,
    matrixRows: 0,
    occupiedCells: [],
    selectedCells: [],
  });
  const [useNowItem, setUseNowItem] = useState<InventorySearchResultSetItem | undefined>();
  const { api } = props;

  function onUseNowToggle(): void {
    setUseNowProps({ ...useNowProps, isOpen: false });
    setUseNowItem(undefined);
  }

  async function onUseNowOpened(item: InventorySearchResultSetItem): Promise<void> {
    let occupiedCells: string[] = [];
    if (item.locationId && item.storageCellCols && item.storageCellRows) {
      const fb = await api.getFreezerBox(item.locationId);
      occupiedCells = fb.contents;
    }

    const newProps: UseNowStateProps = {
      isOpen: true,
      matrixCols: item.storageCellCols || 0,
      matrixRows: item.storageCellRows || 0,
      displayFormat: item.storageCellFormat,
      defaultUsageAmount: "0",
      defaultUsageUnit: item.unit,
      selectedCells: item.storageCells,
      occupiedCells,
    };

    setUseNowItem(item);
    setUseNowProps(newProps);
  }

  function onSelectedCellsChanged(selectedCells: string[]): void {
    const newProps = { ...useNowProps, selectedCells };
    setUseNowProps(newProps);
  }

  function onUsageSaved(amount: number, unit: string): void {
    if (!useNowItem) {
      return;
    }

    props.onUseNow(useNowItem, amount, unit, useNowProps.selectedCells).then(() => {
      return setUseNowProps({ ...useNowProps, isOpen: false });
    });
  }

  return {
    onUseNowOpened,
    useNowProps: { ...useNowProps, onSelectedCellsChanged, onToggle: onUseNowToggle, onUsageSaved },
  };
}
