import React, { ReactElement } from "react";
import { Col, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { AlertWithIcon, Button, Modal } from "@labarchives/ui-design/dist";
import { Loading } from "../../components";
import { OrderModalProps } from "../types/props";
import "./OrderModal.scss";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";

interface ModalOrderProps extends OrderModalProps {
  onOrderPlaced(orderId: number, notes: string, quantityOrdered: number, pricePaid: number): void;
  quantity: number;
  price: number;
  inventoryName: string;
}

export function ModalOrder(props: ModalOrderProps): ReactElement {
  let notesRef: HTMLInputElement;
  let quantityRef: HTMLInputElement;
  let priceRef: HTMLInputElement;

  let modalBody = (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label for="order-modal-quantity">
              <FormattedMessage id="quantity.ordered" />
              <RequiredLabelIndicator required={true} />
            </Label>
            <Input
              type="number"
              min={0}
              step="any"
              id="order-modal-quantity"
              defaultValue={props.quantity.toString()}
              innerRef={(node: HTMLInputElement) => {
                quantityRef = node;
              }}
              required
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="order-modal-price">
              <FormattedMessage id="price.paid" />
              <RequiredLabelIndicator required={true} />
            </Label>
            <Input
              type="number"
              min={0}
              step="any"
              id="order-modal-price"
              defaultValue={props.price.toString()}
              innerRef={(node: HTMLInputElement) => {
                priceRef = node;
              }}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="order-modal-notes-container">
          <Label for="order-modal-notes">
            <FormattedMessage id="add.notes" />
          </Label>
          <Input
            type="textarea"
            id="order-modal-notes"
            innerRef={(node: HTMLInputElement) => {
              notesRef = node;
            }}
          />
        </Col>
      </Row>
    </>
  );

  let actionRow = (
    <div>
      <Button
        color="primary"
        className="me-1"
        onClick={() => props.onOrderPlaced(props.orderId, notesRef.value, Number.parseFloat(quantityRef.value), Number.parseFloat(priceRef.value))}
        id="order-modal-order-button"
      >
        <FormattedMessage id="mark.ordered" />
      </Button>

      <CancelButton className="order-modal-cancel" onClick={() => props.toggleModal(props.modalId)} />
    </div>
  );

  if (props.isProcessing) {
    actionRow = (
      <div>
        <Loading messageId="updating.order" />
      </div>
    );
  }

  if (props.errorMessage.length > 0) {
    modalBody = (
      <Row>
        <Col>
          <div>
            <AlertWithIcon color="danger">
              <FormattedMessage id="error.marking.ordered" />
            </AlertWithIcon>
          </div>
          <div>{props.errorMessage}</div>
        </Col>
      </Row>
    );
    actionRow = (
      <div>
        <Button color="none" outline onClick={() => props.toggleModal(props.modalId)}>
          <FormattedMessage id="close" />
        </Button>
      </div>
    );
  }

  return (
    <Modal id={props.modalId} isOpen={props.isModalOpen(props.modalId)} toggle={() => props.toggleModal(props.modalId)} className="order-modal-modal">
      <ModalHeader toggle={() => props.toggleModal(props.modalId)}>
        <FormattedMessage id="mark.ordered" />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <span className="order-modal-title">{props.inventoryName}</span>
          </Col>
        </Row>
        {modalBody}
      </ModalBody>
      <ModalFooter>{actionRow}</ModalFooter>
    </Modal>
  );
}
