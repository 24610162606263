import React, { ReactElement, useContext } from "react";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { useDropzone } from "react-dropzone";
import { AlertWithIcon, Button } from "@labarchives/ui-design";
import { useInventoryImportModal } from "../../inventory/import/InventoryImportModalHooks";
import { InventoryTypesContext } from "../../inventorytypes/InventoryTypesContext";
import { StorageContext } from "../../storage/StorageContext";
import { Loading } from "../../components";
import { VendorsContext } from "../../vendors/VendorsContext";
import { InventoryApiClient } from "../../api";
import { useBulkInventoryImport } from "./BulkInventoryImportHooks";

export function BulkInventoryImportComponent(): ReactElement {
  const { inventoryTypeViews, storageLocations, onItemsImported } = useBulkInventoryImport({
    inventoryTypesContext: useContext(InventoryTypesContext),
    storageLocationsContext: useContext(StorageContext),
    vendorsContext: useContext(VendorsContext),
    api: new InventoryApiClient(),
  });

  const { onTemplateDownloaded, onImportRestarted, onImportStarted, onImportUploaded, importResult, isParsing, wasParsed, step, isUploading } =
    useInventoryImportModal({ inventoryTypeViews, storageLocations, onItemsImported });

  const intl = useIntl();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/vnd.ms-excel": [".xls", ".xlsx"] },
    multiple: false,
    onDropAccepted: onImportUploaded,
  });
  const dropzoneDivProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = { ...getRootProps() };

  const showTemplate = step === 0 && !wasParsed;
  const showConfirmation = importResult.items.length > 0 && importResult.isValid;
  const showEmptyFile = wasParsed && importResult.items.length === 0 && importResult.isValid;
  const showErrors = wasParsed && !importResult.isValid;

  return (
    <div>
      <div className="management-header justify-content-between">
        <h1 className="inventory-heading">
          <FormattedMessage id="import.items" />
        </h1>
      </div>
      {showTemplate && (
        <div>
          <div id={"bulk-import-template"} className={"bulk-update-container"}>
            <div className={"row"}>
              <div className={"col"}>
                <FormattedHTMLMessage id={"management.inventory.import.instructions"} />
              </div>
            </div>
          </div>
          {!isParsing && (
            <div id={"bulk-import-actions"} className={"bulk-update-actions"}>
              <div className="bulk-inventory-import-download-template">
                <Button onClick={() => onTemplateDownloaded()} color="primary" id={"bulk-inventory-import-download-template-button"}>
                  <FormattedMessage id="download.template" />
                </Button>
              </div>
              <section className={"mt-3"}>
                <div className="dropzone" {...dropzoneDivProps}>
                  <input id="bulk-inventory-import-file" {...getInputProps()} />
                  <div>
                    <FormattedMessage id="upload.import.prompt" />
                  </div>
                  <div>
                    <img src="/images/Inventory_Import.svg" className="svg-inline--fa fa-2x" alt={intl.formatMessage({ id: "import.items" })} />
                  </div>
                </div>
              </section>
            </div>
          )}
          {isParsing && <Loading messageId="parsing.upload" />}
        </div>
      )}
      {showConfirmation && (
        <div id={"bulk-import-parse-confirmation"} className={"bulk-update-container"}>
          <div className={"mb-3"}>
            <FormattedMessage id="inventory.upload.parse.success" values={{ rowCount: importResult.items.length }} />
          </div>
          <div className="">
            {!isUploading && (
              <>
                <Button onClick={onImportStarted} size="sm" color="primary" className={"me-2"} id={"bulk-inventory-import-start-upload-button"}>
                  <FormattedMessage id="start.import" />
                </Button>
                <Button color="link" size="sm" onClick={onImportRestarted}>
                  <FormattedMessage id="choose.another.file" />
                </Button>
              </>
            )}
            {isUploading && (
              <div className={"bulk-import-upload-success"} id={"bulk-import-upload-success"}>
                <FormattedMessage id={"inventory.upload.upload.success"} />
              </div>
            )}
          </div>
        </div>
      )}
      {showErrors && (
        <AlertWithIcon color="danger" className="bulk-inventory-import-parse-errors">
          {importResult.errors.map((e, index) => (
            <ul key={`${e.id}${index}`} className={"bulk-inventory-import-parse-errors-list"}>
              <li>
                <FormattedHTMLMessage id={e.id} values={e.values} />
              </li>
            </ul>
          ))}
          <div className="inventory-import-modal-parse-error-retry">
            <FormattedMessage id="inventory.upload.error.retry" />
            <div className={"mt-3"}>
              <Button color="link" size="sm" onClick={onImportRestarted}>
                <FormattedMessage id="choose.another.file" />
              </Button>
            </div>
          </div>
        </AlertWithIcon>
      )}
      {showEmptyFile && (
        <AlertWithIcon color="danger" className="inventory-import-modal-parse-errors">
          <FormattedMessage id="import.inventory.empty.file" />

          <div className="inventory-import-modal-parse-error-retry">
            <div className={"mt-3"}>
              <Button color="link" size="sm" onClick={onImportRestarted}>
                <FormattedMessage id="choose.another.file" />
              </Button>
            </div>
          </div>
        </AlertWithIcon>
      )}
    </div>
  );
}
