import { FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Button, CheckboxInput, CheckboxLabel, Modal } from "@labarchives/ui-design";
import React, { ReactElement, useEffect, useState } from "react";
import { getConvertibleUnits } from "@labarchives/inventory-shared/build/util/units";
import { FreezerBoxDisplayFormat } from "@labarchives/inventory-shared/build/inventory";
import { EditableStorageCells } from "../../storage/EditableStorageCells";
import { CancelButton } from "../../components/CancelButton/CancelButton";

export interface UseNowModalProps {
  isOpen: boolean;
  defaultUsageAmount: string;
  defaultUsageUnit: string;
  matrixCols: number;
  matrixRows: number;
  selectedCells: string[];
  displayFormat: FreezerBoxDisplayFormat | null;
  occupiedCells: string[];

  onSelectedCellsChanged(selectedCells: string[]): void;

  onUsageSaved(amount: number, unitOfMeasure: string): void;

  onToggle(): void;
}

export function UseNowModal(props: UseNowModalProps): ReactElement {
  let amountInput: HTMLInputElement;
  let unitInput: HTMLInputElement;

  const [isUseNowValid, setIsUseNowValid] = useState(true);
  const [isUsingNone, setIsUsingNone] = useState(false);

  const {
    isOpen,
    defaultUsageAmount,
    defaultUsageUnit,
    matrixCols,
    matrixRows,
    selectedCells,
    displayFormat,
    occupiedCells,
    onSelectedCellsChanged,
    onUsageSaved,
    onToggle,
  } = props;

  useEffect(() => {
    setIsUseNowValid(true);
    setIsUsingNone(false);
  }, [isOpen]);

  const onTryUsageSaved = (): void => {
    const parsedAmount = Number.parseFloat(amountInput.value);
    if (parsedAmount > 0 || isUsingNone) {
      setIsUseNowValid(true);
      onUsageSaved(parsedAmount, unitInput.value);
    } else {
      setIsUseNowValid(false);
    }
  };

  const onUsingNoneClicked = (usingNone: boolean): void => {
    setIsUsingNone(usingNone);
    amountInput.value = "0";
    unitInput.value = defaultUsageUnit;
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle} id="use-now-modal">
      <ModalHeader toggle={onToggle}>
        <FormattedMessage id="use.now" />
      </ModalHeader>
      <ModalBody className="horizontal-scrollable-modal">
        <div>
          <div className="label-secondary">
            <FormattedMessage id="amount.used" />
          </div>
          <div className="display-flex">
            <FormGroup>
              <Label for="use-now-modal-usage-quantity" className="visually-hidden">
                <FormattedMessage id="usage.quantity" />
              </Label>
              <Input
                id="use-now-modal-usage-quantity"
                type="number"
                min={0}
                step="1"
                defaultValue={defaultUsageAmount}
                innerRef={(node: HTMLInputElement) => {
                  amountInput = node;
                }}
                autoFocus
                className="quantity d-inline"
                invalid={!isUseNowValid && !isUsingNone}
                disabled={isUsingNone}
              />
              <FormFeedback>
                <FormattedMessage id="usage.amount.error" />
              </FormFeedback>
            </FormGroup>
            <FormGroup className={"flex-grow-1"}>
              <Label for="use-now-modal-usage-unit" className="visually-hidden">
                <FormattedMessage id="units" />
              </Label>
              <Input
                id="use-now-modal-usage-unit"
                type="select"
                className="d-inline ms-1"
                defaultValue={defaultUsageUnit}
                innerRef={(node: HTMLInputElement) => {
                  unitInput = node;
                }}
                disabled={isUsingNone}
              >
                {getConvertibleUnits(defaultUsageUnit).map((u) => (
                  <option key={u} value={u}>
                    {u}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <CheckboxLabel>
                <CheckboxInput
                  id="use-now-modal-usage-unit"
                  onChange={(e) => onUsingNoneClicked(e.target.checked)}
                  uncheckedValue="0"
                  checked={isUsingNone}
                />
                <span>
                  <FormattedMessage id="record.usage.no.quantity.change" />
                </span>
              </CheckboxLabel>
            </FormGroup>
          </div>
          <div>
            <EditableStorageCells
              matrixCols={matrixCols}
              matrixRows={matrixRows}
              selectedCells={selectedCells}
              onSelectedCellsChange={onSelectedCellsChanged}
              displayFormat={displayFormat}
              occupiedCells={occupiedCells}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onTryUsageSaved} color="primary" id="use-now-modal-save-button">
          <FormattedMessage id="save" />
        </Button>
        <CancelButton onClick={onToggle} />
      </ModalFooter>
    </Modal>
  );
}
