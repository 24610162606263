import { CustomAttribute, CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { CustomAttributeDefinitionView } from "../../inventory/types/views";

interface AttributeOption {
  key: string;
  value: string;
}

export interface ManageInventoryTypeAttributeHooks {
  isEditing: boolean;
  options: AttributeOption[];
  newOption: string;
  type: string;
  isRequired: boolean;
  isLabelValid: boolean;

  onToggleEdit(): void;

  onCancelEdit(): void;

  onRemoveOption(key: string): void;

  onOptionChanged(key: string, value: string): void;

  onTypeChanged(value: string): void;

  onLabelChanged(value: string): void;

  onRequiredChange(isRequired: boolean): void;

  onNewOptionChanged(value: string): void;

  onAddOption(): void;

  onSave(): void;

  onDelete(): void;
}

export interface ManageInventoryTypeAttributeProps {
  attribute: CustomAttributeDefinitionView;
  isEditing?: boolean;

  onSaved(customAttribute: CustomAttribute): void;

  onDeleted(attribute: CustomAttributeDefinitionView): void;

  onCancelEdit?(attribute: CustomAttributeDefinitionView): void;

  isAttributeValid(customAttribute: CustomAttribute): boolean;
}

export function useManageInventoryTypeAttribute(props: ManageInventoryTypeAttributeProps): ManageInventoryTypeAttributeHooks {
  const buildOptions = (optionValues: string[]): AttributeOption[] => {
    return optionValues.map((optionValue, index) => {
      return { key: index.toString(), value: optionValue };
    });
  };

  const [isEditing, setIsEditing] = useState(props.isEditing ?? false);
  const [options, setOptions] = useState(buildOptions(props.attribute.possibleValues));
  const [optionKeyIndex, setOptionKeyIndex] = useState(props.attribute.possibleValues.length);
  const [newOption, setNewOption] = useState("");
  const [type, setType] = useState(props.attribute.type.toString());
  const [label, setLabel] = useState(props.attribute.label);
  const [isRequired, setIsRequired] = useState(props.attribute.required);
  const [isLabelValid, setIsLabelValid] = useState(true);

  const onToggleEdit = (): void => {
    setIsEditing(!isEditing);
    setOptions(buildOptions(props.attribute.possibleValues));
    setOptionKeyIndex(props.attribute.possibleValues.length);
    setType(props.attribute.type.toString());
    setNewOption("");
    setLabel(props.attribute.label);
    setIsRequired(props.attribute.required);
  };

  const onCancelEdit = (): void => {
    onToggleEdit();
    if (props.onCancelEdit) {
      props.onCancelEdit(props.attribute);
    }
  };

  const onRemoveOption = (key: string): void => {
    setOptions(options.filter((option) => option.key !== key));
  };

  const onOptionChanged = (key: string, value: string): void => {
    const index = options.findIndex((option) => option.key === key);
    const newOptions = [...options];
    newOptions[index].value = value.trim();
    setOptions(newOptions);
  };

  const onTypeChanged = (value: string): void => {
    setType(value);
  };

  const onLabelChanged = (value: string): void => {
    setLabel(value.trim());
    const customAttribute = buildCustomAttribute();
    customAttribute.label = value.trim();
    setIsLabelValid(props.isAttributeValid(customAttribute));
  };

  const onRequiredChange = (isRequiredChange: boolean): void => {
    setIsRequired(isRequiredChange);
  };

  const onNewOptionChanged = (value: string): void => {
    setNewOption(value);
  };

  const onAddOption = (): void => {
    if (newOption.trim() !== "") {
      setOptions([...options, { key: (optionKeyIndex + 1).toString(), value: newOption.trim() }]);
      setNewOption("");
      setOptionKeyIndex(optionKeyIndex + 1);
    }
  };

  const allowsOptions = (): boolean => {
    return (
      Number.parseInt(type) === CustomAttributeType.Checkbox ||
      Number.parseInt(type) === CustomAttributeType.Dropdown ||
      Number.parseInt(type) === CustomAttributeType.Radio
    );
  };

  function validateAttribute(attribute: CustomAttribute): boolean {
    const isValid = props.isAttributeValid(attribute);
    setIsLabelValid(isValid);
    return isValid;
  }

  function buildCustomAttribute(): CustomAttribute {
    let possibleValues: string[] = [];
    if (allowsOptions()) {
      possibleValues = options.map((option) => option.value);
      if (newOption.trim() !== "") {
        possibleValues.push(newOption.trim());
      }
    }

    return {
      id: props.attribute.id,
      possibleValues,
      required: isRequired,
      label: label.trim(),
      type: Number.parseInt(type),
      isLocked: false,
    };
  }

  const onSave = (): void => {
    const attribute = buildCustomAttribute();
    const isValid = validateAttribute(attribute);
    if (!isValid) {
      return;
    }
    props.onSaved(attribute);

    setIsEditing(false);
  };

  const onDelete = (): void => {
    props.onDeleted(props.attribute);
  };

  return {
    isRequired,
    isEditing,
    options,
    newOption,
    type,
    isLabelValid,
    onToggleEdit,
    onCancelEdit,
    onRemoveOption,
    onOptionChanged,
    onTypeChanged,
    onLabelChanged,
    onRequiredChange,
    onNewOptionChanged,
    onAddOption,
    onSave,
    onDelete,
  };
}
