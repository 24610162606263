import React, { ReactElement, useContext } from "react";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { FormattedMessage } from "react-intl";
import { AuthenticationContext } from "./AuthenticationContext";
import { AuthenticationState } from "./AuthenticationState";

export type AuthorizedComponentCheck = (authenticationState: AuthenticationState) => boolean;

export interface AuthorizedComponentProps {
  requiredPermissions: RolePermissions[];
  additionalChecks?: AuthorizedComponentCheck;
  showUnauthorizedMessage?: boolean;
}

export function AuthorizedComponent(props: React.PropsWithChildren<AuthorizedComponentProps>): ReactElement {
  const authState = useContext<AuthenticationState>(AuthenticationContext);

  const hasPermissions = (): boolean => {
    return authState.hasPermissions(props.requiredPermissions);
  };

  const passedAdditionalChecks = (): boolean => {
    return !props.additionalChecks || props.additionalChecks(authState);
  };

  if (hasPermissions() && passedAdditionalChecks()) {
    return <>{props.children}</>;
  }

  if (props.showUnauthorizedMessage) {
    return (
      <div>
        <FormattedMessage id="unauthorized.error" />
      </div>
    );
  }
  return <></>;
}
