import { FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { Button } from "@labarchives/ui-design/dist";
import { ButtonProps } from "@labarchives/ui-design/dist/components/Button";

export function DeleteButton(props: ButtonProps): ReactElement {
  return (
    <Button color="danger" {...props}>
      <FormattedMessage id="delete" />
    </Button>
  );
}
