import React, { ReactElement } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { SuperUserUserDetailsView } from "./views";

export interface SuperUserSiteAdminModalProps {
  isOpen: boolean;
  user: SuperUserUserDetailsView;
  onToggle(): void;
  onChangeUserSiteAdmin(isSiteAdmin: boolean): void;
}

export function SuperUserSiteAdminModal(props: SuperUserSiteAdminModalProps): ReactElement {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle}>
      <ModalHeader toggle={props.onToggle}>Change Inventory Admin</ModalHeader>
      <ModalBody>
        {props.user.isSiteAdmin && (
          <div>
            {props.user.fullName} <strong>is</strong> an Inventory Admin
          </div>
        )}
        {!props.user.isSiteAdmin && (
          <div>
            {props.user.fullName} <strong>is not</strong> an Inventory Admin
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => props.onChangeUserSiteAdmin(!props.user.isSiteAdmin)} className="me-1">
          {props.user.isSiteAdmin && <span>Remove Inventory Admin Rights</span>}
          {!props.user.isSiteAdmin && <span>Enable Inventory Admin Rights</span>}
        </Button>
        <CancelButton onClick={props.onToggle} />
      </ModalFooter>
    </Modal>
  );
}
