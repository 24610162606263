import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { AddInventoryTypeProps, useAddInventoryType } from "./AddInventoryTypeHooks";

export function AddInventoryType(props: AddInventoryTypeProps): ReactElement {
  const { isInvalid, onTypeAdded, updateName, onCancelTypeAdded } = useAddInventoryType(props);

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onTypeAdded();
        }}
      >
        <FormGroup>
          <Label for="add-inventory-type-name">
            <FormattedMessage id="inventory.type.name" />
            <RequiredLabelIndicator required={true} />
          </Label>
          <Input
            id="add-inventory-type-name"
            type="text"
            bsSize="sm"
            autoFocus
            required
            invalid={isInvalid}
            onChange={(e) => updateName(e.target.value)}
            maxLength={255}
          />
          <FormFeedback>
            <FormattedMessage id="inventory.type.name.invalid.error" />
          </FormFeedback>
        </FormGroup>
        <div className="management-edit-footer">
          <Button type="submit" className="me-1" color="primary" id={"add-inventory-type-submit-button"}>
            <FormattedMessage id="add.inventory.type" />
          </Button>
          <CancelButton onClick={onCancelTypeAdded} />
        </div>
      </Form>
    </div>
  );
}
