import { LabArchivesRegions } from "./LabArchivesRegions";

const GOVT_DOMAIN = ".labarchives-gov.com";
export class Settings {
  private static getUrlPrefix(region: LabArchivesRegions): string {
    switch (region) {
      case LabArchivesRegions.UK: {
        return "uk-";
      }
      case LabArchivesRegions.AU: {
        return "au-";
      }
      case LabArchivesRegions.EU: {
        return "eu-";
      }
      case LabArchivesRegions.OH: {
        return "oh-";
      }
      case LabArchivesRegions.DR: {
        return "dr-";
      }
    }

    return "";
  }

  private static getRegionalUrl(variableName: string, defaultBaseUrl = "", region?: LabArchivesRegions): string {
    let hostname = defaultBaseUrl;
    const currentRegion = region || Settings.getRegionFromCurrentHost();

    if (process.env) {
      let variableWithSuffix = variableName;
      if (currentRegion === LabArchivesRegions.QA) {
        variableWithSuffix = `${variableName}_QA`;
      }

      if (process.env[variableWithSuffix] !== undefined) {
        // @ts-ignore
        hostname = process.env[variableWithSuffix];
      }
    }

    let hostPrefix = Settings.getUrlPrefix(currentRegion);
    if (currentRegion === LabArchivesRegions.GOV) {
      hostname = hostname.replace(".labarchives.com", GOVT_DOMAIN);
      hostPrefix = "";
    }

    return `${Settings.getBaseHttp()}${hostPrefix}${hostname}`;
  }

  public static getBaseInventoryWebUrl(): string {
    return [window.location.protocol, "//", window.location.host].join("");
  }

  public static getCookieDomain(region?: LabArchivesRegions): string {
    const currentRegion = region || Settings.getRegionFromCurrentHost();

    if (currentRegion === LabArchivesRegions.GOV) {
      return GOVT_DOMAIN;
    }

    return process.env.REACT_APP_COOKIE_DOMAIN || ".labarchives.com";
  }

  public static getGoogleAnalyticsTrackingId(region?: LabArchivesRegions): string {
    const currentRegion = region || Settings.getRegionFromCurrentHost();

    if (currentRegion === LabArchivesRegions.GOV) {
      return "";
    }
    return process.env.REACT_APP_GA_TRACKING_ID || "";
  }

  public static getDataCacheTimeout(): number {
    const timeout = process.env.REACT_APP_DATA_CACHE_TIMEOUT || "10";
    return Number.parseInt(timeout);
  }

  public static getBundleProductCode(): string {
    return process.env.REACT_APP_BUNDLE_PRODUCT_PRODUCT_CODE || "ELNINV";
  }

  public static getBaseHttp(): string {
    return process.env.REACT_APP_BASE_HTTP || "http://";
  }

  public static getBaseApiUrl(): string {
    return Settings.getRegionalUrl("REACT_APP_BASE_API_URL", "ec2-34-212-29-90.us-west-2.compute.amazonaws.com/api/v1");
  }

  public static getAccountUrlBase(region?: LabArchivesRegions): string {
    return Settings.getRegionalUrl("REACT_APP_SSO_ACCOUNT_URL", "localhost:3010", region);
  }

  public static getNotebookUrl(): string {
    return Settings.getRegionalUrl("REACT_APP_NOTEBOOK_URL", "localhost:3010");
  }

  public static getBaseAccountServiceApiUrl(): string {
    return Settings.getRegionalUrl("REACT_APP_BASE_ACCOUNT_SERVICE_API_URL", "localhost:3232/api/v1");
  }

  public static getLabArchivesSuperUserBaseUrl(): string {
    return Settings.getRegionalUrl("REACT_APP_BASE_SUPER_USER_URL", "localhost:3233");
  }

  public static getKBUrl(): string {
    if (Settings.getRegionFromCurrentHost() === LabArchivesRegions.GOV) {
      return "https://help.labarchives-gov.com/hc/en-us/categories/16591035261332";
    }

    return "https://help.labarchives.com/hc/en-us/categories/15057299624084";
  }

  public static getTOSUrl(): string {
    if (Settings.getRegionFromCurrentHost() === LabArchivesRegions.GOV) {
      return "https://help.labarchives-gov.com/hc/en-us/articles/16591122280724";
    }

    return "https://www.labarchives.com/terms-of-use";
  }

  public static getPrivacyPolicyUrl(): string {
    if (Settings.getRegionFromCurrentHost() === LabArchivesRegions.GOV) {
      return "https://help.labarchives-gov.com/hc/en-us/articles/16591203476756";
    }

    return "https://www.labarchives.com/privacy";
  }

  public static getHelpUrl(): string {
    if (Settings.getRegionFromCurrentHost() === LabArchivesRegions.GOV) {
      return "https://help.labarchives-gov.com/";
    }

    return "https://help.labarchives.com";
  }

  public static getAllowVideoAndContact(): boolean {
    return Settings.getRegionFromCurrentHost() !== LabArchivesRegions.GOV;
  }

  public static isExternalLinkWarningEnabled(): boolean {
    return Settings.getRegionFromCurrentHost() === LabArchivesRegions.GOV;
  }

  public static getRegionFromCurrentHost(): LabArchivesRegions {
    const hostName = window && window.location && window.location.hostname;
    return Settings.getRegion(hostName || "");
  }

  public static getRegion(hostName: string): LabArchivesRegions {
    const currentUrl = hostName.toLowerCase();

    if (currentUrl.startsWith("uk")) {
      return LabArchivesRegions.UK;
    }
    if (currentUrl.startsWith("au")) {
      return LabArchivesRegions.AU;
    }
    if (currentUrl.startsWith("eu")) {
      return LabArchivesRegions.EU;
    }
    if (currentUrl.startsWith("oh")) {
      return LabArchivesRegions.OH;
    }
    if (currentUrl.startsWith("dr")) {
      return LabArchivesRegions.DR;
    }
    if (currentUrl.startsWith("qa")) {
      return LabArchivesRegions.QA;
    }

    if (currentUrl.includes(GOVT_DOMAIN)) {
      return LabArchivesRegions.GOV;
    }

    return LabArchivesRegions.US;
  }
}
