import { FreezerBoxDisplayFormat } from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useState } from "react";
import { InventoryItemView } from "../types/views";
import { StorageLocationView } from "../../storage/types/views";
import { InventoryApi } from "../../api/InventoryApi";

export interface ActionRowProps {
  onInventoryUsed(id: number, amount: number, unitOfMeasure: string, storageCells: string[]): void;

  currentItemView: InventoryItemView;
  storageLocations: StorageLocationView;
  units: string[];
  defaultUsageAmount: string;
  defaultUsageUnit: string;
  currency: string;
  api: InventoryApi;
}

export interface ActionRowHooks {
  onUsageSaved(amount: number, unitOfMeasure: string): void;

  onSelectedCellsChanged(cells: string[]): void;

  onCopyTriggered(): void;

  onShowUsage(): void;

  onHideUsage(): void;

  selectedCells: string[];
  occupiedCells: string[];
  isUpdatingUse: boolean;
  wasCopied: boolean;
  matrixRows: number;
  matrixCols: number;
  displayFormat: FreezerBoxDisplayFormat;
}

export function useActionRow(props: ActionRowProps): ActionRowHooks {
  const { api, currentItemView, storageLocations } = props;
  const [selectedCells, setSelectedCells] = useState<string[]>(currentItemView.storageCells);
  const [isUpdatingUse, setIsUpdatingUse] = useState(false);
  const [wasCopied, setWasCopied] = useState(false);
  const [occupiedCells, setOccupiedCells] = useState<string[]>([]);


  let matrixRows = 0;
  let matrixCols = 0;
  let displayFormat = FreezerBoxDisplayFormat.Default;
  const currentStorageLocation = storageLocations.getSelectedLocation(currentItemView.storageLocationId);
  if (currentStorageLocation && currentStorageLocation.numberOfRows && currentStorageLocation.numberOfColumns) {
    matrixRows = currentStorageLocation.numberOfRows;
    matrixCols = currentStorageLocation.numberOfColumns;
    displayFormat = currentStorageLocation.freezerBoxDisplayFormat || FreezerBoxDisplayFormat.Default;
  }

  function onHideUsage(): void {
    setIsUpdatingUse(false);
  }

  function onUsageSaved(amount: number, unitOfMeasure: string): void {
    props.onInventoryUsed(currentItemView.id, amount, unitOfMeasure, selectedCells);
    onHideUsage();
  }

  function onSelectedCellsChanged(cells: string[]): void {
    setSelectedCells(cells);
  }

  function onShowUsage(): void {
    if (currentStorageLocation && currentStorageLocation.numberOfRows && currentStorageLocation.numberOfColumns) {
      api
        .getFreezerBox(currentStorageLocation.id)
        .then((freezerBox) => {
          setIsUpdatingUse(true);
          setOccupiedCells(freezerBox.contents);
          return freezerBox;
        })
        .catch((error) => {
          api.logError(error);
          setIsUpdatingUse(true);
          setOccupiedCells([]);
        });
    }
    setIsUpdatingUse(true);
  }

  function onCopyTriggered(): void {
    setWasCopied(true);
    setTimeout(() => {
      setWasCopied(false);
    }, 5000);
  }

  useEffect(() => {
    setSelectedCells(currentItemView.storageCells);
  }, [currentItemView.storageCells]);

  return {
    selectedCells,
    occupiedCells,
    isUpdatingUse,
    wasCopied,
    matrixRows,
    matrixCols,
    displayFormat,
    onUsageSaved,
    onSelectedCellsChanged,
    onHideUsage,
    onCopyTriggered,
    onShowUsage,
  };
}
