import { UserAutoSuggestItem } from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useState } from "react";
import * as EmailValidator from "email-validator";
import { UserView } from "../../types/views";
import { addIfNotExists } from "../../../utils";
import { InventoryApi } from "../../../api/InventoryApi";

export interface InviteUsersHooks {
  isInvitingMore: boolean;
  invalidEmailIndexes: number[];
  multipleEmailIndexes: number[];
  duplicateEmailIndexes: number[];
  inviteeOptions: UserAutoSuggestItem[];
  isFormInvalid: boolean;

  onUsersInvited(): void;

  onInviteMore(): void;

  onEmailChanged(email: string, index: number): void;
}

export interface InviteUsersProps {
  userViews: UserView[];
  api: InventoryApi;

  onUsersInvited(email: string[]): void;

  onCancelInvite(): void;
}

export function useInviteUsers(props: InviteUsersProps): InviteUsersHooks {
  const defaultInvalidEmailIndexes: number[] = [];
  const [emails, setEmails] = useState<string[]>([]);
  const [isInvitingMore, setIsInvitingMore] = useState(false);
  const [invalidEmailIndexes, setInvalidEmailIndexes] = useState(defaultInvalidEmailIndexes);
  const [multipleEmailIndexes, setMultipleEmailIndexes] = useState(defaultInvalidEmailIndexes);
  const [duplicateEmailIndexes, setDuplicateEmailIndexes] = useState<number[]>([]);
  const [inviteeOptions, setInviteeOptions] = useState<UserAutoSuggestItem[]>([]);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const onUsersInvited = (): void => {
    setIsFormInvalid(invalidEmailIndexes.length > 0 || emails.length === 0);
    if (invalidEmailIndexes.length === 0 && emails.length > 0) {
      props.onUsersInvited(emails.filter((email) => email !== ""));
    }
  };

  const onInviteMore = (): void => {
    setIsInvitingMore(true);
  };

  const onEmailChanged = (email: string, index: number): void => {
    const emailValue = email.trim();
    if (emailValue === "") {
      return;
    }
    const slicedEmails = [...emails];
    slicedEmails[index] = emailValue;
    let invalidIndexes: number[] = invalidEmailIndexes.filter((i) => i !== index);
    let multipleIndexes: number[] = multipleEmailIndexes.filter((i) => i !== index);
    let duplicateIndexes: number[] = duplicateEmailIndexes.filter((i) => i !== index);
    if (emailValue !== "" && !EmailValidator.validate(emailValue)) {
      invalidIndexes = addIfNotExists(invalidEmailIndexes, index);
    }
    if (emailValue !== "" && (emailValue.includes(" ") || emailValue.includes(",") || emailValue.includes(";"))) {
      multipleIndexes = addIfNotExists(multipleEmailIndexes, index);
    }
    if (props.userViews.some((u) => u.email.toLocaleLowerCase() === email.toLocaleLowerCase())) {
      duplicateIndexes = addIfNotExists(duplicateEmailIndexes, index);
    }
    setInvalidEmailIndexes(invalidIndexes);
    setMultipleEmailIndexes(multipleIndexes);
    setDuplicateEmailIndexes(duplicateIndexes);
    setEmails(slicedEmails);
  };

  useEffect(() => {
    props.api.getInviteSuggestions().then((u) => setInviteeOptions(u));
  }, []);

  return {
    isInvitingMore,
    invalidEmailIndexes,
    multipleEmailIndexes,
    inviteeOptions,
    isFormInvalid,
    duplicateEmailIndexes,
    onUsersInvited,
    onInviteMore,
    onEmailChanged,
  };
}
