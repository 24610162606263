import { Vendor } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import isUrl from "is-url";
import { VendorView } from "../../orders/types/views";
import { getVendorFromView } from "../selectors";
import { VendorState } from "../types/state";

export type OnFormInvalid = (isNameValid: boolean, isUrlValid: boolean) => void;

export interface ManageVendorHooks {
  isNameDuplicated: boolean;
  isNameValid: boolean;
  isUrlValid: boolean;

  onNameChange(name: string): void;

  onUrlChange(url: string): void;

  onContactInfoChange(contactInfo: string): void;

  onNotesChange(notes: string): void;

  onSave(invalidCallback: OnFormInvalid): void;
}

export interface ManageVendorProps {
  vendorView: VendorView;
  saveButtonId: string;

  onSave(vendor: Vendor): void;

  onCancel(): void;
}

export function useManageVendor(props: ManageVendorProps, vendorState: VendorState): ManageVendorHooks {
  const [isNameValid, setIsNameValid] = useState(true);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [vendorView, setVendorView] = useState({ ...props.vendorView });
  const [isNameDuplicated, setIsNameDuplicated] = useState(false);

  const validateName = (): boolean => {
    const isValid = vendorView.name !== "";
    setIsNameValid(isValid);
    return isValid;
  };

  const validateUrl = (): boolean => {
    let isValid = false;
    isValid = vendorView.url === "" ? true : isUrl(vendorView.url);

    setIsUrlValid(isValid);

    return isValid;
  };

  const onNameChange = (name: string): void => {
    vendorView.name = name.trim();
    validateName();
    const duplicated = vendorState.allIds.some((id) => {
      return id !== vendorView.id && vendorState.byId[id].name.toLowerCase() === vendorView.name.toLowerCase();
    });
    setIsNameDuplicated(duplicated);
    setVendorView(vendorView);
  };

  const onUrlChange = (url: string): void => {
    vendorView.url = url.trim();
    validateUrl();
    setVendorView(vendorView);
  };

  const onContactInfoChange = (contactInfo: string): void => {
    vendorView.contactInfo = contactInfo.trim();
    setVendorView(vendorView);
  };

  const onNotesChange = (notes: string): void => {
    vendorView.notes = notes.trim();
    setVendorView(vendorView);
  };

  const onSave = (invalidCallback: OnFormInvalid): void => {
    const validName = validateName();
    const validUrl = validateUrl();

    const isValid = validName && validUrl;

    if (isValid) {
      props.onSave(getVendorFromView(vendorView));
    } else {
      invalidCallback(validName, validUrl);
    }
  };

  return {
    onNameChange,
    onUrlChange,
    onContactInfoChange,
    onNotesChange,
    onSave,
    isNameValid,
    isUrlValid,
    isNameDuplicated,
  };
}
