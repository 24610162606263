import { useState, useEffect } from "react";
import { FreezerBoxDisplayFormat } from "@labarchives/inventory-shared/build/inventory";
import { getFreezerBoxCellLabelFromLocation } from "./selectors";

export interface EditableStorageCellsRowProps {
  selectedCells: string[];
  occupiedCells: string[];
  matrixRows: number;
  matrixCols: number;
  displayFormat: FreezerBoxDisplayFormat | null;
  rowNumber: number;
  columns: number[];
  wasOriginallySelected(cell: string): boolean;
  isCellSelected(cell: string): boolean;
  isCellOccupied(cell: string): boolean;
  updateCellStates(selected: string[], occupied: string[]): void;
  onCellClick(cell: string): void;
  onCellClickOnly(cell: string, selectedInput: string[], occupiedInput: string[]): { selected: string[]; occupied: string[] };
  onSelectedCellsChange(selectedCells: string[]): void;
}

export interface EditableStorageCellsRowHooks {
  rowAllChecked: boolean;
  onRowCheckboxClick(checked: boolean): void;
}

export function useEditableStorageCellsRow(props: EditableStorageCellsRowProps): EditableStorageCellsRowHooks {
  const [rowAllChecked, setRowAllChecked] = useState(false);

  const updateRowAllChecked = (): void => {
    let checked = true;
    props.columns.forEach((c) => {
      const label = getFreezerBoxCellLabelFromLocation(props.rowNumber, c);
      checked = checked && (props.isCellSelected(label) || props.wasOriginallySelected(label));
    });
    setRowAllChecked(checked);
  };

  const onRowCheckboxClick = (checked: boolean): void => {
    let selectedInput = props.selectedCells;
    let occupiedInput = props.occupiedCells;
    props.columns.forEach((c) => {
      const label = getFreezerBoxCellLabelFromLocation(props.rowNumber, c);
      if (checked) {
        if (!props.isCellSelected(label) && !props.wasOriginallySelected(label)) {
          const { selected, occupied } = props.onCellClickOnly(label, selectedInput, occupiedInput);
          selectedInput = selected;
          occupiedInput = occupied;
        }
      } else if (props.isCellSelected(label)) {
        const { selected, occupied } = props.onCellClickOnly(label, selectedInput, occupiedInput);
        selectedInput = selected;
        occupiedInput = occupied;
      }
    });
    props.updateCellStates(selectedInput, occupiedInput);
    props.onSelectedCellsChange(selectedInput);
    updateRowAllChecked();
  };

  useEffect(() => {
    updateRowAllChecked();
  });

  return {
    rowAllChecked,
    onRowCheckboxClick,
  };
}
