import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Col, FormFeedback, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { AlertWithIcon, Button, Modal, Input } from "@labarchives/ui-design/dist";
import { FormattedMessage, useIntl } from "react-intl";
import { RolePermissions, User } from "@labarchives/inventory-shared/build/inventory";
import { Loading } from "../../components";
import { OrderModalProps } from "../types/props";
import { EditableStorage } from "../../storage/EditableStorage";
import { StorageLocationView } from "../../storage/types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { UserView } from "../../user/types/views";
import { UsersContext } from "../../user/UsersContext";
import { getUserView } from "../../user/selectors";
import { RoleState } from "../../roles/types/state";
import { RolesContext } from "../../roles/RolesContext";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import { InventoryStorageChangedFunction } from "../../storage/EditableStorageHooks";
import { InventoryApi } from "../../api/InventoryApi";
import "./OrderModal.scss";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";

export interface OrderReceiveHooks {
  locationId: number | null;
  storageCells: string[] | null;
  storageNotes: string | null;
  addToInventoryMyself: boolean;
  quantity: string;
  units: string;
  notes: string;
  receivableUsers: UserView[];
  receiverIsInvalid: boolean;
  receiverId: number | null;

  onReceiveItem(updateAfterReceive: boolean): void;

  onInventoryStorageChanged: InventoryStorageChangedFunction;

  onAddToInventoryMyselfChanged(addToInventoryMyself: boolean): void;

  onQuantityChanged(quantity: string): void;

  onUnitsChanged(units: string): void;

  onNotesChanged(notes: string): void;

  onReceiverChanged(receiverId: number | null): void;

  onCancel(): void;
}

interface OrderReceiveHooksProps extends OrderReceiveProps {
  orderId: number;
  users: User[];
  roleState: RoleState;
  userId: number;
}

export function useOrderReceive(props: OrderReceiveHooksProps): OrderReceiveHooks {
  const [locationId, setLocationId] = useState<number | null>(null);
  const [storageCells, setStorageCells] = useState<string[] | null>([]);
  const [storageNotes, setStorageNotes] = useState<string | null>(null);
  const [addToInventoryMyself, setAddToInventoryMyself] = useState(true);
  const [quantity, setQuantity] = useState(props.quantity.toString());
  const [units, setUnits] = useState(props.unitOfMeasure);
  const [notes, setNotes] = useState("");
  const [receiverId, setReceiverId] = useState<number | null>(null);

  function onInventoryStorageChanged(locationIdUpdate?: number | null, storageCellsUpdate?: string[] | null, notesUpdate?: string | null): void {
    setLocationId(locationIdUpdate || null);
    setStorageCells(storageCellsUpdate || null);
    setStorageNotes(notesUpdate || null);
  }

  function onAddToInventoryMyselfChanged(isAddingNow: boolean): void {
    setAddToInventoryMyself(isAddingNow);
    if (!isAddingNow) {
      setReceiverId(null);
    }
  }

  function onReceiveItem(updateAfterReceive: boolean): void {
    if (!addToInventoryMyself && receiverId === null) {
      return;
    }

    props.onOrderReceived(
      props.orderId,
      addToInventoryMyself,
      Number.parseFloat(quantity),
      units,
      notes,
      locationId,
      storageCells,
      storageNotes,
      updateAfterReceive,
      receiverId,
    );
  }

  function onQuantityChanged(updatedQuantity: string): void {
    setQuantity(updatedQuantity);
  }

  function onUnitsChanged(updatedUnits: string): void {
    setUnits(updatedUnits);
  }

  function onNotesChanged(updatedNotes: string): void {
    setNotes(updatedNotes);
  }

  function onReceiverChanged(updatedReceiverId: number | null): void {
    setReceiverId(updatedReceiverId);
  }

  function getUsersWhoCanReceive(): UserView[] {
    return props.users
      .filter(
        (u) =>
          u.id !== props.userId &&
          u.fullName.trim() !== "" &&
          (u.permissions.some((p) => p.permissionId === RolePermissions.OrdersReceiveAll) ||
            (u.id === props.requestedById && u.permissions.some((p) => p.permissionId === RolePermissions.OrdersReceiveOwn))),
      )
      .map((u) => getUserView(u, props.roleState));
  }

  function onCancel(): void {
    setLocationId(null);
    setStorageCells([]);
    setStorageNotes(null);
    setAddToInventoryMyself(true);
    setQuantity(props.quantity.toString());
    setUnits(props.unitOfMeasure);
    setNotes("");
    setReceiverId(null);

    console.log("cancel");
  }

  useEffect(() => {
    setQuantity(props.quantity.toString());
  }, [props.quantity]);

  useEffect(() => {
    setUnits(props.unitOfMeasure);
  }, [props.unitOfMeasure]);

  return {
    locationId,
    storageCells,
    storageNotes,
    addToInventoryMyself,
    quantity,
    units,
    notes,
    receiverId,
    receiverIsInvalid: !addToInventoryMyself && receiverId == null,
    receivableUsers: getUsersWhoCanReceive(),
    onReceiveItem,
    onInventoryStorageChanged,
    onAddToInventoryMyselfChanged,
    onQuantityChanged,
    onUnitsChanged,
    onNotesChanged,
    onReceiverChanged,
    onCancel,
  };
}

interface OrderReceiveProps {
  onOrderReceived(
    orderId: number,
    addToInventoryMyself: boolean,
    quantity: number,
    units: string,
    notes: string,
    locationId: number | null,
    storageCells: string[] | null,
    storageNotes: string | null,
    updateAfterReceive: boolean,
    delegateToId: number | null,
  ): void;

  storage: StorageLocationView;
  isStorageLocationRequired: boolean;
  units: string[];
  quantity: number;
  price: number;
  unitOfMeasure: string;
  requestedByName: string;
  requestedById: number;
  inventoryName: string;
}

interface ModalReceiveProps extends OrderReceiveProps, OrderModalProps {
  api: InventoryApi;
}

export function ModalReceive(props: ModalReceiveProps): ReactElement {
  const userContext = useContext(UsersContext);
  const authContext = useContext(AuthenticationContext);
  const intl = useIntl();

  const {
    addToInventoryMyself,
    quantity,
    units,
    locationId,
    storageCells,
    storageNotes,
    receivableUsers,
    receiverIsInvalid,
    receiverId,
    onInventoryStorageChanged,
    onReceiveItem,
    onAddToInventoryMyselfChanged,
    onQuantityChanged,
    onUnitsChanged,
    onNotesChanged,
    onReceiverChanged,
    onCancel,
  } = useOrderReceive({
    ...props,
    users: userContext.allIds.map((id) => userContext.byId[id]),
    roleState: useContext(RolesContext),
    userId: authContext.getUser()!.id,
  });

  function toggle(): void {
    onCancel();
    props.toggleModal(props.modalId);
  }

  let actionRow = (
    <div>
      <Button color="primary" className="me-1" onClick={() => onReceiveItem(false)} id="order-modal-receive-button">
        <FormattedMessage id="mark.received" />
      </Button>
      {addToInventoryMyself && (
        <Button color="primary" className="me-1" onClick={() => onReceiveItem(true)} id="order-modal-receive-update-button">
          <FormattedMessage id="mark.received.update" />
        </Button>
      )}
      <CancelButton className="order-modal-cancel" onClick={() => toggle()} />
    </div>
  );

  if (props.isProcessing) {
    actionRow = (
      <div>
        <Loading messageId="updating.order" />
      </div>
    );
  }

  let modalBody = (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label htmlFor="order-search-add-to-inventory" className={"visually-hidden"}>
              <FormattedMessage id="add.to.inventory" />
            </Label>
            <FormGroup check>
              <Input
                type="radio"
                id="order-search-add-to-inventory-self"
                name="order-search-add-to-inventory"
                checked={addToInventoryMyself}
                value="1"
                onChange={() => onAddToInventoryMyselfChanged(true)}
              />
              <Label htmlFor={"order-search-add-to-inventory-self"}>{intl.formatMessage({ id: "i.will.add.to.inventory" })}</Label>
            </FormGroup>
            {receivableUsers.length > 0 && (
              <>
                <FormGroup check>
                  <Input
                    type="radio"
                    id="order-search-add-to-inventory-requester"
                    name="order-search-add-to-inventory"
                    value="0"
                    checked={!addToInventoryMyself}
                    onChange={() => onAddToInventoryMyselfChanged(false)}
                  />
                  <Label htmlFor={"order-search-add-to-inventory-requester"}>{intl.formatMessage({ id: "user.will.add.to.inventory" })}</Label>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor={"receiverId"} className={"visually-hidden"}>
                    Put Away By
                  </Label>

                  <Input
                    type={"select"}
                    disabled={addToInventoryMyself}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        onReceiverChanged(null);
                      } else {
                        onReceiverChanged(Number.parseInt(e.target.value));
                      }
                    }}
                    invalid={receiverIsInvalid}
                    value={receiverId || ""}
                  >
                    <option value={""}>&nbsp;</option>
                    {receivableUsers.map((u) => (
                      <option key={u.id} value={u.id}>
                        {u.fullName}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>
                    <FormattedMessage id={"please.choose.who.will.add.this.item.to.inventory"} />
                  </FormFeedback>
                </FormGroup>
              </>
            )}
          </FormGroup>
        </Col>
      </Row>
      {addToInventoryMyself && (
        <>
          <Row>
            <Col>
              <FormGroup>
                <Label for="order-modal-quantity">
                  <FormattedMessage id="quantity" />
                </Label>
                <Input
                  type="number"
                  step="any"
                  min="0"
                  defaultValue={quantity}
                  onChange={(e) => onQuantityChanged(e.target.value)}
                  id="order-modal-quantity"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="order-modal-units">
                  <FormattedMessage id="units" />
                </Label>
                <Input type="select" defaultValue={units} onChange={(e) => onUnitsChanged(e.target.value)} id="order-modal-units">
                  {props.units.map((u) => (
                    <option value={u} key={u}>
                      {u}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>
                <FormattedMessage id="location" />
                <RequiredLabelIndicator required={props.isStorageLocationRequired} />
              </Label>
              <EditableStorage
                onInventoryStorageChanged={onInventoryStorageChanged}
                storageLocations={props.storage}
                selectedStorageLocationId={locationId}
                selectedStorageCells={storageCells || []}
                storageNotes={storageNotes}
                api={props.api}
                isRequired={props.isStorageLocationRequired}
              />
            </Col>
          </Row>
        </>
      )}
      {!addToInventoryMyself && (
        <Row>
          <Col className="order-modal-notes-container">
            <Label for="order-modal-notes">
              <FormattedMessage id="add.notes" />
            </Label>
            <Input type="textarea" id="order-modal-notes" onChange={(e) => onNotesChanged(e.target.value)} />
          </Col>
        </Row>
      )}
    </>
  );

  if (props.errorMessage.length > 0) {
    modalBody = (
      <Row>
        <Col>
          <div>
            <AlertWithIcon color="danger">
              <FormattedMessage id="error.marking.received" />
            </AlertWithIcon>
          </div>
          <div>{props.errorMessage}</div>
        </Col>
      </Row>
    );
    actionRow = (
      <div>
        <Button color="none" outline onClick={() => toggle()}>
          <FormattedMessage id="close" />
        </Button>
      </div>
    );
  }

  return (
    <Modal id={props.modalId} isOpen={props.isModalOpen(props.modalId)} toggle={() => toggle()} className="order-modal-modal">
      <ModalHeader toggle={() => toggle()}>
        <FormattedMessage id="mark.received" />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <span className="order-modal-title">{props.inventoryName}</span>
          </Col>
        </Row>
        {modalBody}
      </ModalBody>
      <ModalFooter>{actionRow}</ModalFooter>
    </Modal>
  );
}
