import React, { ReactElement } from "react";
import { Button } from "@labarchives/ui-design";
import { useIntl } from "react-intl";
import { StorageLocationTreeView } from "../types/views";
import { ArrowIcon } from "../../components/CheckboxTree/ArrowIcon";

interface ManageStorageExpandCollapseProps {
  location: StorageLocationTreeView;
  isCollapsed(location: StorageLocationTreeView): boolean;
  onExpand(location: StorageLocationTreeView): void;
  onCollapse(location: StorageLocationTreeView): void;
}

export function ManageStorageExpandCollapse(props: ManageStorageExpandCollapseProps): ReactElement {
  const intl = useIntl();
  const { location, isCollapsed, onCollapse, onExpand } = props;
  if (location.children.length === 0) {
    return <></>;
  }

  const ariaControls = `manage-location-section-${location.location.id}`;

  if (isCollapsed(location)) {
    return (
      <Button
        type={"button"}
        color={"link"}
        className={"me-2"}
        onClick={() => onExpand(location)}
        title={intl.formatMessage({ id: "Expand" })}
        aria-expanded={false}
        aria-controls={ariaControls}
      >
        <ArrowIcon isOpen={false} />
      </Button>
    );
  }

  return (
    <Button
      type={"button"}
      color={"link"}
      className={"me-2"}
      onClick={() => onCollapse(location)}
      title={intl.formatMessage({ id: "Collapse" })}
      aria-expanded={true}
      aria-controls={ariaControls}
    >
      <ArrowIcon isOpen={true} />
    </Button>
  );
}
