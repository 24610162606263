import React, { ReactElement, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Badge, Col, Row } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VendorView } from "../../orders/types/views";
import { Loading } from "../../components";
import { VendorState } from "../types/state";
import { VendorsContext } from "../VendorsContext";
import { ManageVendor } from "./ManageVendor";
import { ManageVendorsList } from "./ManageVendorsList";
import { useManageVendors } from "./ManageVendorsHooks";
import { InventoryApiClient } from "../../api";

export function ManageVendors(): ReactElement {
  const vendorState: VendorState = useContext<VendorState>(VendorsContext);

  const {
    activeVendorView,
    setActiveVendorView,
    isAdding,
    setIsAdding,
    onShowVendor,
    onShowAddVendor,
    onAddVendor,
    onUpdateVendor,
    onDeleteVendor,
    onShowDeleteVendor,
    vendorViews,
  } = useManageVendors(vendorState, new InventoryApiClient());
  const emptyVendorView: VendorView = { id: 0, notes: "", url: "", contactInfo: "", name: "" };

  if (vendorState.isLoading) {
    return <Loading />;
  }

  function showVendorList(): boolean {
    return !isAdding && !activeVendorView;
  }

  function showAddVendor(): boolean {
    return isAdding && !activeVendorView;
  }

  function showEditVendor(): boolean {
    return activeVendorView !== undefined && !isAdding;
  }

  function onCancelEdit(): void {
    setActiveVendorView(undefined);
    setIsAdding(false);
  }

  return (
    <div>
      <div className="management-header">
        <h1 className="inventory-heading">
          <FormattedMessage id="vendors" />
          <Badge>{vendorViews.length}</Badge>
        </h1>

        <Button className="management-header-action" color="link" onClick={onShowAddVendor}>
          <FontAwesomeIcon icon={faPlusCircle} /> <FormattedMessage id="add.new.vendor" />
        </Button>
      </div>
      <Row>
        <Col>
          {showVendorList() && (
            <ManageVendorsList
              vendorViews={vendorViews}
              onShowVendor={onShowVendor}
              onDelete={onDeleteVendor}
              onShowDeleteVendor={onShowDeleteVendor}
            />
          )}
          {showAddVendor() && <ManageVendor vendorView={emptyVendorView} onSave={onAddVendor} onCancel={onCancelEdit} saveButtonId="add.vendor" />}
          {showEditVendor() && activeVendorView && (
            <ManageVendor vendorView={activeVendorView} onSave={onUpdateVendor} onCancel={onCancelEdit} saveButtonId="update.vendor" />
          )}
        </Col>
      </Row>
    </div>
  );
}
