export default function copyToClipboard(richText: string): void {
  const temp = document.createElement("div");
  temp.innerHTML = richText;
  temp.style.position = "fixed";
  temp.style.left = "-1000px";
  temp.style.top = "-1000px";
  temp.contentEditable = "true";
  document.body.append(temp);
  temp.focus();
  document.execCommand("selectAll");
  document.execCommand("copy");
  temp.remove();
}
