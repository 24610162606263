import { useEffect, useState } from "react";
import { FreezerBoxDisplayFormat, InventoryBulkUpdateResponse } from "@labarchives/inventory-shared/build/inventory";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { InventoryBulkUpdateParseResult } from "../../inventory/import/InventoryBulkUpdateParseResult";
import { InventoryExcel } from "../../inventory/import/InventoryExcel";
import { InventoryTypeView } from "../../inventory/types/views";
import { StorageLocationView } from "../../storage/types/views";
import { UserView } from "../../user/types/views";
import * as utils from "../../utils";
import { InventoryApi } from "../../api/InventoryApi";

export interface BulkInventoryUpdateComponentHooks {
  showImportConflicts: boolean;
  showPreUploadConfirmation: boolean;
  isParsing: boolean;
  parseResult: InventoryBulkUpdateParseResult;
  updateResult: InventoryBulkUpdateResponse;
  showUpload: boolean;
  showTemplateStep: boolean;
  showError: boolean;
  isConsentChecked: boolean;
  isExporting: boolean;
  isUpdating: boolean;
  showImportConfirmation: boolean;
  isIgnoreQuantityChecked: boolean;

  onRestart(): void;

  onConsentChecked(isConsentChecked: boolean): void;

  onIgnoreQuantityChanged(isIgnoreQuantityChanged: boolean): void;

  onImportUploaded(files: File[]): void;

  onDownloadTemplate(): Promise<void>;

  onDownloadConflicts(referenceNumber: string): Promise<void>;

  onImport(): Promise<void>;
}

export interface BulkInventoryUpdateComponentHooksProps {
  inventoryTypeViews: InventoryTypeView[];
  storageLocations: StorageLocationView;
  userViews: UserView[];
  api: InventoryApi;

  freezerBoxCellFormatter(cells: string[], format: FreezerBoxDisplayFormat | null, rows: number | null, cols: number | null): string;
}

export function useBulkInventoryUpdateComponent(props: BulkInventoryUpdateComponentHooksProps): BulkInventoryUpdateComponentHooks {
  const defaultParseResult: InventoryBulkUpdateParseResult = { items: [], isValid: true, errors: [], fileDate: clock.getNow() };
  const defaultImportResult: InventoryBulkUpdateResponse = { referenceNumber: "", numberOfConflicts: 0, numberImported: 0 };
  const [step, setStep] = useState(1);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isIgnoreQuantityChecked, setIsIgnoreQuantityChecked] = useState(false);
  const [parseResult, setParseResult] = useState<InventoryBulkUpdateParseResult>(defaultParseResult);
  const [isParsing, setIsParsing] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showImportConfirmation, setShowImportConfirmation] = useState(false);
  const [updateResult, setUpdateResult] = useState<InventoryBulkUpdateResponse>(defaultImportResult);
  const { api, inventoryTypeViews, storageLocations, userViews, freezerBoxCellFormatter } = props;

  function onConsentChecked(newConsentChecked: boolean): void {
    setIsConsentChecked(newConsentChecked);
  }

  function onIgnoreQuantityChanged(newIgnoreQuantityChecked: boolean): void {
    setIsIgnoreQuantityChecked(newIgnoreQuantityChecked);
  }

  function onRestart(): void {
    setIsParsing(false);
    setParseResult(defaultParseResult);
    setUpdateResult(defaultImportResult);
    setShowImportConfirmation(false);
    setStep(1);
  }

  function onImportUploaded(files: File[]): void {
    setIsParsing(true);
    const excel = InventoryExcel.Create(inventoryTypeViews, storageLocations);
    excel.ParseBulkUpdate(files[0]).then((result) => {
      setIsParsing(false);
      setParseResult(result);
      setStep(2);

      return result;
    });
  }

  async function onDownloadTemplate(): Promise<void> {
    setIsExporting(true);

    const exportedItems = await api.exportInventory({ term: "", locationIds: [], typeIds: [] });
    const excel = InventoryExcel.CreateExport(exportedItems, inventoryTypeViews, userViews, storageLocations, freezerBoxCellFormatter, true);

    const blob = await excel.AsBlob();
    utils.saveAs(blob, "InventoryBulkUpdate.xlsx");

    setIsExporting(false);
  }

  async function onImport(): Promise<void> {
    setIsUpdating(true);
    const result = await api.updateInventoryItems({
      items: parseResult.items,
      dateCreated: parseResult.fileDate,
      skipQuantity: isIgnoreQuantityChecked,
    });
    setParseResult(defaultParseResult);
    setShowImportConfirmation(true);
    setIsUpdating(false);
    setUpdateResult(result);
  }

  async function onDownloadConflicts(referenceNumber: string): Promise<void> {
    setIsExporting(true);

    const exportedItems = await api.getBulkUpdateConflicts(referenceNumber);
    const excel = InventoryExcel.CreateExport(exportedItems, inventoryTypeViews, userViews, storageLocations, freezerBoxCellFormatter, true);

    const blob = await excel.AsBlob();
    utils.saveAs(blob, "InventoryFailedBulkUpdateReport.xlsx");

    setIsExporting(false);
  }

  useEffect(() => {
    api.getLastBulkUpdateDetails().then((r) => setUpdateResult(r));
  }, []);

  return {
    showTemplateStep: step === 1 && !isExporting,
    showUpload: step === 1 && isConsentChecked && !isParsing && !isExporting,
    showPreUploadConfirmation: step === 2 && parseResult.isValid && parseResult.items.length > 0 && !isExporting,
    showError: step === 2 && !parseResult.isValid && !isExporting,
    showImportConfirmation: showImportConfirmation && !isExporting,
    showImportConflicts: updateResult.numberOfConflicts > 0,
    updateResult,
    isParsing,
    isUpdating,
    isExporting,
    parseResult,
    isConsentChecked,
    isIgnoreQuantityChecked,
    onConsentChecked,
    onImportUploaded,
    onRestart,
    onDownloadTemplate,
    onImport,
    onDownloadConflicts,
    onIgnoreQuantityChanged,
  };
}
