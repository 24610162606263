import { InventoryApi } from "../api/InventoryApi";
import { FreezerCapacityReportDownloadHooks } from "./FreezerCapacityReportDownloadHooks";

export interface FreezerCapacityReportHooks {
  onReportDownloaded(): Promise<void>;
}

interface FreezerCapacityReportHooksProps {
  downloadHooks: FreezerCapacityReportDownloadHooks;
  api: InventoryApi;
}

export function useFreezerCapacityReport(props: FreezerCapacityReportHooksProps): FreezerCapacityReportHooks {
  const { downloadHooks, api } = props;

  async function onReportDownloaded(): Promise<void> {
    const items = await api.reportFreezerCapacity();
    return downloadHooks.onReportDownloaded(items, "freezer-capacity-report.xlsx");
  }

  return {
    onReportDownloaded,
  };
}
