import { InventorySearchCriteria } from "@labarchives/inventory-shared/build/inventory";
import { InventoryFilterView, InventoryTypeView } from "../../types/views";
import { StorageLocationTreeView } from "../../../storage/types/views";

export interface InventorySearchFilterHooks {
  onFilterCleared(): void;

  onChangedTypes(selectedTypeIds: number[]): void;

  onChangedLocations(selectedLocationIds: number[]): void;

  onChangedReceivedDates(start?: Date, end?: Date): void;

  onChangedOutOfStock(includeOutOfStock: boolean): void;

  onChangedNoLocation(includeNoLocation: boolean): void;
}

export interface InventorySearchFilterProps {
  inventoryTypeViews: InventoryTypeView[];
  storageLocations: StorageLocationTreeView[];
  filters: InventorySearchCriteria;
  isShowingFilter: boolean;
  onFilterChanged(filters: InventoryFilterView): void;
  onFilterCleared(): void;
  onFilterToggle(): void;
}

export function useInventorySearchFilter(props: InventorySearchFilterProps): InventorySearchFilterHooks {
  function onFilterCleared(): void {
    props.onFilterCleared();
  }

  function onChangedTypes(selectedTypeIds: number[]): void {
    const filter: InventoryFilterView = { ...props.filters, typeIds: selectedTypeIds };
    props.onFilterChanged(filter);
  }

  function onChangedLocations(selectedLocationIds: number[]): void {
    const filter: InventoryFilterView = { ...props.filters, locationIds: selectedLocationIds };
    props.onFilterChanged(filter);
  }

  function onChangedReceivedDates(start?: Date, end?: Date): void {
    const filter: InventoryFilterView = { ...props.filters, receivedStartDate: start, receivedEndDate: end };
    props.onFilterChanged(filter);
  }

  function onChangedOutOfStock(includeOutOfStock: boolean): void {
    const filter: InventoryFilterView = { ...props.filters, includeOutOfStock };
    props.onFilterChanged(filter);
  }

  function onChangedNoLocation(includeNoLocation: boolean): void {
    const filter: InventoryFilterView = { ...props.filters, includeMissingLocationId: includeNoLocation };
    props.onFilterChanged(filter);
  }

  return {
    onFilterCleared,
    onChangedTypes,
    onChangedLocations,
    onChangedReceivedDates,
    onChangedOutOfStock,
    onChangedNoLocation,
  };
}
