import { useEffect, useState } from "react";
import { UserStatus } from "@labarchives/inventory-shared/build/inventory";
import "./SuperUserTools.scss";
import { InventoryApi } from "../api/InventoryApi";
import { SuperUserUserAccountView, SuperUserUserDetailsView } from "./views";
import { getDefaultSuperUserUserDetailsView, getSuperUserUserDetailsView } from "./selectors";

export interface SuperUserUserDetailsHooks {
  isChangingSiteAdmin: boolean;
  userDetails: SuperUserUserDetailsView;
  isLoading: boolean;
  hasUpdateStatusError: boolean;
  accountToChange?: SuperUserUserAccountView;
  userNotFound: boolean;
  showSubscriptionDetails: boolean;

  onPromptChangeStatus(accountId: number): void;

  onCancelChangeStatus(): void;

  onChangeUserStatus(accountId: number): void;

  setHasUpdateStatusError(hasError: boolean): void;

  onToggleChangeSiteAdmin(): void;

  onChangeUserSiteAdmin(isSiteAdmin: boolean): void;
}

export function useSuperUserUserDetails(userId: number, api: InventoryApi): SuperUserUserDetailsHooks {
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<SuperUserUserDetailsView>(getDefaultSuperUserUserDetailsView());
  const [accountToChange, setAccountToChange] = useState<SuperUserUserAccountView | undefined>(undefined);
  const [hasUpdateStatusError, setHasUpdateStatusError] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [isChangingSiteAdmin, setIsChangingSiteAdmin] = useState(false);

  function onPromptChangeStatus(accountId: number): void {
    const account = userDetails.accounts.find((a) => a.id === accountId);
    setAccountToChange(account);
  }

  function onToggleChangeSiteAdmin(): void {
    setIsChangingSiteAdmin((prev) => {
      return !prev;
    });
  }

  function onCancelChangeStatus(): void {
    setAccountToChange(undefined);
  }

  function onChangeUserStatus(): void {
    if (!accountToChange) {
      return;
    }
    setIsLoading(true);
    let newStatus = UserStatus.Active;
    if (accountToChange.status === UserStatus.Active) {
      newStatus = UserStatus.Inactive;
    }

    api
      .superUserChangeUserStatus({ userId, accountId: accountToChange.id, status: newStatus })
      .then((response) => {
        setUserDetails(getSuperUserUserDetailsView(response));
        setIsLoading(false);
        return response;
      })
      .catch((error) => {
        setIsLoading(false);
        setHasUpdateStatusError(true);
        api.logError(error);
      });
    setAccountToChange(undefined);
  }

  function onChangeUserSiteAdmin(isSiteAdmin: boolean): void {
    setIsLoading(true);

    api
      .superUserChangeUserSiteAdmin({ userId: userDetails.id, isSiteAdmin })
      .then((response) => {
        setUserDetails(getSuperUserUserDetailsView(response));
        setIsLoading(false);
        onToggleChangeSiteAdmin();
        return response;
      })
      .catch((error) => {
        setIsLoading(false);
        onToggleChangeSiteAdmin();
        api.logError(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    api
      .superUserGetUserDetails(userId)
      .then((details) => {
        setUserDetails(getSuperUserUserDetailsView(details));
        setIsLoading(false);
        return details;
      })
      .catch((error) => {
        setUserNotFound(true);
        setIsLoading(false);
        api.logError(error);
      });
  }, []);

  return {
    isLoading,
    userDetails,
    accountToChange,
    hasUpdateStatusError,
    userNotFound,
    setHasUpdateStatusError,
    isChangingSiteAdmin,
    showSubscriptionDetails: userDetails.accounts.some((a) => !a.hasSiteLicense),
    onPromptChangeStatus,
    onCancelChangeStatus,
    onChangeUserStatus,
    onToggleChangeSiteAdmin,
    onChangeUserSiteAdmin,
  };
}
