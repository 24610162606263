import React, { ReactElement } from "react";
import { Router } from "react-router";
import { FormattedMessage } from "react-intl";
import { Navigation } from "../components";
import { Settings } from "../utils/Settings";
import { history } from "./history";

export function AccessDeniedApp(): ReactElement {
  return (
    <Router history={history}>
      <div>
        <Navigation />
        <div className="container-fluid">
          <div>
            <FormattedMessage id="access.denied" />
          </div>
          <div>
            <a href={Settings.getAccountUrlBase()}>
              <FormattedMessage id="sign.in" />
            </a>
          </div>
        </div>
      </div>
    </Router>
  );
}
