import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { SubscriptionType } from "@labarchives/inventory-shared/build/inventory";
import { FormattedDate, FormattedMessage } from "react-intl";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import "./SubscriptionNotice.scss";
import { ApplicationPaths } from "../../app/ApplicationPaths";

export const SubscriptionNotice = (): ReactElement => {
  const auth = useContext(AuthenticationContext);
  const user = auth.getUser();

  const subscriptionExpiringSoon = (): boolean => {
    if (!user) {
      return false;
    }

    return !user.activeAccount.subscriptionIsRenewed && user.activeAccount.subscriptionEnd < clock.addDays(new Date(), 14);
  };

  if (!user || !user.activeAccount.subscriptionManagementAllowed || !user.isAccountOwner || !subscriptionExpiringSoon()) {
    return <></>;
  }

  return (
    <div className="subscription-expiration-notice">
      {user.activeAccount.subscriptionType === SubscriptionType.Free && <FormattedMessage id="free.trial.is.ending" />}
      {user.activeAccount.subscriptionType === SubscriptionType.Paid && <FormattedMessage id="subscription.is.ending" />}
      <span className="subscription-expiration-date">
        <FormattedDate value={user.activeAccount.subscriptionEnd} />
      </span>
      <span>.</span>
      <span className="subscription-expiration-manage-link">
        <Link to={ApplicationPaths.Subscription.Home}>
          {user.activeAccount.subscriptionType === SubscriptionType.Free && <FormattedMessage id="subscribe.now" />}
          {user.activeAccount.subscriptionType === SubscriptionType.Paid && <FormattedMessage id="renew.now" />}
        </Link>
      </span>
    </div>
  );
};
