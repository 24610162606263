import { MergedCells } from "./MergedCells";

export class ExcelSheet {
  private readonly name: string = "";

  private columns: string[] = [];

  private requiredColumns: string[] = [];

  private readonly rows: string[][] = [];

  private readonly mergedCells: MergedCells[] = [];

  private readonly tabColor?: string;

  public constructor(name: string, tabColor?: string) {
    this.name = name;
    this.tabColor = tabColor;
  }

  public get Name(): string {
    return this.name;
  }

  public get TabColor(): string | undefined {
    return this.tabColor;
  }

  public get Columns(): string[] {
    return this.columns;
  }

  public AddColumns(columnNames: string[]): void {
    this.columns = columnNames;
  }

  public SetRequiredColumns(columnNames: string[]): void {
    this.requiredColumns = columnNames;
  }

  public get Rows(): string[][] {
    return this.rows;
  }

  public Row(id: number | string): string[] {
    let index = 0;
    index = typeof id === "string" ? this.columns.indexOf(id) : id;

    return this.rows[index];
  }

  public AppendRow(values: string[]): void {
    this.rows[this.rows.length] = values;
  }

  public IsColumnRequired(columnName: string): boolean {
    return this.requiredColumns.includes(columnName);
  }

  public get MergedCells(): MergedCells[] {
    return this.mergedCells;
  }

  public AddMergedCells(rangeStart: string, rangeEnd: string, someContent: string): void {
    this.mergedCells.push(new MergedCells(rangeStart, rangeEnd, someContent));
  }
}
