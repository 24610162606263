import React, { ReactElement, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { UserStatus } from "@labarchives/inventory-shared/build/inventory";
import { AlertWithIcon, Button, CheckboxInput, CheckboxLabel, Modal } from "@labarchives/ui-design/dist";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { SuperUserAccountDetailsView, SuperUserAccountUserView } from "./views";

export interface SuperUserTransferOwnershipHooks {
  ownerId: number;
  isConfirmed: boolean;
  users: SuperUserAccountUserView[];

  onSave(): void;
  onOwnerChanged(ownerId: number): void;
  onConfirmationToggled(): void;
}

export function useSuperUserTransferOwnershipHooks(props: SuperUserTransferOwnershipModalProps): SuperUserTransferOwnershipHooks {
  const [ownerId, setOwnerId] = useState(props.account.ownerId);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    setIsConfirmed(false);
  }, [props.isOpen]);

  function onSave(): void {
    if (isConfirmed) {
      props.onUpdate(ownerId);
    }
  }

  function onConfirmationToggled(): void {
    setIsConfirmed(!isConfirmed);
  }

  return {
    ownerId,
    isConfirmed,
    users: props.account.users.filter((u) => u.status === UserStatus.Active && u.isLinkable),
    onSave,
    onOwnerChanged: setOwnerId,
    onConfirmationToggled,
  };
}

export interface SuperUserTransferOwnershipModalProps {
  isOpen: boolean;
  account: SuperUserAccountDetailsView;

  onToggle(): void;

  onUpdate(ownerId: number): void;
}

export function SuperUserTransferOwnershipModal(props: SuperUserTransferOwnershipModalProps): ReactElement {
  const { users, ownerId, isConfirmed, onSave, onConfirmationToggled, onOwnerChanged } = useSuperUserTransferOwnershipHooks(props);

  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle}>
      <ModalHeader toggle={props.onToggle}>Transfer Account Ownership</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {props.account.hasSiteLicense && <AlertWithIcon color="danger">Ensure the new owner is part of this site</AlertWithIcon>}
            <FormGroup>
              <Label for="transfer-ownership-ownerid">New Owner</Label>
              <Input type="select" id="transfer-ownership-ownerid" value={ownerId} onChange={(e) => onOwnerChanged(Number.parseInt(e.target.value))}>
                {users.map((u) => (
                  <option value={u.id} key={u.id}>
                    {u.fullName} ({u.email})
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup>
              <CheckboxLabel>
                <CheckboxInput
                  id="transfer-ownership-confirmation-checkbox"
                  name="confirmation"
                  onChange={onConfirmationToggled}
                  uncheckedValue="0"
                  checked={isConfirmed}
                />
                <span>I am sure that I want to transfer account ownership</span>
              </CheckboxLabel>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="me-1" onClick={onSave} disabled={!isConfirmed}>
          Save
        </Button>
        <CancelButton onClick={props.onToggle} />
      </ModalFooter>
    </Modal>
  );
}
