import React, { ReactElement } from "react";
import { FreezerBoxDisplayFormat } from "@labarchives/inventory-shared/build/inventory";
import { FormattedMessage } from "react-intl";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { ManageFreezerBox } from "./ManageFreezerBox";

interface AddStorageLocationProps {
  isNameValid: boolean;
  isFreezerBox: boolean;
  isNameDuplicated: boolean;
  invalidNameErrorMessageId: string;

  onFreezerBoxChanged(rows: number, columns: number, format: FreezerBoxDisplayFormat): void;
  onNameChanged(name: string): void;
  onToggleFreezerBox(isFreezerBox: boolean): void;
}

export function AddStorageLocation(props: AddStorageLocationProps): ReactElement {
  return (
    <div className="add-storage-location-container">
      <div>
        <FormGroup className="add-storage-location-name">
          <Label>
            <FormattedMessage id="location.name" />
            <RequiredLabelIndicator required={true} />
          </Label>
          <Input
            id="add-storage-location-name"
            type="text"
            bsSize="sm"
            defaultValue=""
            onChange={(e) => props.onNameChanged(e.target.value.trim())}
            required
            invalid={!props.isNameValid || props.isNameDuplicated}
            autoFocus
            maxLength={255}
          />
          <FormFeedback>
            <FormattedMessage id={props.invalidNameErrorMessageId} />
          </FormFeedback>
        </FormGroup>
      </div>
      <div>
        <FormGroup>
          <CheckboxLabel>
            <CheckboxInput
              id="add-storage-location-is-freezer-box"
              onChange={(e) => props.onToggleFreezerBox(e.target.checked)}
              uncheckedValue="0"
              checked={props.isFreezerBox}
              value={props.isFreezerBox.toString()}
            />
            <span>
              <FormattedMessage id="freezer.box" />
            </span>
          </CheckboxLabel>
        </FormGroup>
      </div>
      {props.isFreezerBox && (
        <ManageFreezerBox columns={9} rows={9} format={FreezerBoxDisplayFormat.Default} onFreezerBoxChanged={props.onFreezerBoxChanged} />
      )}
    </div>
  );
}
