import React, { ReactElement, useContext } from "react";
import { AppContext, AppState } from "./AppContext";
import { InventoryAdminApp } from "./InventoryAdminApp";
import { InventoryUserApp } from "./InventoryUserApp";

export function InventoryApplicationHarness(): ReactElement {
  const appState = useContext<AppState>(AppContext);
  if (appState.isInventoryAdminMode) {
    return <InventoryAdminApp />;
  }

  return <InventoryUserApp />;
}
