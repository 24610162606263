export class MergedCells {
  private readonly rangeStart: string;

  private readonly rangeEnd: string;

  private readonly someContent: string;

  public get Range(): string {
    return `${this.rangeStart}:${this.rangeEnd}`;
  }

  public get Content(): string {
    return this.someContent;
  }

  public get ContentCell(): string {
    return this.rangeStart;
  }

  public get IsHighlighted(): boolean {
    return true;
  }

  public constructor(rangeStart: string, rangeEnd: string, someContent: string) {
    this.rangeStart = rangeStart;
    this.rangeEnd = rangeEnd;
    this.someContent = someContent;
  }
}
