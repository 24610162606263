import { useEffect, useState } from "react";
import { Account, InventorySortableColumnDefault, SiteLocations, SubscriptionType } from "@labarchives/inventory-shared/build/inventory";
import { AuthenticationState } from "../../components/Authentication/AuthenticationState";
import { getSiteLocationBuildingViews, getSiteLocationFloorViews, getSiteLocationRoomViews, SiteLocationView } from "../selectors";
import { InventoryApi } from "../../api/InventoryApi";

export interface LabSettingsHooks {
  buildingId: number | undefined;
  floorId: number | undefined;
  roomId: number | undefined;
  buildingOptions: SiteLocationView[];
  floorOptions: SiteLocationView[];
  roomOptions: SiteLocationView[];
  isLoading: boolean;
  autoApprovalThreshold: string | null;
  isAutoApproveEnabled: boolean;
  showUpdate: boolean;
  currency: string;
  name: string;
  building: string;
  floor: string;
  room: string;
  isNameValid: boolean;
  isLocationValid: boolean;

  onAutoApprovalChanged(shouldApprove: boolean): void;

  onAutoApprovalThresholdChanged(amount: string | null): void;

  onSettingsSaved(): void;

  onHideUpdate(): void;

  onCurrencyChanged(currency: string): void;

  onNameChanged(name: string): void;

  onBuildingChanged(name: string): void;

  onFloorChanged(name: string): void;

  onRoomChanged(name: string): void;

  onBuildingIdChanged(id: number | undefined): void;

  onFloorIdChanged(id: number | undefined): void;

  onRoomIdChanged(id: number | undefined): void;
}

export function useLabSettings(authState: AuthenticationState, api: InventoryApi): LabSettingsHooks {
  const [isLoading, setIsLoading] = useState(true);
  const [autoApprovalThreshold, setAutoApprovalThreshold] = useState<string | null>(null);
  const [isAutoApproveEnabled, setIsAutoApproveEnabled] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const [name, setName] = useState("");
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [room, setRoom] = useState("");
  const [buildingId, setBuildingId] = useState<number | undefined>();
  const [floorId, setFloorId] = useState<number | undefined>();
  const [roomId, setRoomId] = useState<number | undefined>();
  const [isNameValid, setIsNameValid] = useState(true);
  const [locations, setLocations] = useState<SiteLocations>({ isPublished: true, buildings: [], floors: [], rooms: [] });
  const [account, setAccount] = useState<Account>({
    id: 0,
    name: "",
    building: "",
    floor: "",
    room: "",
    buildingId: undefined,
    floorId: undefined,
    roomId: undefined,
    autoApprovalThreshold: null,
    currency: "USD",
    timezone: "UTC",
    locale: "en-us",
    subscriptionSeatCount: 0,
    subscriptionEnd: new Date(2299, 1, 10),
    subscriptionBegin: new Date(),
    subscriptionType: SubscriptionType.Free,
    subscriptionIsRenewed: false,
    subscriptionManagementAllowed: false,
    subscriptionHasExpired: false,
    displaySettings: { inventorySearchOrder: InventorySortableColumnDefault },
  });
  const [showUpdate, setShowUpdate] = useState(false);

  const onAutoApprovalChanged = (shouldApprove: boolean): void => {
    setIsAutoApproveEnabled(shouldApprove);
    setAutoApprovalThreshold(getCleanAmount(`${autoApprovalThreshold}`));
  };

  const onAutoApprovalThresholdChanged = (amount: string | null): void => {
    setAutoApprovalThreshold(amount || "1.00");
  };

  const onCurrencyChanged = (accountCurrency: string): void => {
    setCurrency(accountCurrency);
  };

  const onNameChanged = (updatedName: string): void => {
    setName(updatedName);
    setIsNameValid(updatedName.trim() !== "");
  };

  const onBuildingChanged = (updatedBuilding: string): void => {
    setBuilding(updatedBuilding);
  };

  const onFloorChanged = (updatedFloor: string): void => {
    setFloor(updatedFloor);
  };

  const onRoomChanged = (updatedRoom: string): void => {
    setRoom(updatedRoom);
  };

  const onBuildingIdChanged = (id: number | undefined): void => {
    setBuildingId(id);
    setFloorId(undefined);
    setRoomId(undefined);
  };

  const onFloorIdChanged = (id: number | undefined): void => {
    setFloorId(id);
    setRoomId(undefined);
  };

  const onRoomIdChanged = (id: number | undefined): void => {
    setRoomId(id);
  };

  const onSettingsSaved = async (): Promise<void> => {
    account.autoApprovalThreshold = null;
    if (isAutoApproveEnabled) {
      account.autoApprovalThreshold = autoApprovalThreshold ? Number.parseFloat(autoApprovalThreshold) : null;
    }
    account.currency = currency;
    account.name = name.trim();
    account.building = building.trim();
    account.floor = floor.trim();
    account.room = room.trim();
    account.buildingId = buildingId;
    account.floorId = floorId;
    account.roomId = roomId;

    if (account.name === "") {
      setIsNameValid(false);
      return;
    }

    if (!isLocationValid()) {
      return;
    }

    const updated = await api.updateAccountSettings(account);
    setAccountDetails(updated);

    await authState.refreshUser();

    setShowUpdate(true);
  };

  function getCleanAmount(amount: string | null | number): string {
    const cleanAmount = amount ? Math.max(1, Number.parseFloat(`${amount}`)) : 1;
    return cleanAmount.toFixed(2);
  }

  function isLocationValid(): boolean {
    if (locations.buildings.length === 0) {
      return true;
    }

    const selectedBuilding = locations.buildings.find((b) => b.id === buildingId);
    const selectedFloor = locations.floors.find((f) => f.id === floorId);
    const selectedRoom = locations.rooms.find((r) => r.id === roomId);
    return selectedBuilding !== undefined && selectedRoom !== undefined && (selectedFloor !== undefined || selectedRoom.floorId === undefined);
  }

  function setAccountDetails(acct: Account): void {
    setAccount(acct);
    setAutoApprovalThreshold(getCleanAmount(acct.autoApprovalThreshold));
    setIsAutoApproveEnabled(acct.autoApprovalThreshold !== null && acct.autoApprovalThreshold !== 0);
    setCurrency(acct.currency);
    setName(acct.name);
    setBuilding(acct.building || "");
    setFloor(acct.floor || "");
    setRoom(acct.room || "");
    setBuildingId(acct.buildingId);
    setFloorId(acct.floorId);
    setRoomId(acct.roomId);
  }

  async function fetchAccountDetails(): Promise<void> {
    setIsLoading(true);

    const user = authState.getUser();

    if (user && user.isSite) {
      try {
        const l = await api.getSiteLocations();
        setLocations(l);
        // eslint-disable-next-line no-empty
      } catch {}
    }

    const settings = await api.getAccountSettings();
    setAccountDetails(settings);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setShowUpdate(false);
      window.clearTimeout(timeout);
    }, 5000);

    return () => window.clearTimeout(timeout);
  }, [showUpdate]);

  return {
    isLoading,
    autoApprovalThreshold,
    isAutoApproveEnabled,
    showUpdate,
    currency,
    name,
    building,
    floor,
    room,
    onAutoApprovalChanged,
    onAutoApprovalThresholdChanged,
    onSettingsSaved,
    onHideUpdate: () => setShowUpdate(false),
    onCurrencyChanged,
    onNameChanged,
    onBuildingChanged,
    onFloorChanged,
    onRoomChanged,
    isNameValid,
    buildingId,
    floorId,
    roomId,
    buildingOptions: getSiteLocationBuildingViews(locations),
    floorOptions: getSiteLocationFloorViews(locations, buildingId),
    roomOptions: getSiteLocationRoomViews(locations, buildingId, floorId),
    onBuildingIdChanged,
    onFloorIdChanged,
    onRoomIdChanged,
    isLocationValid: isLocationValid(),
  };
}
