import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { InventorySearchTypeFilter } from "../../../inventory/search/filter/InventorySearchTypeFilter";
import { SearchDates } from "../../../components/SearchDates/SearchDates";
import { OrderSearchStatusFilter } from "./OrderSearchStatusFilter";
import "./OrderSearchFilter.scss";
import { OrderSearchFilterProps, useOrderSearchFilter } from "./OrderSearchFilterHooks";

export function OrderSearchFilter(props: OrderSearchFilterProps): ReactElement {
  const { onFilterCleared, onChangedTypes, onChangedStatuses, onChangedOrderDates } = useOrderSearchFilter(props);

  return (
    <div className={classnames("order-search-filter responsive-search-filter", { "filter-shown": props.isShowingFilter })}>
      <div className="order-search-filter-head">
        <span className="order-search-filter-head-title">
          <FormattedMessage id="filters" />
        </span>
        <Button onClick={() => onFilterCleared()} className="order-search-filter-head-clear" size="sm" color="link">
          <FormattedMessage id="clear.filters" />
        </Button>
        <Button color="link" className="responsive-search-filter-head-close" onClick={props.onFilterToggle}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Button>
      </div>
      <fieldset className="order-filter-criteria" id="order-filter-criteria-type">
        <legend className="order-filter-criteria-title">
          <FormattedMessage id="types" />
        </legend>
        <InventorySearchTypeFilter
          inventoryTypeViews={props.inventoryTypeViews}
          selectedTypeIds={props.filters.typeIds}
          onTypesChanged={onChangedTypes}
        />
      </fieldset>
      <fieldset className="order-filter-criteria" id="order-filter-criteria-status">
        <legend className="order-filter-criteria-title">
          <FormattedMessage id="order.status" />
        </legend>
        <OrderSearchStatusFilter selectedStatusIds={props.filters.statusIds} onChangedStatuses={onChangedStatuses} />
      </fieldset>
      <fieldset className="order-filter-criteria" id="order-filter-order-dates">
        <legend className="order-filter-criteria-title">
          <FormattedMessage id="order.date.range" />
        </legend>
        <SearchDates selectedStart={props.filters.orderStartDate} selectedEnd={props.filters.orderStartDate} onDatesChanged={onChangedOrderDates} />
      </fieldset>
    </div>
  );
}
