import React, { ReactElement } from "react";
import { Router } from "react-router";
import { LastLocationProvider } from "react-router-last-location";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import { history } from "./history";
import { AppProvider } from "./AppProvider";
import { InventoryApplicationHarness } from "./InventoryApplicationHarness";

export function AuthenticatedApp(): ReactElement {
  return (
    <Router history={history}>
      <LastLocationProvider>
        <ErrorBoundary>
          <AppProvider>
            <InventoryApplicationHarness />
          </AppProvider>
        </ErrorBoundary>
      </LastLocationProvider>
    </Router>
  );
}
