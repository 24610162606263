import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Col, Row } from "reactstrap";
import classnames from "classnames";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { onPageLoad } from "../../utils/onPageLoad";
import { AdminViewManageLocations } from "./AdminViewManageLocations";

export function AdminViewManagement(props: RouteComponentProps): ReactElement {
  const intl = useIntl();

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.management" }));
  }, []);

  return (
    <Row className="management-container">
      <Col lg={2} xs={12} className="management-container-list">
        <nav className="management-nav">
          <ol>
            <li
              key={""}
              className={classnames({
                "management-container-list-item": true,
                active: props.location && props.location.pathname.includes(ApplicationPaths.Admin.Routes.Management.Locations),
              })}
            >
              <Link to={ApplicationPaths.Admin.Routes.Management.Locations}>
                <FormattedMessage id={"admin.manage.locations"} />
              </Link>
            </li>
          </ol>
        </nav>
      </Col>
      <Col lg={10} className="management-container-contents">
        {props.location && props.location.pathname === ApplicationPaths.Admin.Routes.Management.Home && (
          <Redirect exact from={ApplicationPaths.Admin.Routes.Management.Home} to={ApplicationPaths.Admin.Routes.Management.Locations} />
        )}
        <Switch>
          <Route path={ApplicationPaths.Admin.Routes.Management.Locations} component={AdminViewManageLocations} />
        </Switch>
      </Col>
    </Row>
  );
}
