import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { Button } from "@labarchives/ui-design/dist";
import "./GettingStarted.scss";
import { Link } from "react-router-dom";
import { ApplicationPaths } from "../app/ApplicationPaths";

export interface GeneralGettingStartedProps {
  isLabManager: boolean;

  startTour(): void;
}

export function GeneralGettingStarted(props: GeneralGettingStartedProps): ReactElement {
  const intl = useIntl();
  if (props.isLabManager) {
    return (
      <div className="getting-started-container getting-started-general">
        <div className="getting-started-icons">
          <div className="getting-started-icon">
            <span>Find</span>
            <img src="/images/tour/Inventory_Find.svg" alt="Find" />
            <div className="getting-started-icon-action">
              <Link to={ApplicationPaths.Inventory.Search} aria-label={intl.formatMessage({ id: "search.inventory" })}>
                Search & Browse
              </Link>{" "}
              Inventory.
              <br />
              Use QR codes to find item details.
            </div>
          </div>
          <div className="getting-started-icon">
            <span>Organize</span>
            <img src="/images/tour/Inventory_Organize.svg" alt="Organize" />
            <div className="getting-started-icon-action">
              Review{" "}
              <Link to={ApplicationPaths.Orders.Search} aria-label={intl.formatMessage({ id: "search.orders" })}>
                order
              </Link>{" "}
              requests and view status in real time from anywhere.
            </div>
          </div>
          <div className="getting-started-icon">
            <span>Track</span>
            <img src="/images/tour/Inventory_Track.png" alt="Track" />
            <div className="getting-started-icon-action">
              Track item{" "}
              <Link to={ApplicationPaths.Inventory.Search} aria-label={intl.formatMessage({ id: "search.inventory" })}>
                details
              </Link>
              , usage history, and connect to your ELN.
            </div>
          </div>
        </div>
        <div className="getting-started-general-footer">
          <div>&nbsp;</div>
          <div className="text-center">
            <a
              href="https://www.labarchives.com/labarchives-knowledge-base/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={intl.formatMessage({ id: "open.knowledge.base" })}
            >
              {intl.formatMessage({ id: "learn.more" })}
            </a>
          </div>
          <div className="text-right">
            <Button onClick={props.startTour} color="link" aria-label={intl.formatMessage({ id: "open.getting.started.tour" })}>
              Take a Tour
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="getting-started-container getting-started-general">
      <div className="getting-started-icons">
        <div className="getting-started-icon">
          <span>Find</span>
          <img src="/images/tour/Inventory_Find.svg" alt="Find" />
          <div className="getting-started-icon-action">
            <Link
              to={ApplicationPaths.Inventory.Search}
              aria-label={intl.formatMessage({ id: "search.inventory" })}
              style={{ textDecoration: "underline" }}
            >
              Search & Browse
            </Link>{" "}
            Inventory.
            <br />
            Use QR codes to find item details.
          </div>
        </div>
        <div className="getting-started-icon">
          <span>Track</span>
          <img src="/images/tour/Inventory_Track.png" alt="Track" />
          <div className="getting-started-icon-action">
            Track item{" "}
            <Link
              to={ApplicationPaths.Inventory.Search}
              aria-label={intl.formatMessage({ id: "search.inventory" })}
              style={{ textDecoration: "underline" }}
            >
              details
            </Link>
            , usage history,{" "}
            <Link
              to={ApplicationPaths.Orders.Search}
              aria-label={intl.formatMessage({ id: "search.orders" })}
              style={{ textDecoration: "underline" }}
            >
              orders
            </Link>
            , and connect to your ELN.
          </div>
        </div>
        <div className="getting-started-icon">
          <span>Request</span>
          <img src="/images/tour/Inventory_Request.svg" alt="Request" />
          <div className="getting-started-icon-action">
            Submit an{" "}
            <Link
              to={ApplicationPaths.Orders.Search}
              aria-label={intl.formatMessage({ id: "search.orders" })}
              style={{ textDecoration: "underline" }}
            >
              order
            </Link>{" "}
            request and view status.
          </div>
        </div>
      </div>
      <div className="getting-started-general-footer">
        <div>&nbsp;</div>
        <div className="text-center">
          <a
            href="https://www.labarchives.com/labarchives-knowledge-base/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={intl.formatMessage({ id: "open.knowledge.base" })}
          >
            {intl.formatMessage({ id: "learn.more" })}
          </a>
        </div>
        <div className="text-right">
          <Button onClick={props.startTour} color="link" aria-label={intl.formatMessage({ id: "open.getting.started.tour" })}>
            Take a Tour
          </Button>
        </div>
      </div>
    </div>
  );
}
