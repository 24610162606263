import { Col, Pagination, PaginationItem, PaginationLink, Row } from "reactstrap";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

export interface ResultSetPaginationProps {
  totalResultCount: number;
  totalPageCount: number;
  currentPageNumber: number;
  pageSize: number;

  onPageChanged(pageNumber: number): void;
}

export function ResultSetPagination(props: ResultSetPaginationProps): ReactElement {
  const prevPage = Math.max(1, props.currentPageNumber - 1);
  const nextPage = Math.min(props.totalPageCount, props.currentPageNumber + 1);
  const pageNeighbors = 2;
  const jumpLeft = Math.floor((props.currentPageNumber - pageNeighbors - 1) / 2);
  const jumpRight = Math.ceil((props.currentPageNumber + pageNeighbors + props.totalPageCount + 1) / 2);

  const LEFT_PAGE = "LEFT";
  const RIGHT_PAGE = "RIGHT";

  const range = (from: number, to: number, step = 1): Array<string | number> => {
    let i = from;
    const numbers: number[] = [];

    while (i <= to) {
      numbers.push(i);
      i += step;
    }

    return numbers;
  };

  const fetchPageNumbers = (): Array<string | number> => {
    const totalPages = props.totalPageCount;
    const currentPage = props.currentPageNumber;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbors * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  return (
    <Row>
      <Col>
        <Pagination aria-label="Page Navigation">
          <PaginationItem>
            <PaginationLink previous onClick={() => props.onPageChanged(prevPage)} aria-label="Previous Page" />
          </PaginationItem>
          {fetchPageNumbers().map((pageNumber) => {
            const active = pageNumber === props.currentPageNumber;
            if (pageNumber === LEFT_PAGE)
              return (
                <PaginationItem key={`PaginationItem${pageNumber}`}>
                  <PaginationLink onClick={() => props.onPageChanged(jumpLeft)} aria-label={`Page ${jumpLeft}`}>
                    ...
                  </PaginationLink>
                </PaginationItem>
              );

            if (pageNumber === RIGHT_PAGE)
              return (
                <PaginationItem key={`PaginationItem${pageNumber}`}>
                  <PaginationLink onClick={() => props.onPageChanged(jumpRight)} aria-label={`Page ${jumpRight}`}>
                    ...
                  </PaginationLink>
                </PaginationItem>
              );

            return (
              <PaginationItem key={`PaginationItem${pageNumber}`} active={active}>
                <PaginationLink
                  key={`PaginationItem${pageNumber}`}
                  onClick={() => props.onPageChanged(Number.parseInt(pageNumber.toString()))}
                  aria-label={`Page ${pageNumber}`}
                >
                  {pageNumber}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          <PaginationItem>
            <PaginationLink next onClick={() => props.onPageChanged(nextPage)} aria-label="Next Page" />
          </PaginationItem>
        </Pagination>
      </Col>
      <Col className="col-md-auto">
        <span>
          <FormattedMessage id="result.count" values={{ resultCount: props.totalResultCount }} />
        </span>
      </Col>
    </Row>
  );
}
