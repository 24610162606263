import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { Settings } from "../../utils/Settings";
import { WarningRestrictedDropdownItem } from "./WarningRestrictedDropdownItem";

interface NavigationHelpMenuProps {
  onWarningModalShown(href: string, target: string): void;
}
export function NavigationHelpMenu(props: NavigationHelpMenuProps): ReactElement {
  const intl = useIntl();
  const { onWarningModalShown } = props;

  return (
    <UncontrolledDropdown className={"nav-item"}>
      <DropdownToggle caret className={"nav-link"}>
        <FontAwesomeIcon icon={faInfoCircle} className="me-1" title={intl.formatMessage({ id: "help" })} />
        <span className="d-inline d-sm-none">{intl.formatMessage({ id: "help" })}</span>
      </DropdownToggle>
      <DropdownMenu end>
        <WarningRestrictedDropdownItem href={Settings.getHelpUrl()} target={"support"} textKey={"help"} onWarningModalShown={onWarningModalShown} />

        <WarningRestrictedDropdownItem
          href={Settings.getKBUrl()}
          target={"knowledge"}
          textKey={"knowledge.base"}
          onWarningModalShown={onWarningModalShown}
        />

        {Settings.getAllowVideoAndContact() && (
          <>
            <WarningRestrictedDropdownItem
              href={"https://help.labarchives.com/hc/en-us/requests/new"}
              target={"supportcontact"}
              textKey={"ask.a.question"}
              onWarningModalShown={onWarningModalShown}
            />

            <WarningRestrictedDropdownItem
              href={"https://www.labarchives.com/resources"}
              target={"youtube"}
              textKey={"video.tutorial"}
              onWarningModalShown={onWarningModalShown}
            />

            <WarningRestrictedDropdownItem
              href={"https://www.labarchives.com/webinars"}
              target={"webinars"}
              textKey={"webinars"}
              onWarningModalShown={onWarningModalShown}
            />

            <WarningRestrictedDropdownItem
              href={"https://help.labarchives.com/hc/en-us/sections/11786057250452"}
              target={"tutorials"}
              textKey={"quick.start.guide"}
              onWarningModalShown={onWarningModalShown}
            />
          </>
        )}
        <WarningRestrictedDropdownItem
          href={Settings.getTOSUrl()}
          target={"terms"}
          textKey={"terms.of.use"}
          onWarningModalShown={onWarningModalShown}
        />

        <WarningRestrictedDropdownItem
          href={Settings.getPrivacyPolicyUrl()}
          target={"privacy"}
          textKey={"privacy.policy"}
          onWarningModalShown={onWarningModalShown}
        />
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
