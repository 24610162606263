import React, { ReactElement } from "react";

export function NotFound(): ReactElement {
  return (
    <div id="error">
      <h2 className="notFoundTitle">Oops! That page can’t be found.</h2>
      <p className="notFoundDesc">It looks like nothing was found at this location. Please try one of the links in the menu to navigate the site.</p>
    </div>
  );
}
