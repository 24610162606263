import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { StorageLocationTreeView } from "../types/views";

import "./DeleteStorageLocationPrompt.scss";
import { CancelButton } from "../../components/CancelButton/CancelButton";

interface DeleteStorageLocationPromptProps {
  isOpen: boolean;
  location?: StorageLocationTreeView;
  onDelete(storageLocation: StorageLocation): void;
  onCancel(): void;
}

export function DeleteStorageLocationPrompt(props: DeleteStorageLocationPromptProps): ReactElement {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel} id={"delete-storage-location-modal"}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id="delete.storage.location" />
      </ModalHeader>
      <ModalBody>
        <FormattedHTMLMessage id="delete.location.warning" values={{ location: props.location ? props.location.location.name : "" }} />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => (props.location ? props.onDelete(props.location.location) : {})} id={"delete-storage-location-button"}>
          <FormattedMessage id="delete" />
        </Button>
        <CancelButton className="delete-storage-cancel-button" onClick={props.onCancel} />
      </ModalFooter>
    </Modal>
  );
}
