import { Button } from "@labarchives/ui-design";
import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InventoryApi } from "../api/InventoryApi";
import { FreezerCapacityReportDownloadHooks } from "./FreezerCapacityReportDownloadHooks";
import { useFreezerCapacityReport } from "./FreezerCapacityReportHooks";

export function FreezerCapacityReport(props: { downloadHooks: FreezerCapacityReportDownloadHooks; api: InventoryApi }): ReactElement {
  const { onReportDownloaded } = useFreezerCapacityReport({
    downloadHooks: props.downloadHooks,
    api: props.api,
  });

  const intl = useIntl();

  return (
    <div className={"mt-5"}>
      <h2 className={"reports-header"}>
        <FormattedMessage id={"freezer.capacity.report.nav"} />
      </h2>

      <div className={"mt-3 mb-3"}>
        <FormattedMessage id={"freezer.capacity.report.description"} />
      </div>

      <div>
        <Button outline={true} onClick={() => onReportDownloaded()}>
          <img src="/images/Inventory_Export.svg" className="link button-icon" alt={intl.formatMessage({ id: "export.items" })} />
          <FormattedMessage id={"download.freezer.capacity.report"} />
        </Button>
      </div>
    </div>
  );
}
