import { UserStatus } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { UserRoleView, UserView } from "../types/views";
import { RoleState } from "../../roles/types/state";
import { UserState } from "../types/state";
import { getUserRoleViews, getUserViews } from "../selectors";
import { isValidationError } from "../../utils/errorHelpers";

export interface ManageUsersHooks {
  userViews: UserView[];
  userRoleViews: UserRoleView[];
  isLoading: boolean;
  isInviting: boolean;
  isInvitationFailed: boolean;
  activeStatus?: UserStatus;
  isSuccessAlertOpen: boolean;

  onUserDeleted(userId: number): void;

  onUserRoleUpdated(userId: number, roleId: number): void;

  onUsersInvited(emails: string[]): void;

  onUserInviteCancelled(): void;

  setIsInviting(isInviting: boolean): void;

  onFilterUsers(status: UserStatus | undefined): void;
}

export function useManageUsers(roleState: RoleState, userState: UserState): ManageUsersHooks {
  const [isInviting, setIsInviting] = useState(false);
  const [activeStatus, setActiveStatus] = useState<UserStatus | undefined>(UserStatus.Active);
  const [isInvitationFailed, setIsInvitationFailed] = useState(false);

  const onUserDeleted = async (userId: number): Promise<void> => {
    await userState.onUserDeleted(userId);
  };

  const onUserRoleUpdated = async (userId: number, roleId: number): Promise<void> => {
    await userState.onUserRoleUpdated(userId, roleId);
  };

  const onUsersInvited = async (emails: string[]): Promise<void> => {
    if (emails.length > 0) {
      setIsInviting(false);
      try {
        await userState.onUsersInvited(emails);
      } catch (error) {
        if (isValidationError(error)) {
          setIsInvitationFailed(true);
        } else {
          throw error;
        }
      }
    }
  };

  const onUserInviteCancelled = (): void => {
    setIsInviting(false);
  };

  const onFilterUsers = (status: UserStatus | undefined): void => {
    setActiveStatus(status);
    setIsInviting(false);
  };

  const showInviting = (): void => {
    setIsInviting(true);
    setIsInvitationFailed(false);
  };

  return {
    userViews: getUserViews(userState, roleState),
    userRoleViews: getUserRoleViews(roleState),
    isLoading: userState.isLoading || roleState.isLoading,
    onUserDeleted,
    onUserRoleUpdated,
    onUsersInvited,
    isInviting,
    isInvitationFailed,
    activeStatus,
    setIsInviting: showInviting,
    onUserInviteCancelled,
    onFilterUsers,
    isSuccessAlertOpen: userState.hasUpdated,
  };
}
