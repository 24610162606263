export enum InventoryStandardField {
  Name = "name",
  Quantity = "quantity",
  Location = "location",
  Description = "description",
  Vendor = "vendor",
  CatalogNumber = "catalogNumber",
  LotNumber = "lotNumber",
  Price = "price",
  DateReceived = "dateReceived",
  GrantNumber = "grantNumber",
  PONumber = "ponumber",
  Expiration = "expiration",
  SafetySheet = "safetySheet",
  Notes = "notes",
}
