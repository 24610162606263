import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design/dist";

import { InventoryTypeView } from "../../inventory/types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";

interface DuplicateTypePromptProps {
  isOpen: boolean;
  type?: InventoryTypeView;
  isError: boolean;
  onDuplicateType(type: InventoryTypeView): void;
  onCancel(): void;
}

export const DuplicateTypePrompt = (props: DuplicateTypePromptProps): ReactElement => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id="duplicate.inventory.type" />
      </ModalHeader>
      <ModalBody>
        {!props.isError && <FormattedHTMLMessage id="duplicate.inventory.type.warning" values={{ name: props.type ? props.type.name : "" }} />}
        {props.isError && <FormattedHTMLMessage id="duplicate.inventory.type.error" values={{ name: props.type ? props.type.name : "" }} />}
      </ModalBody>
      <ModalFooter>
        {!props.isError && (
          <Button color="primary" onClick={() => (props.type ? props.onDuplicateType(props.type) : {})}>
            <FormattedMessage id="duplicate" />
          </Button>
        )}
        <CancelButton onClick={props.onCancel} />
      </ModalFooter>
    </Modal>
  );
};
