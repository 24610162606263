import React, { ReactElement } from "react";
import { faAsterisk } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface RequiredLabelIndicatorProps {
  required: boolean;
}

export function RequiredLabelIndicator(props: RequiredLabelIndicatorProps): ReactElement {
  if (!props.required) {
    return <></>;
  }

  return (
    <span className="required-field-indicator">
      <FontAwesomeIcon icon={faAsterisk} className="ms-1 required-field-indicator" size="xs" />
      {" (required)"}
    </span>
  );
}
