import React, { ReactElement, useEffect, useState } from "react";
import { InventoryApiClient } from "../api";

export function HealthComponent(): ReactElement {
  const [apiHealth, setApiHealth] = useState<{ status: string }>({ status: "" });
  const api = new InventoryApiClient();

  useEffect(() => {
    api
      .healthCheck()
      .then((h) => setApiHealth(h))
      .catch((error) => {
        console.error(error);
        setApiHealth({ status: "Error" });
      });
  }, []);

  return apiHealth.status === "" ? (
    <div />
  ) : (
    <div id="health-status">{JSON.stringify({ version: process.env.REACT_APP_VERSION, status: apiHealth.status })}</div>
  );
}
