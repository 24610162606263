import React, { ReactElement, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { EditableQuantityProps, useEditableQuantity } from "./EditableQuantityHooks";

export function EditableQuantity(props: EditableQuantityProps): ReactElement {
  const nodeSelf = useRef<HTMLDivElement>(null);

  const { isEditingQuantity, onCancelChangeAmount, onSaveChangedAmount, onEditQuantity } = useEditableQuantity(props);

  const handleAllClicks = (e: Event): void => {
    if (nodeSelf.current && nodeSelf.current.contains(e.target as Node)) {
      return;
    }

    onCancelChangeAmount();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleAllClicks);
    return () => window.removeEventListener("mouseup", handleAllClicks);
  }, []);

  const { current, units, onQuantityChanged } = props;

  let quantity = (
    <Button color="link" onClick={() => onEditQuantity()} id="editable-quantity-change-quantity-button">
      {current.quantityAvailable} {current.unitOfMeasure}
    </Button>
  );

  if (!onQuantityChanged) {
    return (
      <span>
        {current.quantityAvailable} {current.unitOfMeasure}
      </span>
    );
  }

  if (isEditingQuantity) {
    let amountInput: HTMLInputElement;
    let uomInput: HTMLInputElement;

    quantity = (
      <>
        <FormGroup>
          <Label for="quantity-available" hidden>
            <FormattedMessage id="units" />
          </Label>
          <Input
            id="quantity-available"
            defaultValue={`${current.quantityAvailable}`}
            innerRef={(node: HTMLInputElement) => {
              amountInput = node;
            }}
            autoFocus
            type="number"
            bsSize="sm"
            step="any"
            className="quantity"
          />
        </FormGroup>
        <FormGroup>
          <Label for="quantity-unit-of-measure" hidden>
            <FormattedMessage id="unit.of.measure" />
          </Label>
          <Input
            id="quantity-unit-of-measure"
            type="select"
            defaultValue={current.unitOfMeasure}
            innerRef={(node: HTMLInputElement) => {
              uomInput = node;
            }}
            bsSize="sm"
          >
            {units.map((u) => (
              <option key={`unit${u}`}>{u}</option>
            ))}
          </Input>
        </FormGroup>
        <div className="update-icons">
          <Button
            size="sm"
            className="ms-1"
            onClick={() => onSaveChangedAmount(amountInput.value, uomInput.value)}
            color="primary"
            id="editable-quantity-save-quantity-button"
          >
            <FormattedMessage id="save" />
          </Button>
          <CancelButton onClick={() => onCancelChangeAmount()} size="sm" className="ms-1" />
        </div>
      </>
    );
  }

  return (
    <div className="display-flex" ref={nodeSelf}>
      {quantity}
    </div>
  );
}
