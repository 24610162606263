import React, { ReactElement } from "react";
import { Loading } from "../../components";
import { InventoryApi } from "../../api/InventoryApi";
import { BundleCard } from "./BundleCard";
import { useBundleRenewal } from "./BundeRenewalHooks";

interface BundleRenewCardProps {
  numberOfUsers: number;
  labArchivesSubscriptionId: string;
  api: InventoryApi;

  onNumberOfUsersChanged(users: string): void;
}

export function BundleRenewCard(props: BundleRenewCardProps): ReactElement {
  const { isLoading, isBundleAllowed, numberOfUsers, bundleMinimumUsers, currentBundlePricing, perYearTotal, onBundleCheckout } = useBundleRenewal({
    numberOfUsers: props.numberOfUsers,
    subscriptionId: props.labArchivesSubscriptionId,
    api: props.api,
  });

  if (!isBundleAllowed) {
    return <></>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BundleCard
      numberOfUsers={numberOfUsers}
      callToActionTextId={"renew.subscription"}
      minimumNumberOfUsers={bundleMinimumUsers}
      onNumberOfUsersChanged={props.onNumberOfUsersChanged}
      perUserPrice={currentBundlePricing}
      totalPrice={perYearTotal}
      onCheckout={onBundleCheckout}
    />
  );
}
