import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useContext } from "react";
import { AlertWithIcon, Button, Input } from "@labarchives/ui-design/dist";
import { OrganizationBillingType, User } from "@labarchives/inventory-shared/build/inventory";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Loading } from "../components";
import { UsersContext } from "../user/UsersContext";
import { Settings } from "../utils/Settings";
import { InventoryApiClient } from "../api";
import { useSubscriptionManagement } from "./SubscriptionManagementHooks";
import "./FreeTrialSubscriptionManagement.scss";
import "./SubscriptionManagement.scss";
import { BundlePurchaseCard } from "./bundle/BundlePurchaseCard";
import { InventoryPurchaseCard } from "./inventory-standalone/InventoryPurchaseCard";

export interface FreeTrialSubscriptionManagementProps {
  user: User;
}

export const FreeTrialSubscriptionManagement = (props: FreeTrialSubscriptionManagementProps): ReactElement => {
  const userState = useContext(UsersContext);
  const intl = useIntl();
  const api = new InventoryApiClient();

  const {
    isLoading,
    subscriptionSummary,
    numberOfAccountUsers,
    numberOfUsers,
    checkoutErrorMessage,
    billingType,
    currentPricing,
    calculateTotal,
    onCheckout,
    onOrganizationBillingTypeChange,
    onNumberOfUsersChanged,
  } = useSubscriptionManagement(props.user, userState, new InventoryApiClient());

  const isBundleEnabled = Settings.getBundleProductCode() !== "";

  if (isLoading) {
    return <Loading />;
  }

  if (!subscriptionSummary.isFreeTrial) {
    return (
      <div>
        <h1>
          <FormattedMessage id={"subscribe.to.labarchives.inventory"} />
        </h1>
        <FormattedMessage id={"your.free.trial.has.ended"} /> <br />
        <Button onClick={() => window.location.reload()}>
          <FormattedMessage id={"return.to.account.summary"} />
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col xs={12} xl={{ size: 6, offset: 3 }}>
          <h1>
            <FormattedMessage id={"subscribe.to.labarchives.inventory"} />
          </h1>
          <AlertWithIcon color="danger" isOpen={checkoutErrorMessage !== ""}>
            {checkoutErrorMessage}
          </AlertWithIcon>
          {subscriptionSummary.isExpired && <FormattedMessage id="your.free.trial.expired.on" />}
          {!subscriptionSummary.isExpired && <FormattedMessage id="free.trial.is.ending.on" />}{" "}
          <span className="free-trial-subscription-date">
            <FormattedDate value={subscriptionSummary.endDate} />
          </span>
          <div>
            <FormattedMessage id="free.trial.ending.marketing" />
          </div>
        </Col>
      </Row>
      <Row className={"pb-3"}>
        <Col xs={12} xl={{ size: 6, offset: 3 }}>
          <div className="free-trial-organization-type">
            <FormGroup>
              <Label for="organization-type-academic" className="visually-hidden">
                Type of Organization
              </Label>
              <FormGroup check inline={true}>
                <Input
                  type="radio"
                  name="organization-type"
                  id="organization-type-academic"
                  checked={billingType === OrganizationBillingType.Academic}
                  inline={"true"}
                  onChange={() => {
                    onOrganizationBillingTypeChange(OrganizationBillingType.Academic);
                  }}
                  label={intl.formatMessage({ id: "academic.non.profit" })}
                />
                <Label htmlFor={"organization-type-academic"}>
                  <FormattedMessage id={"academic.non.profit"} />
                </Label>
              </FormGroup>
              <FormGroup check inline={true}>
                <Input
                  type="radio"
                  name="organization-type"
                  id="organization-type-commercial"
                  inline={"true"}
                  checked={billingType === OrganizationBillingType.Commercial}
                  onChange={() => {
                    onOrganizationBillingTypeChange(OrganizationBillingType.Commercial);
                  }}
                  label={intl.formatMessage({ id: "commercial.fon.profit" })}
                />
                <Label htmlFor={"organization-type-commercial"}>
                  <FormattedMessage id={"commercial.fon.profit"} />
                </Label>
              </FormGroup>
            </FormGroup>
          </div>
          <div className="subscription-pricing-box d-flex flex-sm-wrap justify-content-center">
            <InventoryPurchaseCard
              numberOfUsers={numberOfUsers}
              minimumNumberOfUsers={numberOfAccountUsers}
              perUserPrice={currentPricing.oneYearCostPerUser}
              totalPrice={calculateTotal(numberOfUsers)}
              callToActionTextId={"subscribe.now"}
              onCheckout={() => onCheckout(numberOfUsers)}
              onNumberOfUsersChanged={onNumberOfUsersChanged}
            />

            {isBundleEnabled && (
              <BundlePurchaseCard
                className={"ms-md-3"}
                billingType={billingType}
                numberOfUsers={numberOfUsers}
                onNumberOfUsersChanged={onNumberOfUsersChanged}
                api={api}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
