/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Link, Redirect, Route, RouteComponentProps, RouteProps, Switch } from "react-router-dom";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { ManageUsers } from "../user/management/ManageUsers";
import { ManageInventoryTypes } from "../inventorytypes/management/ManageInventoryTypes";
import { ManageVendors } from "../vendors/management/ManageVendors";
import { ManageRoles } from "../roles/management/ManageRoles";
import { ManageStorageComponent } from "../storage/management/ManageStorageComponent";
import { AuthorizedComponent } from "../components/Authentication/AuthorizedComponent";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { onPageLoad } from "../utils/onPageLoad";
import { DisplayComponent } from "./DisplaySettings/DisplayComponent";
import { BulkInventoryUpdateComponent } from "./BulkInventoryUpdate/BulkInventoryUpdateComponent";
import { BulkInventoryImportComponent } from "./BulkInventoryImport/BulkInventoryImportComponent";
import { Settings } from "./LabSettings/Settings";
import "./Management.scss";

interface ManagementLink {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path: string;
  messageid: string;
  linkPath: string;
}

export function Management(props: RouteProps): ReactElement {
  const intl = useIntl();
  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.management" }));
  }, []);

  const links: ManagementLink[] = [
    {
      component: ManageInventoryTypes,
      path: ApplicationPaths.Management.Routes.TypeById,
      linkPath: ApplicationPaths.Management.Types,
      messageid: "types",
    },
    {
      component: ManageStorageComponent,
      path: ApplicationPaths.Management.Routes.StorageById,
      linkPath: ApplicationPaths.Management.Storage,
      messageid: "storage",
    },
    {
      component: ManageUsers,
      path: ApplicationPaths.Management.Routes.Users,
      linkPath: ApplicationPaths.Management.Users,
      messageid: "users",
    },
    {
      component: ManageRoles,
      path: ApplicationPaths.Management.Routes.Roles,
      linkPath: ApplicationPaths.Management.Roles,
      messageid: "roles",
    },
    {
      component: ManageVendors,
      path: ApplicationPaths.Management.Routes.VendorsById,
      linkPath: ApplicationPaths.Management.Vendors,
      messageid: "vendors",
    },
    {
      component: Settings,
      path: ApplicationPaths.Management.Routes.Settings,
      linkPath: ApplicationPaths.Management.Settings,
      messageid: "settings",
    },
    {
      component: DisplayComponent,
      path: ApplicationPaths.Management.Routes.Display,
      linkPath: ApplicationPaths.Management.Display,
      messageid: "display.settings",
    },
    {
      component: BulkInventoryImportComponent,
      path: ApplicationPaths.Management.Routes.BulkInventoryImport,
      linkPath: ApplicationPaths.Management.BulkInventoryImport,
      messageid: "import.items",
    },
    {
      component: BulkInventoryUpdateComponent,
      path: ApplicationPaths.Management.Routes.BulkInventoryUpdate,
      linkPath: ApplicationPaths.Management.BulkInventoryUpdate,
      messageid: "bulk.inventory.update",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function isFeatureEnabled(link: ManagementLink): boolean {
    return true;
  }

  return (
    <AuthorizedComponent requiredPermissions={[RolePermissions.LabManagementAllowed]}>
      <Row className="management-container">
        <Col lg={2} xs={12} className="management-container-list">
          <nav className="management-nav" role={"navigation"} aria-label={intl.formatMessage({ id: "management.tools.navigation" })}>
            <ol>
              {links
                .filter((l) => isFeatureEnabled(l))
                .map((link) => (
                  <li
                    key={link.path}
                    className={classnames({
                      "management-container-list-item": true,
                      active: props.location && props.location.pathname.includes(link.linkPath),
                    })}
                  >
                    <Link to={link.linkPath}>
                      <FormattedMessage id={link.messageid} />
                    </Link>
                  </li>
                ))}
            </ol>
          </nav>
        </Col>
        <Col lg={10} className="management-container-contents">
          {props.location && props.location.pathname === ApplicationPaths.Management.Home && (
            <Redirect exact from={ApplicationPaths.Management.Home} to={ApplicationPaths.Management.Types} />
          )}
          <Switch>
            <Route path={ApplicationPaths.Management.Routes.RoleById} component={ManageRoles} />
            {links.map((link) => (
              <Route key={link.path} path={link.path} render={(linkProps) => <link.component {...linkProps} />} />
            ))}
          </Switch>
        </Col>
      </Row>
    </AuthorizedComponent>
  );
}
