import {
  Order,
  OrderPlacedRequest,
  OrderReceivedRequest,
  OrderSearchCriteria,
  OrderSearchResultSet,
  OrderSearchResultSetItem,
  OrderStatus,
  ResultSetOptions,
  RolePermissions,
  SortDirection,
} from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useMemo, useRef, useState } from "react";
import { getUnits } from "@labarchives/inventory-shared/build/util/units";
import { OrderFilterView } from "../types/views";
import { UserState } from "../../user/types/state";
import { AuthenticationState } from "../../components/Authentication/AuthenticationState";
import * as utils from "../../utils";
import { getOrderFilterView } from "../selectors";
import { InventoryTypesState } from "../../inventorytypes/types/state";
import { StorageState } from "../../storage/types/state";
import { getStorageLocationView } from "../../storage/selectors";
import { getInventoryTypeViews, isInventoryStorageLocationRequired } from "../../inventorytypes/selectors";
import { StorageLocationView } from "../../storage/types/views";
import { InventoryTypeView } from "../../inventory/types/views";
import { OrderActionModals } from "../modals/OrderActionModals";
import { history } from "../../app/history";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { InventoryApi } from "../../api/InventoryApi";

export interface OrderSearchHooks {
  filterView: OrderFilterView;
  isLoading: boolean;
  isProcessingOrder: boolean;
  actionErrorMessage: string;
  searchResults: OrderSearchResultSet;
  openOrderRequest: OrderSearchResultSetItem | undefined;
  units: string[];
  searchCriteria: OrderSearchCriteria;
  storageLocations: StorageLocationView;
  inventoryTypeViews: InventoryTypeView[];
  searchTerm: string;
  isBulkActionEnabled: boolean;
  bulkActionStatus: OrderStatus | undefined;
  selectedBulkUpdateOrderIds: number[];
  isBulkSelectAllChecked: boolean;
  isBulkUpdateModalOpen: boolean;
  isStorageLocationRequired: boolean;

  isModalOpen(modalId: string): boolean;

  onFilterChanged(filtersChanges: OrderFilterView): void;

  onFilterCleared(): void;

  changeSearchTerm(): void;

  onResultsOptionsChanged(options: ResultSetOptions): void;

  onSearchTermChange(term: string): void;

  onOrderAction(item: OrderSearchResultSetItem): void;

  toggleModal(modalId: string): void;

  onOrderApproval(orderId: number, notes: string): void;

  onOrderPlaced(orderId: number, notes: string, quantityOrdered: number, pricePaid: number): void;

  onOrderReceived(
    orderId: number,
    addToInventory: boolean,
    quantity: number,
    unit: string,
    notes: string,
    storageLocationId: number | null,
    storageCells: string[] | null,
    storageNotes: string | null,
    updateAfterReceive: boolean,
    delegateToId: number | null,
  ): void;

  onBulkUpdateSelectAll(): void;

  onBulkUpdateOrderChecked(orderId: number, isChecked: boolean): void;

  onBulkOrderApproved(orderIds: number[], notes: string): Promise<void>;

  onBulkOrderPlaced(orderIds: number[], notes: string): Promise<void>;

  onBulkUpdateModalToggled(): void;
}

const defaultSearchResults: OrderSearchResultSet = {
  items: [],
  totalResultCount: 0,
  currentPageNumber: 0,
  totalPageCount: 0,
  pageSize: 0,
  sortField: "",
  sortDirection: SortDirection.DEFAULT,
};

export function useOrderSearch(
  userState: UserState,
  authState: AuthenticationState,
  inventoryTypesState: InventoryTypesState,
  storageState: StorageState,
  api: InventoryApi,
): OrderSearchHooks {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessingOrder, setIsProcessingOrder] = useState(false);
  const [searchTerm, setSearchTerm] = useState(userState.lastOrderFilter.term);
  const [searchResults, setSearchResults] = useState(defaultSearchResults);
  const [openModalId, setOpenModalId] = useState("");
  const [actionErrorMessage, setActionErrorMessage] = useState("");
  const [openOrderRequest, setOpenOrderRequest] = useState<OrderSearchResultSetItem | undefined>();
  const searchCriteria = useMemo(() => userState.lastOrderFilter, [userState.lastOrderFilter]);
  const [selectedBulkUpdateOrderIds, setSelectedBulkUpdateOrderIds] = useState<number[]>([]);
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);

  const apiSearch = (criteria: OrderSearchCriteria): void => {
    api.searchOrders(criteria).then((results) => {
      setIsLoading(false);
      setSearchResults(results);
      userState.updateLastOrderSearchCriteria(criteria);
      return results;
    });
  };

  const debounced = useRef(utils.debounce(apiSearch, 1000)).current;

  const searchOrders = (criteria: OrderSearchCriteria, debounce = true): void => {
    setIsLoading(true);
    if (debounce) {
      debounced(criteria);
    } else {
      apiSearch(criteria);
    }
  };

  const onOrderAction = (item: OrderSearchResultSetItem): void => {
    let modalId = "";
    switch (item.status) {
      case OrderStatus.Requested: {
        modalId = OrderActionModals.Approval;
        break;
      }
      case OrderStatus.Approved: {
        modalId = OrderActionModals.Order;
        break;
      }
      case OrderStatus.Ordered: {
        modalId = OrderActionModals.Receive;
        break;
      }

      default: {
        modalId = "";
      }
    }
    setOpenOrderRequest(item);
    setOpenModalId(modalId);
    setActionErrorMessage("");
  };

  const onFilterChanged = (filter: OrderFilterView): void => {
    const updatedCriteria: OrderSearchCriteria = {
      ...searchCriteria,
      typeIds: filter.typeIds,
      statusIds: filter.statusIds,
      term: filter.term,
      orderStartDate: filter.orderStartDate,
      orderEndDate: filter.orderEndDate,
    };
    searchOrders(updatedCriteria);
  };

  const onFilterCleared = (): void => {
    setSearchTerm("");
    const updatedCriteria: OrderSearchCriteria = {
      resultSetOptions: undefined,
      typeIds: [],
      statusIds: [],
      term: "",
      orderStartDate: undefined,
      orderEndDate: undefined,
    };
    searchOrders(updatedCriteria);
  };

  const onResultsOptionsChanged = (resultSetOptions: ResultSetOptions): void => {
    const updatedCriteria = { ...searchCriteria, resultSetOptions };
    searchOrders(updatedCriteria);
  };

  const onSearchTermChange = (term: string): void => {
    setSearchTerm(term);
  };

  const onOrderApproval = async (orderId: number, notes: string): Promise<void> => {
    setIsProcessingOrder(true);

    await api
      .approveOrder({ id: orderId, notes })
      .then((order) => {
        const index = searchResults.items.findIndex((item) => item.id === order.id);
        const newSearchItems = [...searchResults.items];

        if (index !== -1) {
          newSearchItems[index].dateApproved = order.dateApproved;
          newSearchItems[index].status = order.status;
        }
        const newOrderSearch: OrderSearchResultSet = { ...searchResults, items: newSearchItems };

        setOpenModalId("");
        setOpenOrderRequest(undefined);
        setSearchResults(newOrderSearch);
        setIsProcessingOrder(false);
        return order;
      })
      .catch((error) => {
        setIsProcessingOrder(false);
        setActionErrorMessage(error.message);
      });
  };

  const onOrderPlaced = async (orderId: number, notes: string, quantityOrdered: number, pricePaid: number): Promise<void> => {
    setIsProcessingOrder(true);

    const request: OrderPlacedRequest = { id: orderId, notes, quantity: quantityOrdered, price: pricePaid };
    await api
      .placeOrder(request)
      .then((order) => {
        const index = searchResults.items.findIndex((item) => item.id === order.id);
        const newSearchItems = [...searchResults.items];

        if (index !== -1) {
          newSearchItems[index].dateOrdered = order.dateOrdered;
          newSearchItems[index].status = order.status;
          newSearchItems[index].price = order.price;
          newSearchItems[index].quantity = order.quantity;
          newSearchItems[index].total = order.price * order.quantity;
        }
        const newOrderSearch: OrderSearchResultSet = { ...searchResults, items: newSearchItems };
        setOpenModalId("");
        setOpenOrderRequest(undefined);
        setSearchResults(newOrderSearch);
        setIsProcessingOrder(false);
        return order;
      })
      .catch((error: Error) => {
        setIsProcessingOrder(false);
        setActionErrorMessage(error.message);
      });
  };

  const receiveOrder = async (request: OrderReceivedRequest): Promise<void | Order> => {
    return api
      .receiveOrder(request)
      .then((order) => {
        const index = searchResults.items.findIndex((item) => item.id === order.id);
        const newSearchItems = [...searchResults.items];

        if (index !== -1) {
          newSearchItems[index].dateReceived = order.dateReceived;
          newSearchItems[index].status = order.status;
          if (order.inventoryItemId) {
            newSearchItems[index].inventoryItemId = order.inventoryItemId;
          }
        }
        const newOrderSearch: OrderSearchResultSet = { ...searchResults, items: newSearchItems };
        setOpenModalId("");
        setOpenOrderRequest(undefined);
        setSearchResults(newOrderSearch);
        setIsProcessingOrder(false);
        return order;
      })
      .catch((error: Error) => {
        setIsProcessingOrder(false);
        setActionErrorMessage(error.message);
      });
  };

  const onOrderReceived = async (
    orderId: number,
    addToInventory: boolean,
    quantity: number,
    unit: string,
    notes: string,
    storageLocationId: number | null,
    storageCells: string[] | null,
    storageNotes: string | null,
    updateAfterReceive: boolean,
    delegateToId: number | null,
  ): Promise<void> => {
    if (addToInventory && !delegateToId && getIsStorageLocationRequired() && !storageLocationId) {
      return;
    }
    setIsProcessingOrder(true);

    const request: OrderReceivedRequest = {
      id: orderId,
      notes,
      quantity,
      units: unit,
      addToInventory,
      storageLocationId,
      storageCells,
      storageLocationNotes: storageNotes,
      delegateTo: delegateToId,
    };
    const order = await receiveOrder(request);

    if (updateAfterReceive && order && order.inventoryItemId) {
      history.push(ApplicationPaths.Inventory.Edit(order.inventoryItemId));
    }
  };

  const changeSearchTerm = (): void => {
    onFilterChanged({ ...searchCriteria, term: searchTerm, resultSetOptions: undefined });
  };

  const isModalOpen = (modalId: string): boolean => {
    return openModalId === modalId;
  };

  const toggleModal = (modalId: string): void => {
    if (modalId === openModalId) {
      setOpenModalId("");
    } else {
      setOpenModalId(modalId);
    }
  };

  function getIsBulkActionEnabled(): boolean {
    if (searchCriteria.statusIds.length !== 1) {
      return false;
    }

    if (searchCriteria.statusIds[0] === OrderStatus.Requested) {
      return authState.hasPermissions([RolePermissions.OrdersUpdateAll, RolePermissions.OrdersApproveAll]);
    }
    if (searchCriteria.statusIds[0] === OrderStatus.Approved) {
      return authState.hasPermissions([RolePermissions.OrdersUpdateAll]);
    }

    return false;
  }

  function getBulkActionStatus(): OrderStatus | undefined {
    if (!getIsBulkActionEnabled()) {
      return undefined;
    }

    return searchCriteria.statusIds[0];
  }

  function onBulkUpdateSelectAll(): void {
    if (getIsBulkSelectAllChecked()) {
      setSelectedBulkUpdateOrderIds([]);
    } else {
      setSelectedBulkUpdateOrderIds(searchResults.items.map((i) => i.id));
    }
  }

  function getIsBulkSelectAllChecked(): boolean {
    return selectedBulkUpdateOrderIds.length > 0 && selectedBulkUpdateOrderIds.length === searchResults.items.length;
  }

  function onBulkUpdateOrderChecked(orderId: number, isChecked: boolean): void {
    if (isChecked) {
      setSelectedBulkUpdateOrderIds([...selectedBulkUpdateOrderIds, orderId]);
    } else {
      setSelectedBulkUpdateOrderIds(selectedBulkUpdateOrderIds.filter((id) => id !== orderId));
    }
  }

  function getSelectedBulkUpdateOrderIds(): number[] {
    if (!getIsBulkActionEnabled()) {
      return [];
    }

    return searchResults.items.filter((i) => i.status === searchCriteria.statusIds[0] && selectedBulkUpdateOrderIds.includes(i.id)).map((i) => i.id);
  }

  async function bulkOrderAction(
    orderIds: number[],
    notes: string,
    apiCall: (r: { orderIds: number[]; notes: string }) => Promise<unknown>,
  ): Promise<void> {
    setIsLoading(true);
    await apiCall({ orderIds, notes });
    setSelectedBulkUpdateOrderIds([]);
    await apiSearch(searchCriteria);
    setIsBulkUpdateModalOpen(false);
  }

  async function onBulkOrderApproved(orderIds: number[], notes: string): Promise<void> {
    await bulkOrderAction(orderIds, notes, (r) => api.approveOrderInBulk(r));
  }

  async function onBulkOrderPlaced(orderIds: number[], notes: string): Promise<void> {
    await bulkOrderAction(orderIds, notes, (r) => api.placeOrderInBulk(r));
  }

  function onBulkUpdateModalToggled(): void {
    setIsBulkUpdateModalOpen(!isBulkUpdateModalOpen);
  }

  function getIsStorageLocationRequired(): boolean {
    if (!openOrderRequest) {
      return false;
    }
    return isInventoryStorageLocationRequired(inventoryTypesState, openOrderRequest.typeId);
  }

  useEffect(() => {
    userState.refresh();
    storageState.refresh();
    inventoryTypesState.refresh();

    searchOrders(searchCriteria, false);
  }, []);

  return {
    filterView: getOrderFilterView(searchCriteria),
    isLoading,
    isModalOpen,
    isProcessingOrder,
    actionErrorMessage,
    searchResults,
    isStorageLocationRequired: getIsStorageLocationRequired(),
    onFilterChanged,
    onFilterCleared,
    changeSearchTerm,
    onResultsOptionsChanged,
    onSearchTermChange,
    onOrderAction,
    openOrderRequest,
    toggleModal,
    onOrderApproval,
    onOrderPlaced,
    onOrderReceived,
    onBulkUpdateSelectAll,
    units: getUnits(),
    searchCriteria,
    searchTerm,
    storageLocations: getStorageLocationView(storageState),
    inventoryTypeViews: getInventoryTypeViews(inventoryTypesState),
    isBulkActionEnabled: getIsBulkActionEnabled(),
    bulkActionStatus: getBulkActionStatus(),
    selectedBulkUpdateOrderIds: getSelectedBulkUpdateOrderIds(),
    isBulkSelectAllChecked: getIsBulkSelectAllChecked(),
    isBulkUpdateModalOpen,
    onBulkUpdateOrderChecked,
    onBulkOrderApproved,
    onBulkOrderPlaced,
    onBulkUpdateModalToggled,
  };
}
