export enum InventoryImportTemplateColumns {
  Name = "Name",
  Vendor = "Vendor",
  CatalogNumber = "Catalog Number",
  Owner = "Owner (email address)",
  Quantity = "Quantity",
  Description = "Description",
  DateReceived = "Date Received",
  ExpirationDate = "Expiration Date",
  GrantNumber = "Grant Number",
  PoNumber = "PO Number",
  LotNumber = "Lot Number",
  Price = "Price",
  Notes = "Notes",
  SafetySheetUrl = "Safety Sheet URL",
  Unit = "Unit",
  StorageTop = "Storage Location - Top",
  StorageBottom = "Storage Location - Bottom",
  StorageFreezerBoxCells = "Storage Location - Freezer Box Cells",
  ReorderNotificationQuantity = "Reorder Notification Quantity",
  ReorderNotificationUnit = "Reorder Notification Unit",
}

export enum InventoryUpdateTemplateColumns {
  ItemURL = "Item URL",
  ItemId = "Item Id",
}
