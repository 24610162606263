import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Label, Table } from "reactstrap";
import { Input } from "@labarchives/ui-design";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { Loading, ResultSetPagination } from "../../../components";
import { SortableTableHeader } from "../../../components/SortableTableHeader/SortableTableHeader";
import { OrderSearchResultsRow } from "./OrderSearchResultsRow";
import { OrderSearchResultsProps, useOrderSearchResults } from "./OrderSearchResultsHooks";
import "./OrderSearchResults.scss";

export function OrderSearchResults(props: OrderSearchResultsProps): ReactElement {
  const { onSortChanged, onPageChanged } = useOrderSearchResults(props);
  const intl = useIntl();

  const {
    searchResults,
    isLoading,
    currency,
    isBulkActionEnabled,
    isBulkSelectAllChecked,
    onBulkUpdateSelectAll,
    onBulkUpdateOrderChecked,
    selectedBulkUpdateOrderIds,
    onOrderAction,
  } = props;

  if (isLoading) {
    return <Loading messageId="searching" />;
  }

  if (searchResults.items.length === 0) {
    return (
      <div id="order-search-results-no-results" aria-live="assertive" role="alert">
        <div>
          <FormattedMessage id="no.order.search.results" />
        </div>
        <div>
          <FormattedMessage id="clear.search.and.try.again" />
        </div>
      </div>
    );
  }

  const sortInput = React.createRef<HTMLInputElement>();

  return (
    <>
      <span aria-live="assertive" role="alert" className="visually-hidden">
        <FormattedMessage id="result.count" values={{ resultCount: searchResults.totalResultCount }} />
      </span>
      <div id="order-search-result-container" className="table-to-card">
        <div className="sort-results-mobile">
          <Label for="sort-result-select">{intl.formatMessage({ id: "sort.by" })}</Label>
          <Input
            id="sort-result-select"
            type="select"
            bsSize="sm"
            innerRef={sortInput}
            onChange={() => onSortChanged(sortInput.current ? sortInput.current.value : "")}
            value={searchResults.sortField}
          >
            <option value="name">{intl.formatMessage({ id: "name" })}</option>
            <option value="typeId">{intl.formatMessage({ id: "type" })}</option>
            <option value="vendor">{intl.formatMessage({ id: "vendor" })}</option>
            <option value="price">{intl.formatMessage({ id: "price" })}</option>
            <option value="dateRequested">{intl.formatMessage({ id: "date.requested" })}</option>
            <option value="status">{intl.formatMessage({ id: "order.status" })}</option>
          </Input>
        </div>
        <Table striped>
          <caption className={"visually-hidden"}>
            <FormattedMessage id="order.search.results" />
          </caption>
          <thead>
            <tr>
              {isBulkActionEnabled && (
                <th scope={"col"}>
                  <CheckboxLabel>
                    <CheckboxInput onChange={onBulkUpdateSelectAll} uncheckedValue="0" checked={isBulkSelectAllChecked} />
                    <span className={"d-sm-none"}>Select All</span>
                  </CheckboxLabel>
                </th>
              )}
              <SortableTableHeader
                currentlySortedFieldName={searchResults.sortField}
                currentlySortedDirection={searchResults.sortDirection}
                onSortChanged={onSortChanged}
                fieldName={"name"}
                displayTextMessageId={"name"}
                buttonId={"order-search-header-name"}
              />
              <SortableTableHeader
                currentlySortedFieldName={searchResults.sortField}
                currentlySortedDirection={searchResults.sortDirection}
                onSortChanged={onSortChanged}
                fieldName={"type"}
                displayTextMessageId={"type"}
                buttonId={"order-search-header-type"}
              />
              <th scope={"col"}>
                <FormattedMessage id="info" />
              </th>
              <SortableTableHeader
                currentlySortedFieldName={searchResults.sortField}
                currentlySortedDirection={searchResults.sortDirection}
                onSortChanged={onSortChanged}
                fieldName={"vendor"}
                displayTextMessageId={"vendor"}
                buttonId={"order-search-header-vendor"}
              />
              <SortableTableHeader
                currentlySortedFieldName={searchResults.sortField}
                currentlySortedDirection={searchResults.sortDirection}
                onSortChanged={onSortChanged}
                fieldName={"price"}
                displayTextMessageId={"price"}
                buttonId={"order-search-header-price"}
              />
              <SortableTableHeader
                currentlySortedFieldName={searchResults.sortField}
                currentlySortedDirection={searchResults.sortDirection}
                onSortChanged={onSortChanged}
                fieldName={"dateRequested"}
                displayTextMessageId={"date.requested"}
                buttonId={"order-search-header-date-requested"}
              />
              <SortableTableHeader
                currentlySortedFieldName={searchResults.sortField}
                currentlySortedDirection={searchResults.sortDirection}
                onSortChanged={onSortChanged}
                fieldName={"status"}
                displayTextMessageId={"order.status"}
                buttonId={"order-search-header-status"}
              />
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.items.map((item) => (
              <OrderSearchResultsRow
                key={item.id}
                item={item}
                onOrderAction={onOrderAction}
                currency={currency}
                isBulkActionEnabled={isBulkActionEnabled}
                selectedBulkUpdateOrderIds={selectedBulkUpdateOrderIds}
                onBulkUpdateOrderChecked={onBulkUpdateOrderChecked}
              />
            ))}
          </tbody>
        </Table>
        <ResultSetPagination
          totalResultCount={searchResults.totalResultCount}
          totalPageCount={searchResults.totalPageCount}
          currentPageNumber={searchResults.currentPageNumber}
          pageSize={searchResults.pageSize}
          onPageChanged={onPageChanged}
        />
      </div>
    </>
  );
}
