import React, { ReactElement, useMemo } from "react";
import { InventorySearchResultSetItem } from "@labarchives/inventory-shared/build/inventory";
import { getFormattedFreezerBoxCells, getFreezerBoxCellLabelFromLocation } from "../selectors";
import "./InventoryFreezerBoxView.scss";
import { UseNowModal } from "../../inventory/UseNowModal/UseNowModal";
import { FreezerBoxCellMultiple } from "./FreezerBoxCellMultiple";
import { InventoryFreezerBoxViewProps, useInventoryFreezerBox } from "./InventoryFreezerBoxViewHooks";
import { FreezerBoxCellEmpty } from "./FreezerBoxCellEmpty";
import { FreezerBoxCellItem } from "./FreezerBoxCellItem";
import { FreezerBoxCellDetails } from "./FreezerBoxCellDetails";

export function InventoryFreezerBoxView(props: InventoryFreezerBoxViewProps): ReactElement {
  const {
    activeCell,
    activeItem,
    selectedUseNowCells,
    allOccupiedCells,
    isUseNowOpen,
    isMoveActive,
    isCopyActive,
    getCellContents,
    onSelectedUseNowCellsUpdated,
    onCellActivated,
    onDetailsClosed,
    onQuantityUsed,
    onUseNowOpened,
    onItemDragStart,
    onItemDragDrop,
    onMoveItemActivated,
    onMoveItemConfirmed,
    onMoveItemCancelled,
    onCopyItemActivated,
    onCopyItemCancelled,
    onCopyItemConfirmed,
  } = useInventoryFreezerBox(props);

  const { location, canAddItems, currency } = props;
  const rows = Array.from({ length: location.numberOfRows || 0 }, (v, k) => k + 1);
  const cols = Array.from({ length: location.numberOfColumns || 0 }, (v, k) => k + 1);
  let tabIndex = 1;

  function CellDisplay(props: { row: number; column: number }): ReactElement {
    const { row, column } = props;
    const cell = getFreezerBoxCellLabelFromLocation(row, column);
    const label = getFormattedFreezerBoxCells([cell], location.freezerBoxDisplayFormat, location.numberOfRows, location.numberOfColumns);

    const cellItems = getCellContents(cell);

    const item: InventorySearchResultSetItem | undefined = useMemo(() => {
      return cellItems.length === 1 ? cellItems[0] : undefined;
    }, [cellItems]);

    const key = `row-${row}-col-${column}`;

    if (cellItems.length > 1) {
      return <FreezerBoxCellMultiple cell={cell} label={label} tabIndex={tabIndex++} key={key} />;
    }

    if (item !== undefined) {
      return (
        <FreezerBoxCellItem
          item={item}
          label={label}
          cell={cell}
          tabIndex={tabIndex++}
          key={key}
          onClick={() => onCellActivated(cell)}
          onDragStart={onItemDragStart}
          activeCell={activeCell}
        />
      );
    }

    return (
      <FreezerBoxCellEmpty
        label={label}
        tabIndex={tabIndex++}
        key={key}
        canAddItems={canAddItems}
        locationId={location.id}
        cell={cell}
        onDropped={onItemDragDrop}
        isMoveActive={isMoveActive}
        isCopyActive={isCopyActive}
        onMoveConfirmed={onMoveItemConfirmed}
        onCopyConfirmed={onCopyItemConfirmed}
      />
    );
  }

  function getBoxClassName(): string {
    if (isMoveActive) {
      return "active moving";
    }

    if (isCopyActive) {
      return "active copying";
    }

    return "";
  }

  function getColClassName(): string {
    if (activeCell) {
      return "col active";
    }

    return "col-10";
  }

  return (
    <>
      <div className={`inventory-freezer-box-col ${getColClassName()}`}>
        <div id={"inventory-freezer-box"} className={getBoxClassName()}>
          {rows.map((r) => (
            <div key={`row-${r}`} className={"inventory-freezer-box-row"}>
              {cols.map((c) => (
                <CellDisplay key={`row-${r}-col-${c}`} row={r} column={c} />
              ))}
            </div>
          ))}
        </div>
      </div>
      {activeItem && activeCell && (
        <FreezerBoxCellDetails
          activeItem={activeItem}
          activeCell={activeCell}
          location={location}
          currency={currency}
          onDetailsClosed={onDetailsClosed}
          onUseNowOpened={onUseNowOpened}
          onMoveItemActivated={onMoveItemActivated}
          isMoveActive={isMoveActive}
          isCopyActive={isCopyActive}
          onMoveItemCancelled={onMoveItemCancelled}
          onCopyItemActivated={onCopyItemActivated}
          onCopyItemCancelled={onCopyItemCancelled}
        />
      )}

      <UseNowModal
        isOpen={isUseNowOpen}
        defaultUsageAmount={"0"}
        defaultUsageUnit={activeItem?.unit || "units"}
        matrixCols={location.numberOfColumns!}
        matrixRows={location.numberOfRows!}
        selectedCells={selectedUseNowCells}
        displayFormat={location.freezerBoxDisplayFormat}
        occupiedCells={allOccupiedCells}
        onSelectedCellsChanged={onSelectedUseNowCellsUpdated}
        onUsageSaved={(amount, unitOfMeasure) => onQuantityUsed(amount, unitOfMeasure)}
        onToggle={() => onUseNowOpened(false)}
      />
    </>
  );
}
