import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { InventoryApiClient } from "../../api";
import { ApplicationErrorState } from "./ApplicationErrorState";

export const ApplicationErrorContext = React.createContext<ApplicationErrorState>({
  isError: false,
  errorReportId: "",
  setError(): void {
    // no-op
  },
});

export function useApplicationErrorContext(): ApplicationErrorState {
  const [isError, setIsError] = useState(false);
  const [errorReportId, setErrorReportId] = useState("");
  const api = new InventoryApiClient();

  const setError = (error: Error): void => {
    const errorId = uuidv4();
    setErrorReportId(errorId);
    setIsError(true);

    // eslint-disable-next-line promise/no-promise-in-callback
    api.logError(error, "", errorId).catch((error_: Error) => {
      console.error("Error logging the error!", error_);
    });
  };

  return {
    isError,
    errorReportId,
    setError,
  };
}
