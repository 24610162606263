import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import React, { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-light-svg-icons";

import { VendorAssignmentsView, VendorView } from "../../orders/types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";

interface ManageVendorsListProps {
  onShowVendor(vendorView: VendorView): void;
  onDelete(vendorId: number): void;
  onShowDeleteVendor(vendorId: number): Promise<VendorAssignmentsView>;
  vendorViews: VendorView[];
}

export function ManageVendorsList(props: ManageVendorsListProps): ReactElement {
  const [vendorBeingDeleted, setVendorBeingDeleted] = useState<VendorView | undefined>(undefined);
  const [vendorAssignments, setVendorAssignments] = useState<VendorAssignmentsView | undefined>(undefined);
  const intl = useIntl();

  function onShowDeletePrompt(vendorView: VendorView): void {
    props.onShowDeleteVendor(vendorView.id).then((assignments) => {
      setVendorAssignments(assignments);
      setVendorBeingDeleted(vendorView);
      return assignments;
    });
  }

  function onDeletePromptCancel(): void {
    setVendorBeingDeleted(undefined);
    setVendorAssignments(undefined);
  }

  function onDelete(vendorView: VendorView): void {
    props.onDelete(vendorView.id);
    setVendorBeingDeleted(undefined);
  }

  return (
    <>
      <Table striped>
        <caption className={"visually-hidden"}>
          <FormattedMessage id="vendors" />
        </caption>
        <thead>
          <tr>
            <th scope={"col"}>
              <FormattedMessage id="vendor.name" />
            </th>
            <th scope={"col"}>
              <FormattedMessage id="contact.info" />
            </th>
            <th scope={"col"}>
              <FormattedMessage id="notes" />
            </th>
            <th scope={"col"}>
              <FormattedMessage id="website" />
            </th>
            <th scope={"col"}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {props.vendorViews.map((vendorView) => (
            <tr key={vendorView.id}>
              <td>
                <Button
                  className={"text-left"}
                  color="link"
                  onClick={() => props.onShowVendor(vendorView)}
                  title={intl.formatMessage({ id: "edit.vendor" })}
                >
                  {vendorView.name}
                </Button>
              </td>
              <td>{vendorView.contactInfo}</td>
              <td>{vendorView.notes}</td>
              <td>
                <a href={vendorView.url} target="_blank" rel="noopener noreferrer">
                  {vendorView.url}
                </a>
              </td>
              <td className="management-item-delete">
                <Button color="link" onClick={() => props.onShowVendor(vendorView)} title={intl.formatMessage({ id: "edit.vendor" })}>
                  <FontAwesomeIcon icon={faPencilAlt} className="button-icon" size="lg" />
                </Button>
                <Button onClick={() => onShowDeletePrompt(vendorView)} color="link" title={intl.formatMessage({ id: "delete.vendor" })}>
                  <FontAwesomeIcon icon={faTrash} className="button-icon" size="lg" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal isOpen={vendorBeingDeleted !== undefined} toggle={onDeletePromptCancel}>
        <ModalHeader toggle={onDeletePromptCancel}>
          <FormattedMessage id="delete.vendor" />
        </ModalHeader>
        <ModalBody>
          {vendorAssignments && vendorAssignments.canBeDeleted && (
            <FormattedHTMLMessage id="delete.vendor.warning" values={{ name: vendorBeingDeleted ? vendorBeingDeleted.name : "" }} />
          )}
          {vendorAssignments && !vendorAssignments.canBeDeleted && (
            <FormattedHTMLMessage
              id="vendor.cannot.be.deleted"
              values={{ itemCount: vendorAssignments.itemCount, orderCount: vendorAssignments.orderCount }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {vendorAssignments && vendorAssignments.canBeDeleted && vendorBeingDeleted && (
            <Button color="danger" onClick={() => onDelete(vendorBeingDeleted)}>
              <FormattedMessage id="delete" />
            </Button>
          )}
          <CancelButton onClick={onDeletePromptCancel} />
        </ModalFooter>
      </Modal>
    </>
  );
}
