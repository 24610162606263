import { AuthenticationError } from "../components/Authentication/AuthenticationError";

export function isNotFoundError(error: Error | unknown): boolean {
  // @ts-ignore
  return error.response && error.response.status === 404;
}

export function isValidationError(error: Error | unknown): boolean {
  // @ts-ignore
  return error.response && error.response.status === 400;
}

export function isAuthenticationError(error: Error): boolean {
  return error instanceof AuthenticationError;
}

export function attachWindowErrorHandlers(onError: (error: Error) => void): void {
  window.onunhandledrejection = (event: PromiseRejectionEvent) => {
    console.error("Caught an unhandled rejection", event);

    try {
      if (event.reason && event.reason.code === "ECONNABORTED") {
        // connection aborted happens when navigation occurs before a request finishes, we are ok with this
        return;
      }

      if (event.reason && isAuthenticationError(event.reason)) {
        // this is handled when the authentication error is first encountered at the api layer
        return;
      }

      if (event.reason && event.reason instanceof Error) {
        onError(event.reason);
      } else {
        onError(new Error(event.toString()));
      }
    } catch (error) {
      console.error("Could not handle rejection", error);
    }
  };

  window.addEventListener("error", (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
    console.error("Caught an unhandled error", event);
    try {
      if (error && error instanceof Error) {
        if (isAuthenticationError(error)) {
          // this is handled when the authentication error is first encountered at the api layer
          return;
        }
        onError(error);
      } else {
        onError(new Error(event.toString()));
      }
    } catch (error_) {
      console.error("Could not handle rejection", error_);
    }
  });
}
