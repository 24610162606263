import { FormGroup } from "reactstrap";
import { CheckboxInput, CheckboxLabel } from "@labarchives/ui-design";
import React, { ReactElement } from "react";
import { CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { InventoryAdvanceSearchMatchDropdown } from "./InventoryAdvanceSearchMatchDropdown";
import { InventoryAdvancedSearchCheckboxProps } from "./InventoryAdvancedSearchTypeComponentProps";

export function InventoryAdvancedSearchCheckbox(props: InventoryAdvancedSearchCheckboxProps): ReactElement {
  return (
    <>
      <InventoryAdvanceSearchMatchDropdown
        index={props.index}
        matchType={props.matchType}
        onMatchTypeChanged={props.onMatchTypeChanged}
        attributeType={CustomAttributeType.Checkbox}
      />
      <div className={"criteria-value"}>
        <FormGroup>
          <label className={"hidden"} id={`${props.selectedAttribute.inputId}label`} htmlFor={`chk${props.selectedAttribute.inputId}`}>
            {props.selectedAttribute.label}
          </label>

          {props.selectedAttribute.possibleValues.map((v, index) => {
            return (
              <CheckboxLabel className="me-2" key={props.selectedAttribute.inputId + v}>
                <CheckboxInput
                  name={`chk${props.selectedAttribute.inputId}`}
                  id={props.selectedAttribute.inputId + index}
                  uncheckedValue="0"
                  checked={props.isChecked(v)}
                  value={v}
                  onChange={(e) => props.onValueChanged(e.target.value)}
                />
                <span className={"criteria-value-checkbox-label"}>{v}</span>
              </CheckboxLabel>
            );
          })}
        </FormGroup>
      </div>
    </>
  );
}
