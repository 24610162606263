import React, { ReactElement } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design";
import { CancelButton } from "../../../components/CancelButton/CancelButton";

interface SaveAdvancedSearchModalProps {
  isOpen: boolean;
  name: string;
  onDeleted(): void;
  onCancel(): void;
}

export function DeleteSavedAdvancedSearchModal(props: SaveAdvancedSearchModalProps): ReactElement {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel} autoFocus={false}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id={"delete.saved.search"} />
      </ModalHeader>
      <ModalBody>
        <FormattedHTMLMessage id={"delete.saved.search.confirm"} values={{ name: props.name }} />
      </ModalBody>
      <ModalFooter>
        <div>
          <Button className={"me-1"} color={"primary"} onClick={() => props.onDeleted()}>
            Delete
          </Button>
          <CancelButton onClick={props.onCancel} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
