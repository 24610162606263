import React, { ReactElement } from "react";
import { Button, Modal } from "@labarchives/ui-design";
import { FormGroup, Input, InputGroup, InputGroupText, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { OrganizationBillingType } from "@labarchives/inventory-shared/build/inventory";
import { useIntl } from "react-intl";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { Loading } from "../components";
import { DatePicker } from "../components/DatePicker/DatePicker";
import { SuperUserBundleSubscriptionModalProps, useSuperUserBundleSubscription } from "./SuperUserBundleSubscriptionHooks";

export function SuperUserBundleSubscriptionModal(props: SuperUserBundleSubscriptionModalProps): ReactElement {
  const { onToggle, isOpen } = props;
  const intl = useIntl();

  const {
    isLoading,
    isBundleAllowed,
    minimumSeatCount,
    seatCount,
    perSeatCost,
    totalCharged,
    billingTypeId,
    expirationDate,
    onSeatCountChanged,
    onSubscriptionUpdated,
    onTotalChargeChanged,
    onExpirationDateChanged,
    onOrganizationTypeChanged,
  } = useSuperUserBundleSubscription(props);

  const allowBundle = !isLoading && isBundleAllowed;

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Convert Inventory Subscription to Bundle</ModalHeader>
      <ModalBody>
        {isLoading && <Loading />}

        {!allowBundle && <div>This subscription cannot be bundled</div>}

        {allowBundle && (
          <div>
            <FormGroup>
              <Label htmlFor={"txtSeatCount"}>Seat Count</Label>
              <Input
                id={"txtSeatCount"}
                type={"number"}
                min={minimumSeatCount}
                value={seatCount}
                onChange={(e) => onSeatCountChanged(Number.parseInt(e.target.value))}
              />
              <div className={"font-italic"}>
                (The minimum seat count for this subscription is <strong>{minimumSeatCount}</strong>)
              </div>
            </FormGroup>
            <FormGroup>
              <Label htmlFor={"txtTotalCharged"}>Total Amount Charged</Label>
              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <Input
                  id={"txtSeatCount"}
                  type={"number"}
                  min={0}
                  value={totalCharged}
                  onChange={(e) => onTotalChargeChanged(Number.parseFloat(e.target.value))}
                />
              </InputGroup>
              <div>Current Pricing: ${perSeatCost} per seat</div>
            </FormGroup>
            <FormGroup>
              <Label htmlFor={"txtNewSubExp"}>Expiration Date</Label>
              <DatePicker
                id={"txtNewSubExp"}
                name={"newSubExp"}
                required={true}
                onChange={(d) => onExpirationDateChanged(d!)}
                selected={expirationDate}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor={"newAccountType"}>Account Type</Label>
              <Input type={"select"} value={billingTypeId} onChange={(e) => onOrganizationTypeChanged(Number.parseInt(e.target.value))}>
                <option value={OrganizationBillingType.Academic}>{intl.formatMessage({ id: "academic.non.profit" })}</option>
                <option value={OrganizationBillingType.Commercial}>{intl.formatMessage({ id: "commercial.fon.profit" })}</option>
              </Input>
            </FormGroup>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {allowBundle && (
          <Button color="primary" className="me-1" onClick={onSubscriptionUpdated}>
            Save
          </Button>
        )}
        <CancelButton onClick={onToggle} />
      </ModalFooter>
    </Modal>
  );
}
