import { useEffect, useState } from "react";
import { LocationsImportRequest, SiteLocations } from "@labarchives/inventory-shared/build/inventory";
import { InventoryApi } from "../../api/InventoryApi";

export interface InventoryAdminLocationManagementHooks {
  isLoadingLocations: boolean;
  isImportOpen: boolean;
  locations: SiteLocations;
  currentlyEditedId: number | null;
  expandedIds: number[];
  isPublishAllowed: boolean;
  isImportAllowed: boolean;

  onExpandToggled(id: number): void;

  onLocationsImported(request: LocationsImportRequest): void;

  setIsImportOpen(isOpen: boolean): void;

  canAddBuilding(name: string): boolean;

  canAddFloor(name: string, buildingId: number): boolean;

  canRenameBuilding(id: number, name: string): boolean;

  canRenameFloor(id: number, name: string): boolean;

  canAddRoom(name: string, buildingId: number, floorId: number | undefined): boolean;

  canRenameRoom(id: number, name: string): boolean;

  onBuildingAdded(buildingName: string): void;

  onBuildingUpdated(buildingId: number, updatedName: string, newFloorName: string | undefined, newRoomName: string | undefined): void;

  onFloorUpdated(floorId: number, updatedName: string, newRoomName: string | undefined): void;

  onRoomUpdated(roomId: number, updatedName: string): void;

  onLocationDeleted(id: number): void;

  onEditToggle(id: number | null): void;

  onLocationsPublished(): void;
}

export function useAdminLocationManagement(api: InventoryApi): InventoryAdminLocationManagementHooks {
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [currentlyEditedId, setCurrentlyEditedId] = useState<number | null>(null);
  const [locations, setLocations] = useState<SiteLocations>({ buildings: [], floors: [], rooms: [], isPublished: false });
  const [expandedIds, setExpandedIds] = useState<number[]>([]);

  function bindLocations(newLocations: SiteLocations): void {
    const l: SiteLocations = {
      buildings: [...newLocations.buildings].sort((b1, b2) => b1.name.localeCompare(b2.name)),
      floors: [...newLocations.floors].sort((f1, f2) => f1.name.localeCompare(f2.name)),
      rooms: [...newLocations.rooms].sort((r1, r2) => r1.name.localeCompare(r2.name)),
      isPublished: newLocations.isPublished,
    };

    setLocations(l);
  }

  function onLocationsImported(request: LocationsImportRequest): void {
    api.adminImportSiteLocations(request).then((updatedLocations) => {
      bindLocations(updatedLocations);
      setIsImportOpen(false);
      return updatedLocations;
    });
  }

  function stringEquals(s1: string, s2: string): boolean {
    return s1.toLocaleLowerCase().localeCompare(s2.trim().toLocaleLowerCase()) === 0;
  }

  function canAddBuilding(name: string): boolean {
    return !locations.buildings.some((b) => stringEquals(b.name, name));
  }

  function canRenameBuilding(id: number, name: string): boolean {
    const buildingWithSameName = locations.buildings.find((b) => stringEquals(b.name, name));
    if (buildingWithSameName) {
      return buildingWithSameName.id === id;
    }

    return true;
  }

  function canAddFloor(name: string, buildingId: number): boolean {
    return !locations.floors.some((f) => stringEquals(f.name, name) && f.buildingId === buildingId);
  }

  function canRenameFloor(id: number, name: string): boolean {
    const floor = locations.floors.find((f) => f.id === id)!;
    const floorsWithSameName = locations.floors.find((f) => stringEquals(f.name, name) && f.buildingId === floor.buildingId);
    if (floorsWithSameName) {
      return floorsWithSameName.id === id;
    }

    return true;
  }

  function canAddRoom(name: string, buildingId: number, floorId: number | undefined): boolean {
    return !locations.rooms.some((r) => stringEquals(r.name, name) && r.buildingId === buildingId && r.floorId === floorId);
  }

  function canRenameRoom(id: number, name: string): boolean {
    const room = locations.rooms.find((r) => r.id === id)!;
    const roomsWithSameName = locations.rooms.find(
      (r) => stringEquals(r.name, name) && r.buildingId === room.buildingId && r.floorId === room.floorId,
    );
    if (roomsWithSameName) {
      return roomsWithSameName.id === id;
    }

    return true;
  }

  function invokeUpdateApi(update: SiteLocations): void {
    api.updateSiteLocations(update).then((l) => {
      bindLocations(l);
      setCurrentlyEditedId(null);
      setIsLoadingLocations(false);
      return l;
    });
  }

  function onBuildingAdded(name: string): void {
    if (canAddBuilding(name)) {
      setIsLoadingLocations(true);

      const update: SiteLocations = {
        buildings: [{ id: 0, name: name.trim() }],
        floors: [],
        rooms: [],
        isPublished: locations.isPublished,
      };

      invokeUpdateApi(update);
    }
  }

  function onBuildingUpdated(buildingId: number, updatedName: string, newFloorName: string | undefined, newRoomName: string | undefined): void {
    if (updatedName && canRenameBuilding(buildingId, updatedName)) {
      setIsLoadingLocations(true);
      const update: SiteLocations = {
        buildings: [{ id: buildingId, name: updatedName.trim() }],
        floors: newFloorName ? [{ id: 0, name: newFloorName.trim(), buildingId }] : [],
        rooms: newRoomName ? [{ id: 0, name: newRoomName.trim(), buildingId }] : [],
        isPublished: locations.isPublished,
      };

      invokeUpdateApi(update);
    }
  }

  function onFloorUpdated(floorId: number, updatedName: string, newRoomName: string | undefined): void {
    if (updatedName && canRenameFloor(floorId, updatedName)) {
      setIsLoadingLocations(true);
      const { buildingId } = locations.floors.find((f) => f.id === floorId)!;
      const update: SiteLocations = {
        buildings: [],
        floors: [{ id: floorId, name: updatedName.trim(), buildingId }],
        rooms: newRoomName ? [{ id: 0, name: newRoomName.trim(), buildingId, floorId }] : [],
        isPublished: locations.isPublished,
      };

      invokeUpdateApi(update);
    }
  }

  function onRoomUpdated(roomId: number, updatedName: string): void {
    if (updatedName && canRenameRoom(roomId, updatedName)) {
      setIsLoadingLocations(true);
      const { buildingId, floorId } = locations.rooms.find((r) => r.id === roomId)!;
      const update: SiteLocations = {
        buildings: [],
        floors: [],
        rooms: [{ id: roomId, name: updatedName.trim(), buildingId, floorId }],
        isPublished: locations.isPublished,
      };

      invokeUpdateApi(update);
    }
  }

  function onLocationDeleted(id: number): void {
    setIsLoadingLocations(true);
    api.deleteSiteLocation(id).then((l) => {
      bindLocations(l);
      setCurrentlyEditedId(null);
      setIsLoadingLocations(false);
      return l;
    });
  }

  function onExpandToggled(id: number): void {
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter((i) => i !== id));
    } else {
      setExpandedIds([...expandedIds, id]);
    }
  }

  function onLocationsPublished(): void {
    setIsLoadingLocations(true);
    api.publishSiteLocations().then((l) => {
      bindLocations(l);
      setIsLoadingLocations(false);
      return l;
    });
  }

  function isPublishAllowed(): boolean {
    return !locations.isPublished && locations.buildings.length > 0 && locations.rooms.length > 0;
  }

  function isImportAllowed(): boolean {
    return !locations.isPublished || (locations.buildings.length === 0 && locations.floors.length === 0 && locations.rooms.length === 0);
  }

  useEffect(() => {
    setIsLoadingLocations(true);
    api.getSiteLocations().then((l) => {
      bindLocations(l);
      setIsLoadingLocations(false);
      return l;
    });
  }, []);

  return {
    isLoadingLocations,
    isImportOpen,
    isImportAllowed: isImportAllowed(),
    locations,
    currentlyEditedId,
    expandedIds,
    isPublishAllowed: isPublishAllowed(),
    setIsImportOpen,
    onLocationsImported,
    canAddBuilding,
    canRenameBuilding,
    canAddFloor,
    canRenameFloor,
    canAddRoom,
    canRenameRoom,
    onBuildingAdded,
    onBuildingUpdated,
    onFloorUpdated,
    onRoomUpdated,
    onLocationDeleted,
    onEditToggle: setCurrentlyEditedId,
    onExpandToggled,
    onLocationsPublished,
  };
}
