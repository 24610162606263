import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { FreezerBoxCellBaseProps } from "./FreezerBoxCellBaseProps";

export function FreezerBoxCellMultiple(props: FreezerBoxCellBaseProps): ReactElement {
  const { label, tabIndex } = props;
  const intl = useIntl();

  return (
    <div
      className={`inventory-freezer-box-cell occupied no-add`}
      aria-label={intl.formatMessage({ id: "freezerbox.cell.title" }, { label })}
      tabIndex={tabIndex}
      role="cell"
      data-cell-name={label}
    >
      <div className={"inventory-freezer-box-cell-header"}>
        <div className={"inventory-freezer-box-cell-label"}>{label}</div>
      </div>

      <div title={"This cell contains multiple items and can only be viewed in table view"}>Multiple items</div>
    </div>
  );
}
