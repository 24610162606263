import { Notification } from "@labarchives/inventory-shared/build/inventory";
import { createContext, useEffect, useState } from "react";
import { InventoryApi } from "../api/InventoryApi";

export interface NotificationsState {
  notifications: Notification[];

  onDismissNotification(notificationId: number): void;

  onDismissAllNotifications(): void;
}

export function useNotificationsContext(api: InventoryApi): NotificationsState {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const oneMinute = 60_000;

  function markNotificationsAsRead(ids: number[]): Promise<Notification[]> {
    return api.markNotificationsAsRead(ids).then((updatedNotifications) => {
      setNotifications(notifications.filter((n) => !ids.includes(n.id)));
      return updatedNotifications;
    });
  }

  const onDismissNotification = (notificationId: number): void => {
    markNotificationsAsRead([notificationId]).catch((error) => api.logError(error));
  };

  const onDismissAllNotifications = (): void => {
    markNotificationsAsRead(notifications.map((n) => n.id)).catch((error) => api.logError(error));
  };

  function getUnread(): void {
    api
      .getUnreadNotifications()
      .then((n) => {
        setNotifications(n);
        return n;
      })
      .catch((error) => api.logError(error));
  }

  useEffect(() => {
    const timeoutId = setInterval(() => {
      getUnread();
    }, oneMinute);

    getUnread();
    return () => clearInterval(timeoutId);
  }, [oneMinute]);

  return {
    notifications,
    onDismissNotification,
    onDismissAllNotifications,
  };
}

const noopDefault = (): void => {
  // no-op default
};

export const NotificationsContext = createContext<NotificationsState>({
  notifications: [],
  onDismissAllNotifications: noopDefault,
  onDismissNotification: noopDefault,
});
