import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import "./ManageRole.scss";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { AddRoleProps, useAddRole } from "./AddRoleHooks";

export function AddRole(props: AddRoleProps): ReactElement {
  const { isInvalid, permissions, onRoleAdded, onNameUpdated, onPermissionChanged } = useAddRole(props);

  function hasPermission(permission: RolePermissions): boolean {
    return permissions.includes(permission);
  }

  function getCheckbox(permission: RolePermissions, labelKey: string): ReactElement {
    return (
      <div>
        <CheckboxLabel>
          <CheckboxInput
            id={permission.toString()}
            uncheckedValue="0"
            checked={hasPermission(permission)}
            onChange={(e) => onPermissionChanged(permission, e.target.checked)}
          />
          <span>
            <FormattedMessage id={labelKey} />
          </span>
        </CheckboxLabel>
      </div>
    );
  }

  return (
    <div className={"manage-roles-row"}>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onRoleAdded();
        }}
      >
        <div className="management-name-container">
          <div className="management-item-name">
            <FormGroup>
              <Label for="add-role-name">
                <FormattedMessage id="role.name" />
                <RequiredLabelIndicator required={true} />
              </Label>
              <Input
                id="add-role-name"
                type="text"
                bsSize="sm"
                required
                invalid={isInvalid}
                onChange={(e) => onNameUpdated(e.target.value)}
                maxLength={255}
                autoFocus
              />
              <FormFeedback>
                <FormattedMessage id={"invalid.role.name"} />
              </FormFeedback>
            </FormGroup>
          </div>
        </div>
        <Row>
          <Col>
            <div className="manage-role-permission-group">
              <span className="manage-role-permission-group-title">
                <FormattedMessage id="inventory.management" />
              </span>
              <div className="manage-role-permission-details">
                {getCheckbox(RolePermissions.InventoryEditAllItems, "permission.inventory.edit.all.items")}
                {getCheckbox(RolePermissions.InventoryAddCreatedItem, "permission.inventory.add.created.item")}
              </div>
            </div>
            <div className="manage-role-permission-group">
              <span className="manage-role-permission-group-title">
                <FormattedMessage id="searching.role" />
              </span>
              <div className="manage-role-permission-details">
                <div>
                  <CheckboxLabel>
                    <CheckboxInput id={RolePermissions.SearchViewAllItems.toString()} uncheckedValue="0" disabled checked={true} />
                    <span>
                      <FormattedMessage id="permission.search.view.all.items" />
                    </span>
                  </CheckboxLabel>
                </div>
              </div>
            </div>
            <div className="manage-role-permission-group">
              <span className="manage-role-permission-group-title">
                <FormattedMessage id="lab.management" />
              </span>
              <div className="manage-role-permission-details">
                {getCheckbox(RolePermissions.LabManagementAllowed, "permission.lab.management.allowed")}
              </div>
            </div>
          </Col>
          <Col>
            <div className="manage-role-permission-group">
              <span className="manage-role-permission-group-title">
                <FormattedMessage id="ordering" />
              </span>
              <div className="manage-role-permission-details">
                {getCheckbox(RolePermissions.OrdersRequestItem, "permission.orders.request.item")}
                {getCheckbox(RolePermissions.OrdersViewAll, "permission.orders.view.all")}
                {getCheckbox(RolePermissions.OrdersUpdateAll, "permission.orders.update.all")}
                {getCheckbox(RolePermissions.OrdersApproveAll, "permission.orders.approve.all")}
                {getCheckbox(RolePermissions.OrdersCancelAll, "permission.orders.cancel.all")}
                {getCheckbox(RolePermissions.OrdersCancelOwn, "permission.orders.cancel.own")}
                {getCheckbox(RolePermissions.OrdersReceiveOwn, "permission.orders.receive.own")}
                {getCheckbox(RolePermissions.OrdersReceiveAll, "permission.orders.receive.all")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="management-edit-footer">
            <Button type={"submit"} color="primary" className="me-1">
              <FormattedMessage id="add.role" />
            </Button>
            <CancelButton onClick={props.onCancel} />
          </Col>
        </Row>
      </Form>
    </div>
  );
}
