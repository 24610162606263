/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormattedMessage, useIntl } from "react-intl";
import { InventorySearchAutoSuggestItem } from "@labarchives/inventory-shared/build/inventory";
import React, { ReactElement } from "react";
import { Label } from "reactstrap";
import Autosuggest, { RenderSuggestionParams } from "react-autosuggest";
import "./InventorySearchAutoSuggest.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { InventorySearchAutoSuggestProps, useInventorySearchAutoSuggest } from "./InventorySearchAutoSuggestHooks";

export function InventorySearchAutoSuggest(props: InventorySearchAutoSuggestProps): ReactElement {
  const { options, onChange, onSuggestionsClearRequested, onSuggestionsFetchRequested } = useInventorySearchAutoSuggest(props);
  const intl = useIntl();

  const renderInputComponent = (renderInputProps: any): ReactElement => {
    const { ...other } = renderInputProps;

    return <input className="form-control me-sm-2" {...other} />;
  };

  const inputProps = {
    "aria-label": intl.formatMessage({ id: "search.inventory" }),
    placeholder: intl.formatMessage({ id: "search.inventory.placeholder" }),
    value: props.searchTerm,
    onChange,
    type: "text",
    className: "form-control",
  };

  const getSuggestionValue = (suggestion: InventorySearchAutoSuggestItem): string => suggestion.name;

  const renderSuggestion = (suggestion: InventorySearchAutoSuggestItem, { query }: RenderSuggestionParams): ReactElement => {
    const highlighted = suggestion.name.replace(query, `<strong>${query}</strong>`);
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  return (
    <div className="inventory-search-text-input" aria-live="assertive">
      <Label for="inventory-search-text" className="visually-hidden">
        <FormattedMessage id="search.inventory" />
      </Label>
      <Autosuggest
        id="inventory-search-text"
        suggestions={options}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        // @ts-ignore
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        shouldRenderSuggestions={(value) => {
          return value.trim().length > 3;
        }}
      />
      {props.searchTerm.length > 0 && (
        <button type={"button"} className={"inventory-search-text-clear"} onClick={props.onClear}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  );
}
