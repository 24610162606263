import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { OrderSearchResultSetItem, OrderStatus, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { Button } from "@labarchives/ui-design";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { OrderStatusLabel } from "../../OrderStatusLabel";
import { AuthorizedComponent } from "../../../components/Authentication/AuthorizedComponent";
import { userCanReceiveOrder } from "../../../components/Authentication/permissionChecks/userCanReceiveOrderCheck";
import { UsersContext } from "../../../user/UsersContext";
import { ApplicationPaths } from "../../../app/ApplicationPaths";
import { userCanViewOrderCheck } from "../../../components/Authentication/permissionChecks/userCanViewOrderCheck";

interface OrderSearchResultsRowProps {
  item: OrderSearchResultSetItem;
  currency: string;
  isBulkActionEnabled: boolean;
  selectedBulkUpdateOrderIds: number[];

  onOrderAction(item: OrderSearchResultSetItem): void;
  onBulkUpdateOrderChecked(orderId: number, isChecked: boolean): void;
}

export function OrderSearchResultsRow(props: OrderSearchResultsRowProps): ReactElement {
  const userState = useContext(UsersContext);
  const { currency, item, isBulkActionEnabled, onBulkUpdateOrderChecked, selectedBulkUpdateOrderIds, onOrderAction } = props;

  function getUserName(userId: number): string {
    const user = userState.byId[userId];

    if (user) {
      return user.fullName;
    }

    return "";
  }

  const getActionButton = (resultSetItem: OrderSearchResultSetItem): ReactElement => {
    if (resultSetItem.status === OrderStatus.Requested) {
      return (
        <AuthorizedComponent requiredPermissions={[RolePermissions.OrdersUpdateAll, RolePermissions.OrdersApproveAll]}>
          <Button size="sm" className="order-search-result-action-approve" onClick={() => onOrderAction(resultSetItem)} color="primary">
            <FormattedMessage id="approve" />
          </Button>
        </AuthorizedComponent>
      );
    }
    if (resultSetItem.status === OrderStatus.Approved) {
      return (
        <AuthorizedComponent requiredPermissions={[RolePermissions.OrdersUpdateAll]}>
          <Button size="sm" className="order-search-result-action-order" onClick={() => onOrderAction(resultSetItem)} color="primary">
            <FormattedMessage id="mark.ordered" />
          </Button>
        </AuthorizedComponent>
      );
    }
    if (resultSetItem.status === OrderStatus.Ordered) {
      return (
        <AuthorizedComponent requiredPermissions={[]} additionalChecks={userCanReceiveOrder(resultSetItem.requestedById)}>
          <Button size="sm" className="order-search-result-action-receive" onClick={() => onOrderAction(resultSetItem)} color="primary">
            <FormattedMessage id="mark.received" />
          </Button>
        </AuthorizedComponent>
      );
    }

    return <></>;
  };

  const getStatusDate = (i: OrderSearchResultSetItem): Date | null => {
    switch (i.status) {
      case OrderStatus.Approved: {
        return i.dateApproved;
      }
      case OrderStatus.Cancelled: {
        return i.dateCancelled;
      }
      case OrderStatus.Requested: {
        return i.dateRequested;
      }
      case OrderStatus.Ordered: {
        return i.dateOrdered;
      }
      case OrderStatus.Received: {
        return i.dateReceived;
      }
      default: {
        return null;
      }
    }
  };

  const statusDate = getStatusDate(item);
  return (
    <tr className="order-search-result-item" data-order-id={item.id}>
      {isBulkActionEnabled && (
        <td className={"order-search-result-bulk-update"}>
          <CheckboxLabel>
            <CheckboxInput
              onChange={(e) => onBulkUpdateOrderChecked(item.id, e.target.checked)}
              uncheckedValue="0"
              checked={selectedBulkUpdateOrderIds.includes(item.id)}
            />
            <span className={"d-sm-none"}>Bulk Update</span>
          </CheckboxLabel>
        </td>
      )}
      <td className="order-search-result-name">
        <div className="table-to-card-label">
          <FormattedMessage id="name" />
        </div>
        <div className="table-to-card-content">
          {item.inventoryItemId && <Link to={ApplicationPaths.Inventory.Item(item.inventoryItemId)}>{item.inventoryName}</Link>}
          {!item.inventoryItemId && <span>{item.inventoryName}</span>}
        </div>
      </td>
      <td className="order-search-result-type">
        <div className="table-to-card-label">
          <FormattedMessage id="type" />
        </div>
        <div className="table-to-card-content">
          {item.typeColor && (
            <div className={"inventory-type-color"} data-color={item.typeColor} style={{ backgroundColor: item.typeColor }}>
              &nbsp;
            </div>
          )}
          <div className={"d-inline-block"}>{item.typeName}</div>
        </div>
      </td>
      <td className="order-search-result-info">
        <div className="table-to-card-label">
          <FormattedMessage id="info" />
        </div>
        <div className="table-to-card-content">
          <span className="order-search-result-info-item">
            <span className="order-search-result-info-item-label">
              <FormattedMessage id="catalog.number" />
            </span>
            <span className="order-search-result-info-item-value order-search-result-catalog-number">{item.catalogNumber}</span>
          </span>
          <div className="table-to-card-force-break">&nbsp;</div>
          <span className="order-search-result-info-item">
            <span className="order-search-result-info-item-label">
              <FormattedMessage id="grant.number" />
            </span>
            <span className="order-search-result-info-item-value order-search-result-grant-number">{item.grantNumber}</span>
          </span>
          <div className="table-to-card-force-break">&nbsp;</div>
          <span className="order-search-result-info-item">
            <span className="order-search-result-info-item-label">
              <FormattedMessage id="po.number" />
            </span>
            <span className="order-search-result-info-item-value order-search-result-po-number">{item.poNumber}</span>
          </span>
        </div>
      </td>
      <td className="order-search-result-vendor">
        <div className="table-to-card-label">
          <FormattedMessage id="vendor" />
        </div>
        <div className="table-to-card-content">{item.vendorName}</div>
      </td>
      <td className="order-search-result-price">
        <div className="table-to-card-label">
          <FormattedMessage id="price" />
        </div>
        <div className="table-to-card-content">
          <span className="order-search-result-price-total">
            <FormattedNumber style="currency" currency={currency} value={item.total} />
          </span>
          <span className="order-search-result-details">
            (
            <span className="order-search-result-price-each">
              <FormattedNumber style="currency" currency={currency} value={item.price} />
            </span>{" "}
            x{" "}
            <span className="order-search-result-quantity">
              <FormattedNumber value={item.quantity} />
            </span>
            )
          </span>
        </div>
      </td>
      <td className="order-search-result-date-requested">
        <div className="table-to-card-label">
          <FormattedMessage id="date.requested" />
        </div>
        <div className="table-to-card-content">
          <FormattedDate value={item.dateRequested} />
          <div className="table-to-card-force-break">&nbsp;</div>
          <span className="order-search-result-requested-by">{getUserName(item.requestedById)}</span>
        </div>
      </td>
      <td className="order-search-result-status">
        <div className="table-to-card-label">
          <FormattedMessage id="order.status" />
        </div>
        <div className="table-to-card-content">
          <span className="order-search-result-status-name">
            <OrderStatusLabel statusId={item.status} />
          </span>
          {statusDate && (
            <span className="order-search-result-status-date">
              <FormattedDate value={statusDate} />
            </span>
          )}
        </div>
      </td>
      <td className="order-search-result-action">
        {getActionButton(item)}
        <AuthorizedComponent requiredPermissions={[]} additionalChecks={userCanViewOrderCheck(item.requestedById)}>
          <Link to={ApplicationPaths.Orders.Order(item.id)} className="order-search-result-action-view">
            <FormattedMessage id="view" />
          </Link>
        </AuthorizedComponent>
      </td>
    </tr>
  );
}
