import Excel, { FillPattern, Style } from "exceljs";
import moment from "moment";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { ExcelSheet } from "./ExcelSheet";

export abstract class ExcelFile {
  protected sheets: ExcelSheet[] = [];

  protected static formatDate(date: Date | null): string {
    if (!date) {
      return "";
    }
    return moment(date).format(clock.STANDARD_MOMENT_DATE_FORMAT);
  }

  public Sheet(id: string | number): ExcelSheet | undefined {
    if (typeof id === "string") {
      return this.sheets.find((s) => s.Name === id);
    }
    return this.sheets[id];
  }

  public get Sheets(): ExcelSheet[] {
    return this.sheets;
  }

  public async AsBlob(): Promise<Blob> {
    const workbook = new Excel.Workbook();
    this.sheets.forEach((s) => {
      const sheet = workbook.addWorksheet(s.Name, s.TabColor ? { properties: { tabColor: { argb: s.TabColor } } } : {});

      sheet.columns = s.Columns.map((col) => {
        return { header: col, key: col.replace(" ", ""), width: col.length + 5 };
      });

      s.Columns.forEach((c, index) => {
        let style: Partial<Style> = { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFCCCCCC" }, bgColor: { argb: "FF000000" } } };
        const isRequired = s.IsColumnRequired(c);
        if (isRequired) {
          style = {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FF981B1E" }, bgColor: { argb: "FFFFFFFF" } },
            font: { color: { argb: "FFFFFFFF" } },
          };
        }

        sheet.getRow(1).getCell(index + 1).style = style;
      });
      sheet.addRows(s.Rows);

      s.MergedCells.forEach((c) => {
        sheet.mergeCells(c.Range);
        sheet.getCell(c.ContentCell).value = c.Content;
        let fill: FillPattern | undefined;
        if (c.IsHighlighted) {
          fill = { type: "pattern", pattern: "solid", bgColor: { argb: "FFFFFFE0" }, fgColor: { argb: "FFFFFFE0" } };
        }
        sheet.getCell(c.ContentCell).style = {
          fill,
          alignment: { vertical: "top", wrapText: true },
          font: { size: 14 },
        };
      });
    });

    // this would allow us to do a lookup of Types
    // for (let row = 2; row < 1002; row++) {
    // inventorySheet.getCell(`B${row}`).dataValidation = {
    // type: "list",
    // allowBlank: false,
    // formulae: ["Types!$A$2:$A$100"],
    // };
    // }
    const buffer = await workbook.xlsx.writeBuffer();
    return new Blob([buffer], { type: "application/octet-stream" });
  }
}
