import { InventorySearchAutoSuggestItem } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { InventoryApi } from "../../api/InventoryApi";

export interface InventoryAutocompleteHooks {
  isLoading: boolean;
  options: InventorySearchAutoSuggestItem[];

  onSearch(query: string): void;
}

export function useInventoryAutocomplete(api: InventoryApi): InventoryAutocompleteHooks {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<InventorySearchAutoSuggestItem[]>([]);

  function onSearch(query: string): void {
    setIsLoading(true);
    api
      .getInventorySuggestions(query, ["name", "catalogNumber", "typeName"])
      .then((results) => {
        setOptions(results);
        setIsLoading(false);
        return results;
      })
      .catch((error) => {
        setIsLoading(false);
        setOptions([]);
        // eslint-disable-next-line promise/no-nesting
        api.logError(error, "getInventorySuggestions").catch((_error) => {
          console.error(_error);
        });
      });
  }

  return {
    isLoading,
    options,
    onSearch,
  };
}
