import React, { ReactElement } from "react";
import { ManageInventoryTypeName } from "./ManageInventoryTypeName";
import { ManageInventoryTypeProps } from "./ManageInventoryTypeHooks";
import "./ManageInventoryType.scss";
import { ManageInventoryTypeCustomFields } from "./ManageInventoryTypeCustomFields";
import { ManageInventoryTypeStandardFields } from "./ManageInventoryTypeStandardFields";
import { CancelButton } from "../../components/CancelButton/CancelButton";

export function ManageInventoryType(props: ManageInventoryTypeProps): ReactElement {
  if (!props.inventoryTypeView) {
    return <div />;
  }

  return (
    <div>
      <ManageInventoryTypeName type={props.inventoryTypeView!} onSave={props.onUpdated} isNameValid={props.isNameValid} />
      <ManageInventoryTypeStandardFields {...props} />
      <ManageInventoryTypeCustomFields {...props} />
      <div className="management-edit-footer">
        <CancelButton onClick={props.onCancel} />
      </div>
    </div>
  );
}
