import React, { ReactElement } from "react";
import { Col, Row } from "reactstrap";
import { Route, Switch } from "react-router";
import { InventoryTypesProvider } from "../inventorytypes/InventoryTypesProvider";
import { RolesProvider } from "../roles/RolesProvider";
import { UsersProvider } from "../user/UsersProvider";
import { VendorsProvider } from "../vendors/VendorsProvider";
import { StorageProvider } from "../storage/StorageProvider";
import { NotificationsProvider } from "../notifications/NotificationsProvider";
import { Navigation } from "../components";
import { SubscriptionNotice } from "../subscription/notice/SubscriptionNotice";
import { MaintenanceNotice } from "../components/MaintenanceNotice/maintenanceNotice";
import { UserSpecificNotice } from "../components/UserSpecificMessage/UserSpecificNotice";
import { Logout } from "../components/Authentication/Logout";
import { SubscriptionManagement } from "../subscription/SubscriptionManagement";
import { DuplicateInventory } from "../inventory/DuplicateInventory";
import { AddInventory } from "../inventory/AddInventory";
import { UpdateInventory } from "../inventory/UpdateInventory";
import { ViewInventory } from "../inventory/ViewInventory";
import { InventorySearch } from "../inventory/search/InventorySearch";
import { OrderRequest } from "../orders/OrderRequest";
import { Order } from "../orders/Order";
import { OrderSearch } from "../orders/search/OrderSearch";
import { Management } from "../management/Management";
import { Notifications } from "../notifications/Notifications";
import { SuperUserToolsUserDetails } from "../superuser/SuperUserToolsUserDetails";
import { SuperUserToolsAccountDetails } from "../superuser/SuperUserToolsAccountDetails";
import { SuperUserTools } from "../superuser/SuperUserTools";
import { NotFound } from "../notfound/NotFound";
import { InventoryTour } from "../components/ApplicationTour/InventoryTour";
import { LoginNotificationModal } from "../components/LoginNotifications/LoginNotificationModal";
import { StorageBrowser } from "../storage/StorageBrowser";
import { Home } from "../home/Home";
import { Reports } from "../reports/Reports";
import { ApplicationPaths } from "./ApplicationPaths";

export function InventoryUserApp(): ReactElement {
  return (
    <InventoryTypesProvider>
      <RolesProvider>
        <UsersProvider>
          <VendorsProvider>
            <StorageProvider>
              <NotificationsProvider>
                <Navigation />
                <SubscriptionNotice />
                <MaintenanceNotice />
                <UserSpecificNotice />
                <main className="container-fluid" id={"main-content"} role={"main"}>
                  <Row>
                    <Col>
                      <Switch>
                        <Route exact path={ApplicationPaths.Routes.Home} component={Home} />
                        <Route exact path={ApplicationPaths.Routes.Logout} component={Logout} />
                        <Route exact path={ApplicationPaths.Subscription.Routes.Home} component={SubscriptionManagement} />
                        <Route exact path={ApplicationPaths.Inventory.Routes.Duplicate} component={DuplicateInventory} />
                        <Route exact path={ApplicationPaths.Inventory.Routes.Add} component={AddInventory} />
                        <Route exact path={ApplicationPaths.Inventory.Routes.Edit} component={UpdateInventory} />
                        <Route exact path={ApplicationPaths.Inventory.Routes.Item} component={ViewInventory} />
                        <Route exact path={ApplicationPaths.Inventory.Routes.Search} component={InventorySearch} />
                        <Route exact path={ApplicationPaths.Orders.Routes.ReorderItem} component={OrderRequest} />
                        <Route exact path={ApplicationPaths.Orders.Routes.Request} component={OrderRequest} />
                        <Route exact path={ApplicationPaths.Orders.Routes.Order} component={Order} />
                        <Route exact path={ApplicationPaths.Orders.Routes.Search} component={OrderSearch} />
                        <Route exact path={ApplicationPaths.Storage.Routes.Home} component={StorageBrowser} />
                        <Route exact path={ApplicationPaths.Storage.Routes.Browse} component={StorageBrowser} />
                        <Route path={ApplicationPaths.Management.Routes.Home} component={Management} />
                        <Route exact path={ApplicationPaths.Notifications.Routes.Home} component={Notifications} />
                        <Route exact path={ApplicationPaths.SuperUser.Routes.User} component={SuperUserToolsUserDetails} />
                        <Route exact path={ApplicationPaths.SuperUser.Routes.Account} component={SuperUserToolsAccountDetails} />
                        <Route exact path={ApplicationPaths.SuperUser.Routes.Home} component={SuperUserTools} />
                        <Route path={ApplicationPaths.Reports.Home} component={Reports} />
                        <Route component={NotFound} />
                      </Switch>
                    </Col>
                  </Row>
                  <InventoryTour />
                  <LoginNotificationModal />
                </main>
              </NotificationsProvider>
            </StorageProvider>
          </VendorsProvider>
        </UsersProvider>
      </RolesProvider>
    </InventoryTypesProvider>
  );
}
