import {
  SuperUserAccountActivityHistory,
  SuperUserAccountDetails,
  SuperUserSearchResultUser,
  SuperUserSummary,
  SuperUserUserDetails,
} from "@labarchives/inventory-shared/build/inventory/superuser/types";
import { SuperUserUserAccount } from "@labarchives/inventory-shared/build/inventory/superuser/SuperUserUserAccount";
import { SuperUserAccountUser } from "@labarchives/inventory-shared/build/inventory/superuser/SuperUserAccountUser";
import { OrganizationBillingType, SubscriptionType } from "@labarchives/inventory-shared/build/inventory";
import { SuperUserAccountSubscription } from "@labarchives/inventory-shared/build/inventory/superuser/SuperUserAccountSubscription";
import { SuperUserAccountTransaction } from "@labarchives/inventory-shared/build/inventory/superuser/SuperUserAccountTransaction";
import {
  SuperUserAccountActivityHistoryView,
  SuperUserAccountDetailsView,
  SuperUserAccountSubscriptionView,
  SuperUserAccountTransactionView,
  SuperUserAccountUserView,
  SuperUserSearchResultView,
  SuperUserSummaryView,
  SuperUserUserAccountView,
  SuperUserUserDetailsView,
} from "./views";

export function getSuperUserSummaryView(summary: SuperUserSummary): SuperUserSummaryView {
  return {
    items: summary.numberOfItems,
    orders: summary.numberOfOrders,
    users: summary.numberOfUsers,
    freeTrials: summary.numberOfFreeTrialSubscriptions,
    paidSubscriptions: summary.numberOfPaidSubscriptions,
    inventories: summary.numberOfInventories,
  };
}

export function getSuperUserSearchResultsView(results: SuperUserSearchResultUser[]): SuperUserSearchResultView[] {
  return results.map((r) => {
    return {
      id: r.id,
      email: r.email,
      first: r.firstName,
      last: r.lastName,
    };
  });
}

export function getDefaultSuperUserUserDetailsView(): SuperUserUserDetailsView {
  return { accounts: [], email: "", first: "", id: 0, last: "", fullName: "", isSiteAdmin: false };
}

export function getSuperUserUserAccountView(account: SuperUserUserAccount): SuperUserUserAccountView {
  return {
    id: account.id,
    name: account.name,
    dateCreated: account.dateCreated,
    dateUserAdded: account.dateUserAdded,
    subscriptionType: account.subscriptionType,
    seatCount: account.seatCount,
    ownerName: account.ownerName,
    ownerId: account.ownerId,
    roleName: account.roleName,
    status: account.status,
    hasSiteLicense: account.hasSiteLicense,
    subscriptionBegin: account.subscriptionBegin,
    subscriptionEnd: account.subscriptionEnd,
    permissions: account.permissions,
    isBundleSubscription: account.isBundleSubscription,
  };
}

export function getSuperUserUserDetailsView(userDetails: SuperUserUserDetails): SuperUserUserDetailsView {
  return {
    accounts: userDetails.accounts.map((a) => getSuperUserUserAccountView(a)).sort((a1, a2) => a1.name.localeCompare(a2.name)),
    email: userDetails.email,
    first: userDetails.first,
    id: userDetails.id,
    last: userDetails.last,
    fullName: `${userDetails.first} ${userDetails.last}`,
    isSiteAdmin: userDetails.isSiteAdmin,
  };
}

export function getDefaultSuperUserAccountDetailsView(): SuperUserAccountDetailsView {
  return {
    dateCreated: new Date(),
    hasSiteLicense: false,
    siteLicenseName: "",
    id: 0,
    name: "",
    ownerEmail: "",
    ownerId: 0,
    ownerName: "",
    subscriptionBegin: new Date(),
    subscriptionEnd: new Date(),
    subscriptionHistory: [],
    subscriptionSeatCount: 0,
    subscriptionType: SubscriptionType.Free,
    subscriptionId: 0,
    transactionHistory: [],
    users: [],
    activityHistory: [],
    numberOfOrders: 0,
    numberOfItems: 0,
    billingType: OrganizationBillingType.Commercial,
    notes: "",
    isBundleSubscription: false,
  };
}

export function getSuperUserSubscriptionHistoryView(history: SuperUserAccountSubscription): SuperUserAccountSubscriptionView {
  return {
    begin: history.begin,
    end: history.end,
    seatCount: history.seatCount,
    type: history.type,
    id: history.id,
    isBundleSubscription: history.isBundleSubscription,
  };
}

export function getSuperUserTransactionHistoryView(history: SuperUserAccountTransaction): SuperUserAccountTransactionView {
  return {
    seatCount: history.seatCount,
    action: history.action,
    date: history.date,
    perUserPrice: history.perUserPrice,
    totalPrice: history.totalPrice,
    transactionId: history.transactionId,
  };
}

export function getSuperUserAccountUserView(user: SuperUserAccountUser): SuperUserAccountUserView {
  return {
    dateAdded: user.dateAdded,
    email: user.email,
    first: user.first,
    last: user.last,
    fullName: `${user.first} ${user.last}`,
    id: user.id,
    roleName: user.roleName,
    status: user.status,
    isLinkable: user.id !== 0,
  };
}

export function getSuperUserAccountActivityHistoryView(history: SuperUserAccountActivityHistory): SuperUserAccountActivityHistoryView {
  return {
    date: history.date,
    description: history.description,
    id: `${history.id}${history.type}`,
    userId: history.userId,
    userName: history.userName,
    isLinkable: history.userId !== 0,
  };
}

export function getSuperUserAccountDetailsView(accountDetails: SuperUserAccountDetails): SuperUserAccountDetailsView {
  return {
    id: accountDetails.id,
    dateCreated: accountDetails.dateCreated,
    hasSiteLicense: accountDetails.hasSiteLicense,
    siteLicenseName: accountDetails.siteLicenseName,
    name: accountDetails.name,
    ownerEmail: accountDetails.ownerEmail,
    ownerId: accountDetails.ownerId,
    ownerName: accountDetails.ownerName,
    subscriptionBegin: accountDetails.subscriptionBegin,
    subscriptionEnd: accountDetails.subscriptionEnd,
    subscriptionSeatCount: accountDetails.subscriptionSeatCount,
    subscriptionId: accountDetails.subscriptionId,
    subscriptionType: accountDetails.subscriptionType,
    numberOfItems: accountDetails.inventoryItemCount,
    numberOfOrders: accountDetails.orderCount,
    billingType: accountDetails.billingType,
    subscriptionHistory: accountDetails.subscriptionHistory
      .map((h) => getSuperUserSubscriptionHistoryView(h))
      .sort((h1, h2) => h2.begin.getTime() - h1.begin.getTime()),
    transactionHistory: accountDetails.transactionHistory
      .map((h) => getSuperUserTransactionHistoryView(h))
      .sort((h1, h2) => h2.date.getTime() - h1.date.getTime()),
    users: accountDetails.users.map((u) => getSuperUserAccountUserView(u)).sort((u1, u2) => u1.last.localeCompare(u2.last)),
    activityHistory: accountDetails.activityHistory.map((h) => getSuperUserAccountActivityHistoryView(h)),
    notes: accountDetails.notes || "",
    isBundleSubscription: accountDetails.isBundleSubscription,
  };
}
