import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { Badge, Table } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { CustomAttributeDefinitionView } from "../../inventory/types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { ManageInventoryTypeAttribute } from "./ManageInventoryTypeAttribute";
import { ManageInventoryTypeProps, useManageInventoryTypeCustomFields } from "./ManageInventoryTypeHooks";
import { DeleteAttributePrompt } from "./DeleteAttributePrompt";

export function ManageInventoryTypeCustomFields(props: ManageInventoryTypeProps): ReactElement {
  const intl = useIntl();
  const {
    deletingAttribute,
    isAdding,
    onAttributeAdded,
    onAttributeUpdated,
    onAttributeDeleted,
    onDeleteAttributePrompt,
    onAttributeDeleteCancelled,
    onDeleteNewAttribute,
    onToggleAddAttribute,
    isAttributeValid,
  } = useManageInventoryTypeCustomFields(props);

  const emptyAttribute: CustomAttributeDefinitionView = {
    id: 0,
    label: "",
    required: false,
    possibleValues: [],
    type: CustomAttributeType.Textbox,
    inputId: "new",
  };
  const { attributes, name } = props.inventoryTypeView!;

  return (
    <>
      <div id={"manage-inventory-type-custom-attributes-section"} className={"management-container-contents management-panel"}>
        <div className="manage-inventory-attributes-header-container management-name-container">
          <div className={"d-flex"}>
            <div className="management-item-name">
              <FormattedMessage id="custom.fields" />
            </div>
            <div>
              <Badge role={"status"} aria-label={intl.formatMessage({ id: "manage.inventory.type.fields.badge" }, { count: attributes.length })}>
                {attributes.length}
              </Badge>
            </div>
            <div>
              <Button color="link" onClick={onToggleAddAttribute} className="ms-1" id={"show-add-custom-field-button"}>
                <FontAwesomeIcon icon={faPlusCircle} size="lg" className="me-1" />
                <FormattedMessage id="add.field" />
              </Button>
            </div>
          </div>
        </div>
        {(attributes.length > 0 || isAdding) && (
          <Table striped>
            <caption className={"visually-hidden"}>
              <FormattedMessage id="fields" />
            </caption>
            <thead>
              <tr>
                <th scope={"col"} className="manage-inventory-type-attribute-column-label">
                  <FormattedMessage id="label" />
                </th>
                <th scope={"col"} className="manage-inventory-type-attribute-column-type">
                  <FormattedMessage id="attribute.format" />
                </th>
                <th scope={"col"} className="manage-inventory-type-attribute-column-options">
                  <FormattedMessage id="options" />
                </th>
                <th scope={"col"} className="align-center manage-inventory-type-attribute-column-required">
                  <FormattedMessage id="is.required" />
                </th>
                <th scope={"col"} className="manage-inventory-type-attribute-column-actions">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {isAdding && (
                <ManageInventoryTypeAttribute
                  attribute={emptyAttribute}
                  onSaved={onAttributeAdded}
                  onDeleted={onDeleteNewAttribute}
                  onCancelEdit={onDeleteNewAttribute}
                  isEditing
                  isAttributeValid={isAttributeValid}
                />
              )}
              {attributes.map((attribute) => (
                <ManageInventoryTypeAttribute
                  key={attribute.id}
                  attribute={attribute}
                  onSaved={onAttributeUpdated}
                  onDeleted={onDeleteAttributePrompt}
                  isAttributeValid={isAttributeValid}
                />
              ))}
            </tbody>
          </Table>
        )}
      </div>
      <DeleteAttributePrompt
        attribute={deletingAttribute}
        isOpen={deletingAttribute !== undefined}
        onDelete={onAttributeDeleted}
        onCancel={onAttributeDeleteCancelled}
        typeName={name}
      />
    </>
  );
}
