import { InventoryTypeView } from "../../../inventory/types/views";
import { OrderFilterView } from "../../types/views";

export interface OrderSearchFilterHooks {
  onFilterCleared(): void;

  onChangedTypes(selectedTypeIds: number[]): void;

  onChangedStatuses(selectedStatusIds: number[]): void;

  onChangedOrderDates(start?: Date, end?: Date): void;
}

export interface OrderSearchFilterProps {
  inventoryTypeViews: InventoryTypeView[];
  filters: OrderFilterView;
  isShowingFilter: boolean;

  onFilterChanged(filters: OrderFilterView): void;

  onFilterCleared(): void;

  onFilterToggle(): void;
}

export function useOrderSearchFilter(props: OrderSearchFilterProps): OrderSearchFilterHooks {
  function onFilterCleared(): void {
    props.onFilterCleared();
  }

  function onChangedTypes(selectedTypeIds: number[]): void {
    const filter: OrderFilterView = { ...props.filters, typeIds: selectedTypeIds };
    props.onFilterChanged(filter);
  }

  function onChangedStatuses(selectedStatusIds: number[]): void {
    const filter: OrderFilterView = { ...props.filters, statusIds: selectedStatusIds };
    props.onFilterChanged(filter);
  }

  function onChangedOrderDates(start?: Date, end?: Date): void {
    const filter: OrderFilterView = { ...props.filters, orderStartDate: start, orderEndDate: end };
    props.onFilterChanged(filter);
  }

  return {
    onFilterCleared,
    onChangedTypes,
    onChangedStatuses,
    onChangedOrderDates,
  };
}
