import { StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { createContext, useEffect, useState } from "react";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { NormalizedState } from "../utils/types";
import { addIfNotExists, createReduced } from "../utils";
import { Settings } from "../utils/Settings";
import { InventoryApi } from "../api/InventoryApi";
import { StorageState } from "./types/state";

const normalizedState: NormalizedState<StorageLocation> = {
  isLoading: false,
  byId: createReduced<StorageLocation>([]),
  allIds: [],
};

export function useStorageContext(api: InventoryApi): StorageState {
  const [localState, setLocalState] = useState<NormalizedState<StorageLocation>>(normalizedState);
  const [lastDataRefreshTime, setLastDataRefreshTime] = useState<Date>(new Date(0));

  const loadAllLocations = async (): Promise<void> => {
    setLocalState({ ...localState, isLoading: true });
    api
      .getStorageLocations()
      .then((locations) => {
        setLocalState({ byId: createReduced(locations), allIds: locations.map((l) => l.id), isLoading: false });
        setLastDataRefreshTime(clock.getNow());
        return locations;
      })
      .catch((error) => api.logError(error));
  };

  function onLocationAdded(storageLocation: StorageLocation): Promise<StorageLocation> {
    return api
      .addStorageLocation(storageLocation)
      .then((added) => {
        const newState: NormalizedState<StorageLocation> = {
          allIds: addIfNotExists(localState.allIds, added.id),
          byId: { ...localState.byId, [added.id]: added },
          isLoading: false,
        };
        setLocalState(newState);
        return added;
      })
      .catch((error) => {
        loadAllLocations();
        throw error;
      });
  }

  function onLocationDeleted(storageLocation: StorageLocation): Promise<void> {
    return (
      api
        .deleteStorageLocation(storageLocation)
        // eslint-disable-next-line promise/always-return
        .then(() => {
          const newById = { ...localState.byId };
          delete newById[storageLocation.id];
          const newState: NormalizedState<StorageLocation> = {
            allIds: localState.allIds.filter((id) => id !== storageLocation.id),
            byId: newById,
            isLoading: false,
          };
          setLocalState(newState);
        })
        .catch((error) => {
          loadAllLocations();
          throw error;
        })
    );
  }

  function onLocationUpdated(storageLocation: StorageLocation): Promise<StorageLocation> {
    return api
      .updateStorageLocation(storageLocation)
      .then((updated) => {
        const newState: NormalizedState<StorageLocation> = {
          allIds: addIfNotExists(localState.allIds, updated.id),
          byId: { ...localState.byId, [updated.id]: updated },
          isLoading: false,
        };
        setLocalState(newState);
        return updated;
      })
      .catch((error) => {
        loadAllLocations();
        throw error;
      });
  }

  const refresh = async (force = false): Promise<void> => {
    if (force || (!localState.isLoading && clock.addMinutes(lastDataRefreshTime, Settings.getDataCacheTimeout()) < clock.getNow())) {
      await loadAllLocations();
    }
  };

  useEffect(() => {
    loadAllLocations();
  }, []);

  return {
    allIds: localState.allIds,
    byId: localState.byId,
    isLoading: localState.isLoading,
    onLocationAdded,
    onLocationUpdated,
    onLocationDeleted,
    refresh,
  };
}

const defaultEmptyLocation: StorageLocation = {
  id: 0,
  name: "",
  parentId: null,
  freezerBoxDisplayFormat: null,
  numberOfColumns: null,
  numberOfRows: null,
};

const defaultStorageState: StorageState = {
  ...normalizedState,
  onLocationAdded(): Promise<StorageLocation> {
    return Promise.resolve<StorageLocation>(defaultEmptyLocation);
  },
  onLocationDeleted(): Promise<void> {
    return Promise.resolve();
  },
  onLocationUpdated(): Promise<StorageLocation> {
    return Promise.resolve<StorageLocation>(defaultEmptyLocation);
  },
  refresh: () => Promise.resolve(),
};

export const StorageContext = createContext<StorageState>(defaultStorageState);
