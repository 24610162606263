import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, FormGroup, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { AlertWithIcon, Button } from "@labarchives/ui-design";
import "./InviteUsers.scss";
import { CancelButton } from "../../../components/CancelButton/CancelButton";
import { InviteUsersAutoSuggest } from "./InviteUsersAutoSuggest";
import { InviteUsersProps, useInviteUsers } from "./InviteUsersHooks";

export function InviteUsers(props: InviteUsersProps): ReactElement {
  const inviteMoreCounter: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const {
    isInvitingMore,
    invalidEmailIndexes,
    multipleEmailIndexes,
    duplicateEmailIndexes,
    isFormInvalid,
    onUsersInvited,
    onInviteMore,
    onEmailChanged,
    inviteeOptions,
  } = useInviteUsers(props);

  function onFormSubmitted(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    onUsersInvited();
  }

  return (
    <div className="invite-users-container">
      <AlertWithIcon isOpen={isFormInvalid} color="danger">
        <FormattedMessage id="invalid.invitee.emails" />
      </AlertWithIcon>
      <Form onSubmit={onFormSubmitted}>
        <FormGroup>
          <Label for="invite-email-address">
            <FormattedMessage id="email.address" />
          </Label>
          <InviteUsersAutoSuggest
            id="invite-email-address"
            autoFocus
            onChange={(e) => onEmailChanged(e, 0)}
            invalid={invalidEmailIndexes.includes(0)}
            options={inviteeOptions}
          />
          <div className="invalid-feedback" style={{ display: multipleEmailIndexes.includes(0) ? "inline-block" : "none" }}>
            <FormattedMessage id="multiple.invitee.email.error" />
          </div>
          <div className="invalid-feedback" style={{ display: invalidEmailIndexes.includes(0) ? "inline-block" : "none" }}>
            <FormattedMessage id="invalid.invitee.email" />
          </div>
          <div className="invalid-feedback" style={{ display: duplicateEmailIndexes.includes(0) ? "inline-block" : "none" }}>
            <FormattedMessage id="duplicate.invitee.email" />
          </div>
        </FormGroup>
        {isInvitingMore && (
          <div>
            {inviteMoreCounter.map((i) => (
              <div key={`invite-more-${i}`}>
                <FormGroup>
                  <Label for={`invite-email-address${i}`} className="visually-hidden">
                    <FormattedMessage id="email.address" />
                  </Label>
                  <InviteUsersAutoSuggest
                    id={`invite-email-address${i}`}
                    onChange={(e) => onEmailChanged(e, i)}
                    invalid={invalidEmailIndexes.includes(i)}
                    options={inviteeOptions}
                  />
                  <div className="invalid-feedback" style={{ display: multipleEmailIndexes.includes(i) ? "inline-block" : "none" }}>
                    <FormattedMessage id="multiple.invitee.email.error" />
                  </div>
                  <div className="invalid-feedback" style={{ display: invalidEmailIndexes.includes(i) ? "inline-block" : "none" }}>
                    <FormattedMessage id="invalid.invitee.email" />
                  </div>
                  <div className="invalid-feedback" style={{ display: duplicateEmailIndexes.includes(i) ? "inline-block" : "none" }}>
                    <FormattedMessage id="duplicate.invitee.email" />
                  </div>
                </FormGroup>
              </div>
            ))}
          </div>
        )}
        <Button type="submit" color="primary">
          <FontAwesomeIcon icon={faPaperPlane} /> <FormattedMessage id="send.invite" />
        </Button>
        <CancelButton className="ms-1" onClick={props.onCancelInvite} />
        <Button className="ms-1" color="none" outline onClick={onInviteMore}>
          <FormattedMessage id="invite.more.people" />
        </Button>
      </Form>
    </div>
  );
}
