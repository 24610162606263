import { FormattedMessage } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import React, { ReactElement } from "react";

interface InventoryPurchaseCardProps {
  numberOfUsers: number;
  minimumNumberOfUsers: number;
  callToActionTextId: string;
  perUserPrice: number;
  totalPrice: number;
  className?: string;
  onNumberOfUsersChanged(users: string): void;
  onCheckout(): Promise<void>;
}

export function InventoryPurchaseCard(props: InventoryPurchaseCardProps): ReactElement {
  const { numberOfUsers, minimumNumberOfUsers, callToActionTextId, perUserPrice, totalPrice, className, onNumberOfUsersChanged, onCheckout } = props;
  return (
    <div className={`subscription-pricing ${className || ""}`}>
      <div className="subscription-pricing-term">LabArchives Inventory</div>
      <div className={"subscription-pricing-details"}>
        <span className={"subscription-pricing-price-dollar"}>$</span>
        <span className="subscription-pricing-price">{perUserPrice}</span>
        <span className="subscription-pricing-expected-cost">
          ${totalPrice} <FormattedMessage id="per.year.for.users" values={{ users: numberOfUsers }} />
        </span>
        <div className="subscription-subscribe-now">
          <FormGroup className={"mb-4 align-center"}>
            <Input
              className={"me-2"}
              type={"number"}
              value={numberOfUsers}
              min={minimumNumberOfUsers}
              onChange={(e) => onNumberOfUsersChanged(e.target.value)}
              id={"subscription-inventory-user-count"}
            />
            <Label className={"align-self-end"} htmlFor={"subscription-inventory-user-count"}>
              {numberOfUsers === 1 && <FormattedMessage id={"user"} />}
              {numberOfUsers > 1 && <FormattedMessage id={"users"} />}
            </Label>
          </FormGroup>

          <Button onClick={() => onCheckout()} id={"subscription-inventory-purchase-btn"}>
            <FormattedMessage id={callToActionTextId} />
          </Button>
        </div>

        <div className={"subscription-features"}>
          <div>
            <FormattedMessage id={"includes"} />:
          </div>
          <ul>
            <li>
              <FormattedMessage id={"unlimited.inventory.items"} />
            </li>
            <li>
              <FormattedMessage id={"custom.inventory.types"} />
            </li>
            <li>
              <FormattedMessage id={"order.request.workflow"} />
            </li>
            <li>
              <FormattedMessage id={"manage.your.research.inventory.efficiently"} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
