import { FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { LaLoaderSpinner } from "@labarchives/ui-design/dist";
import "./Loading.scss";

interface LoadingProps {
  messageId?: string;
  className?: string;
  id?: string;
  hideMessage?: boolean;
}

export function Loading(props: LoadingProps): ReactElement {
  return (
    <div aria-live="assertive" role="alert" id={props.id} className={props.className ?? "loading"}>
      <LaLoaderSpinner width="24px" height="24px" className="me-2" />
      {!props.hideMessage && <FormattedMessage id={props.messageId ?? "loading"} />}
    </div>
  );
}
