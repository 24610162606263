import { FormGroup, Label } from "reactstrap";
import { Input } from "@labarchives/ui-design";
import React, { ReactElement } from "react";
import { CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { InventoryAdvancedSearchTypeComponentProps } from "./InventoryAdvancedSearchTypeComponentProps";
import { InventoryAdvanceSearchMatchDropdown } from "./InventoryAdvanceSearchMatchDropdown";

export function InventoryAdvancedSearchTextbox(props: InventoryAdvancedSearchTypeComponentProps): ReactElement {
  return (
    <>
      <InventoryAdvanceSearchMatchDropdown
        index={props.index}
        matchType={props.matchType}
        onMatchTypeChanged={props.onMatchTypeChanged}
        attributeType={CustomAttributeType.Textbox}
      />
      <div className={"criteria-value"}>
        <FormGroup>
          <Label className="visually-hidden" for={`value${props.index}`}>
            Search Value
          </Label>
          <Input
            id={`value${props.index}`}
            bsSize="sm"
            defaultValue={props.defaultValue}
            type="text"
            onChange={(e) => props.onValueChanged(e.target.value)}
          />
        </FormGroup>
      </div>
    </>
  );
}
