import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { StorageLocationTreeView } from "../../../storage/types/views";
import { ApplicationPaths } from "../../../app/ApplicationPaths";
import { CheckboxTree } from "../../../components/CheckboxTree/CheckboxTree";
import { InventorySearchStorageLocationFilterProps, useInventorySearchStorageFilter } from "./InventorySearchStorageLocationFilterHooks";
import { TreeData } from "../../../components/CheckboxTree/TreeData";

export function InventorySearchStorageLocationFilter(props: InventorySearchStorageLocationFilterProps): ReactElement {
  const { selectedLocationIds, onCheck, isStorageLinkShown, isNoLocationSelected, onNoLocationChanged } = useInventorySearchStorageFilter(props);

  function asTreeNode(location: StorageLocationTreeView): TreeData {
    return {
      name: `${location.location.name}`,
      value: `${location.location.id.toString()}`,
      label: <span>{location.location.name}</span>,
      children: location.children.map((c) => asTreeNode(c)),
      data: location,
    };
  }

  function asTreeNodes(): TreeData[] {
    return props.locations.map((location) => asTreeNode(location));
  }

  return (
    <div>
      {isStorageLinkShown && (
        <div>
          <Link to={ApplicationPaths.Storage.Home}>
            <FormattedMessage id={"browse.freezer.boxes"} /> <FontAwesomeIcon icon={faExternalLink} />
          </Link>
        </div>
      )}
      <CheckboxTree
        data={asTreeNodes()}
        onChecked={(checkedIds) => onCheck(checkedIds)}
        defaultExpanded={[]}
        defaultChecked={selectedLocationIds.map((l) => l.toString())}
      />
      <div>
        <CheckboxLabel>
          <CheckboxInput onChange={(e) => onNoLocationChanged(e.target.checked)} uncheckedValue="0" checked={isNoLocationSelected} />
          <span>
            <FormattedMessage id={"no.location.set"} />
          </span>
        </CheckboxLabel>
      </div>
    </div>
  );
}
