import { useState } from "react";
import bwipjs from "bwip-js";
import FileSaver from "file-saver";
import { InventoryItemView } from "./types/views";

export type DateFormatter = (date: Date) => string;

export function useLabelPrintingHooks(currentItemView: InventoryItemView, logError: (error: any) => Promise<void>): InventoryLabelPrintingHooks {
  const [showDymoDirections, setShowDymoDirections] = useState(false);
  const [showDymoLabels, setShowDymoLabels] = useState(false);
  const [dymoError, setDymoError] = useState<Error | null>(null);
  const [arePrintersLoading, setArePrintersLoading] = useState(false);

  function handleDymoError(error: Error | unknown): void {
    setShowDymoLabels(false);
    setShowDymoDirections(true);
    setDymoError(error instanceof Error ? error : null);
    logError(error);
  }

  function generateLabels(formatDate: DateFormatter): void {
    const line1 = currentItemView.name;
    const line2 = [currentItemView.typeName, currentItemView.vendorName, currentItemView.catalogNumber].join(" ");
    let line3 = ``;
    if (currentItemView.expirationDate) {
      line3 = `exp ${formatDate(currentItemView.expirationDate)}`;
    }
    const infoCanvas = document.querySelector<HTMLCanvasElement>("#info-canvas");
    if (infoCanvas) {
      // @ts-ignore
      infoCanvas.height = 38;
      // @ts-ignore
      infoCanvas.width = 132;
      // @ts-ignore
      const ctx = infoCanvas.getContext("2d");
      if (ctx) {
        ctx.font = "12px Arial";
        ctx.fillText(line1, 0, 10);
        ctx.fillText(line2, 0, 23);
        ctx.fillText(line3, 0, 36);
      }
    }

    // @ts-ignore
    bwipjs.toCanvas("qrcode-canvas", {
      bcid: "qrcode", // Barcode type
      text: `item ${currentItemView.id}`, // Text to encode
      scale: 1, // 3x scaling factor

      includetext: false, // Show human-readable text
      textxalign: "center", // Always good to set this
    });

    const infocopy = document.querySelector<HTMLButtonElement>("#info-copy");
    const infocopycanvas = document.querySelector<HTMLButtonElement>("#info-copy-canvas");
    const infotext = [line1, line2, line3].join(" ");
    if (infocopy) {
      infocopy.addEventListener("click", () => {
        navigator.clipboard.writeText(infotext);
      });
    }
    if (infocopycanvas) {
      infocopycanvas.addEventListener("click", () => {
        navigator.clipboard.writeText(infotext);
      });
    }
  }

  function onQRDownloaded(): void {
    const qrcode = document.querySelector<HTMLCanvasElement>("#qrcode-canvas");
    if (qrcode) {
      FileSaver.saveAs(qrcode.toDataURL("image/jpg"), `${currentItemView.name}.jpg`);
    }
  }

  function printLabel(): void {
    try {
      const info = document.querySelector<HTMLCanvasElement>("#info-canvas");
      const qrcode = document.querySelector<HTMLCanvasElement>("#qrcode-canvas");
      const printer = document.querySelector<HTMLInputElement>("#printersSelect");
      const label = document.querySelector<HTMLInputElement>("#labelsSelect");

      if (info && qrcode && printer && printer.value && label && label.value) {
        // @ts-ignore
        window.printLabel(
          // @ts-ignore
          label.value,
          // @ts-ignore
          printer.value,
          // @ts-ignore
          info.toDataURL("image/png").replace("data:image/png;base64,", ""),
          // @ts-ignore
          qrcode.toDataURL("image/png").replace("data:image/png;base64,", ""),
        );
      }
      setShowDymoDirections(false);
    } catch (error) {
      handleDymoError(error);
    }
  }

  function loadPrinters(): void {
    setDymoError(null);
    setShowDymoLabels(true);
    setShowDymoDirections(false);
    setArePrintersLoading(true);

    window.setTimeout(() => {
      try {
        // @ts-ignore
        window.loadPrinters(document.querySelector("#printersSelect"));
        setArePrintersLoading(false);
      } catch (error) {
        setArePrintersLoading(false);
        handleDymoError(error);
      }
    }, 10);
  }

  return {
    showDymoDirections,
    showDymoLabels,
    dymoError,
    arePrintersLoading,
    generateLabels,
    printLabel,
    loadPrinters,
    onQRDownloaded,
  };
}

export interface InventoryLabelPrintingHooks {
  showDymoDirections: boolean;
  showDymoLabels: boolean;
  dymoError: Error | null;
  arePrintersLoading: boolean;

  generateLabels(formatDate: DateFormatter): void;

  printLabel(): void;

  loadPrinters(): void;

  onQRDownloaded(): void;
}
