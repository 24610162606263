import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Col, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { Loading } from "../components";
import { SuperUserAccountDetailsView, SuperUserSearchResultView } from "./views";

export interface SuperUserAddUserModalProps {
  isOpen: boolean;
  isSearching: boolean;
  account: SuperUserAccountDetailsView;
  userSearchResults: SuperUserSearchResultView[];

  onToggle(): void;

  onSearchUsers(term: string): void;

  onUserAdded(userId: number, isLabManager: boolean): void;
}

export function SuperUserAddUserModal(props: SuperUserAddUserModalProps): ReactElement {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLabManager, setIsLabManager] = useState(false);
  const [userId, setUserId] = useState(0);

  const userSelected = useMemo(() => {
    return userId !== 0;
  }, [userId]);

  useEffect(() => {
    setSearchTerm("");
    setIsLabManager(false);
    setUserId(0);
  }, [props.isOpen]);

  useEffect(() => {
    setUserId(0);
  }, [props.userSearchResults]);

  function onUserAdded(): void {
    if (userSelected) {
      props.onUserAdded(userId, isLabManager);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle}>
      <ModalHeader toggle={props.onToggle}>Add User to Inventory</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setUserId(0);
                  props.onSearchUsers(searchTerm);
                }}
              >
                <div className={"d-flex align-content-end align-items-end"}>
                  <div className={"flex-grow-1"}>
                    <div className={"mb-0 me-2"}>
                      <Label for="new-user-email">Search for a user</Label>
                      <Input type="search" id="new-user-email" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} autoFocus />
                    </div>
                  </div>
                  <div>
                    <Button type={"submit"} disabled={searchTerm.trim() === ""}>
                      Search
                    </Button>
                  </div>
                </div>
              </Form>
            </div>

            {props.isSearching && <Loading />}

            {props.userSearchResults.length > 0 && (
              <div className={"mt-3 ms-4"}>
                {props.userSearchResults.map((u) => (
                  <FormGroup key={u.id} check>
                    <Input
                      id={`add-user-${u.id}`}
                      value={u.id}
                      type={"radio"}
                      onChange={(e) => setUserId(Number.parseInt(e.target.value))}
                      checked={u.id === userId}
                    />
                    <Label check htmlFor={`add-user-${u.id}`}>
                      {u.first} {u.last} ({u.email})
                    </Label>
                  </FormGroup>
                ))}
              </div>
            )}

            {userSelected && (
              <FormGroup>
                <Label for="add-user-role">Role</Label>
                <Input type="select" id="add-user-role" defaultValue={"0"} onChange={(e) => setIsLabManager(e.target.value === "1")}>
                  <option value={"0"}>Lab Member</option>
                  <option value={"1"}>Lab Manager</option>
                </Input>
              </FormGroup>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="me-1" disabled={!userSelected} onClick={onUserAdded}>
          Add User
        </Button>
        <CancelButton onClick={props.onToggle} />
      </ModalFooter>
    </Modal>
  );
}
