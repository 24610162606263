import React, { ReactElement, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { VendorsContext } from "../VendorsContext";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { ManageVendorProps, useManageVendor } from "./ManageVendorHooks";

export function ManageVendor(props: ManageVendorProps): ReactElement {
  const { onNameChange, onUrlChange, onContactInfoChange, onNotesChange, onSave, isNameValid, isUrlValid, isNameDuplicated } = useManageVendor(
    props,
    useContext(VendorsContext),
  );
  const nameRef = React.createRef<HTMLInputElement>();
  const urlRef = React.createRef<HTMLInputElement>();

  function highlightInvalid(nameValid: boolean, urlValid: boolean): void {
    let firstInvalid: React.RefObject<HTMLInputElement> | undefined;
    if (!urlValid) {
      firstInvalid = urlRef;
    }
    if (!nameValid) {
      firstInvalid = nameRef;
    }
    if (firstInvalid && firstInvalid.current) {
      window.scrollTo({ top: firstInvalid.current.offsetTop });
      firstInvalid.current.focus();
    }
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    onSave(highlightInvalid);
  }

  return (
    <div>
      <Form onSubmit={onSubmit} noValidate>
        <FormGroup>
          <Label for="management-edit-name">
            <FormattedMessage id="name" />
            <RequiredLabelIndicator required={true} />
          </Label>
          <Input
            id="management-edit-name"
            type="text"
            defaultValue={props.vendorView.name}
            onChange={(e) => onNameChange(e.target.value)}
            autoFocus
            required
            invalid={!isNameValid}
            innerRef={nameRef}
            maxLength={255}
          />
          <FormFeedback>
            <FormattedMessage id="name.is.required" />
          </FormFeedback>
          <div className="invalid-feedback" style={{ display: isNameDuplicated ? "inline-block" : "none" }}>
            {" "}
            <FormattedMessage id="duplicate.vendor.name" />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="manage-vendor-url">
            <FormattedMessage id="website" />
          </Label>
          <Input
            id="manage-vendor-url"
            type="url"
            defaultValue={props.vendorView.url}
            onChange={(e) => onUrlChange(e.target.value)}
            invalid={!isUrlValid}
            innerRef={urlRef}
          />
          <FormFeedback>
            <FormattedMessage id="invalid.url" />
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="manage-vendor-contact">
            <FormattedMessage id="contact.info" />
          </Label>
          <Input
            id="manage-vendor-contact"
            type="textarea"
            defaultValue={props.vendorView.contactInfo}
            onChange={(e) => onContactInfoChange(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="manage-vendor-notes">
            <FormattedMessage id="notes" />
          </Label>
          <Input id="manage-vendor-notes" type="textarea" defaultValue={props.vendorView.notes} onChange={(e) => onNotesChange(e.target.value)} />
        </FormGroup>
        <div className="management-edit-footer">
          <Button type="submit" className="me-1" color="primary">
            <FormattedMessage id={props.saveButtonId} />
          </Button>
          <CancelButton onClick={props.onCancel} />
        </div>
      </Form>
    </div>
  );
}
