import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useContext } from "react";
import { AlertWithIcon, Button } from "@labarchives/ui-design";
import { Col, FormGroup, Row } from "reactstrap";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { useDropzone } from "react-dropzone";
import "./BulkInventoryUpdate.scss";
import { Loading } from "../../components";
import { getInventoryTypeViews } from "../../inventorytypes/selectors";
import { InventoryTypesContext } from "../../inventorytypes/InventoryTypesContext";
import { StorageContext } from "../../storage/StorageContext";
import { getFormattedFreezerBoxCells, getStorageLocationView } from "../../storage/selectors";
import { getUserViews } from "../../user/selectors";
import { UsersContext } from "../../user/UsersContext";
import { RolesContext } from "../../roles/RolesContext";
import { InventoryApiClient } from "../../api";
import { useBulkInventoryUpdateComponent } from "./BulkInventoryUpdateComponentHooks";

export function BulkInventoryUpdateComponent(): ReactElement {
  const {
    isConsentChecked,
    showTemplateStep,
    showUpload,
    showPreUploadConfirmation,
    showError,
    showImportConfirmation,
    showImportConflicts,
    parseResult,
    updateResult,
    isParsing,
    isExporting,
    isUpdating,
    isIgnoreQuantityChecked,
    onConsentChecked,
    onImportUploaded,
    onRestart,
    onDownloadTemplate,
    onImport,
    onDownloadConflicts,
    onIgnoreQuantityChanged,
  } = useBulkInventoryUpdateComponent({
    inventoryTypeViews: getInventoryTypeViews(useContext(InventoryTypesContext)),
    storageLocations: getStorageLocationView(useContext(StorageContext)),
    userViews: getUserViews(useContext(UsersContext), useContext(RolesContext)),
    freezerBoxCellFormatter: getFormattedFreezerBoxCells,
    api: new InventoryApiClient(),
  });

  const intl = useIntl();

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/vnd.ms-excel": [".xls", ".xlsx"] },
    multiple: false,
    onDropAccepted: onImportUploaded,
  });
  const dropzoneDivProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = { ...getRootProps() };

  return (
    <div>
      <div className="management-header justify-content-between">
        <h1 className="inventory-heading">
          <FormattedMessage id="bulk.inventory.update" />
        </h1>
      </div>
      <Row>
        <Col>
          <div>
            {(isParsing || isExporting || isUpdating) && (
              <div>
                <Loading />
              </div>
            )}

            {showTemplateStep && (
              <div>
                <div id={"bulk-update-template"} className={"bulk-update-container"}>
                  <div className={"row"}>
                    <div className={"col"}>
                      <FormattedHTMLMessage id={"inventory.bulk.update.instructions"} />
                    </div>

                    <div className={"col me-3"}>
                      <div className={"col me-3 alert alert-info"}>
                        <FormattedMessage id={"inventory.bulk.update.tip"} />
                      </div>
                    </div>
                  </div>

                  <div>
                    <FormGroup>
                      <CheckboxLabel>
                        <CheckboxInput
                          id="bulk-inventory-update-step-consent-checkbox"
                          onChange={(e) => onConsentChecked(e.target.checked)}
                          uncheckedValue="0"
                          checked={isConsentChecked}
                        />
                        <span>
                          <FormattedMessage id={"inventory.bulk.update.i.understand"} />
                        </span>
                      </CheckboxLabel>
                    </FormGroup>
                  </div>
                </div>
              </div>
            )}

            {showUpload && (
              <div id={"bulk-update-actions"} className={"bulk-update-actions"}>
                <div className="bulk-inventory-update-download-template">
                  <Button onClick={() => onDownloadTemplate()} color="primary" id={"bulk-inventory-update-download-template-button"}>
                    <FormattedMessage id="download.template" />
                  </Button>
                </div>
                <section className={"mt-3"}>
                  <div className="dropzone" {...dropzoneDivProps}>
                    <input id="bulk-inventory-update-file" {...getInputProps()} />
                    <div>
                      <FormattedMessage id="upload.import.prompt" />
                    </div>
                    <div>
                      <img src="/images/Inventory_Import.svg" className="svg-inline--fa fa-2x" alt={intl.formatMessage({ id: "import.items" })} />
                    </div>
                  </div>
                </section>
              </div>
            )}

            {showPreUploadConfirmation && (
              <div id={"bulk-update-parse-confirmation"} className={"bulk-update-container"}>
                <div className={"mb-3"}>
                  <FormattedMessage id={"inventory.bulk.update.total.items"} values={{ total: parseResult.items.length }} />
                </div>

                <div className={"mb-3"}>
                  <FormGroup>
                    <CheckboxLabel>
                      <CheckboxInput
                        id="bulk-inventory-update-ignore-quantity"
                        onChange={(e) => onIgnoreQuantityChanged(e.target.checked)}
                        uncheckedValue="0"
                        checked={isIgnoreQuantityChecked}
                      />
                      <span>
                        <FormattedMessage id={"update.items.not.quantity"} />
                      </span>
                    </CheckboxLabel>
                  </FormGroup>
                </div>

                <div>
                  <Button onClick={() => onImport()} color="primary" id={"bulk-inventory-update-start-upload-button"}>
                    <FormattedMessage id="start.import" />
                  </Button>

                  <Button className={"ms-5"} onClick={() => onRestart()} color={"link"} id={"bulk-inventory-update-choose-another-file-button"}>
                    <FormattedMessage id="choose.another.file" />
                  </Button>
                </div>
              </div>
            )}

            {showError && (
              <div id={"bulk-update-errors"} className={"bulk-update-container"}>
                <div>
                  <AlertWithIcon color="danger" className="bulk-inventory-update-parse-errors" fade={false}>
                    {parseResult.errors.map((e, index) => (
                      <ul key={`${e.id}${index}`}>
                        <li>
                          <FormattedHTMLMessage id={e.id} values={e.values} />
                        </li>
                      </ul>
                    ))}
                    <div className="bulk-inventory-update-parse-error-retry">
                      <FormattedMessage id="inventory.upload.error.retry" />
                    </div>
                  </AlertWithIcon>
                </div>

                <div className={"mt-3"}>
                  <Button onClick={() => onRestart()} color={"link"} id={"bulk-inventory-update-restart-button"}>
                    <FormattedMessage id="choose.another.file" />
                  </Button>
                </div>
              </div>
            )}

            {showImportConfirmation && (
              <div id={"bulk-update-import-confirmation"} className={"bulk-update-container"}>
                <div>
                  <FormattedMessage id={"inventory.bulk.update.conflicts.success"} />
                </div>
              </div>
            )}
          </div>
        </Col>

        {showImportConflicts && (
          <Col sm={12} className={"mt-3"}>
            <div id={"bulk-update-import-conflicts"} className={"bulk-update-container"}>
              <div>
                <FormattedMessage
                  id={"inventory.bulk.update.conflicts.alert"}
                  values={{
                    numberOfConflicts: updateResult.numberOfConflicts,
                    totalItems: updateResult.numberImported + updateResult.numberOfConflicts,
                  }}
                />
              </div>
              <div>
                <Button
                  className={"mt-2"}
                  onClick={() => onDownloadConflicts(updateResult.referenceNumber)}
                  id={"bulk-inventory-update-download-conflicts-button"}
                >
                  <FormattedMessage id={"download.failed.update.report"} />
                </Button>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
