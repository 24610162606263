import { SiteLocations } from "@labarchives/inventory-shared/build/inventory";

export interface SiteLocationView {
  id: number;
  name: string;
}

export function getSiteLocationBuildingViews(locations: SiteLocations): SiteLocationView[] {
  return [...locations.buildings]
    .sort((b1, b2) => b1.name.localeCompare(b2.name))
    .map((b) => {
      return { id: b.id, name: b.name };
    });
}

export function getSiteLocationFloorViews(locations: SiteLocations, buildingId: number | undefined): SiteLocationView[] {
  return locations.floors
    .filter((f) => f.buildingId === buildingId)
    .sort((f1, f2) => f1.name.localeCompare(f2.name))
    .map((f) => {
      return { id: f.id, name: f.name };
    });
}

export function getSiteLocationRoomViews(locations: SiteLocations, buildingId: number | undefined, floorId: number | undefined): SiteLocationView[] {
  return locations.rooms
    .filter((r) => (r.buildingId === buildingId && !r.floorId && !floorId) || (r.buildingId === buildingId && r.floorId === floorId))
    .sort((r1, r2) => r1.name.localeCompare(r2.name))
    .map((r) => {
      return { id: r.id, name: r.name };
    });
}
