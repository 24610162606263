import { FormattedMessage } from "react-intl";
import React, { Fragment, ReactElement } from "react";
import { Col, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import "./AddSubscriptionUsersModal.scss";
import { InventoryApi } from "../../api/InventoryApi";
import { useAddSubscriptionUsers } from "./AddSubscriptionUsersHooks";

export interface AddSubscriptionUsersProps {
  isOpen: boolean;
  api: InventoryApi;

  toggleModal(): void;
}

export const AddSubscriptionUsersModal = (props: AddSubscriptionUsersProps): ReactElement => {
  const { numberOfUsers, subscriptionTotal, setUsers, isPurchasing, onCheckout } = useAddSubscriptionUsers({ isOpen: props.isOpen, api: props.api });

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggleModal}>
      <ModalHeader toggle={props.toggleModal}>
        <FormattedMessage id="add.users.to.subscription" />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div>
              <FormGroup>
                <Label>
                  <FormattedMessage id="users.pluralized" values={{ users: numberOfUsers }} />
                </Label>
                <Input type="number" min={1} step={1} value={numberOfUsers} onChange={(e) => setUsers(Number.parseInt(e.target.value))} />
              </FormGroup>
            </div>
          </Col>

          <Col>
            <div className="add-subscription-users-total">${subscriptionTotal.total}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="add-subscription-users-description">{subscriptionTotal.description}</div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {isPurchasing && <div>Redirecting to secure checkout...</div>}
        {!isPurchasing && (
          <Fragment>
            <Button onClick={() => onCheckout()}>
              <FormattedMessage id="checkout" />
            </Button>
            <CancelButton onClick={props.toggleModal} />
          </Fragment>
        )}
      </ModalFooter>
    </Modal>
  );
};
