import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { EditableStorageCellsRow } from "./EditableStorageCellsRow";
import { EditableStorageCellsProps, useEditableStorageCells } from "./EditableStorageCellsHooks";

export function EditableStorageCells(props: EditableStorageCellsProps): ReactElement {
  const {
    allSelected,
    rows,
    cols,
    selectedCells,
    occupiedCells,
    wasOriginallySelected,
    isCellSelected,
    isCellOccupied,
    updateCellStates,
    onCellClick,
    onCellClickOnly,
    onSelectAllCheckboxClick,
  } = useEditableStorageCells(props);

  const idPrefix = props.idPrefix || "";

  if (props.matrixRows !== 0 && props.matrixCols !== 0) {
    return (
      <div>
        <div className="label-secondary">
          <FormattedMessage id="location" />
        </div>
        <div className="label-select-all">
          <CheckboxLabel>
            <CheckboxInput
              id={`${idPrefix}selectAll`}
              onChange={(e) => onSelectAllCheckboxClick(e.target.checked)}
              uncheckedValue="0"
              checked={allSelected}
            />
            <span>
              <FormattedMessage id="select.all" />
            </span>
          </CheckboxLabel>
        </div>
        <div className="storage-table">
          {rows.map((r) => (
            <EditableStorageCellsRow
              key={`row${r}`}
              selectedCells={selectedCells}
              occupiedCells={occupiedCells}
              matrixRows={props.matrixRows}
              matrixCols={props.matrixCols}
              displayFormat={props.displayFormat}
              rowNumber={r}
              columns={cols}
              wasOriginallySelected={wasOriginallySelected}
              isCellSelected={isCellSelected}
              isCellOccupied={isCellOccupied}
              updateCellStates={updateCellStates}
              onCellClick={onCellClick}
              onCellClickOnly={onCellClickOnly}
              onSelectedCellsChange={props.onSelectedCellsChange}
            />
          ))}
        </div>
      </div>
    );
  }

  return <></>;
}
