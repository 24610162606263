export enum NotificationType {
  ReorderNeeded = 1,
  OrderRequested = 2,
  OrderRequestApproved = 3,
  OrderPlaced = 4,
  OrderReceived = 5,
  OrderCancelled = 6,
  ExpirationDateApproaching = 7,
  InventoryImportCompleted = 8,
  OrderReceivedAndDelegatedToRequester = 9,
  OrderReceivedAndDelegatedToThirdParty = 10,
}
