import React, { ReactElement } from "react";
import { Col, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FormattedHTMLMessage, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { AlertWithIcon, Button, Modal } from "@labarchives/ui-design/dist";
import { Loading } from "../../components";
import { OrderModalProps } from "../types/props";
import "./OrderModal.scss";
import { CancelButton } from "../../components/CancelButton/CancelButton";

interface ModalApprovalProps extends OrderModalProps {
  onOrderApproval(orderId: number, notes: string): void;
  orderId: number;
  requestedByName: string;
  notes: string;
  quantityOnHand: number;
  dateLastOrdered: Date | null;
  price: number;
  quantity: number;
  total: number;
  inventoryName: string;
  currency: string;
}

export function ModalApproval(props: ModalApprovalProps): ReactElement {
  let notesRef: HTMLInputElement;
  const intl = useIntl();

  const {
    currency,
    dateLastOrdered,
    errorMessage,
    inventoryName,
    isModalOpen,
    isProcessing,
    modalId,
    notes,
    onOrderApproval,
    orderId,
    price,
    quantity,
    quantityOnHand,
    requestedByName,
    toggleModal,
    total,
  } = props;

  const notesRow =
    notes.length > 0 ? (
      <Row>
        <Col>
          <div className="order-modal-notes-from">
            <FormattedMessage id="order.request.notes.from" values={{ name: requestedByName }} />
          </div>
          <div className="order-modal-notes-content">{notes}</div>
        </Col>
      </Row>
    ) : (
      <></>
    );

  let modalBody = (
    <>
      <Row>
        <Col>
          <div className="order-modal-details">
            <FormattedHTMLMessage id="order.search.quantity.in.stock" values={{ onHand: quantityOnHand, className: "order-modal-important" }} />
          </div>
          {dateLastOrdered && (
            <div className="order-modal-details">
              <FormattedHTMLMessage
                id="order.search.last.ordered"
                values={{
                  lastOrdered: intl.formatDate(dateLastOrdered),
                  className: "order-modal-important",
                }}
              />
            </div>
          )}
        </Col>
        <Col className="order-modal-price-container">
          <div className="order-modal-price-summary-container">
            <FormattedNumber style="currency" currency={currency} value={price} />
            {" x "}
            <FormattedNumber value={quantity} />
          </div>
          <div className="order-modal-important">
            <FormattedNumber style="currency" currency={currency} value={total} />
          </div>
        </Col>
      </Row>
      {notesRow}
      <Row>
        <Col className="order-modal-notes-container">
          <Label for="order-modal-notes">
            <FormattedMessage id="add.notes" />
          </Label>
          <Input
            type="textarea"
            id="order-modal-notes"
            innerRef={(node: HTMLInputElement) => {
              notesRef = node;
            }}
          />
        </Col>
      </Row>
    </>
  );

  let actionRow = (
    <div>
      <Button color="primary" onClick={() => onOrderApproval(orderId, notesRef.value)} className="me-1" id="order-modal-approve-button">
        <FormattedMessage id="approve" />
      </Button>
      <CancelButton className="order-modal-cancel" onClick={() => toggleModal(modalId)} />
    </div>
  );

  if (isProcessing) {
    actionRow = (
      <div>
        <Loading messageId="updating.order" />
      </div>
    );
  }

  if (errorMessage.length > 0) {
    modalBody = (
      <Row>
        <Col>
          <div>
            <AlertWithIcon color="danger">
              <FormattedMessage id="error.marking.approved" />
            </AlertWithIcon>
          </div>
          <div>{errorMessage}</div>
        </Col>
      </Row>
    );
    actionRow = (
      <div>
        <Button color="none" outline onClick={() => toggleModal(modalId)}>
          <FormattedMessage id="close" />
        </Button>
      </div>
    );
  }

  return (
    <Modal id={modalId} isOpen={isModalOpen(modalId)} toggle={() => toggleModal(modalId)} className="order-modal-modal">
      <ModalHeader toggle={() => toggleModal(modalId)}>
        <FormattedMessage id="approve" />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <span className="order-modal-title">{inventoryName}</span>
          </Col>
        </Row>
        {modalBody}
      </ModalBody>
      <ModalFooter>{actionRow}</ModalFooter>
    </Modal>
  );
}
