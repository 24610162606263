/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faMinusSquare, faSquare } from "@fortawesome/pro-light-svg-icons";

export const CheckBoxIcon = (checkboxIconProps: { variant: "all" | "none" | "some"; onClick(e: any): void }): ReactElement => {
  switch (checkboxIconProps.variant) {
    case "all": {
      return <FontAwesomeIcon icon={faCheckSquare} onClick={checkboxIconProps.onClick} />;
    }
    case "none": {
      return <FontAwesomeIcon icon={faSquare} onClick={checkboxIconProps.onClick} />;
    }
    case "some": {
      return <FontAwesomeIcon icon={faMinusSquare} onClick={checkboxIconProps.onClick} />;
    }
    default: {
      return <></>;
    }
  }
};
