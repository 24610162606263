import { useIntl } from "react-intl";
import React, { ReactElement, useContext } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { VendorsContext } from "../VendorsContext";
import { VendorView } from "../../orders/types/views";
import { CreatableVendorsDropdownProps, useCreatableVendorsDropdown } from "./CreatableVendorsDropdownHooks";

export function CreatableVendorsDropdown(props: CreatableVendorsDropdownProps): ReactElement {
  const { vendors, selectedVendor, onChanged } = useCreatableVendorsDropdown(props, useContext(VendorsContext));
  const intl = useIntl();

  interface CreatedOption {
    customOption: boolean;
    name: string;
  }

  const onSelected = (selected: VendorView[] | CreatedOption[]): void => {
    if (selected.length === 0) {
      onChanged(undefined);
      return;
    }

    const vendor: VendorView | CreatedOption = selected[0];
    if (vendor.hasOwnProperty("customOption")) {
      onChanged({ id: 0, name: vendor.name, url: "", contactInfo: "", notes: "" });
    } else {
      onChanged(vendor as VendorView);
    }
  };

  return (
    <span aria-live="assertive">
      {props.innerRef && <input type="hidden" ref={props.innerRef} />}
      <Typeahead
        id={`${props.id}-typeahead`}
        inputProps={{ id: props.id }}
        multiple={false}
        options={vendors}
        labelKey="name"
        allowNew
        defaultSelected={selectedVendor ? [selectedVendor] : undefined}
        // @ts-ignore
        onChange={onSelected}
        isInvalid={!props.isValid}
        newSelectionPrefix={`${intl.formatMessage({ id: "add.new.vendor" })}: `}
        disabled={props.disabled}
      />
    </span>
  );
}
