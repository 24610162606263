import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InventorySearchResultSet, ResultSetOptions } from "@labarchives/inventory-shared/build/inventory";
import { Input, Label, Table } from "reactstrap";
import { ResultSetPagination } from "../../../components";
import { useInventorySearchResults } from "../../../inventory/search/results/InventorySearchResultsHooks";
import { SiteAccountView } from "../../types/views";
import { SortableTableHeader } from "../../../components/SortableTableHeader/SortableTableHeader";
import { InventoryAdminSearchResultsRow } from "./InventoryAdminSearchResultsRow";
import "./InventoryAdminSearchResults.scss";

export interface InventoryAdminSearchResultsProps {
  onResultSetOptionsChanged(resultOptions: ResultSetOptions): Promise<void>;

  results: InventorySearchResultSet;
  accounts: SiteAccountView[];
}

export function InventoryAdminSearchResults(props: InventoryAdminSearchResultsProps): ReactElement {
  const intl = useIntl();
  const searchResults = props.results;
  const indexedAccounts = new Map<number, SiteAccountView>(props.accounts.map((a) => [a.id, a]));

  const { onPageChanged, onSortChanged } = useInventorySearchResults({
    onResultsOptionsChanged: props.onResultSetOptionsChanged,
    searchResults,
  });

  if (props.results.items.length === 0) {
    return (
      <div id="inventory-search-results-no-results" aria-live="assertive" role="alert">
        <div>
          <FormattedMessage id="no.inventory.search.results" />
        </div>
        <div>
          <FormattedMessage id="clear.search.and.try.again" />
        </div>
      </div>
    );
  }

  const sortInput = React.createRef<HTMLInputElement>();

  return (
    <div id="inventory-search-result-container" className="table-to-card">
      <div className="sort-results-mobile">
        <Label for="sort-result-select">{intl.formatMessage({ id: "sort.by" })}</Label>
        <Input
          id="sort-result-select"
          type="select"
          bsSize="sm"
          innerRef={sortInput}
          onChange={() => onSortChanged(sortInput.current ? sortInput.current.value : "")}
          value={searchResults.sortField}
        >
          <option value="name">{intl.formatMessage({ id: "name" })}</option>
          <option value="typeId">{intl.formatMessage({ id: "type" })}</option>
          <option value="accountId">{intl.formatMessage({ id: "lab.name" })}</option>
          <option value="quantity">{intl.formatMessage({ id: "quantity" })}</option>
        </Input>
      </div>
      <Table striped>
        <caption className={"visually-hidden"}>
          <FormattedMessage id="inventory.search.results" />
        </caption>
        <thead>
          <tr>
            <SortableTableHeader
              currentlySortedFieldName={searchResults.sortField}
              currentlySortedDirection={searchResults.sortDirection}
              onSortChanged={onSortChanged}
              fieldName={"name"}
              displayTextMessageId={"name"}
            />
            <SortableTableHeader
              currentlySortedFieldName={searchResults.sortField}
              currentlySortedDirection={searchResults.sortDirection}
              onSortChanged={onSortChanged}
              fieldName={"accountId"}
              displayTextMessageId={"lab.name"}
            />
            <SortableTableHeader
              currentlySortedFieldName={searchResults.sortField}
              currentlySortedDirection={searchResults.sortDirection}
              onSortChanged={onSortChanged}
              fieldName={"typeId"}
              displayTextMessageId={"type"}
            />
            <SortableTableHeader
              currentlySortedFieldName={searchResults.sortField}
              currentlySortedDirection={searchResults.sortDirection}
              onSortChanged={onSortChanged}
              fieldName={"quantity"}
              displayTextMessageId={"quantity"}
            />
          </tr>
        </thead>
        <tbody>
          {searchResults.items.map((item) => (
            <InventoryAdminSearchResultsRow key={item.id} item={item} accounts={indexedAccounts} />
          ))}
        </tbody>
      </Table>
      <ResultSetPagination
        totalResultCount={searchResults.totalResultCount}
        totalPageCount={searchResults.totalPageCount}
        currentPageNumber={searchResults.currentPageNumber}
        pageSize={searchResults.pageSize}
        onPageChanged={onPageChanged}
      />
    </div>
  );
}
