import { StorageLocationView } from "../types/views";

export function isLocationNameDuplicated(storageLocations: StorageLocationView, name: string, id: number, parentId: number | null): boolean {
  function normalizeName(locationName: string): string {
    const spaces = /\s+/g;
    return locationName.toLocaleLowerCase().replace(spaces, " ").trim();
  }

  function topLevelIsDuplicated(): boolean {
    return storageLocations.locationList.some((l) => l.parentId === null && normalizeName(l.name) === normalizeName(name) && l.id !== id);
  }

  function duplicatedWithinTree(): boolean {
    return storageLocations.getAllLocationsInTree(parentId!).some((l) => normalizeName(l.name) === normalizeName(name) && l.id !== id);
  }

  if (parentId === null) {
    return topLevelIsDuplicated();
  }

  return duplicatedWithinTree();
}
