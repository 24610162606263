import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { FreezerBoxDisplayFormat } from "@labarchives/inventory-shared/build/inventory";

import "./ManageFreezerBox.scss";

interface ManageFreezerBoxProps {
  rows: number | null;
  columns: number | null;
  format: number | null;
  onFreezerBoxChanged(numberOfRows: number, numberOfColumns: number, format: FreezerBoxDisplayFormat): void;
}

export function ManageFreezerBox(props: ManageFreezerBoxProps): ReactElement {
  let rowsInput: HTMLInputElement;
  let colsInput: HTMLInputElement;
  let formatInput: HTMLInputElement;
  const intl = useIntl();

  const maxRowsCols = [];
  for (let i = 1; i <= 50; i++) {
    maxRowsCols.push(i);
  }
  return (
    <div className="manage-freezer-box-container">
      <FormGroup>
        <Label>
          <FormattedMessage id="rows" />
        </Label>
        <Input
          id="manage-freezer-box-rows"
          type="select"
          bsSize="sm"
          defaultValue={(props.rows || 1).toString()}
          innerRef={(node: HTMLInputElement) => {
            rowsInput = node;
          }}
          onChange={() =>
            props.onFreezerBoxChanged(Number.parseInt(rowsInput.value), Number.parseInt(colsInput.value), Number.parseInt(formatInput.value))
          }
        >
          {maxRowsCols.map((i) => (
            <option key={i} value={i.toString()}>
              {i}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>
          <FormattedMessage id="columns" />
        </Label>
        <Input
          id="manage-freezer-box-cols"
          type="select"
          bsSize="sm"
          defaultValue={(props.columns || 1).toString()}
          innerRef={(node: HTMLInputElement) => {
            colsInput = node;
          }}
          onChange={() =>
            props.onFreezerBoxChanged(Number.parseInt(rowsInput.value), Number.parseInt(colsInput.value), Number.parseInt(formatInput.value))
          }
        >
          {maxRowsCols.map((i) => (
            <option key={i} value={i.toString()}>
              {i}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>
          <FormattedMessage id="format" />
        </Label>
        <Input
          type="select"
          bsSize="sm"
          defaultValue={(props.format || FreezerBoxDisplayFormat.Default).toString()}
          innerRef={(node: HTMLInputElement) => {
            formatInput = node;
          }}
          onChange={() =>
            props.onFreezerBoxChanged(Number.parseInt(rowsInput.value), Number.parseInt(colsInput.value), Number.parseInt(formatInput.value))
          }
        >
          <option value={FreezerBoxDisplayFormat.LetterNumber.toString()}>{intl.formatMessage({ id: "freezer.box.letter.number" })}</option>
          <option value={FreezerBoxDisplayFormat.NumberLetter.toString()}>{intl.formatMessage({ id: "freezer.box.number.letter" })}</option>
          <option value={FreezerBoxDisplayFormat.NumberOnly.toString()}>{intl.formatMessage({ id: "freezer.box.number.only" })}</option>
        </Input>
      </FormGroup>
    </div>
  );
}
