import { Role, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { RoleView } from "./types/views";
import { RoleState } from "./types/state";

export function getRoleView(role: Role): RoleView {
  return {
    id: role.id,
    name: role.name,
    permissions: role.permissions.map((p) => p.permissionId),
  };
}

export function getRoleViews(roleState: RoleState): RoleView[] {
  return roleState.allIds.map((id) => getRoleView(roleState.byId[id])).sort((r1, r2) => r1.name.localeCompare(r2.name));
}

export function getRequiredPermissions(selectedPermissions: RolePermissions[]): RolePermissions[] {
  const required: RolePermissions[] = [];
  const triggers: Map<RolePermissions, RolePermissions[]> = new Map([
    [RolePermissions.OrdersUpdateAll, [RolePermissions.OrdersViewAll]],
    [RolePermissions.OrdersCancelAll, [RolePermissions.OrdersViewAll]],
    [RolePermissions.OrdersApproveAll, [RolePermissions.OrdersViewAll]],
    [RolePermissions.OrdersReceiveAll, [RolePermissions.OrdersViewAll, RolePermissions.InventoryAddCreatedItem]],
    [RolePermissions.OrdersCancelOwn, [RolePermissions.OrdersViewAll]],
    [RolePermissions.OrdersReceiveOwn, [RolePermissions.OrdersViewAll, RolePermissions.InventoryAddCreatedItem]],
    [
      RolePermissions.LabManagementAllowed,
      [
        RolePermissions.InventoryEditAllItems,
        RolePermissions.InventoryAddCreatedItem,
        RolePermissions.InventoryViewLocation,
        RolePermissions.OrdersViewAll,
        RolePermissions.OrdersApproveAll,
        RolePermissions.OrdersUpdateAll,
        RolePermissions.OrdersCancelAll,
        RolePermissions.OrdersRequestItem,
        RolePermissions.OrdersCancelOwn,
        RolePermissions.OrdersReceiveOwn,
        RolePermissions.OrdersReceiveAll,
        RolePermissions.SearchViewAllItems,
        RolePermissions.InventoryReceiveNotifications,
      ],
    ],
  ]);

  selectedPermissions.forEach((p) => {
    if (triggers.has(p)) {
      triggers.get(p)!.forEach((d) => {
        if (!required.includes(d)) {
          required.push(d);
        }
      });
    }
  });

  return required;
}
