import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { AuthorizedComponentCheck } from "../AuthorizedComponent";

export function userCanViewOrderCheck(orderRequestedById: number | null): AuthorizedComponentCheck {
  return (auth) => {
    if (auth.hasPermissions([RolePermissions.OrdersUpdateAll, RolePermissions.OrdersCancelAll])) {
      return true;
    }

    const session = auth.getUser();
    return session !== undefined && session.id === orderRequestedById;
  };
}
