import { FormattedMessage } from "react-intl";
import React, { ReactElement, useContext, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { Loading } from "../components";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { useSuperUser } from "./SuperUserToolsHooks";
import { InventoryApiClient } from "../api";

export function SuperUserTools(): ReactElement {
  const authState = useContext(AuthenticationContext);
  const [searchTerm, setSearchTerm] = useState("");

  const { summary, isSearching, isLoading, searchResults, onSearchUsers } = useSuperUser(new InventoryApiClient());

  const user = authState.getUser();
  if (!user || !user.isSuperUser) {
    return <></>;
  }

  if (isLoading) {
    return <Loading />;
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    onSearchUsers(searchTerm.trim());
  }

  function friendlyNumber(rawNumber: number): string {
    if (rawNumber > 1_000_000) {
      return `${(rawNumber / 1_000_000).toFixed(1).toString()}M`;
    }

    if (rawNumber > 1000) {
      return `${(rawNumber / 1000).toFixed(1).toString()}K`;
    }

    return rawNumber.toString();
  }

  return (
    <Row>
      <Col xs={12} xl={{ size: 10, offset: 1 }}>
        <div className="superuser-find-user">
          <div className="superuser-find-header">Find a User</div>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Label for="nameOrEmail">Name or Email</Label>
              <div className="superuser-find-user-search">
                <Input
                  className="me-2"
                  type="text"
                  id="nameOrEmail"
                  autoFocus
                  required
                  onChange={(e) => setSearchTerm(e.target.value)}
                  maxLength={255}
                />
                <Button type="submit">Find</Button>
              </div>
            </FormGroup>
          </Form>
          <div className="superuser-find-user-results">
            {isSearching && <Loading />}
            {searchResults && (
              <div>
                <div>
                  <FormattedMessage id="result.count" values={{ resultCount: searchResults.length }} />
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.map((r) => (
                      <tr key={r.id}>
                        <td>
                          {r.first} {r.last}
                        </td>
                        <td>{r.email}</td>
                        <td>
                          <Link to={ApplicationPaths.SuperUser.User(r.id)}>View Details</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}
