import React, { ReactElement, useState } from "react";
import { Col, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { AlertWithIcon, Button, Modal } from "@labarchives/ui-design/dist";
import { FormattedMessage } from "react-intl";
import { Loading } from "../../components";
import { OrderModalProps } from "../types/props";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import "./OrderModal.scss";

interface ModalCancelProps extends OrderModalProps {
  onOrderCancelled(orderId: number, notes: string): void;

  quantity: number;
  price: number;
  unitOfMeasure: string;
  requestedByName: string;
  inventoryName: string;
}

export function ModalCancel(props: ModalCancelProps): ReactElement {
  const [notes, setNotes] = useState("");

  let actionRow = (
    <div>
      <Button color="primary" className="me-1" onClick={() => props.onOrderCancelled(props.orderId, notes)} id="order-modal-cancel-button">
        <FormattedMessage id="mark.cancelled" />
      </Button>
      <CancelButton className="order-modal-cancel" onClick={() => props.toggleModal(props.modalId)} />
    </div>
  );

  if (props.isProcessing) {
    actionRow = (
      <div>
        <Loading messageId="updating.order" />
      </div>
    );
  }

  let modalBody = <></>;

  if (props.errorMessage.length > 0) {
    modalBody = (
      <Row>
        <Col>
          <div>
            <AlertWithIcon color="danger">
              <FormattedMessage id="error.marking.cancelled" />
            </AlertWithIcon>
          </div>
          <div>{props.errorMessage}</div>
        </Col>
      </Row>
    );
    actionRow = (
      <div>
        <Button color="none" outline onClick={() => props.toggleModal(props.modalId)}>
          <FormattedMessage id="close" />
        </Button>
      </div>
    );
  }

  return (
    <Modal id={props.modalId} isOpen={props.isModalOpen(props.modalId)} toggle={() => props.toggleModal(props.modalId)} className="order-modal-modal">
      <ModalHeader toggle={() => props.toggleModal(props.modalId)}>
        <FormattedMessage id="mark.cancelled" />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <span className="order-modal-title">{props.inventoryName}</span>
          </Col>
        </Row>
        <Row>
          <Col className="order-modal-notes-container">
            <Label for="order-modal-notes">
              <FormattedMessage id="add.notes" />
            </Label>
            <Input type="textarea" id="order-modal-notes" onChange={(e) => setNotes(e.target.value)} />
          </Col>
        </Row>
        {modalBody}
      </ModalBody>
      <ModalFooter>{actionRow}</ModalFooter>
    </Modal>
  );
}
