import React, { ReactElement } from "react";
import { FreezerBoxDisplayFormat } from "@labarchives/inventory-shared/build/inventory";
import { StorageLocationView } from "./types/views";
import { FormattedFreezerBoxCells } from "./FormattedFreezerBoxCells";

interface ReadOnlyStorageProps {
  selectedStorageLocationId: number | null;
  storageLocations: StorageLocationView;
  storageNotes: string | null;
  selectedStorageCells: string[];
}

export function ReadOnlyStorage(props: ReadOnlyStorageProps): ReactElement {
  const currentLocation = props.storageLocations.getSelectedLocation(props.selectedStorageLocationId);
  const displayFormat = currentLocation ? currentLocation.freezerBoxDisplayFormat : FreezerBoxDisplayFormat.Default;
  const rows = currentLocation ? currentLocation.numberOfRows : 0;
  const cols = currentLocation ? currentLocation.numberOfColumns : 0;

  return (
    <div id="storage-location-description">
      <div>{props.storageLocations.getFormattedDescription(props.selectedStorageLocationId)}</div>
      <div id="storage-location-cells">
        <FormattedFreezerBoxCells cells={props.selectedStorageCells} format={displayFormat} rows={rows} cols={cols} />
      </div>
      <div id="storage-location-notes-display">{props.storageNotes}</div>
    </div>
  );
}
