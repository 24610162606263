import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Input, Modal } from "@labarchives/ui-design/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { InventoryTypeView } from "../../inventory/types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { isEnterKey } from "../../utils/isEnterKey";

interface InventoryTypeColorPromptProps {
  isOpen: boolean;
  type?: InventoryTypeView;

  onSave(type: InventoryTypeView): void;

  isColorDuplicated(color: string | undefined, type: InventoryTypeView): boolean;

  onCancel(): void;
}

export const InventoryTypeColorPrompt = (props: InventoryTypeColorPromptProps): ReactElement => {
  const colorRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const { type, isOpen, onCancel, isColorDuplicated } = props;
  const intl = useIntl();
  const [color, setColor] = useState<string>(type?.color || "");

  const predefinedColors: { label: string; color: string }[] = [
    { label: "Red", color: "#AA0000" },
    { label: "Orange", color: "#E67E22" },
    { label: "Yellow", color: "#F2CA27" },
    { label: "Blue", color: "#009FD4" },
    { label: "Green", color: "#28A228" },
    { label: "Purple", color: "#765AB0" },
    { label: "Gray", color: "#939393" },
    { label: "Brown", color: "#552F00" },
    { label: "Black", color: "#000000" },
  ];

  function onSave(): void {
    if (type) {
      props.onSave({ ...type, color });
    }
  }

  function openColorPicker(): void {
    colorRef.current!.focus();
    colorRef.current!.click();
  }

  useEffect(() => {
    setColor(type?.color || "");
  }, [type]);

  return (
    <Modal isOpen={isOpen} toggle={onCancel} id={"inventory-type-color-modal"}>
      <ModalHeader toggle={onCancel}>
        <FormattedMessage id="inventory.type.color" />
      </ModalHeader>
      <ModalBody>
        <div className={"d-flex"}>
          <div
            role={"button"}
            id={"inventory-type-color-clear-button"}
            className={"inventory-type-color inventory-type-color-none"}
            data-color={"type.color"}
            title={intl.formatMessage({ id: "clear.color" })}
            onClick={() => setColor("")}
            onKeyDown={(e) => {
              if (isEnterKey(e.key)) {
                setColor("");
              }
            }}
            tabIndex={0}
          >
            &nbsp;
          </div>

          {predefinedColors.map((c) => (
            <div
              role={"button"}
              className={"inventory-type-color"}
              onClick={() => setColor(c.color)}
              onKeyDown={(e) => {
                if (isEnterKey(e.key)) {
                  setColor(c.color);
                }
              }}
              style={{ backgroundColor: c.color }}
              title={c.label}
              key={c.color}
              tabIndex={0}
            >
              &nbsp;
            </div>
          ))}

          <Button color={"link"} onClick={() => openColorPicker()} className={"inventory-type-color-custom"} title={"Custom Color"}>
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>

          <FormGroup>
            <Label for={"inventory-type-color"} className={"visually-hidden"}>
              <FormattedMessage id={"choose.color"} />
            </Label>
            <Input
              innerRef={colorRef}
              id={"inventory-type-color"}
              type={"color"}
              value={color || "#FF0000"}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
          </FormGroup>
        </div>
        <div className={"mt-4 ms-auto me-auto"}>
          <div>
            <div className={"align-center"}>
              {" "}
              <FormattedHTMLMessage id={"selected.color"} values={{ name: type?.name }} />
            </div>
            {color && (
              <div className={"inventory-type-color"} style={{ backgroundColor: color }}>
                &nbsp;
              </div>
            )}
            {!color && <div className={"inventory-type-color inventory-type-color-none"}>&nbsp;</div>}
          </div>

          {type && isColorDuplicated(color, type) && (
            <div id={"inventory-type-duplicate-color-warning"} className={"mt-2 error-note align-center"}>
              <FormattedHTMLMessage id="duplicate.inventory.type.color" />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onSave()} id={"save-inventory-type-color-button"}>
          <FormattedMessage id="save" />
        </Button>
        <CancelButton onClick={onCancel} />
      </ModalFooter>
    </Modal>
  );
};
