import React, { ChangeEvent, ReactElement, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import moment from "moment";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { RouteComponentProps } from "react-router";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { Loading } from "../components";
import { InventoryTypesContext } from "../inventorytypes/InventoryTypesContext";
import { AuthorizedComponent } from "../components/Authentication/AuthorizedComponent";
import { RequiredLabelIndicator } from "../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { DatePicker } from "../components/DatePicker/DatePicker";
import { CreatableVendorsDropdown } from "../vendors/dropdown/CreatableVendorsDropdown";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import "./OrderRequest.scss";
import { VendorsContext } from "../vendors/VendorsContext";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { onPageLoad } from "../utils/onPageLoad";
import { InventoryApiClient } from "../api";
import { useOrderRequest } from "./OrderRequestHooks";

interface RouteParams {
  id: string;
}

export function OrderRequest(props: RouteComponentProps<RouteParams>): ReactElement {
  const authState = useContext(AuthenticationContext);
  const intl = useIntl();

  const {
    formInputs,
    totalPrice,
    enableNotifications,
    onNotifyMeChange,
    onFormInputChange,
    onClose,
    onSubmitForm,
    orderView,
    isLoading,
    inventoryTypeViews,
  } = useOrderRequest(props.match.params.id, authState, useContext(InventoryTypesContext), useContext(VendorsContext), new InventoryApiClient());

  function onInputChange(fieldName: string, e: ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value.trim();
    onFormInputChange(fieldName, value);
  }

  function onDateChange(fieldName: string, date: Date | null): void {
    onFormInputChange(fieldName, date ? moment(date).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "");
  }

  function changeVendor(vendorId: number | null): void {
    onFormInputChange(formInputs.vendorId.fieldName, vendorId !== 0 && vendorId !== null ? vendorId.toString() : "");
  }

  function submitForm(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    onSubmitForm();
  }

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.order.request" }));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!orderView.itemExists) {
    return (
      <div className="item-not-found">
        <FormattedMessage id="item.not.found" />
      </div>
    );
  }

  return (
    <AuthorizedComponent requiredPermissions={[RolePermissions.OrdersRequestItem]}>
      <Row>
        <Col lg={12} xl={{ size: 10, offset: 1 }}>
          <Form id="order-request-form" onSubmit={(e) => submitForm(e)} noValidate>
            <div>
              <div className="faux-modal-header">
                <h1 className="faux-modal-title">
                  <FormattedMessage id="order.request" />
                </h1>
                <div className="faux-modal-close">
                  <Button type="button" onClick={onClose} aria-label={intl.formatMessage({ id: "cancel" })}>
                    ×
                  </Button>
                </div>
              </div>
              <div className="faux-modal-body">
                {orderView.inventoryId && (
                  <Row>
                    <Col className="order-request-note-text alert alert-info">
                      <div>
                        <FormattedMessage id="order.request.on.hand" values={{ onHand: orderView.quantityOnHand }} />
                        {orderView.quantityOnHand > 0 && (
                          <Link to={ApplicationPaths.Inventory.Item(orderView.inventoryId)} className="order-view-inventory">
                            <FormattedMessage id="view.inventory" />
                          </Link>
                        )}
                      </div>

                      {orderView.lastOrderedDate && (
                        <div>
                          <FormattedMessage id="order.request.last.ordered" values={{ lastOrdered: intl.formatDate(orderView.lastOrderedDate) }} />
                        </div>
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} sm={6}>
                    <FormGroup>
                      <Label for="order-inventory-item-name">
                        <FormattedMessage id="product.name" />
                        <RequiredLabelIndicator required={formInputs.inventoryName.required} />
                      </Label>
                      <Input
                        type="text"
                        id="order-inventory-item-name"
                        defaultValue={formInputs.inventoryName.value}
                        name={formInputs.inventoryName.fieldName}
                        required={formInputs.inventoryName.required}
                        invalid={!formInputs.inventoryName.isValid}
                        onChange={(e) => onInputChange(formInputs.inventoryName.fieldName, e)}
                        innerRef={formInputs.inventoryName.ref}
                        maxLength={500}
                      />
                      <FormFeedback>
                        <FormattedMessage id="product.name.is.required" />
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="order-inventory-item-type">
                        <FormattedMessage id="type" />
                        <RequiredLabelIndicator required={formInputs.inventoryTypeId.required} />
                      </Label>
                      <Input
                        type="select"
                        id="order-inventory-item-type"
                        defaultValue={formInputs.inventoryTypeId.value}
                        name={formInputs.inventoryTypeId.fieldName}
                        required={formInputs.inventoryTypeId.required}
                        invalid={!formInputs.inventoryTypeId.isValid}
                        onChange={(e) => onInputChange(formInputs.inventoryTypeId.fieldName, e)}
                        innerRef={formInputs.inventoryTypeId.ref}
                      >
                        {inventoryTypeViews.map((t) => (
                          <option key={`item-type${t.id}`} value={t.id}>
                            {t.name}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>
                        <FormattedMessage id="item.type.is.required" />
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="order-inventory-item-catalog-number">
                        <FormattedMessage id="catalog.number" />
                        <RequiredLabelIndicator required={formInputs.catalogNumber.required} />
                      </Label>
                      <Input
                        type="text"
                        id="order-inventory-item-catalog-number"
                        defaultValue={formInputs.catalogNumber.value}
                        name={formInputs.catalogNumber.fieldName}
                        required={formInputs.catalogNumber.required}
                        invalid={!formInputs.catalogNumber.isValid}
                        onChange={(e) => onInputChange(formInputs.catalogNumber.fieldName, e)}
                        innerRef={formInputs.catalogNumber.ref}
                        maxLength={255}
                      />
                      <FormFeedback>
                        <FormattedMessage id="catalog.number.is.required" />
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="order-inventory-item-vendor">
                        <FormattedMessage id="vendor" />
                        <RequiredLabelIndicator required={formInputs.vendorId.required} />
                      </Label>
                      <CreatableVendorsDropdown
                        isValid={formInputs.vendorId.isValid}
                        selectedVendorId={formInputs.vendorId.value}
                        innerRef={formInputs.vendorId.ref}
                        onChange={changeVendor}
                        id="order-inventory-item-vendor"
                      />
                      <div className="invalid-feedback" style={{ display: formInputs.vendorId.isValid ? "none" : "inline-block" }}>
                        <FormattedMessage id="vendor.is.required" />
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="order-inventory-item-po-number">
                        <FormattedMessage id="po.number" />
                        <RequiredLabelIndicator required={formInputs.poNumber.required} />
                      </Label>
                      <Input
                        type="text"
                        id="order-inventory-item-po-number"
                        defaultValue={formInputs.poNumber.value}
                        name={formInputs.poNumber.fieldName}
                        required={formInputs.poNumber.required}
                        invalid={!formInputs.poNumber.isValid}
                        onChange={(e) => onInputChange(formInputs.poNumber.fieldName, e)}
                        innerRef={formInputs.poNumber.ref}
                        maxLength={255}
                      />
                      <FormFeedback>
                        <FormattedMessage id="po.number.is.required" />
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="order-inventory-item-grant-number">
                        <FormattedMessage id="grant.number" />
                        <RequiredLabelIndicator required={formInputs.grantNumber.required} />
                      </Label>
                      <Input
                        type="text"
                        id="order-inventory-item-grant-number"
                        defaultValue={formInputs.grantNumber.value}
                        name={formInputs.grantNumber.fieldName}
                        required={formInputs.grantNumber.required}
                        invalid={!formInputs.grantNumber.isValid}
                        onChange={(e) => onInputChange(formInputs.grantNumber.fieldName, e)}
                        innerRef={formInputs.grantNumber.ref}
                        maxLength={255}
                      />
                    </FormGroup>
                    <FormFeedback>
                      <FormattedMessage id="grant.number.is.required" />
                    </FormFeedback>

                    <FormGroup>
                      <Label for="order-inventory-item-date-required">
                        <FormattedMessage id="date.required" />
                        <RequiredLabelIndicator required={formInputs.requiredDate.required} />
                      </Label>
                      <DatePicker
                        id="order-inventory-item-date-required"
                        selected={formInputs.requiredDate.value}
                        name={formInputs.requiredDate.fieldName}
                        required={formInputs.requiredDate.required}
                        invalid={!formInputs.requiredDate.isValid}
                        onChange={(date) => onDateChange(formInputs.requiredDate.fieldName, date)}
                        innerRef={formInputs.requiredDate.ref}
                      />
                    </FormGroup>
                    <FormFeedback>
                      <FormattedMessage id="date.required.is.required" />
                    </FormFeedback>

                    <FormGroup>
                      <Label for="order-inventory-item-notes">
                        <FormattedMessage id="notes" />
                        <RequiredLabelIndicator required={formInputs.notes.required} />
                      </Label>
                      <Input
                        type="textarea"
                        id="order-inventory-item-notes"
                        defaultValue={formInputs.notes.value}
                        name={formInputs.notes.fieldName}
                        required={formInputs.notes.required}
                        invalid={!formInputs.notes.isValid}
                        onChange={(e) => onInputChange(formInputs.notes.fieldName, e)}
                        innerRef={formInputs.notes.ref}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <Col className="order-total-container" lg={{ size: 6, offset: 6 }}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="order-inventory-item-quantity">
                              <FormattedMessage id="quantity" />
                              <RequiredLabelIndicator required={formInputs.quantity.required} />
                            </Label>
                            <Input
                              type="number"
                              id="order-inventory-item-quantity"
                              defaultValue={formInputs.quantity.value}
                              name={formInputs.quantity.fieldName}
                              required={formInputs.quantity.required}
                              invalid={!formInputs.quantity.isValid}
                              onChange={(e) => onInputChange(formInputs.quantity.fieldName, e)}
                              min={1}
                              step="any"
                              innerRef={formInputs.quantity.ref}
                            />
                            <FormFeedback>
                              <FormattedMessage id="quantity.is.required" />
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="order-inventory-item-price">
                              <FormattedMessage id="price" />
                              <RequiredLabelIndicator required={formInputs.price.required} />
                            </Label>
                            <Input
                              type="number"
                              id="order-inventory-item-price"
                              name={formInputs.price.fieldName}
                              defaultValue={Number.parseFloat(formInputs.price.value).toString()}
                              required={formInputs.price.required}
                              invalid={!formInputs.price.isValid}
                              onChange={(e) => onInputChange(formInputs.price.fieldName, e)}
                              min={0}
                              step="any"
                              innerRef={formInputs.price.ref}
                            />
                            <FormFeedback>
                              <FormattedMessage id="price.is.required" />
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="order-total-line">
                          <hr />
                          <FormattedNumber value={totalPrice} style="currency" currency={authState.getCurrency()} />
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </div>
              <div className="faux-modal-footer">
                <div className={"d-flex justify-content-between"}>
                  <div className={"faux-modal-footer-checkbox"}>
                    <CheckboxLabel>
                      <CheckboxInput
                        id="order-inventory-item-notify-me"
                        name="notifyMe"
                        onChange={(e) => onNotifyMeChange(e.target.checked)}
                        uncheckedValue="0"
                        checked={enableNotifications}
                      />
                      <span>
                        <FormattedMessage id="notify.me.order.updates" />
                      </span>
                    </CheckboxLabel>
                  </div>
                  <div>
                    <Button className="me-1" type="submit" color="primary" id="order-inventory-request-button">
                      <FormattedMessage id="request.item" />
                    </Button>
                    <CancelButton onClick={onClose} />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </AuthorizedComponent>
  );
}
