import {
  AdvancedSearchCriteria,
  InventoryAdvancedSearchCriteria,
  InventoryImportRequest,
  InventoryItem,
  InventorySearchCriteria,
  InventorySearchResultSet,
  InventorySearchResultSetItem,
  InventorySortableColumn,
  InventorySortableColumnDefault,
  ResultSetOptions,
  SortDirection,
} from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useRef, useState } from "react";
import { InventoryFilterView, InventoryTypeView } from "../types/views";
import { UserView } from "../../user/types/views";
import { StorageLocationView } from "../../storage/types/views";
import { UserState } from "../../user/types/state";
import { RoleState } from "../../roles/types/state";
import { InventoryTypesState } from "../../inventorytypes/types/state";
import { StorageState } from "../../storage/types/state";
import * as utils from "../../utils";
import { getInventoryFilterView } from "../selectors";
import { getUserViews } from "../../user/selectors";
import { getInventoryTypeViews } from "../../inventorytypes/selectors";
import { getStorageLocationView } from "../../storage/selectors";
import { VendorState } from "../../vendors/types/state";
import { AuthenticationState } from "../../components/Authentication/AuthenticationState";
import { InventoryApi } from "../../api/InventoryApi";

export interface InventorySearchHooks {
  isAdvancedSearchBoxShown: boolean;
  isLoading: boolean;
  isShowingImport: boolean;
  isShowingExport: boolean;
  searchResults: InventorySearchResultSet;
  filters: InventoryFilterView;
  userViews: UserView[];
  searchCriteria: InventorySearchCriteria;
  inventoryTypeViews: InventoryTypeView[];
  storageLocations: StorageLocationView;
  advancedSearchCriteria: AdvancedSearchCriteria[];
  columnOrder: InventorySortableColumn[];
  currency: string;
  searchTerm: string;
  accountName: string | undefined;

  onResultsOptionsChanged(options: ResultSetOptions): void;

  onFilterChanged(filters: InventoryFilterView): void;

  onFilterCleared(): void;

  onImportModalToggled(): void;

  onExportModalToggled(): void;

  onImportStarted(request: InventoryImportRequest): void;

  onSearchOnNewTerm(): void;

  onClearSearchTerm(): void;

  onSearchTermChange(term: string): void;

  onAdvancedSearchToggle(): void;

  onAdvancedSearch(criteria: AdvancedSearchCriteria[]): void;

  onUseNow(item: InventorySearchResultSetItem, amount: number, unit: string, selectedCells: string[]): Promise<InventoryItem>;

  isUsed(item: InventorySearchResultSetItem): boolean;

  onInventoryStorageChanged(
    item: InventorySearchResultSetItem,
    locationId?: number | null,
    storageCells?: string[] | null,
    notes?: string | null,
  ): Promise<void>;
}

const defaultSearchResults: InventorySearchResultSet = {
  items: [],
  totalResultCount: 0,
  currentPageNumber: 0,
  totalPageCount: 0,
  pageSize: 0,
  sortField: "",
  sortDirection: SortDirection.DEFAULT,
};

export interface InventorySearchHooksFilters {
  searchCriteria: InventorySearchCriteria;
  advancedCriteria: AdvancedSearchCriteria[];

  onCriteriaChanged(searchCriteria: InventorySearchCriteria, advancedCriteria: AdvancedSearchCriteria[]): void;
}

export function useInventorySearch(
  userState: UserState,
  roleState: RoleState,
  inventoryTypeState: InventoryTypesState,
  storageState: StorageState,
  vendorState: VendorState,
  authState: AuthenticationState,
  searchFilter: InventorySearchHooksFilters,
  api: InventoryApi,
): InventorySearchHooks {
  const [isLoading, setIsLoading] = useState(true);
  const [searchResults, setSearchResults] = useState(defaultSearchResults);
  const [isShowingImport, setIsShowingImport] = useState(false);
  const [isShowingExport, setIsShowingExport] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchFilter.searchCriteria.term);
  const [usedItemIds, setUsedItemIds] = useState<number[]>([]);

  const apiSearch = (criteria: InventorySearchCriteria): void => {
    api.searchInventory(criteria).then((newSearchResults) => {
      setIsLoading(false);
      setSearchResults(newSearchResults);
      searchFilter.onCriteriaChanged(criteria, []);
      return newSearchResults;
    });
  };

  const apiAdvancedSearch = (criteria: AdvancedSearchCriteria[], standardSearchCriteria: InventorySearchCriteria | undefined = undefined): void => {
    const standardCriteria = standardSearchCriteria || searchFilter.searchCriteria;
    const newSearch: InventoryAdvancedSearchCriteria = {
      criteria,
      resultSetOptions: standardCriteria.resultSetOptions,
      includeOutOfStock: standardCriteria.includeOutOfStock,
      locationIds: standardCriteria.locationIds,
      receivedEndDate: standardCriteria.receivedEndDate,
      receivedStartDate: standardCriteria.receivedStartDate,
      typeIds: standardCriteria.typeIds,
      includeMissingLocationId: standardCriteria.includeMissingLocationId,
    };

    api.searchInventoryAdvanced(newSearch).then((newSearchResults) => {
      setIsLoading(false);
      setShowAdvanced(false);
      setSearchResults(newSearchResults);
      searchFilter.onCriteriaChanged(standardCriteria, criteria);
      return newSearchResults;
    });
  };

  const debouncedSearch = useRef(utils.debounce(apiSearch, 1000)).current;
  const debouncedAdvancedSearch = useRef(utils.debounce(apiAdvancedSearch, 1000)).current;

  const searchInventory = (criteria: InventorySearchCriteria, debounce = true): void => {
    setIsLoading(true);
    if (debounce) {
      debouncedSearch(criteria);
    } else {
      apiSearch(criteria);
    }
  };

  const searchInventoryAdvanced = (
    criteria: AdvancedSearchCriteria[],
    debounce = true,
    standardSearchCriteria: InventorySearchCriteria | undefined = undefined,
  ): void => {
    setIsLoading(true);
    if (debounce) {
      debouncedAdvancedSearch(criteria, standardSearchCriteria);
    } else {
      apiAdvancedSearch(criteria, standardSearchCriteria);
    }
  };

  const onFilterChanged = (filters: InventoryFilterView): void => {
    const updatedFilters: InventorySearchCriteria = {
      ...searchFilter.searchCriteria,
      term: filters.term,
      receivedStartDate: filters.receivedStartDate,
      receivedEndDate: filters.receivedEndDate,
      locationIds: filters.locationIds,
      typeIds: filters.typeIds,
      includeOutOfStock: filters.includeOutOfStock,
      includeMissingLocationId: filters.includeMissingLocationId,
    };
    if (searchFilter.advancedCriteria.length > 0) {
      searchInventoryAdvanced(searchFilter.advancedCriteria, true, updatedFilters);
    } else {
      searchInventory(updatedFilters);
    }
  };

  const onFilterCleared = (): void => {
    setSearchTerm("");
    const updatedFilters: InventorySearchCriteria = {
      includeOutOfStock: false,
      locationIds: [],
      receivedEndDate: undefined,
      receivedStartDate: undefined,
      resultSetOptions: undefined,
      term: "",
      typeIds: [],
      includeMissingLocationId: false,
    };
    searchInventory(updatedFilters);
  };

  const onSearchTermChange = (term: string): void => {
    setSearchTerm(term);
  };

  const onClearSearchTerm = (): void => {
    setSearchTerm("");
    const updatedFilters = { ...searchFilter.searchCriteria, term: "", resultSetOptions: undefined };
    onFilterChanged(updatedFilters);
  };

  const onResultsOptionsChanged = (resultSetOptions: ResultSetOptions): void => {
    const updatedFilters = { ...searchFilter.searchCriteria, resultSetOptions };
    if (searchFilter.advancedCriteria.length > 0) {
      searchInventoryAdvanced(searchFilter.advancedCriteria, false, updatedFilters);
    } else {
      searchInventory(updatedFilters);
    }
  };

  const onImportModalToggled = (): void => {
    setIsShowingImport(!isShowingImport);
  };

  const onExportModalToggled = (): void => {
    setIsShowingExport(!isShowingExport);
  };

  const onImportStarted = (request: InventoryImportRequest): void => {
    // eslint-disable-next-line promise/always-return
    api.importInventoryItems(request).then(() => {
      inventoryTypeState.refresh(true);
      vendorState.refresh(true);

      onFilterCleared();
      setIsShowingImport(false);
    });
  };

  const onSearchOnNewTerm = (): void => {
    const updatedFilters = { ...searchFilter.searchCriteria, term: searchTerm, resultSetOptions: undefined };
    onFilterChanged(updatedFilters);
  };

  function onAdvancedSearch(criteria: AdvancedSearchCriteria[]): void {
    searchInventoryAdvanced(criteria, false);
  }

  function onAdvancedSearchToggle(): void {
    setShowAdvanced(!showAdvanced);
  }

  async function onUseNow(item: InventorySearchResultSetItem, amount: number, unit: string, selectedCells: string[]): Promise<InventoryItem> {
    const updatedItem = await api.updateInventoryUsage(item.id, amount, unit, selectedCells);

    setUsedItemIds([...usedItemIds, item.id]);

    const { items } = searchResults;
    const searchResultsIndex = items.findIndex((i) => i.id === updatedItem.id);
    if (searchResultsIndex !== -1) {
      items[searchResultsIndex] = {
        ...items[searchResultsIndex],
        quantityAvailable: updatedItem.quantityAvailable,
        storageCells: updatedItem.storageCells,
        unit: updatedItem.unit,
      };

      setSearchResults({ ...searchResults, items });
    }

    return updatedItem;
  }

  async function onInventoryStorageChanged(
    item: InventorySearchResultSetItem,
    locationId?: number | null,
    storageCells?: string[] | null,
    notes?: string | null,
  ): Promise<void> {
    const updatedItem = await api.updateInventoryStorageLocation(item.id, locationId, storageCells, notes);
    const { items } = searchResults;
    const searchResultsIndex = items.findIndex((i) => i.id === updatedItem.id);
    if (searchResultsIndex !== -1) {
      items[searchResultsIndex] = {
        ...items[searchResultsIndex],
        locationId: updatedItem.storageLocationId,
        storageCells: updatedItem.storageCells,
        storageLocationNotes: updatedItem.storageLocationNotes,
      };

      setSearchResults({ ...searchResults, items });
    }
  }

  function isUsed(item: InventorySearchResultSetItem): boolean {
    return usedItemIds.includes(item.id);
  }

  function getColumnOrder(): InventorySortableColumn[] {
    const user = authState.getUser();
    if (!user) {
      return InventorySortableColumnDefault;
    }

    return user!.activeAccount.displaySettings.inventorySearchOrder;
  }

  function getAccountName(): string | undefined {
    const user = authState.getUser();
    if (!user || user.accounts.length === 1) {
      return undefined;
    }

    return user.activeAccount.name;
  }

  // eslint-disable-next-line
  useEffect(() => {
    inventoryTypeState.refresh();
    storageState.refresh();
    userState.refresh();
    if (searchFilter.advancedCriteria.length > 0) {
      searchInventoryAdvanced(searchFilter.advancedCriteria, false, searchFilter.searchCriteria);
    } else {
      searchInventory(searchFilter.searchCriteria, false);
    }
  }, []);

  return {
    isLoading,
    isShowingImport,
    isShowingExport,
    searchResults,
    searchTerm,
    isAdvancedSearchBoxShown: showAdvanced,
    accountName: getAccountName(),
    filters: getInventoryFilterView(searchFilter.searchCriteria),
    currency: authState.getCurrency(),
    columnOrder: getColumnOrder(),
    userViews: getUserViews(userState, roleState),
    searchCriteria: searchFilter.searchCriteria,
    inventoryTypeViews: getInventoryTypeViews(inventoryTypeState),
    storageLocations: getStorageLocationView(storageState),
    advancedSearchCriteria: searchFilter.advancedCriteria,
    onResultsOptionsChanged,
    onFilterChanged,
    onFilterCleared,
    onImportModalToggled,
    onExportModalToggled,
    onImportStarted,
    onSearchOnNewTerm,
    onSearchTermChange,
    onClearSearchTerm,
    onAdvancedSearch,
    onAdvancedSearchToggle,
    onUseNow,
    isUsed,
    onInventoryStorageChanged,
  };
}
