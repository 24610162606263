import { InventoryApi } from "../api/InventoryApi";
import { ItemReportDownloadHooks } from "./ItemReportDownloadHooks";

export interface ItemExpirationReportHooks {
  onReportDownloaded(expirationDays: number): Promise<void>;
}

interface ItemExpirationReportHooksProps {
  downloadHooks: ItemReportDownloadHooks;
  api: InventoryApi;
}

export function useItemExpirationReport(props: ItemExpirationReportHooksProps): ItemExpirationReportHooks {
  const { downloadHooks, api } = props;

  async function onReportDownloaded(expirationDays: number): Promise<void> {
    const items = await api.reportItemExpiration(expirationDays);
    return downloadHooks.onReportDownloaded(items, "item-expiration.xlsx");
  }

  return {
    onReportDownloaded,
  };
}
