import { FreezerBoxDisplayFormat, StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { FreezerBoxLocation, StorageLocationView } from "./types/views";
import { StorageState } from "./types/state";

function getLetters(r: number): string {
  let charNumber = r;
  let letter = "";
  while (charNumber > 0) {
    const charOffset = (charNumber - 1) % 26;
    letter = String.fromCodePoint(65 + charOffset) + letter;
    charNumber = Math.floor((charNumber - charOffset) / 26);
  }
  return letter;
}

function lettersToNumber(letters: string): number {
  return [...letters.toUpperCase()].reduce((r, a) => r * 26 + Number.parseInt(a, 36) - 9, 0);
}

function splitLettersNumber(val: string): string[] {
  return val.split(/(\d+)/g).filter((i) => i !== "");
}

export function getStorageLocationView(state: StorageState): StorageLocationView {
  return getStorageLocationViewFromList(state.allIds.map((id) => state.byId[id]));
}

export function getDefaultStorageLocationView(): StorageLocationView {
  return new StorageLocationView([]);
}

export function getStorageLocationViewFromList(storageLocations: StorageLocation[]): StorageLocationView {
  return new StorageLocationView(storageLocations);
}

function compareCell(location1: FreezerBoxLocation, location2: FreezerBoxLocation): number {
  if (location1.row === location2.row) {
    return location1.col - location2.col;
  }

  return location1.row - location2.row;
}

export const getFreezerBoxCellLabelFromLocation = (row: number, column: number): string => {
  const letters = getLetters(row);
  return letters + column;
};

export const getFreezerBoxLocationFromLabel = (
  label: string,
  rows: number,
  cols: number,
  format: FreezerBoxDisplayFormat | null,
): FreezerBoxLocation => {
  let row = 0;
  let col = 0;

  if (format === FreezerBoxDisplayFormat.NumberLetter) {
    const split = splitLettersNumber(label);

    row = Number.parseInt(split[0]);
    col = lettersToNumber(split[1]);
  }

  if (format === FreezerBoxDisplayFormat.NumberOnly) {
    const num = Number.parseInt(label);
    const column = num % cols;

    row = Math.ceil(num / cols);
    col = column === 0 ? cols : column;
  }

  if (!format || format === FreezerBoxDisplayFormat.LetterNumber) {
    const split = splitLettersNumber(label);

    row = lettersToNumber(split[0]);
    col = Number.parseInt(split[1]);
  }

  return {
    row,
    col,
    label,
    normalizedLabel: getFreezerBoxCellLabelFromLocation(row, col),
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NoTransform = (letterNumber: string, cols: number): number | string => {
  return letterNumber;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NumberLetterTransform = (letterNumber: string, cols: number): number | string => {
  const parts = splitLettersNumber(letterNumber);
  const number = lettersToNumber(parts[0]);
  const letter = getLetters(Number.parseInt(parts[1])).toUpperCase();
  return `${number}${letter}`;
};

const NumberOnly = (letterNumber: string, cols: number): number | string => {
  const parts = splitLettersNumber(letterNumber);
  const number1 = (lettersToNumber(parts[0]) - 1) * cols;
  const number2 = Number.parseInt(parts[1]);
  return number1 + number2;
};

export function getFormattedFreezerBoxCells(
  cells: string[],
  format?: FreezerBoxDisplayFormat | null,
  rows?: number | null,
  cols?: number | null,
): string {
  let transform = NoTransform;
  if (!format || format === FreezerBoxDisplayFormat.LetterNumber) {
    transform = NoTransform;
  }
  if (format === FreezerBoxDisplayFormat.NumberLetter) {
    transform = NumberLetterTransform;
  }
  if (format === FreezerBoxDisplayFormat.NumberOnly) {
    transform = NumberOnly;
  }

  return cells
    .map((c) => getFreezerBoxLocationFromLabel(c, rows || 1, cols || 1, FreezerBoxDisplayFormat.Default))
    .sort((a, b) => compareCell(a, b))
    .map((c) => transform(c.normalizedLabel, cols || 1))
    .join(", ");
}

export function getIsFreezerBox(location: StorageLocation | null): boolean {
  return location !== null && location.numberOfRows !== null && location.numberOfColumns !== null;
}
