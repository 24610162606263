import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { AuthorizedComponentCheck } from "../AuthorizedComponent";

export function userCanEditItemCheck(inventoryOwnerId: number | null): AuthorizedComponentCheck {
  return (auth) => {
    if (auth.hasPermissions([RolePermissions.InventoryEditAllItems])) {
      return true;
    }

    const user = auth.getUser();
    return user !== undefined && user.id === inventoryOwnerId;
  };
}
