import { useEffect, useRef, useState } from "react";
import { StorageLocationTreeView } from "../types/views";

export interface ManageStorageLocationTreeHooksProps {
  fullLocationTree?: StorageLocationTreeView;
}

export interface ManageStorageLocationTreeHooks {
  isCollapsed(location: StorageLocationTreeView): boolean;

  isHidden(location: StorageLocationTreeView): boolean;

  onExpand(location: StorageLocationTreeView): void;

  onCollapse(location: StorageLocationTreeView): void;
}

export function useManageStorageLocationTree(props: ManageStorageLocationTreeHooksProps): ManageStorageLocationTreeHooks {
  const { fullLocationTree } = props;
  const indexedTree = useRef<Map<number, string>>(new Map());

  const [collapsedIds, setCollapsedIds] = useState<number[]>([]);

  useEffect(() => {
    if (!fullLocationTree) {
      return;
    }

    indexTree(fullLocationTree);
  }, [fullLocationTree]);

  function indexTree(location: StorageLocationTreeView): void {
    let parentIndex = "";
    if (location.location.parentId) {
      parentIndex = indexedTree.current.get(location.location.parentId)! + "-";
    }
    indexedTree.current.set(location.location.id, `${parentIndex}${location.location.id}`);
    location.children.forEach((c) => indexTree(c));
  }

  function anyParentIsCollapsed(myId: number): boolean {
    const myIndex = indexedTree.current.get(myId);
    if (!myIndex) {
      return false;
    }
    const parents = new Set(myIndex.split("-").map((id) => Number.parseInt(id)));
    return collapsedIds.filter(id => id !== myId).some((id) => parents.has(id));
  }

  return {
    isCollapsed(location: StorageLocationTreeView): boolean {
      return collapsedIds.includes(location.location.id);
    },
    isHidden(location: StorageLocationTreeView): boolean {
      const myId = location.location.id!;
      return anyParentIsCollapsed(myId);
    },
    onExpand(location: StorageLocationTreeView): void {
      setCollapsedIds(collapsedIds.filter((id) => id !== location.location.id));
      return;
    },
    onCollapse(location: StorageLocationTreeView): void {
      setCollapsedIds([...collapsedIds, location.location.id]);
      return;
    },
  };
}
