import { createContext, useEffect, useState } from "react";
import {
  AdvancedSearchCriteria,
  InventorySearchCriteria,
  OrderSearchCriteria,
  RecentlyUsedItem,
  SiteAdminInventorySearchCriteria,
  User,
} from "@labarchives/inventory-shared/build/inventory";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import * as localStorage from "../api/inventoryLocalStorage";
import { addIfNotExists, createReduced } from "../utils";
import { NormalizedState } from "../utils/types";
import { Settings } from "../utils/Settings";
import { InventoryApi } from "../api/InventoryApi";
import { UserAlerts } from "./types/userAlerts";
import { UserState } from "./types/state";

const normalizedState: NormalizedState<User> = {
  isLoading: false,
  byId: {},
  allIds: [],
};

const noopDefault = (): void => {
  // no-op default
};

const defaultUserState: UserState = {
  ...normalizedState,
  recentlyUsedInventory: [],
  lastInventoryFilter: { ...localStorage.getInventorySearchCriteria() },
  lastInventoryAdvancedCriteria: [...localStorage.getInventoryAdvancedSearchCriteria()],
  lastOrderFilter: { ...localStorage.getOrderSearchCriteria() },
  lastAdminSearchCriteria: { ...localStorage.getInventoryAdminSearchCriteria() },
  hasUpdated: false,
  updateRecentlyUsedInventory: noopDefault,
  updateLastInventorySearchCriteria: noopDefault,
  updateLastOrderSearchCriteria: noopDefault,
  updateLastAdminInventorySearchCriteria: noopDefault,
  onUsersInvited: async (): Promise<User[]> => [],
  onUserDeleted: async (): Promise<User> => {
    throw new Error("User State is not initialized");
  },
  onUserRoleUpdated: async () => {
    throw new Error("User State is not initialized");
  },
  refresh: () => Promise.resolve(),
  shouldDisplayAlert(): boolean {
    return false;
  },
  onToggleAlert(): void {
    throw new Error("User State is not initialized");
  },
  shouldDisplayFeatureAlert(): boolean {
    return false;
  },
  onDismissFeatureAlert(): Promise<void> {
    return Promise.resolve();
  },
};
export const UsersContext = createContext<UserState>(defaultUserState);

export function useUsersContext(api: InventoryApi): UserState {
  const [localState, setLocalState] = useState({ ...normalizedState });
  const [recentlyUsedInventory, setRecentlyUsedInventory] = useState<RecentlyUsedItem[]>([]);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [lastDataRefreshTime, setLastDataRefreshTime] = useState<Date>(new Date(0));
  const [dismissedFeatures, setDismissedFeatures] = useState<string[] | undefined>();
  const [lastInventoryAdvancedCriteria, setLastInventoryAdvancedCriteria] = useState([...localStorage.getInventoryAdvancedSearchCriteria()]);
  const [lastAdminSearchCriteria, setLastAdminSearchCriteria] = useState({ ...localStorage.getInventoryAdminSearchCriteria() });
  const [lastInventoryFilter, setLastInventoryFilter] = useState({ ...localStorage.getInventorySearchCriteria() });
  const [lastOrderFilter, setLastOrderFilter] = useState({ ...localStorage.getOrderSearchCriteria() });

  const loadAllUsers = async (): Promise<void> => {
    setLocalState({ ...normalizedState, isLoading: true });
    api.getUsers().then((users) => {
      const newState: NormalizedState<User> = {
        allIds: users.map((user) => user.id),
        byId: createReduced<User>(users),
        isLoading: false,
      };
      setLocalState(newState);
      setLastDataRefreshTime(clock.getNow());
      return users;
    });
  };

  const loadDismissedFeatureAlerts = async (): Promise<void> => {
    return api
      .getDismissedFeatureAlerts()
      .then((l) => setDismissedFeatures(l))
      .catch((error) => api.logError(error));
  };

  const updateRecentlyUsedInventory = (inventoryId: number): void => {
    const index = recentlyUsedInventory.findIndex((usedInventory) => usedInventory.id === inventoryId);
    if (index > -1) {
      const newArray: RecentlyUsedItem[] = [...recentlyUsedInventory];
      newArray[index] = { id: inventoryId, date: clock.getNow().toJSON() };
      setRecentlyUsedInventory(newArray);
    } else {
      setRecentlyUsedInventory([...recentlyUsedInventory, { id: inventoryId, date: clock.getNow().toJSON() }]);
    }
  };

  const updateLastInventorySearchCriteria = (criteria: InventorySearchCriteria, advanced: AdvancedSearchCriteria[]): void => {
    localStorage.setInventorySearchCriteria(criteria);
    localStorage.setInventoryAdvancedSearchCriteria(advanced);
    setLastInventoryFilter(criteria);
    setLastInventoryAdvancedCriteria(advanced);
  };

  const updateLastOrderSearchCriteria = (criteria: OrderSearchCriteria): void => {
    localStorage.setOrderSearchCriteria(criteria);
    setLastOrderFilter(criteria);
  };

  const updateLastAdminInventorySearchCriteria = (criteria: SiteAdminInventorySearchCriteria): void => {
    setLastAdminSearchCriteria(criteria);
    localStorage.setInventoryAdminSearchCriteria(criteria);
  };

  const onUsersInvited = async (emailAddresses: string[]): Promise<User[]> => {
    try {
      setLocalState({ ...localState, isLoading: true });
      const newUsers = await api.sendUserInvites(emailAddresses);
      const updatedState = { ...localState, isLoading: false };
      newUsers.forEach((user) => {
        updatedState.byId[user.id] = user;
        updatedState.allIds = addIfNotExists(updatedState.allIds, user.id);
      });

      setLocalState(updatedState);
      return newUsers;
    } catch (error) {
      await loadAllUsers();
      throw error;
    }
  };

  const onUserDeleted = async (userId: number): Promise<User> => {
    try {
      setLocalState({ ...localState, isLoading: true });

      const user = await api.deleteUser(userId);

      const updatedState = { ...localState, isLoading: false };
      updatedState.byId[userId] = user;
      setLocalState(updatedState);

      return user;
    } catch (error) {
      await loadAllUsers();
      throw error;
    }
  };

  const onUserRoleUpdated = async (userId: number, roleId: number): Promise<User> => {
    return api
      .updateUserRole(userId, roleId)
      .then((updatedUser) => {
        const updatedState = { ...localState, isLoading: false };

        updatedState.byId[userId] = updatedUser;
        setLocalState(updatedState);
        setHasUpdated(true);
        return updatedUser;
      })
      .catch((error) => {
        loadAllUsers();
        throw error;
      });
  };

  const refresh = async (): Promise<void> => {
    if (!localState.isLoading && clock.addMinutes(lastDataRefreshTime, Settings.getDataCacheTimeout()) < clock.getNow()) {
      await loadAllUsers();
    }
  };

  const onToggleAlert = (id: UserAlerts): void => {
    const alerts = localStorage.getUserAlerts();
    if (alerts.hiddenIds.includes(id)) {
      alerts.hiddenIds = alerts.hiddenIds.filter((h) => h !== id);
    } else {
      alerts.hiddenIds.push(id);
    }

    localStorage.setUserAlerts(alerts);
  };

  const shouldDisplayAlert = (id: UserAlerts): boolean => {
    const alerts = localStorage.getUserAlerts();
    return !alerts.hiddenIds.includes(id);
  };

  const onDismissFeatureAlert = (featureId: string): Promise<void> => {
    setDismissedFeatures([...(dismissedFeatures || []), featureId]);
    return api
      .dismissFeatureAlert(featureId)
      .then((dismissed) => setDismissedFeatures(dismissed))
      .catch((error) => api.logError(error));
  };

  const shouldDisplayFeatureAlert = (featureId: string): boolean => {
    if (dismissedFeatures === undefined) {
      return false;
    }
    return !dismissedFeatures.includes(featureId);
  };

  useEffect(() => {
    loadAllUsers();
    loadDismissedFeatureAlerts();
  }, []);

  useEffect(() => {
    if (hasUpdated) {
      const timeout = window.setTimeout(() => {
        setHasUpdated(false);
        window.clearTimeout(timeout);
      }, 5000);

      return () => window.clearTimeout(timeout);
    }

    return () => {
      // nothing to do
    };
  }, [hasUpdated]);

  return {
    recentlyUsedInventory,
    lastInventoryFilter,
    lastInventoryAdvancedCriteria,
    lastAdminSearchCriteria,
    lastOrderFilter,
    hasUpdated,
    isLoading: localState.isLoading,
    byId: localState.byId,
    allIds: localState.allIds,
    updateRecentlyUsedInventory,
    updateLastInventorySearchCriteria,
    updateLastOrderSearchCriteria,
    updateLastAdminInventorySearchCriteria,
    onUsersInvited,
    onUserDeleted,
    onUserRoleUpdated,
    refresh,
    onToggleAlert,
    shouldDisplayAlert,
    shouldDisplayFeatureAlert,
    onDismissFeatureAlert,
  };
}
