import React, { ReactElement } from "react";
import { Route, Switch } from "react-router";
import { Col, Row } from "reactstrap";
import { UsersProvider } from "../user/UsersProvider";
import { MaintenanceNotice } from "../components/MaintenanceNotice/maintenanceNotice";
import { InventorySiteAdminSearch } from "../inventorySiteAdmin/inventory/search/InventorySearch";
import { AdminViewInventory } from "../inventorySiteAdmin/inventory/AdminViewInventory";
import { NotFound } from "../notfound/NotFound";
import { InventoryAdminNavigation } from "../components/NavBar/AdminNavigation";
import { Logout } from "../components/Authentication/Logout";
import { AdminViewManagement } from "../inventorySiteAdmin/management/AdminViewManagement";
import { ApplicationPaths } from "./ApplicationPaths";

export function InventoryAdminApp(): ReactElement {
  return (
    <UsersProvider>
      <InventoryAdminNavigation />
      <MaintenanceNotice />
      <main className="container-fluid" id={"main-content"} role={"main"}>
        <Row>
          <Col>
            <Switch>
              <Route exact path={ApplicationPaths.Routes.Home} component={InventorySiteAdminSearch} />
              <Route exact path={ApplicationPaths.Admin.Routes.InventorySearch} component={InventorySiteAdminSearch} />
              <Route exact path={ApplicationPaths.Admin.Routes.InventoryItem} component={AdminViewInventory} />
              <Route exact path={ApplicationPaths.Admin.Routes.Management.Home} component={AdminViewManagement} />
              <Route path={ApplicationPaths.Admin.Routes.Management.Home} component={AdminViewManagement} />
              <Route exact path={ApplicationPaths.Routes.Logout} component={Logout} />
              <Route component={NotFound} />
            </Switch>
          </Col>
        </Row>
      </main>
    </UsersProvider>
  );
}
