import React, { ReactElement } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { UserStatus } from "@labarchives/inventory-shared/build/inventory";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { SuperUserUserAccountView } from "./views";

export interface SuperUserChangeUserStatusModalProps {
  isOpen: boolean;
  userName: string;
  account: SuperUserUserAccountView | undefined;

  onToggle(): void;

  onChangeStatus(accountId: number): void;
}

export function SuperUserChangeUserStatusModal(props: SuperUserChangeUserStatusModalProps): ReactElement {
  function getStatusText(): string {
    if (props.account?.status === UserStatus.Active) {
      return "Active";
    }
    return "Inactive";
  }

  function onChangeStatus(): void {
    if (props.account) {
      props.onChangeStatus(props.account.id);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle}>
      <ModalHeader toggle={props.onToggle}>Change Status</ModalHeader>
      <ModalBody>
        {props.userName} is <strong>{getStatusText()}</strong> for account <strong>{props.account?.name}</strong>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onChangeStatus} className="me-1">
          {props.account?.status === UserStatus.Active && <FormattedMessage id="deactivate" />}
          {props.account?.status === UserStatus.Inactive && <FormattedMessage id="activate" />}
        </Button>
        <CancelButton onClick={props.onToggle} />
      </ModalFooter>
    </Modal>
  );
}
