import { useState } from "react";
import { FreezerBoxDisplayFormat, StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { StorageLocationTreeView } from "../types/views";

export interface ManageStorageLocationProps {
  storageLocation?: StorageLocationTreeView;
  storageLocations: StorageLocationTreeView[];

  onLocationAdded(storageLocation: StorageLocation): void;

  onLocationUpdated(storageLocation: StorageLocation): void;

  onLocationDeleted(storageLocation: StorageLocation): void;

  isLocationNameDuplicated(name: string, id: number, parentId: number | null): boolean;

  onDuplicateStorageLocation(location: StorageLocationTreeView): Promise<boolean>;
}

export interface ManageStorageLocationHooks {
  currentEditingLocation?: StorageLocationTreeView;
  addingChild: boolean;
  isFreezerBox: boolean;
  isLocationNameValid: boolean;
  isSubLocationNameValid: boolean;
  isDeleting: boolean;
  locationNameDuplicated: boolean;
  storageLocationBeingDuplicated: StorageLocationTreeView | undefined;
  isDuplicateError: boolean;

  onLocationNameChanged(name: string): void;

  onSublocationNameChanged(name: string): void;

  onLocationFreezerBoxChanged(numberOfRows: number, numberOfColumns: number, format: FreezerBoxDisplayFormat | null): void;

  onSubLocationFreezerBoxChanged(numberOfRows: number, numberOfColumns: number, format: FreezerBoxDisplayFormat | null): void;

  onSave(): void;

  onItemMoved(sourceItem: StorageLocationTreeView, newParent: StorageLocationTreeView): void;

  onDelete(storageLocation: StorageLocation): void;

  onDeletePrompt(): void;

  onCancelDeletePrompt(): void;

  onCancelEdit(): void;

  onToggleFreezerBox(checked: boolean): void;

  onToggleAddChild(): void;

  onToggleEdit(item: StorageLocationTreeView): void;

  onShowDuplicatePrompt(location: StorageLocationTreeView): void;

  onDuplicatePromptCancel(): void;

  onDuplicateStorageLocation(location: StorageLocationTreeView): Promise<void>;
}

export function useManageStorageLocation(props: ManageStorageLocationProps): ManageStorageLocationHooks {
  const [currentEditingLocation, setCurrentEditingLocation] = useState<undefined | StorageLocationTreeView>(undefined);
  const [addingChild, setAddingChild] = useState(false);
  const [isFreezerBox, setIsFreezerBox] = useState(props.storageLocation ? props.storageLocation.location.numberOfRows !== null : false);
  const [locationName, setLocationName] = useState(props.storageLocation ? props.storageLocation.location.name : "");
  const [locationRows, setLocationRows] = useState<number | null>(props.storageLocation ? props.storageLocation.location.numberOfRows : null);
  const [locationCols, setLocationCols] = useState<number | null>(props.storageLocation ? props.storageLocation.location.numberOfColumns : null);
  const [locationDisplayFormat, setLocationDisplayFormat] = useState<FreezerBoxDisplayFormat | null>(
    props.storageLocation ? props.storageLocation.location.freezerBoxDisplayFormat : null,
  );
  const [subLocationName, setSubLocationName] = useState("");
  const [subLocationRows, setSubLocationRows] = useState<number | null>(null);
  const [subLocationCols, setSubLocationCols] = useState<number | null>(null);
  const [subLocationDisplayFormat, setSubLocationDisplayFormat] = useState<FreezerBoxDisplayFormat | null>(null);
  const [isLocationNameValid, setIsLocationNameValid] = useState(true);
  const [isSubLocationNameValid, setIsSubLocationNameValid] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [locationNameDuplicated, setLocationNameDuplicated] = useState(false);
  const [storageLocationBeingDuplicated, setStorageLocationBeingDuplicated] = useState<StorageLocationTreeView | undefined>(undefined);
  const [isDuplicateError, setIsDuplicateError] = useState(false);

  function onShowDuplicatePrompt(location: StorageLocationTreeView): void {
    setIsDuplicateError(false);
    setStorageLocationBeingDuplicated(location);
  }

  function onDuplicatePromptCancel(): void {
    setStorageLocationBeingDuplicated(undefined);
  }

  async function onDuplicateStorageLocation(location: StorageLocationTreeView): Promise<void> {
    const isDuplicated = await props.onDuplicateStorageLocation(location);
    if (isDuplicated) {
      setIsDuplicateError(false);
      setStorageLocationBeingDuplicated(undefined);
    } else {
      setIsDuplicateError(true);
    }
  }

  function onLocationNameChanged(name: string): void {
    const currentLocationId = currentEditingLocation ? currentEditingLocation.location.id : 0;
    const currentParentId = currentEditingLocation ? currentEditingLocation.location.parentId : null;
    setLocationName(name);
    setIsLocationNameValid(name.trim() !== "" && !props.isLocationNameDuplicated(name, currentLocationId, currentParentId));
  }

  function onSublocationNameChanged(name: string): void {
    const currentParentId = currentEditingLocation ? currentEditingLocation.location.id : null;
    setSubLocationName(name);
    setIsSubLocationNameValid(name.trim() !== "" && !props.isLocationNameDuplicated(name, 0, currentParentId));
  }

  function onLocationFreezerBoxChanged(numberOfRows: number, numberOfColumns: number, format: FreezerBoxDisplayFormat | null): void {
    setLocationRows(numberOfRows);
    setLocationCols(numberOfColumns);
    setLocationDisplayFormat(format);
  }

  function onSubLocationFreezerBoxChanged(numberOfRows: number, numberOfColumns: number, format: FreezerBoxDisplayFormat | null): void {
    setSubLocationRows(numberOfRows);
    setSubLocationCols(numberOfColumns);
    setSubLocationDisplayFormat(format);
  }

  function saveLocation(currentLocation: StorageLocationTreeView): void {
    if (
      locationName.trim() !== currentLocation.location.name.trim() ||
      locationCols !== currentLocation.location.numberOfColumns ||
      locationRows !== currentLocation.location.numberOfRows ||
      locationDisplayFormat !== currentLocation.location.freezerBoxDisplayFormat
    ) {
      props.onLocationUpdated({
        id: currentLocation.location.id,
        name: locationName.trim(),
        parentId: currentLocation.location.parentId,
        numberOfRows: locationRows || null,
        numberOfColumns: locationCols || null,
        freezerBoxDisplayFormat: locationDisplayFormat || null,
      });
    }

    if (addingChild) {
      props.onLocationAdded({
        id: 0,
        name: subLocationName.trim(),
        parentId: currentLocation.location.id,
        numberOfRows: isFreezerBox ? subLocationRows : null,
        numberOfColumns: isFreezerBox ? subLocationCols : null,
        freezerBoxDisplayFormat: isFreezerBox ? subLocationDisplayFormat : null,
      });
    }

    onCancelEdit();
  }

  function onSave(): void {
    if (!props.storageLocation || !currentEditingLocation) {
      return;
    }

    if (locationName.trim() === "" || !isLocationNameValid) {
      setIsLocationNameValid(false);
      return;
    }

    if (addingChild && (subLocationName.trim() === "" || !isSubLocationNameValid)) {
      setIsSubLocationNameValid(false);
      return;
    }

    saveLocation(currentEditingLocation);
  }

  function onItemMoved(sourceItem: StorageLocationTreeView, newParent: StorageLocationTreeView): void {
    const sourceLocation = sourceItem.location;
    props.onLocationUpdated({
      id: sourceLocation.id,
      name: sourceLocation.name,
      numberOfRows: sourceLocation.numberOfRows,
      numberOfColumns: sourceLocation.numberOfColumns,
      freezerBoxDisplayFormat: sourceLocation.freezerBoxDisplayFormat,
      parentId: newParent.location.id,
    });
  }

  function onDelete(storageLocation: StorageLocation): void {
    props.onLocationDeleted(storageLocation);
    setIsDeleting(false);
  }

  function onCancelEdit(): void {
    setCurrentEditingLocation(undefined);
    setLocationNameDuplicated(false);
  }

  function onDeletePrompt(): void {
    setIsDeleting(true);
  }

  function onCancelDeletePrompt(): void {
    setIsDeleting(false);
  }

  function onToggleFreezerBox(checked: boolean): void {
    setIsFreezerBox(checked);
    setSubLocationCols(addingChild && checked ? 9 : null);
    setSubLocationRows(addingChild && checked ? 9 : null);
    setSubLocationDisplayFormat(addingChild && checked ? FreezerBoxDisplayFormat.Default : null);
  }

  function onToggleAddChild(): void {
    setAddingChild(true);
    setSubLocationName("");
    setSubLocationRows(null);
    setSubLocationCols(null);
    setLocationDisplayFormat(null);
  }

  function onToggleEdit(item: StorageLocationTreeView): void {
    setCurrentEditingLocation(item);
    setAddingChild(false);
    setIsFreezerBox(item.location.numberOfRows !== null);
    setLocationName(item.location.name.trim());
    setLocationDisplayFormat(item.location.freezerBoxDisplayFormat);
    setLocationRows(item.location.numberOfRows);
    setLocationCols(item.location.numberOfColumns);
    setSubLocationRows(null);
    setSubLocationCols(null);
    setIsLocationNameValid(true);
    setIsSubLocationNameValid(true);
    setLocationNameDuplicated(false);
  }

  return {
    currentEditingLocation,
    addingChild,
    isFreezerBox,
    isLocationNameValid,
    isSubLocationNameValid,
    isDeleting,
    locationNameDuplicated,
    storageLocationBeingDuplicated,
    isDuplicateError,
    onLocationNameChanged,
    onSublocationNameChanged,
    onLocationFreezerBoxChanged,
    onSubLocationFreezerBoxChanged,
    onSave,
    onDelete,
    onCancelEdit,
    onDeletePrompt,
    onCancelDeletePrompt,
    onToggleFreezerBox,
    onToggleAddChild,
    onToggleEdit,
    onShowDuplicatePrompt,
    onDuplicatePromptCancel,
    onDuplicateStorageLocation,
    onItemMoved,
  };
}
