import React, { ChangeEvent, ReactElement, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { OrderStatus, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { RouteComponentProps } from "react-router";
import { Loading } from "../components";
import { history } from "../app/history";
import { InventoryTypesContext } from "../inventorytypes/InventoryTypesContext";
import "./Order.scss";
import { StorageContext } from "../storage/StorageContext";
import { RequiredLabelIndicator } from "../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { DatePicker } from "../components/DatePicker/DatePicker";
import { AuthorizedComponent } from "../components/Authentication/AuthorizedComponent";
import { userCanEditOrderCheck } from "../components/Authentication/permissionChecks/userCanEditOrderCheck";
import { CreatableVendorsDropdown } from "../vendors/dropdown/CreatableVendorsDropdown";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { userCanReceiveOrder } from "../components/Authentication/permissionChecks/userCanReceiveOrderCheck";
import { userCanCancelOrder } from "../components/Authentication/permissionChecks/userCanCancelOrder";
import { FlashAlertMessage } from "../components/FlashAlertMessage/FlashAlertMessage";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { userCanViewOrderCheck } from "../components/Authentication/permissionChecks/userCanViewOrderCheck";
import { onPageLoad } from "../utils/onPageLoad";
import { InventoryApiClient } from "../api";
import { OrderStatusLabel } from "./OrderStatusLabel";
import { ModalApproval } from "./modals/ModalApproval";
import { ModalOrder } from "./modals/ModalOrder";
import { ModalReceive } from "./modals/ModalReceive";
import { useOrder } from "./OrderHooks";
import { OrderActionModals } from "./modals/OrderActionModals";
import { ModalCancel } from "./modals/ModalCancel";

interface RouteParams {
  id: string;
}

export function Order(props: RouteComponentProps<RouteParams>): ReactElement {
  const authState = useContext(AuthenticationContext);
  const inventoryTypesState = useContext(InventoryTypesContext);
  const storageState = useContext(StorageContext);
  const intl = useIntl();
  const api = new InventoryApiClient();

  const {
    orderView,
    isLoading,
    formInputs,
    totalPrice,
    openModalId,
    units,
    isSuccessAlertOpen,
    isUpdatingOrder,
    isAddingNote,
    inventoryTypeViews,
    storageLocations,
    isStorageLocationRequired,
    onOrderApproval,
    onOrderPlaced,
    onOrderReceived,
    onOrderCancelled,
    onNoteAdded,
    toggleAddNote,
    onOrderSubmitted,
    onInputChange,
    toggleModal,
    isModalOpen,
    setIsSuccessAlertOpen,
  } = useOrder(Number.parseInt(props.match.params.id), authState, inventoryTypesState, storageState, api);

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.order" }));
  }, []);

  const canEdit = userCanEditOrderCheck(orderView.requestedById, orderView.status)(authState);
  const canView = userCanViewOrderCheck(orderView.requestedById)(authState);

  function highlightFirst(firstInvalid: HTMLInputElement): void {
    window.scrollTo({ top: firstInvalid.offsetTop - 30 });
    firstInvalid.focus();
  }

  function submitOrder(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    onOrderSubmitted(highlightFirst);
  }

  function onFormInputChange(fieldName: string, e: ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value.trim();

    onInputChange(fieldName, value);
  }

  function onDateChange(fieldName: string, date: Date | null): void {
    onInputChange(fieldName, date ? moment(date).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "");
  }

  function changeVendor(vendorId: number | null): void {
    onInputChange(formInputs.vendorId.fieldName, vendorId !== 0 && vendorId !== null ? vendorId.toString() : "");
  }

  function onClose(): void {
    history.goBack();
  }

  function getActionButton(status: OrderStatus, requestedById: number): ReactElement {
    if (status === OrderStatus.Cancelled) {
      return <></>;
    }

    if (status === OrderStatus.Requested) {
      return (
        <AuthorizedComponent requiredPermissions={[RolePermissions.OrdersUpdateAll, RolePermissions.OrdersApproveAll]}>
          <Button onClick={() => toggleModal(OrderActionModals.Approval)} color="primary">
            <FormattedMessage id="approve" />
          </Button>
        </AuthorizedComponent>
      );
    }
    if (status === OrderStatus.Approved) {
      return (
        <AuthorizedComponent requiredPermissions={[RolePermissions.OrdersUpdateAll]}>
          <Button onClick={() => toggleModal(OrderActionModals.Order)} color="primary">
            <FormattedMessage id="mark.ordered" />
          </Button>
        </AuthorizedComponent>
      );
    }
    if (status === OrderStatus.Ordered) {
      return (
        <AuthorizedComponent requiredPermissions={[]} additionalChecks={userCanReceiveOrder(requestedById)}>
          <Button onClick={() => toggleModal(OrderActionModals.Receive)} color="primary">
            <FormattedMessage id="mark.received" />
          </Button>
        </AuthorizedComponent>
      );
    }

    return <></>;
  }

  function getCancelButton(status: OrderStatus, requestedById: number): ReactElement {
    if (status !== OrderStatus.Received && status !== OrderStatus.Cancelled) {
      return (
        <AuthorizedComponent requiredPermissions={[]} additionalChecks={userCanCancelOrder(requestedById, status)}>
          <Button onClick={() => toggleModal(OrderActionModals.Cancel)} color="primary">
            <FormattedMessage id="mark.cancelled" />
          </Button>
        </AuthorizedComponent>
      );
    }

    return <></>;
  }

  if (isLoading && openModalId === "" && !isUpdatingOrder) {
    return <Loading />;
  }

  const disabled = orderView.status === OrderStatus.Cancelled || (!canEdit && canView);

  let noteInput: HTMLInputElement;

  return (
    <AuthorizedComponent requiredPermissions={[]} additionalChecks={userCanViewOrderCheck(orderView.requestedById)} showUnauthorizedMessage>
      <Row>
        <Col lg={12} xl={{ size: 10, offset: 1 }}>
          <Form id="order-request-form" onSubmit={(e) => submitOrder(e)} noValidate>
            <div>
              <div className="order-container-header faux-modal-header">
                <h1 className="faux-modal-title">
                  <FormattedMessage id="order.request" />
                </h1>
                <div className="order-container-header-status">
                  <div className="d-none d-sm-inline">
                    <span className="order-container-header-status-label">Status:</span>
                    <OrderStatusLabel className="order-container-header-status-description" statusId={orderView.status} />
                  </div>
                  {getActionButton(orderView.status, orderView.requestedById)}
                  {getCancelButton(orderView.status, orderView.requestedById)}
                </div>
                <div className="faux-modal-close">
                  <Button type="button" onClick={onClose} aria-label={intl.formatMessage({ id: "cancel" })}>
                    ×
                  </Button>
                </div>
              </div>
              <div className="faux-modal-body">
                {isSuccessAlertOpen && (
                  <Row>
                    <Col>
                      <FlashAlertMessage isOpen={isSuccessAlertOpen} onDismiss={() => setIsSuccessAlertOpen(false)} alertColor="success" />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="order-request-note-text alert alert-info">
                    <div>
                      <FormattedMessage id="order.request.on.hand" values={{ onHand: orderView.quantityOnHand }} />
                      {orderView.quantityOnHand > 0 && orderView.sourceInventoryId && (
                        <Link to={ApplicationPaths.Inventory.Item(orderView.sourceInventoryId)} className="order-view-inventory">
                          <FormattedMessage id="view.inventory" />
                        </Link>
                      )}
                    </div>
                    {orderView.lastOrderedDate && (
                      <div>
                        <FormattedMessage id="order.request.last.ordered" values={{ lastOrdered: intl.formatDate(orderView.lastOrderedDate) }} />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    <FormGroup>
                      <Label for="order-item-name">
                        <FormattedMessage id="product.name" />
                        <RequiredLabelIndicator required={formInputs.inventoryName.required} />
                      </Label>
                      <Input
                        type="text"
                        id="order-item-name"
                        defaultValue={formInputs.inventoryName.value}
                        name={formInputs.inventoryName.fieldName}
                        required={formInputs.inventoryName.required}
                        invalid={!formInputs.inventoryName.isValid}
                        onChange={(e) => onFormInputChange(formInputs.inventoryName.fieldName, e)}
                        innerRef={formInputs.inventoryName.ref}
                        disabled={disabled}
                        maxLength={500}
                      />
                      <FormFeedback>
                        <FormattedMessage id="product.name.is.required" />
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="order-item-type">
                        <FormattedMessage id="type" />
                        <RequiredLabelIndicator required={formInputs.inventoryTypeId.required} />
                      </Label>
                      <Input
                        type="select"
                        id="order-item-type"
                        defaultValue={formInputs.inventoryTypeId.value}
                        name={formInputs.inventoryTypeId.fieldName}
                        required={formInputs.inventoryTypeId.required}
                        invalid={!formInputs.inventoryTypeId.isValid}
                        onChange={(e) => onFormInputChange(formInputs.inventoryTypeId.fieldName, e)}
                        innerRef={formInputs.inventoryTypeId.ref}
                        disabled={disabled}
                      >
                        {inventoryTypeViews.map((t) => (
                          <option key={t.name} value={t.id}>
                            {t.name}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>
                        <FormattedMessage id="item.type.is.required" />
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="order-catalog-number">
                        <FormattedMessage id="catalog.number" />
                        <RequiredLabelIndicator required={formInputs.catalogNumber.required} />
                      </Label>
                      <Input
                        type="text"
                        id="order-catalog-number"
                        defaultValue={formInputs.catalogNumber.value}
                        name={formInputs.catalogNumber.fieldName}
                        required={formInputs.catalogNumber.required}
                        invalid={!formInputs.catalogNumber.isValid}
                        onChange={(e) => onFormInputChange(formInputs.catalogNumber.fieldName, e)}
                        innerRef={formInputs.catalogNumber.ref}
                        disabled={disabled}
                        maxLength={255}
                      />
                      <FormFeedback>
                        <FormattedMessage id="catalog.number.is.required" />
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="order-vendor">
                        <FormattedMessage id="vendor" />
                        <RequiredLabelIndicator required={formInputs.vendorId.required} />
                      </Label>
                      <CreatableVendorsDropdown
                        isValid={formInputs.vendorId.isValid}
                        selectedVendorId={formInputs.vendorId.value}
                        innerRef={formInputs.vendorId.ref}
                        onChange={changeVendor}
                        id="order-vendor"
                        disabled={disabled}
                      />
                      <div className="invalid-feedback" style={{ display: formInputs.vendorId.isValid ? "none" : "inline-block" }}>
                        <FormattedMessage id="vendor.is.required" />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="order-po-number">
                        <FormattedMessage id="po.number" />
                        <RequiredLabelIndicator required={formInputs.poNumber.required} />
                      </Label>
                      <Input
                        type="text"
                        id="order-po-number"
                        defaultValue={formInputs.poNumber.value}
                        name={formInputs.poNumber.fieldName}
                        required={formInputs.poNumber.required}
                        invalid={!formInputs.poNumber.isValid}
                        onChange={(e) => onFormInputChange(formInputs.poNumber.fieldName, e)}
                        innerRef={formInputs.poNumber.ref}
                        disabled={disabled}
                        maxLength={255}
                      />
                      <FormFeedback>
                        <FormattedMessage id="po.number.is.required" />
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="order-grant-number">
                        <FormattedMessage id="grant.number" />
                        <RequiredLabelIndicator required={formInputs.grantNumber.required} />
                      </Label>
                      <Input
                        type="text"
                        id="order-grant-number"
                        defaultValue={formInputs.grantNumber.value}
                        name={formInputs.grantNumber.fieldName}
                        required={formInputs.grantNumber.required}
                        invalid={!formInputs.grantNumber.isValid}
                        onChange={(e) => onFormInputChange(formInputs.grantNumber.fieldName, e)}
                        innerRef={formInputs.grantNumber.ref}
                        disabled={disabled}
                        maxLength={255}
                      />
                      <FormFeedback>
                        <FormattedMessage id="grant.number.is.required" />
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="order-date-required">
                        <FormattedMessage id="date.required" />
                        <RequiredLabelIndicator required={formInputs.requiredDate.required} />
                      </Label>
                      <DatePicker
                        id="order-date-required"
                        selected={formInputs.requiredDate.value}
                        name={formInputs.requiredDate.fieldName}
                        required={formInputs.requiredDate.required}
                        invalid={!formInputs.requiredDate.isValid}
                        onChange={(date) => onDateChange(formInputs.requiredDate.fieldName, date)}
                        innerRef={formInputs.requiredDate.ref}
                        disabled={disabled}
                      />
                    </FormGroup>
                    <FormFeedback>
                      <FormattedMessage id="date.required.is.required" />
                    </FormFeedback>
                  </Col>
                  <Col>
                    <Col className="order-total-container" lg={{ size: 6, offset: 6 }}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="order-quantity">
                              <FormattedMessage id="quantity" />
                              <RequiredLabelIndicator required={formInputs.quantity.required} />
                            </Label>
                            <Input
                              type="number"
                              id="order-quantity"
                              defaultValue={formInputs.quantity.value}
                              name={formInputs.quantity.fieldName}
                              onChange={(e) => onFormInputChange(formInputs.quantity.fieldName, e)}
                              required={formInputs.quantity.required}
                              invalid={!formInputs.quantity.isValid}
                              min={1}
                              step="any"
                              innerRef={formInputs.quantity.ref}
                              disabled={disabled}
                            />
                            <FormFeedback>
                              <FormattedMessage id="quantity.is.required" />
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="order-price">
                              <FormattedMessage id="price" />
                              <RequiredLabelIndicator required={formInputs.price.required} />
                            </Label>
                            <Input
                              type="number"
                              id="order-price"
                              name={formInputs.price.fieldName}
                              defaultValue={Number.parseFloat(formInputs.price.value).toString()}
                              required={formInputs.price.required}
                              invalid={!formInputs.price.isValid}
                              onChange={(e) => onFormInputChange(formInputs.price.fieldName, e)}
                              min={0}
                              step="any"
                              innerRef={formInputs.price.ref}
                              disabled={disabled}
                            />
                            <FormFeedback>
                              <FormattedMessage id="price.is.required" />
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="order-total-line">
                          <hr />
                          <FormattedNumber value={totalPrice} style="currency" currency={authState.getCurrency()} />
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                <Row className="order-notes-history">
                  <Col xs={12} sm={6}>
                    <span className="label-secondary order-notes-title">
                      <FormattedMessage id="notes" />
                    </span>
                    {!disabled && (
                      <Button color="link" onClick={toggleAddNote} title={intl.formatMessage({ id: "add.note" })}>
                        <FontAwesomeIcon icon={faPlusCircle} size="sm" />
                      </Button>
                    )}
                    {isAddingNote && (
                      <div>
                        <FormGroup>
                          <Label for="order-add-note" className="visually-hidden">
                            <FormattedMessage id="note" />
                          </Label>
                          <Input
                            type="textarea"
                            id="order-add-note"
                            innerRef={(node: HTMLInputElement) => {
                              noteInput = node;
                            }}
                            autoFocus
                          />
                        </FormGroup>
                        <Button size="sm" onClick={() => onNoteAdded(noteInput.value.trim())} color="primary" className="me-1">
                          <FormattedMessage id="add.note" />
                        </Button>
                        <CancelButton size="sm" onClick={toggleAddNote} />
                      </div>
                    )}
                    {orderView.notes.map((note) => (
                      <div key={note.noteDate.toString()} className="history-item">
                        <span className="history-item-date">
                          <FormattedDate
                            value={note.noteDate}
                            year="numeric"
                            month="numeric"
                            day="numeric"
                            hour="numeric"
                            minute="numeric"
                            timeZoneName="short"
                          />
                        </span>
                        {note.userName !== "" && <span className="history-item-user">{note.userName}</span>}
                        <span className="history-item-description">{note.note}</span>
                      </div>
                    ))}
                  </Col>
                  <Col>
                    <span className="label-secondary">
                      <FormattedMessage id="history" />
                    </span>
                    {orderView.history.map((item) => (
                      <div key={item.activityDate.toString()} className="history-item">
                        <span className="history-item-date">
                          <FormattedDate
                            value={item.activityDate}
                            year="numeric"
                            month="numeric"
                            day="numeric"
                            hour="numeric"
                            minute="numeric"
                            timeZoneName="short"
                          />
                        </span>
                        {item.userName !== "" && <span className="history-item-user">{item.userName}</span>}
                        <span className="history-item-description">{item.description}</span>
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>
              {orderView.status !== OrderStatus.Received && orderView.status !== OrderStatus.Cancelled && (
                <div className="faux-modal-footer">
                  <Row>
                    <Col className="align-right">
                      {isLoading && isUpdatingOrder && <Loading messageId="updating.order" />}
                      {!isLoading && !disabled && (
                        <>
                          <Button type="submit" className="me-1" color="primary">
                            <FormattedMessage id="update.order" />
                          </Button>
                          <CancelButton onClick={onClose} />
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Form>
          <ModalApproval
            onOrderApproval={onOrderApproval}
            orderId={orderView.id}
            requestedByName={orderView.requestedByName}
            notes={orderView.notes.length > 0 ? orderView.notes[0].note : ""}
            quantityOnHand={orderView.quantityOnHand}
            price={Number.parseFloat(formInputs.price.value)}
            quantity={Number.parseFloat(formInputs.quantity.value)}
            total={totalPrice}
            inventoryName={orderView.inventoryName}
            modalId={OrderActionModals.Approval}
            isModalOpen={(id) => isModalOpen(id, OrderStatus.Requested)}
            toggleModal={toggleModal}
            isProcessing={isLoading}
            errorMessage=""
            dateLastOrdered={orderView.lastOrderedDate}
            currency={authState.getCurrency()}
          />

          <ModalOrder
            modalId={OrderActionModals.Order}
            isModalOpen={(id) => isModalOpen(id, OrderStatus.Approved)}
            toggleModal={toggleModal}
            isProcessing={isLoading}
            onOrderPlaced={onOrderPlaced}
            errorMessage=""
            orderId={orderView.id}
            quantity={Number.parseFloat(formInputs.quantity.value)}
            price={Number.parseFloat(formInputs.price.value)}
            inventoryName={orderView.inventoryName}
          />

          <ModalReceive
            modalId={OrderActionModals.Receive}
            isModalOpen={(id) => isModalOpen(id, OrderStatus.Ordered)}
            toggleModal={toggleModal}
            isProcessing={isLoading}
            onOrderReceived={onOrderReceived}
            errorMessage=""
            orderId={orderView.id}
            price={Number.parseFloat(formInputs.price.value)}
            quantity={Number.parseFloat(formInputs.quantity.value)}
            requestedByName={orderView.requestedByName}
            requestedById={orderView.requestedById}
            inventoryName={orderView.inventoryName}
            storage={storageLocations}
            units={units}
            unitOfMeasure={orderView.unitOfMeasure}
            api={api}
            isStorageLocationRequired={isStorageLocationRequired}
          />

          <ModalCancel
            modalId={OrderActionModals.Cancel}
            isModalOpen={() => openModalId === OrderActionModals.Cancel && orderView.status !== OrderStatus.Cancelled}
            toggleModal={toggleModal}
            isProcessing={isLoading}
            onOrderCancelled={onOrderCancelled}
            errorMessage=""
            orderId={orderView.id}
            price={Number.parseFloat(formInputs.price.value)}
            quantity={Number.parseFloat(formInputs.quantity.value)}
            requestedByName={orderView.requestedByName}
            inventoryName={orderView.inventoryName}
            unitOfMeasure={orderView.unitOfMeasure}
          />
        </Col>
      </Row>
    </AuthorizedComponent>
  );
}
