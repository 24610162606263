import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { AuthorizedComponentCheck } from "../AuthorizedComponent";

export function userCanReceiveOrder(orderRequestedById: number | null): AuthorizedComponentCheck {
  return (auth) => {
    if (auth.hasPermissions([RolePermissions.OrdersUpdateAll, RolePermissions.OrdersReceiveAll])) {
      return true;
    }

    const session = auth.getUser();
    return session !== undefined && session.id === orderRequestedById && auth.hasPermissions([RolePermissions.OrdersReceiveOwn]);
  };
}
