/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InventorySearchAutoSuggestItem } from "@labarchives/inventory-shared/build/inventory";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "./InventoryAutocomplete.scss";
import { InventoryApi } from "../../api/InventoryApi";
import { useInventoryAutocomplete } from "./InventoryAutocompleteHooks";

export interface InventoryAutocompleteProps {
  onItemSelected: (item: InventorySearchAutoSuggestItem) => void;
  onInputChange?: (text: string, event: Event) => void;

  placeholderMessageId?: string;
  clearOnSelect?: boolean;
  defaultValue?: string;
  api: InventoryApi;
}

export function InventoryAutocomplete(props: InventoryAutocompleteProps): ReactElement {
  const { isLoading, options, onSearch } = useInventoryAutocomplete(props.api);
  const intl = useIntl();

  function noOp(): void {
    // no-op
  }

  const componentRef = useRef<any>(null);
  // @ts-ignore
  return (
    <span aria-live="assertive">
      <AsyncTypeahead
        labelKey="name"
        // @ts-ignore
        renderMenuItemChildren={(option: InventorySearchAutoSuggestItem, typeaheadContainerProps: any, index: any) => {
          const catalogNumber = option.catalogNumber ? (
            <div key={`${index}catalog`}>
              <small>
                <FormattedMessage id="catalog.number" />:{" "}
                <Highlighter key={`${option.id}catalog`} search={typeaheadContainerProps.text}>
                  {option.catalogNumber}
                </Highlighter>
              </small>
            </div>
          ) : (
            <span key={`${index}catalog`} />
          );
          const type = option.typeName ? (
            <div key={`${index}type`}>
              <small>
                <FormattedMessage id="type" />:{" "}
                <Highlighter key={`${option.id}type`} search={typeaheadContainerProps.text}>
                  {option.typeName}
                </Highlighter>
              </small>
            </div>
          ) : (
            <span key={`${index}type`} />
          );
          return (
            <>
              <Highlighter key={option.id} search={typeaheadContainerProps.text}>
                {option.name}
              </Highlighter>
              {type}
              {catalogNumber}
            </>
          );
        }}
        multiple={false}
        onSearch={onSearch}
        aria-label={intl.formatMessage({ id: props.placeholderMessageId || "search.inventory" })}
        placeholder={intl.formatMessage({ id: props.placeholderMessageId || "autocomplete.find.inventory" })}
        maxResults={100}
        isLoading={isLoading}
        options={options}
        id="inventory-autocomplete"
        ref={componentRef}
        filterBy={["name", "catalogNumber", "typeName"]}
        onChange={(selected) => {
          if (selected.length > 0) {
            // @ts-ignore
            props.onItemSelected(selected[0]);
            if (props.clearOnSelect && componentRef.current) {
              componentRef.current!.clear();
            }
          }
        }}
        clearButton
        // @ts-ignore
        onInputChange={props.onInputChange || noOp}
        defaultInputValue={props.defaultValue || ""}
        inputProps={{ value: props.defaultValue || "" }}
        minLength={3}
      />
    </span>
  );
}
