import moment from "moment";
import {
  AccountSubscriptionSummary,
  ApplicationPricing,
  SubscriptionType,
  SubscriptionTotal,
  OrganizationBillingType,
} from "@labarchives/inventory-shared/build/inventory";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import {
  SubscriptionProducts,
  SubscriptionAdditionalUserDetails,
  SubscriptionRenewalDetails,
} from "@labarchives/inventory-shared/build/subscription";
import {
  AccountSubscriptionSummaryView,
  ApplicationPricingView,
  ApplicationTypePricingView,
  BundlePricingView,
  SubscriptionTotalView,
} from "./views";

export const getDefaultApplicationPricingView = (): ApplicationPricingView => {
  function forBillingType(): ApplicationTypePricingView {
    return {
      oneYearCostPerUser: 0,
      twoYearCostPerUser: 0,
      multiYearCostPerUser: 0,
    };
  }

  return { forBillingType };
};

export const getApplicationPricingView = (pricing?: ApplicationPricing): ApplicationPricingView => {
  if (!pricing) {
    return getDefaultApplicationPricingView();
  }

  function forBillingType(billingType: OrganizationBillingType): ApplicationTypePricingView {
    if (!pricing || !pricing[billingType]) {
      return {
        oneYearCostPerUser: 0,
        twoYearCostPerUser: 0,
        multiYearCostPerUser: 0,
      };
    }

    return {
      oneYearCostPerUser: pricing[billingType].oneYearCostPerUser,
      twoYearCostPerUser: pricing[billingType].twoYearCostPerUser,
      multiYearCostPerUser: pricing[billingType].multiYearCostPerUser,
    };
  }

  return { forBillingType };
};

export const getBundlePricingView = (subscriptionProducts: SubscriptionProducts, bundleProductCode: string): BundlePricingView => {
  const product = subscriptionProducts.products.find((p) => p.productCode === bundleProductCode)!;
  return {
    academic: product.pricing.find((p) => p.billingTypeId === OrganizationBillingType.Academic)!.perSeatCost,
    commercial: product.pricing.find((p) => p.billingTypeId === OrganizationBillingType.Commercial)!.perSeatCost,
  };
};

export const getDefaultAccountSubscriptionSummaryView = (): AccountSubscriptionSummaryView => {
  return {
    beginDate: new Date(1970, 0, 1),
    endDate: new Date(1970, 0, 1),
    isFreeTrial: true,
    seatCount: 0,
    canBeRenewed: false,
    billingHistory: [],
    numberOfYears: 0,
    organizationBillingType: OrganizationBillingType.Academic,
    isExpired: true,
    productName: "",
    labArchivesSubscriptionId: undefined,
  };
};

export const getAccountSubscriptionSummaryView = (subscriptionSummary?: AccountSubscriptionSummary): AccountSubscriptionSummaryView => {
  if (!subscriptionSummary) {
    return getDefaultAccountSubscriptionSummaryView();
  }

  return {
    beginDate: subscriptionSummary.subscription.begin,
    endDate: subscriptionSummary.subscription.end,
    isFreeTrial: subscriptionSummary.subscription.type === SubscriptionType.Free,
    seatCount: subscriptionSummary.subscription.seatCount || 0,
    canBeRenewed: subscriptionSummary.canBeRenewed,
    upcomingSubscriptionBeginDate: subscriptionSummary.upcomingSubscription?.begin,
    upcomingSubscriptionEndDate: subscriptionSummary.upcomingSubscription?.end,
    productName: subscriptionSummary.productName,
    labArchivesSubscriptionId: subscriptionSummary.subscription.labArchivesSubscriptionId,
    billingHistory: [...subscriptionSummary.payments]
      .sort((p1, p2) => p2.date.getTime() - p1.date.getTime())
      .map((p) => {
        return {
          transactionDate: p.date,
          description: p.description,
          total: p.total,
          action: p.action,
          seatCount: p.seatCount,
          perSeatPrice: p.perSeatPrice,
        };
      }),
    numberOfYears: moment(subscriptionSummary.subscription.end).diff(moment(subscriptionSummary.subscription.begin), "years"),
    organizationBillingType: subscriptionSummary.organizationBillingType,
    isExpired: subscriptionSummary.subscription.end < clock.getNow(),
  };
};

export const getDefaultSubscriptionTotalView = (): SubscriptionTotalView => {
  return {
    total: 0,
    description: "",
  };
};

export const getSubscriptionTotalView = (subscriptionTotal?: SubscriptionTotal): SubscriptionTotalView => {
  if (!subscriptionTotal) {
    return getDefaultSubscriptionTotalView();
  }
  return {
    total: subscriptionTotal.total,
    description: subscriptionTotal.description,
  };
};

export const getSubscriptionTotalViewFromAdditionalUsers = (details?: SubscriptionAdditionalUserDetails): SubscriptionTotalView => {
  if (!details) {
    return getDefaultSubscriptionTotalView();
  }
  return {
    total: details.subtotal,
    description: details.description,
  };
};

export const getSubscriptionTotalViewFromRenewal = (details?: SubscriptionRenewalDetails): SubscriptionTotalView => {
  if (!details) {
    return getDefaultSubscriptionTotalView();
  }
  return {
    total: details.subtotal,
    description: details.description,
  };
};
