import { useState, useEffect, createContext } from "react";
import { Vendor } from "@labarchives/inventory-shared/build/inventory";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { createReduced, addIfNotExists } from "../utils";
import { NormalizedState } from "../utils/types";
import { Settings } from "../utils/Settings";
import { InventoryApi } from "../api/InventoryApi";
import { VendorState } from "./types/state";

const normalizedState: NormalizedState<Vendor> = {
  isLoading: true,
  byId: createReduced<Vendor>([]),
  allIds: [],
};

const defaultVendorState: VendorState = {
  ...normalizedState,
  onVendorAdded: (vendor: Vendor): Promise<Vendor> => Promise.resolve(vendor),
  onVendorUpdated: (vendor: Vendor): Promise<Vendor> => Promise.resolve(vendor),
  onVendorDeleted: (): Promise<void> => Promise.resolve(),
  refresh: () => Promise.resolve(),
};

export const VendorsContext = createContext<VendorState>(defaultVendorState);

export function useVendorsContext(api: InventoryApi): VendorState {
  const [localState, setLocalState] = useState<NormalizedState<Vendor>>(normalizedState);
  const [lastDataRefreshTime, setLastDataRefreshTime] = useState<Date>(new Date(0));

  const loadAllVendors = async (): Promise<void> => {
    setLocalState({ ...normalizedState, isLoading: true });
    await api.getVendors().then((vendors) => {
      const newState: NormalizedState<Vendor> = {
        allIds: vendors.map((vendor) => vendor.id),
        byId: createReduced<Vendor>(vendors),
        isLoading: false,
      };
      setLocalState(newState);
      setLastDataRefreshTime(clock.getNow());
      return vendors;
    });
  };

  const onVendorAdded = async (vendor: Vendor): Promise<Vendor> => {
    return api
      .addVendor(vendor)
      .then((addedVendor) => {
        const newState: NormalizedState<Vendor> = {
          allIds: addIfNotExists(localState.allIds, addedVendor.id),
          byId: { ...localState.byId, [addedVendor.id]: addedVendor },
          isLoading: false,
        };
        setLocalState(newState);
        return addedVendor;
      })
      .catch((error) => {
        loadAllVendors();
        throw error;
      });
  };

  const onVendorUpdated = async (vendor: Vendor): Promise<Vendor> => {
    return api
      .updateVendor(vendor)
      .then((updatedVendor) => {
        const newState: NormalizedState<Vendor> = {
          allIds: addIfNotExists(localState.allIds, updatedVendor.id),
          byId: { ...localState.byId, [updatedVendor.id]: updatedVendor },
          isLoading: false,
        };
        setLocalState(newState);
        return updatedVendor;
      })
      .catch((error) => {
        loadAllVendors();
        throw error;
      });
  };

  const onVendorDeleted = async (vendorId: number): Promise<void> => {
    return (
      api
        .deleteVendor(vendorId)
        // eslint-disable-next-line promise/always-return
        .then(() => {
          const newById = { ...localState.byId };
          delete newById[vendorId];
          const newState: NormalizedState<Vendor> = {
            allIds: localState.allIds.filter((id) => id !== vendorId),
            byId: newById,
            isLoading: false,
          };
          setLocalState(newState);
        })
        .catch((error) => {
          loadAllVendors();
          throw error;
        })
    );
  };

  const refresh = async (force = false): Promise<void> => {
    if (force || (!localState.isLoading && clock.addMinutes(lastDataRefreshTime, Settings.getDataCacheTimeout()) < clock.getNow())) {
      await loadAllVendors();
    }
  };

  useEffect(() => {
    loadAllVendors();
  }, []);

  return {
    isLoading: localState.isLoading,
    byId: localState.byId,
    allIds: localState.allIds,
    onVendorAdded,
    onVendorUpdated,
    onVendorDeleted,
    refresh,
  };
}
