/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from "reactstrap";
import classnames from "classnames";
import { Link, Route, RouteProps, Switch } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useContext, useEffect } from "react";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { AuthorizedComponent } from "../components/Authentication/AuthorizedComponent";
import "./Reports.scss";
import { getInventoryTypeViews } from "../inventorytypes/selectors";
import { getUserViews } from "../user/selectors";
import { getFormattedFreezerBoxCells, getStorageLocationView } from "../storage/selectors";
import { UserState } from "../user/types/state";
import { UsersContext } from "../user/UsersContext";
import { RoleState } from "../roles/types/state";
import { RolesContext } from "../roles/RolesContext";
import { InventoryTypesContext } from "../inventorytypes/InventoryTypesContext";
import { StorageContext } from "../storage/StorageContext";
import { onPageLoad } from "../utils/onPageLoad";
import { InventoryApiClient } from "../api";
import { LowQuantityReport } from "./LowQuantityReport";
import { OrdersReport } from "./OrdersReport";
import { useOrdersReportDownload } from "./OrdersReportDownloadHooks";
import { FreezerCapacityReport } from "./FreezerCapacityReport";
import { useFreezerCapacityReportDownload } from "./FreezerCapacityReportDownloadHooks";
import { useItemReportDownload } from "./ItemReportDownloadHooks";
import { ItemExpirationReport } from "./ItemExpirationReport";

function ReportsHome(): ReactElement {
  const users = useContext<UserState>(UsersContext);
  const roles = useContext<RoleState>(RolesContext);
  const types = useContext(InventoryTypesContext);
  const storage = useContext(StorageContext);
  const storageLocationView = getStorageLocationView(storage);
  const downloadHooks = useItemReportDownload({
    types: getInventoryTypeViews(types),
    users: getUserViews(users, roles),
    storage: storageLocationView,
    cellFormatter: getFormattedFreezerBoxCells,
  });
  const orderDownloadHooks = useOrdersReportDownload({
    userState: users,
    roleState: roles,
  });
  const freezerDownloadHooks = useFreezerCapacityReportDownload({
    storage: storageLocationView,
  });
  const api = new InventoryApiClient();

  return (
    <div>
      <h1>
        <FormattedMessage id={"available.reports"} />
      </h1>
      <ItemExpirationReport downloadHooks={downloadHooks} api={api} />
      <LowQuantityReport downloadHooks={downloadHooks} api={api} />
      <OrdersReport downloadHooks={orderDownloadHooks} api={api} />
      <FreezerCapacityReport downloadHooks={freezerDownloadHooks} api={api} />
    </div>
  );
}

export function Reports(props: RouteProps): ReactElement {
  const intl = useIntl();
  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.reports" }));
  }, []);

  return (
    <AuthorizedComponent requiredPermissions={[RolePermissions.LabManagementAllowed]}>
      <Row className="reports-container">
        <Col lg={2} xs={12} className="reports-container-list">
          <nav className="reports-nav">
            <ol>
              <li
                className={classnames({
                  "reports-container-list-item": true,
                  active: props.location && props.location.pathname.includes(ApplicationPaths.Reports.Home),
                })}
              >
                <Link to={ApplicationPaths.Reports.Home}>
                  <FormattedMessage id={"nav.reports"} />
                </Link>
              </li>
            </ol>
          </nav>
        </Col>
        <Col lg={10} className="reports-contents">
          <Switch>
            <Route path={ApplicationPaths.Reports.Home} component={ReportsHome} />
          </Switch>
        </Col>
      </Row>
    </AuthorizedComponent>
  );
}
