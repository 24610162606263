import { useMemo, useState } from "react";
import { StorageLocationTreeView, StorageLocationView } from "../types/views";
import { getIsFreezerBox } from "../selectors";
import { isLocationNameDuplicated } from "./isLocationNameDuplicated";

export interface MoveAllowed {
  allowed: boolean;
  reason?: "duplicate" | "freezerbox" | "self" | "child";
}

export interface MoveStorageLocationHooks {
  storageLocationBeingMoved: StorageLocationTreeView | undefined;

  onCancelMove(): void;

  onToggleMove(location: StorageLocationTreeView): void;

  onMoveCompleted(location: StorageLocationTreeView): boolean;

  canChooseAsParent(location: StorageLocationTreeView): MoveAllowed;
}

export interface MoveStorageLocationHooksProps {
  storageLocations: StorageLocationView;
  onItemMoved(sourceItem: StorageLocationTreeView, newParent: StorageLocationTreeView): void;
}

export function useMoveStorageLocation(props: MoveStorageLocationHooksProps): MoveStorageLocationHooks {
  const [storageLocationBeingMoved, setStorageLocationBeingMoved] = useState<StorageLocationTreeView>();

  const children: number[] = useMemo(() => {
    if (storageLocationBeingMoved) {
      return props.storageLocations.getChildrenAsFlatList(storageLocationBeingMoved.location.id).map((l) => l.id);
    }

    return [];
  }, [storageLocationBeingMoved]);

  function onToggleMove(item: StorageLocationTreeView): void {
    setStorageLocationBeingMoved(item);
  }

  function onCancelMove(): void {
    setStorageLocationBeingMoved(undefined);
  }

  function onMoveCompleted(newParent: StorageLocationTreeView): boolean {
    if (!storageLocationBeingMoved) {
      return false;
    }

    if (canChooseAsParent(newParent).allowed) {
      props.onItemMoved(storageLocationBeingMoved, newParent);
      setStorageLocationBeingMoved(undefined);
      return true;
    }

    return false;
  }

  function canChooseAsParent(newParent: StorageLocationTreeView): MoveAllowed {
    if (!storageLocationBeingMoved) {
      return { allowed: false, reason: "self" };
    }

    if (storageLocationBeingMoved.location.id === newParent.location.id || storageLocationBeingMoved.location.parentId === newParent.location.id) {
      return { allowed: false, reason: "self" };
    }

    const duplicated = isLocationNameDuplicated(
      props.storageLocations,
      storageLocationBeingMoved.location.name,
      storageLocationBeingMoved.location.id,
      newParent.location.id,
    );

    if (duplicated) {
      return { allowed: false, reason: "duplicate" };
    }

    const freezerBox = getIsFreezerBox(newParent.location);

    if (freezerBox) {
      return { allowed: false, reason: "freezerbox" };
    }

    const isChild = children.includes(newParent.location.id);

    if (isChild) {
      return { allowed: false, reason: "child" };
    }

    return { allowed: true };
  }

  return {
    storageLocationBeingMoved,
    onToggleMove,
    onMoveCompleted,
    onCancelMove,
    canChooseAsParent,
  };
}
