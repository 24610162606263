import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement } from "react";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faLock, faPencilAlt, faSquare, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import "./ManageInventoryTypeAttribute.scss";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import {
  ManageInventoryTypeAttributeHooks,
  ManageInventoryTypeAttributeProps,
  useManageInventoryTypeAttribute,
} from "./ManageInventoryTypeAttributeHooks";

const OptionsCell = (props: { id: number; hooks: ManageInventoryTypeAttributeHooks }): ReactElement => {
  const intl = useIntl();
  const { id, hooks } = props;
  if (
    hooks.type === CustomAttributeType.Checkbox.toString() ||
    hooks.type === CustomAttributeType.Dropdown.toString() ||
    hooks.type === CustomAttributeType.Radio.toString()
  ) {
    return (
      <>
        {hooks.options.map((v) => (
          <div key={v.key} className="manage-inventory-type-attribute-option-container">
            <Label for={`attribute-option-${id}-${v.key}`} className="visually-hidden">
              <FormattedMessage id="option" />
            </Label>
            <Input
              id={`attribute-option-${id}-${v.key}`}
              type="text"
              bsSize="sm"
              defaultValue={v.value}
              onChange={(e) => hooks.onOptionChanged(v.key, e.target.value)}
              maxLength={255}
              autoComplete={"off"}
            />
            <Button
              color="link"
              onClick={() => hooks.onRemoveOption(v.key)}
              className="ms-1"
              aria-label={intl.formatMessage({ id: "delete.option" })}
            >
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </Button>
          </div>
        ))}
        <div className="manage-inventory-type-attribute-option-container">
          <Label for={`attribute-option-${id}-new`} className="visually-hidden">
            <FormattedMessage id="option" />
          </Label>
          <Input
            id={`attribute-option-${id}-new`}
            type="text"
            bsSize="sm"
            value={hooks.newOption}
            onChange={(e) => hooks.onNewOptionChanged(e.target.value)}
            autoFocus
            maxLength={255}
            autoComplete={"off"}
          />
          <Button color="link" onClick={() => hooks.onAddOption()} className="ms-1" aria-label={intl.formatMessage({ id: "add.new.option" })}>
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </Button>
        </div>
      </>
    );
  }

  return <div>&nbsp;</div>;
};

export function ManageInventoryTypeAttribute(props: ManageInventoryTypeAttributeProps): ReactElement {
  const hooks: ManageInventoryTypeAttributeHooks = useManageInventoryTypeAttribute(props);
  const { isEditing, isRequired, isLabelValid, onToggleEdit, onCancelEdit, onTypeChanged, onLabelChanged, onRequiredChange, onSave, onDelete } =
    hooks;

  const { attribute } = props;
  const { id, label, type, isLocked, required, possibleValues } = attribute;
  const intl = useIntl();

  const typeToMessageId = (catype: CustomAttributeType): string => {
    switch (catype) {
      case CustomAttributeType.Textbox: {
        return "textbox";
      }
      case CustomAttributeType.TextArea: {
        return "textarea";
      }
      case CustomAttributeType.Checkbox: {
        return "checkbox";
      }
      case CustomAttributeType.Number: {
        return "number";
      }
      case CustomAttributeType.Date: {
        return "date";
      }
      case CustomAttributeType.Dropdown: {
        return "dropdown";
      }
      case CustomAttributeType.Radio: {
        return "radio";
      }
      case CustomAttributeType.CASNumber: {
        return "textbox";
      }
      case CustomAttributeType.ChemicalSafety: {
        return "checkbox";
      }
      default: {
        return "";
      }
    }
  };

  if (isEditing) {
    return (
      <tr>
        <td className="manage-inventory-type-attribute-label">
          <FormGroup>
            <Label for={`attribute-label-${id}`} className="visually-hidden">
              <FormattedMessage id="label" />
              <RequiredLabelIndicator required={true} />
            </Label>
            <Input
              id={`attribute-label-${id}`}
              type="text"
              bsSize="sm"
              required
              defaultValue={label}
              onChange={(e) => onLabelChanged(e.target.value)}
              autoFocus
              maxLength={255}
              invalid={!isLabelValid}
              autoComplete={"off"}
            />
            <FormFeedback>
              <FormattedMessage id="label.is.required.and.must.be.unique" />
            </FormFeedback>
          </FormGroup>
        </td>
        <td className="manage-inventory-type-attribute-type">
          <Label for={`attribute-type-${id}`} className="visually-hidden">
            <FormattedMessage id="type" />
            <RequiredLabelIndicator required={true} />
          </Label>
          <Input
            id={`attribute-type-${id}`}
            type="select"
            bsSize="sm"
            required
            defaultValue={type.toString()}
            onChange={(e) => onTypeChanged(e.target.value)}
          >
            <option value={CustomAttributeType.Dropdown.toString()}>
              {intl.formatMessage({ id: typeToMessageId(CustomAttributeType.Dropdown) })}
            </option>
            <option value={CustomAttributeType.Checkbox.toString()}>
              {intl.formatMessage({ id: typeToMessageId(CustomAttributeType.Checkbox) })}
            </option>
            <option value={CustomAttributeType.Textbox.toString()}>{intl.formatMessage({ id: typeToMessageId(CustomAttributeType.Textbox) })}</option>
            <option value={CustomAttributeType.Date.toString()}>{intl.formatMessage({ id: typeToMessageId(CustomAttributeType.Date) })}</option>
            <option value={CustomAttributeType.Number.toString()}>{intl.formatMessage({ id: typeToMessageId(CustomAttributeType.Number) })}</option>
            <option value={CustomAttributeType.TextArea.toString()}>
              {intl.formatMessage({ id: typeToMessageId(CustomAttributeType.TextArea) })}
            </option>
            <option value={CustomAttributeType.Radio.toString()}>{intl.formatMessage({ id: typeToMessageId(CustomAttributeType.Radio) })}</option>
          </Input>
        </td>
        <td className="manage-inventory-type-attribute-options">
          <OptionsCell id={id} hooks={hooks} />
        </td>
        <td className="manage-inventory-type-attribute-required">
          <div className={"manage-inventory-type-attribute-required-checkbox"}>
            <CheckboxLabel>
              <CheckboxInput
                id={`attribute-required-${id}`}
                onChange={(e) => onRequiredChange(e.target.checked)}
                uncheckedValue="0"
                checked={isRequired}
                aria-label={intl.formatMessage({ id: "required" })}
              />
            </CheckboxLabel>
          </div>
        </td>
        <td className="align-right manage-inventory-type-attribute-column-actions">
          <Button onClick={() => onSave()} className="me-1 inventory-type-save-field-button" color="primary">
            <FormattedMessage id="save" />
          </Button>
          <CancelButton onClick={() => onCancelEdit()} />
        </td>
      </tr>
    );
  }

  function getCheckbox(): ReactElement {
    if (!required) {
      return <FontAwesomeIcon icon={faSquare} title={intl.formatMessage({ id: "required" })} />;
    }
    return <FontAwesomeIcon icon={faCheckSquare} title={intl.formatMessage({ id: "not.required" })} />;
  }

  return (
    <tr>
      <td className="manage-inventory-type-attribute-label">
        {!isLocked && (
          <Button color="link" title={intl.formatMessage({ id: "edit" })} onClick={() => onToggleEdit()}>
            {label}
          </Button>
        )}
        {isLocked && <span>{label}</span>}
      </td>
      <td className="manage-inventory-type-attribute-type">
        <FormattedMessage id={typeToMessageId(type)} />
      </td>
      <td className="manage-inventory-type-attribute-options">{possibleValues.join(", ")}</td>
      <td className="align-center manage-inventory-type-attribute-required">{getCheckbox()}</td>
      <td className="align-right manage-inventory-type-attribute-column-actions">
        {!isLocked && (
          <>
            <Button data-testid="button.toggle" color="link" title={intl.formatMessage({ id: "edit" })} onClick={() => onToggleEdit()}>
              <FontAwesomeIcon icon={faPencilAlt} size="lg" />
            </Button>
            <Button color="link" title={intl.formatMessage({ id: "delete" })} onClick={() => onDelete()}>
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </Button>
          </>
        )}
        {isLocked && <FontAwesomeIcon icon={faLock} size="lg" title={intl.formatMessage({ id: "locked.custom.field" })} />}
      </td>
    </tr>
  );
}
