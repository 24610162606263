import { Role, User } from "@labarchives/inventory-shared/build/inventory";
import { RoleState } from "../roles/types/state";
import { UserAccountView, UserRoleView, UserView } from "./types/views";
import { UserState } from "./types/state";

function createUserView(user: User, role: Role | undefined): UserView {
  return {
    id: user.id,
    fullName: `${user.firstName} ${user.lastName}`,
    status: user.status,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    roleName: role ? role.name : "",
    roleId: user.roleId,
    accountId: user.activeAccountId,
    isOwner: user.isAccountOwner,
  };
}

export function getUserView(user: User, roleState: RoleState): UserView {
  const role = roleState.byId[user.roleId];
  return createUserView(user, role);
}

export function getUserViews(userState: UserState, roleState: RoleState): UserView[] {
  return userState.allIds.map((id) => getUserView(userState.byId[id], roleState));
}

export function getUserRoleView(role: Role): UserRoleView {
  return {
    id: role.id,
    name: role.name,
    permissions: role.permissions.map((p) => p.permissionId),
  };
}

export function getUserRoleViews(roleState: RoleState): UserRoleView[] {
  return roleState.allIds.map((id) => getUserRoleView(roleState.byId[id])).sort((r1, r2) => r1.name.localeCompare(r2.name));
}

export function getUserAccountViews(user: User): UserAccountView[] {
  return [...user.accounts]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((a) => {
      return { id: a.id, name: a.name };
    });
}
