/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext } from "react";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import classnames from "classnames";
import { Button, LaLogoBrand } from "@labarchives/ui-design/dist";
import "./Nav.scss";
import { matchPath, withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUser } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import { AuthenticationContext } from "../Authentication/AuthenticationContext";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { Settings } from "../../utils/Settings";
import { AppContext } from "../../app/AppContext";
import { useNavBar } from "./NavBarHooks";
import { NavigationHelpMenu } from "./NavigationHelpMenu";

export function AdminNavigationComponent(props: RouteComponentProps): ReactElement {
  const {
    isDrawerOpen,
    hideDrawer,
    onDrawerToggled,
    canViewInventoryAdmin,
    userFullName,
    isAuthenticated,
    onWarningModalShown,
    onAdminViewActivated,
    onInventoryViewActivated,
  } = useNavBar({
    authState: useContext(AuthenticationContext),
    appState: useContext(AppContext),
  });

  const intl = useIntl();

  function isActiveRoute(searchPath: string): boolean {
    return props.location && !!matchPath(props.location.pathname, searchPath);
  }

  return (
    <header>
      <a href="#main-content" className="skip-main">
        {intl.formatMessage({ id: "skip.to.main.content" })}
      </a>

      <Navbar dark expand="lg" fixed="top" id={"primary-navigation"} role={"navigation"} aria-label={"Main"}>
        <NavbarToggler onClick={onDrawerToggled} />
        <NavbarBrand tag={Link as any} {...{ to: ApplicationPaths.Home }} href={ApplicationPaths.Home}>
          <LaLogoBrand className="logo" rightAlignText>
            <span className="appName">Inventory</span>
          </LaLogoBrand>
        </NavbarBrand>

        {isAuthenticated && (
          <Collapse isOpen={isDrawerOpen} navbar color="dark">
            <Nav className="ms-auto" navbar color="dark" style={{ backgroundColor: "rgb(88, 39, 110)" }}>
              <NavItem
                className={classnames({
                  active:
                    props.location.pathname === "/" ||
                    isActiveRoute(ApplicationPaths.Admin.Routes.InventorySearch) ||
                    isActiveRoute(ApplicationPaths.Admin.Routes.InventoryItem),
                })}
              >
                <NavLink tag={Link as any} {...{ to: ApplicationPaths.Admin.Routes.InventorySearch }} onClick={hideDrawer}>
                  {intl.formatMessage({ id: "nav.inventory.admin.search" })}
                </NavLink>
              </NavItem>

              <NavItem
                className={classnames({
                  active: isActiveRoute(ApplicationPaths.Admin.Routes.Management.Home),
                })}
              >
                <NavLink
                  tag={Link as any}
                  {...{ to: ApplicationPaths.Admin.Routes.Management.Home }}
                  onClick={hideDrawer}
                  id={"nav-inventory-admin-management"}
                  title={intl.formatMessage({ id: "nav.site.management" })}
                >
                  <FontAwesomeIcon icon={faCog} className="me-1" />
                  <span className="d-inline d-sm-none">{intl.formatMessage({ id: "nav.site.management" })}</span>
                </NavLink>
              </NavItem>

              <NavigationHelpMenu onWarningModalShown={onWarningModalShown} />

              <NavItem>
                <NavLink href={Settings.getNotebookUrl()} target={"_blank"} title={intl.formatMessage({ id: "notebook.link" })}>
                  <svg className="notebook-link" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.94 568.85">
                    <defs></defs>
                    <title>{intl.formatMessage({ id: "notebook.link" })}</title>
                    <path
                      className="notebook"
                      d="M467.43,290.3c0,82.3-.18,164.6.13,246.89a37.08,37.08,0,0,1-37.48,37.56q-172.5-.12-345,0-20.25,0-40.5-.17c-16.46-.16-29.68-9.39-34.77-24.52A37.45,37.45,0,0,1,8,539.14c-.24-10.2,0-20.4-.13-30.6,0-2.5.81-3.28,3.28-3.24,8.89.11,17.8.16,26.69,0C49,505.1,56,495.21,52.41,485.06A15.16,15.16,0,0,0,38,475.25c-9-.07-18-.09-27,0-2.35,0-3.12-.68-3.11-3.06q.14-21.9,0-43.8c0-2.39.8-3.08,3.13-3.05,8.9.11,17.8.14,26.7,0,9.16-.13,15.76-6.78,15.61-15.48-.14-8.2-6.68-14.54-15.48-14.7s-17.6-.15-26.4,0c-2.74.05-3.6-.74-3.57-3.52q.18-21.45,0-42.9c0-2.89,1-3.5,3.64-3.46,8.89.13,17.8.19,26.69,0C49.07,345.05,56,335,52.39,325c-2.12-5.92-7.69-9.73-14.74-9.81-8.8-.1-17.6-.12-26.4,0-2.68.05-3.39-.85-3.37-3.44q.15-21.6,0-43.2c0-2.72.88-3.36,3.45-3.31,8.7.14,17.4.08,26.1,0,9.1,0,15.83-6.35,15.9-14.84.08-8.72-6.5-15.15-15.79-15.24-8.8-.09-17.6-.1-26.4,0-2.51,0-3.26-.82-3.25-3.28q.14-21.75,0-43.5c0-2.43.63-3.29,3.18-3.25,9.2.13,18.4.15,27.6,0a14.93,14.93,0,0,0,4.69-29,19.7,19.7,0,0,0-6.18-.87c-8.59-.09-17.2-.12-25.79,0-2.55,0-3.53-.62-3.51-3.34q.18-21.75,0-43.5c0-2.72.9-3.35,3.47-3.31q13.64.2,27.29,0a14.93,14.93,0,0,0,3.91-29.29,22.62,22.62,0,0,0-5.93-.65c-8.5-.06-17-.11-25.5,0-2.55,0-3.25-.87-3.22-3.29.1-10.1,0-20.2.05-30.3A35.6,35.6,0,0,1,43.71,6.12Q103.4,6,163.1,6c89,0,178,.13,267-.1a37.13,37.13,0,0,1,37.47,37.5C467.24,125.71,467.43,208,467.43,290.3Z"
                      transform="translate(-7.86 -5.91)"
                    />
                    <path
                      className="notebook"
                      d="M444.24,6.36c13.44-1,26.21-.8,36.67,8.9,7.73,7.16,11.88,15.94,11.88,26.53q0,248.51,0,497a35.5,35.5,0,0,1-32.12,35.4c-5.13.47-10.34.08-16.19.08a10.34,10.34,0,0,1,1.79-1,36,36,0,0,0,26.47-34.29c0-.7,0-1.4,0-2.1q0-246.41,0-492.81c0-14.71-5.85-26.11-18.76-33.48C451.09,8.88,447.8,7.87,444.24,6.36Z"
                      transform="translate(-7.86 -5.91)"
                    />
                    <rect className="notebook-line" x="160.14" y="117.09" width="161" height="95" rx="7.2" />
                  </svg>{" "}
                  <span className="d-inline d-sm-none">{intl.formatMessage({ id: "notebook.link" })}</span>
                </NavLink>
              </NavItem>

              {canViewInventoryAdmin && (
                <div id={"switch-inventory-views"}>
                  <div className={"active me-3"} role={"tab"} aria-selected={true}>
                    <Button className={"nav-link"} id={"switch-to-admin-view"} color={"link"} onClick={onAdminViewActivated}>
                      {intl.formatMessage({ id: "nav.admin.view" })}
                    </Button>
                  </div>
                  <div role={"tab"}>
                    <Button className={"nav-link"} id={"switch-to-inventory-view"} color={"link"} onClick={onInventoryViewActivated}>
                      {intl.formatMessage({ id: "nav.user.view" })}
                    </Button>
                  </div>
                </div>
              )}

              <UncontrolledDropdown className={"nav-item"}>
                <DropdownToggle caret className={"nav-link"}>
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  <span id={"inventory-user-full-name"}>{userFullName}</span>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={"a"} href={`${Settings.getAccountUrlBase()}/user_edit`} target={"user_edit"} className={"nav-dropdown-link"}>
                    <span className="nav-dropdown-link nav-link">{intl.formatMessage({ id: "user.properties" })}</span>
                  </DropdownItem>
                  <DropdownItem tag={Link as any} {...{ to: ApplicationPaths.Logout }} className="nav-dropdown-link" onClick={hideDrawer}>
                    <span className="nav-dropdown-link nav-link">{intl.formatMessage({ id: "logout" })}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        )}
      </Navbar>
    </header>
  );
}

export const InventoryAdminNavigation = withRouter(AdminNavigationComponent);
