import React, { ReactElement } from "react";
import { Route, Router, Switch } from "react-router";
import { history } from "./history";
import { UnauthenticatedRedirectApp } from "./UnauthenticatedRedirectApp";
import { HealthComponent } from "./HealthComponent";

export function UnauthenticatedApp(): ReactElement {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={"/health"} component={HealthComponent} />
        <Route component={UnauthenticatedRedirectApp} />
      </Switch>
    </Router>
  );
}
