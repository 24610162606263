import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import React, { ReactElement } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design";

import { CustomAttributeDefinitionView } from "../../inventory/types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";

interface DeleteAttributePromptProps {
  isOpen: boolean;
  attribute?: CustomAttributeDefinitionView;
  typeName: string;
  onDelete(attribute: CustomAttributeDefinitionView): void;
  onCancel(): void;
}

export const DeleteAttributePrompt = (props: DeleteAttributePromptProps): ReactElement => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>
        <FormattedMessage id="delete.field" />
      </ModalHeader>
      <ModalBody>
        <FormattedHTMLMessage
          id="delete.inventory.type.attribute.warning"
          values={{ label: props.attribute ? props.attribute.label : "", typeName: props.typeName }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => (props.attribute ? props.onDelete(props.attribute) : {})}>
          <FormattedMessage id="delete" />
        </Button>
        <CancelButton onClick={props.onCancel} />
      </ModalFooter>
    </Modal>
  );
};
