import React, { Fragment, ReactElement } from "react";
import { Link } from "react-router-dom";
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { FreezerBoxDisplayFormat, InventorySearchResultSetItem, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InventorySortableColumn } from "@labarchives/inventory-shared/build/inventory/InventorySortableColumn";
import { faCheckCircle, faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@labarchives/ui-design";
import { StorageLocationView } from "../../../storage/types/views";
import { FormattedFreezerBoxCells } from "../../../storage/FormattedFreezerBoxCells";
import "./InventorySearchResultsRow.scss";
import { AuthorizedComponent } from "../../../components/Authentication/AuthorizedComponent";
import { ApplicationPaths } from "../../../app/ApplicationPaths";
import { EditableStorage } from "../../../storage/EditableStorage";
import { InventoryApi } from "../../../api/InventoryApi";

export interface InventorySearchResultsRowProps {
  item: InventorySearchResultSetItem;
  storageLocations: StorageLocationView;
  currency: string;
  columnOrder: InventorySortableColumn[];
  isReorderDisabled?: boolean;
  isFreezerBoxDisabled?: boolean;
  isStorageLocationRequired: boolean;
  isUsed: boolean;
  api: InventoryApi;
  itemLink(id: string | number): string;
  onUseNow(item: InventorySearchResultSetItem): void;
  onInventoryStorageChanged?(locationId?: number | null, storageCells?: string[] | null, notes?: string | null): void;
}

export function InventorySearchResultsRow(props: InventorySearchResultsRowProps): ReactElement {
  const intl = useIntl();
  const {
    storageLocations,
    item,
    currency,
    isReorderDisabled,
    isUsed,
    isFreezerBoxDisabled,
    columnOrder,
    api,
    isStorageLocationRequired,
    itemLink,
    onInventoryStorageChanged,
  } = props;

  const formatCells = (cells: string[], rows: number | null, cols: number | null, format: FreezerBoxDisplayFormat | null): ReactElement => {
    if (cells.length > 0) {
      const slicedCells = cells.slice(0, 17);
      let dots = "";
      if (cells.length !== slicedCells.length) {
        dots = "...";
      }
      return (
        <>
          <div className="table-to-card-force-break">&nbsp;</div>
          <span className="inventory-search-result-location-cells">
            <FormattedFreezerBoxCells cells={slicedCells} rows={rows} cols={cols} format={format} />
            {dots}
          </span>
        </>
      );
    }

    return <></>;
  };

  function showFreezerBox(item: InventorySearchResultSetItem): boolean {
    return (
      isFreezerBoxDisabled !== true &&
      item.locationId !== null &&
      item.storageCellRows !== null &&
      item.storageCellCols !== null &&
      item.storageCells.length > 0 &&
      item.quantityAvailable > 0
    );
  }

  function columnToTd(col: InventorySortableColumn): ReactElement {
    switch (col) {
      case InventorySortableColumn.Location: {
        const locationName = onInventoryStorageChanged ? "" : storageLocations.getFormattedDescription(item.locationId);
        return (
          <td key={col} className="inventory-search-result-location" data-label={intl.formatMessage({ id: "location" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="location" />
            </div>
            <div className="table-to-card-content">
              {onInventoryStorageChanged && (
                <EditableStorage
                  storageLocations={storageLocations}
                  onInventoryStorageChanged={onInventoryStorageChanged}
                  selectedStorageCells={item.storageCells}
                  selectedStorageLocationId={item.locationId}
                  storageNotes={item.storageLocationNotes}
                  idPrefix={item.itemId || item.id.toString()}
                  api={api}
                  isRequired={isStorageLocationRequired}
                />
              )}
              {!onInventoryStorageChanged && (
                <>
                  {showFreezerBox(item) && (
                    <>
                      <Link
                        to={ApplicationPaths.Storage.Browse(item.locationId!)}
                        className={"freezer-box-link"}
                        title={intl.formatMessage({ id: "browse.freezer.box" })}
                      >
                        <FontAwesomeIcon icon={faExternalLink} />
                      </Link>
                      &nbsp;
                    </>
                  )}
                  <span className="inventory-search-result-location-name" title={locationName}>
                    {locationName}
                  </span>
                  {formatCells(item.storageCells, item.storageCellRows, item.storageCellCols, item.storageCellFormat)}
                </>
              )}
            </div>
          </td>
        );
      }
      case InventorySortableColumn.Type: {
        return (
          <td key={col} className="inventory-search-result-type" data-label={intl.formatMessage({ id: "type" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="type" />
            </div>
            <div className="table-to-card-content">
              {item.typeColor && (
                <div className={"inventory-type-color"} data-color={item.typeColor} style={{ backgroundColor: item.typeColor }}>
                  &nbsp;
                </div>
              )}
              <div className={"d-inline-block"}>{item.typeName}</div>
            </div>
          </td>
        );
      }
      case InventorySortableColumn.Price: {
        return (
          <td key={col} className="inventory-search-result-price" data-label={intl.formatMessage({ id: "price" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="price" />
            </div>
            <div className="table-to-card-content">
              {item.price !== null && <FormattedNumber value={item.price} style="currency" currency={currency} />}
            </div>
          </td>
        );
      }
      case InventorySortableColumn.Quantity: {
        return (
          <td key={col} className="inventory-search-result-quantity" data-label={intl.formatMessage({ id: "quantity" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="quantity" />
            </div>
            <div className="table-to-card-content">
              <FormattedNumber value={item.quantityAvailable} />
              <span className="inventory-search-result-unit">{item.unit}</span>
            </div>
          </td>
        );
      }
      case InventorySortableColumn.DateReceived: {
        return (
          <td key={col} className="inventory-search-result-received-dates" data-label={intl.formatMessage({ id: "date.received" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="date.received" />
            </div>
            <div className="table-to-card-content">
              {item.dateReceived && <FormattedDate value={item.dateReceived} />}
              {!item.dateReceived && <span>&nbsp;</span>}
            </div>
          </td>
        );
      }
      case InventorySortableColumn.Reorder: {
        if (isReorderDisabled === true) {
          return <Fragment key={"reorder"} />;
        }
        return (
          <AuthorizedComponent key={col} requiredPermissions={[RolePermissions.OrdersRequestItem]}>
            <td className="inventory-search-result-order-reorder" data-label={intl.formatMessage({ id: "request.reorder" })}>
              <div className="table-to-card-label">
                <FormattedMessage id="request.reorder" />
              </div>
              <div className="table-to-card-content">
                <Link to={ApplicationPaths.Orders.ReorderItem(item.itemId || item.id)}>
                  <FormattedMessage id="request.reorder" />
                </Link>
              </div>
            </td>
          </AuthorizedComponent>
        );
      }
      case InventorySortableColumn.PONumber: {
        return (
          <td key={col} className="inventory-search-result-po-number" data-label={intl.formatMessage({ id: "po.number" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="po.number" />
            </div>
            <div className="table-to-card-content">{item.poNumber}</div>
          </td>
        );
      }
      case InventorySortableColumn.CatalogNumber: {
        return (
          <td key={col} className="inventory-search-result-catalog-number" data-label={intl.formatMessage({ id: "catalog.number" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="catalog.number" />
            </div>
            <div className="table-to-card-content">{item.catalogNumber}</div>
          </td>
        );
      }
      case InventorySortableColumn.Description: {
        return (
          <td key={col} className="inventory-search-result-description" data-label={intl.formatMessage({ id: "description" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="description" />
            </div>
            <div className="table-to-card-content" title={item.description}>
              {item.description}
            </div>
          </td>
        );
      }
      case InventorySortableColumn.Expiration: {
        return (
          <td key={col} className="inventory-search-result-received-dates" data-label={intl.formatMessage({ id: "expiration" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="expiration" />
            </div>
            <div className="table-to-card-content">
              {item.expiration && <FormattedDate value={item.expiration} />}
              {!item.expiration && <span>&nbsp;</span>}
            </div>
          </td>
        );
      }
      case InventorySortableColumn.GrantNumber: {
        return (
          <td key={col} className="inventory-search-result-grant-number" data-label={intl.formatMessage({ id: "grant.number" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="grant.number" />
            </div>
            <div className="table-to-card-content">{item.grantNumber}</div>
          </td>
        );
      }
      case InventorySortableColumn.LotNumber: {
        return (
          <td key={col} className="inventory-search-result-lot-number" data-label={intl.formatMessage({ id: "lot.number" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="lot.number" />
            </div>
            <div className="table-to-card-content">{item.lotNumber}</div>
          </td>
        );
      }
      case InventorySortableColumn.Vendor: {
        return (
          <td key={col} className="inventory-search-result-vendor" data-label={intl.formatMessage({ id: "vendor" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="vendor" />
            </div>
            <div className="table-to-card-content">{item.vendorName}</div>
          </td>
        );
      }
      case InventorySortableColumn.UseNow: {
        return (
          <td key={col} className="inventory-search-result-use-now" data-label={intl.formatMessage({ id: "use.now" })}>
            <div className="table-to-card-label">
              <FormattedMessage id="use.now" />
            </div>
            <div className="table-to-card-content">
              {isUsed && (
                <span className={"inventory-search-result-item-used-notification"} title={intl.formatMessage({ id: "added.to.inventory.list" })}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
              )}
              <Button className={"use-now-button"} color={"link"} onClick={() => props.onUseNow(item)}>
                <FormattedMessage id={"use.now"} />
              </Button>
            </div>
          </td>
        );
      }
      default: {
        return <Fragment key={"none"} />;
      }
    }
  }

  return (
    <tr className="inventory-search-result-item" data-item-id={item.id} data-item-itemid={item.itemId}>
      <td className="inventory-search-result-name" data-label={intl.formatMessage({ id: "name" })}>
        <div className="table-to-card-label">
          <FormattedMessage id="name" />
        </div>
        <div className="table-to-card-content">
          <Link to={itemLink(item.itemId || item.id)} title={item.name}>
            {item.name}
          </Link>
        </div>
      </td>
      {columnOrder.map((col) => columnToTd(col))}
    </tr>
  );
}
