import React, { ReactElement } from "react";
import "./maintenanceNotice.scss";
import { InventoryApiClient } from "../../api";
import { useMaintenanceNoticeHooks } from "./mainenanceNoticeHooks";

export const MaintenanceNotice = (): ReactElement => {
  const { maintenanceMessage } = useMaintenanceNoticeHooks(new InventoryApiClient());

  if (!maintenanceMessage || maintenanceMessage === "") {
    return <></>;
  }

  return (
    <div className="maintenance-notice">
      <span dangerouslySetInnerHTML={{ __html: maintenanceMessage }} />
    </div>
  );
};
