import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { StorageLocationTreeView } from "../types/views";

import { CancelButton } from "../../components/CancelButton/CancelButton";
import "./MoveStorageLocationPrompt.scss";
import { getIsFreezerBox } from "../selectors";
import { MoveAllowed } from "./MoveStorageLocationHooks";

interface MoveStorageLocationPromptProps {
  isOpen: boolean;
  location?: StorageLocationTreeView;
  locations: StorageLocationTreeView[];

  onCancel(): void;

  onMoved(newParent: StorageLocationTreeView): boolean;

  canChooseAsParent(newParent: StorageLocationTreeView): MoveAllowed;
}

export function MoveStorageLocationPrompt(props: MoveStorageLocationPromptProps): ReactElement {
  const { location, locations, isOpen, onCancel, onMoved, canChooseAsParent } = props;
  const intl = useIntl();

  function getExpandedLocation(): number {
    if (!location) {
      return 0;
    }

    const { id } = location.location;

    const inLocations = (l: StorageLocationTreeView): boolean => {
      if (l.location.id === id || l.location.parentId === id) {
        return true;
      }

      return l.children.some((c) => inLocations(c));
    };

    const openLocation = locations.find((l) => inLocations(l));
    if (openLocation) {
      return openLocation.location.id;
    }

    return 0;
  }

  const [expandedLocation, setExpandedLocation] = useState(0);
  const [newParent, setNewParent] = useState<StorageLocationTreeView | undefined>();
  const [moveAllowed, setMoveAllowed] = useState<MoveAllowed | undefined>();

  useEffect(() => {
    const expandedId = getExpandedLocation();
    setExpandedLocation(expandedId);
    setNewParent(undefined);
    setMoveAllowed(undefined);
  }, [isOpen, location, locations]);

  function onExpandLocation(id: number): void {
    if (expandedLocation === id) {
      setExpandedLocation(0);
    } else {
      setExpandedLocation(id);
    }
  }

  function isActiveItem(c: StorageLocationTreeView): boolean {
    return location !== undefined && c.location.id === location.location.id;
  }

  function isPickable(c: StorageLocationTreeView): MoveAllowed {
    if (isActiveItem(c)) {
      return { allowed: false, reason: "self" };
    }
    return canChooseAsParent(c);
  }

  function onNewParentSelected(c: StorageLocationTreeView): void {
    const pickableResult = isPickable(c);
    if (pickableResult.allowed) {
      setNewParent(c);
      setMoveAllowed(undefined);
    } else {
      setNewParent(undefined);
      setMoveAllowed(pickableResult);
    }
  }

  function onMoveClicked(): void {
    if (newParent) {
      onMoved(newParent);
    }
  }

  function getClassName(c: StorageLocationTreeView): string {
    if (newParent !== undefined && newParent.location.id === c.location.id) {
      return "move-item-new-parent";
    }

    if (isActiveItem(c)) {
      return "move-item-source";
    }

    if (isPickable(c).allowed) {
      return "move-item-destination";
    }

    return "move-item-disabled";
  }

  function displayChildren(l: StorageLocationTreeView): ReactElement {
    return (
      <div key={l.location.id} className={"ms-2"}>
        {l.children.map((c) => (
          <Fragment key={c.location.id}>
            <div
              className={getClassName(c)}
              role={"button"}
              onKeyPress={(e) => (e.key === "Enter" ? onNewParentSelected(c) : {})}
              onClick={() => onNewParentSelected(c)}
              tabIndex={0}
              title={intl.formatMessage(
                { id: "move.storage.location.destination" },
                { source: location?.location.name || "", destination: c.location.name },
              )}
            >
              {c.location.name}
              {getIsFreezerBox(c.location) && (
                <span className="manage-storage-location-grid">
                  ({c.location.numberOfRows} x {c.location.numberOfColumns})
                </span>
              )}
            </div>
            {displayChildren(c)}
          </Fragment>
        ))}
      </div>
    );
  }

  function asTree(l: StorageLocationTreeView): ReactElement {
    return (
      <div key={l.location.id}>
        {expandedLocation === l.location.id && (
          <div className={"d-inline-block"}>
            <Button
              color={"link"}
              onClick={() => onExpandLocation(l.location.id)}
              title={intl.formatMessage({ id: "collapse" })}
              data-expand={l.location.name}
            >
              <FontAwesomeIcon icon={faAngleDown} size={"lg"} />
            </Button>
          </div>
        )}
        {expandedLocation !== l.location.id && (
          <div className={"d-inline-block"}>
            <Button
              color={"link"}
              onClick={() => onExpandLocation(l.location.id)}
              title={intl.formatMessage({ id: "expand" })}
              data-expand={l.location.name}
            >
              <FontAwesomeIcon icon={faAngleRight} size={"lg"} />
            </Button>
          </div>
        )}
        <div
          className={`d-inline-block ${getClassName(l)}`}
          role={"button"}
          onKeyPress={(e) => (e.key === "Enter" ? onNewParentSelected(l) : {})}
          onClick={() => onNewParentSelected(l)}
          tabIndex={0}
        >
          {l.location.name}
          {getIsFreezerBox(l.location) && (
            <span className="manage-storage-location-grid">
              ({l.location.numberOfRows} x {l.location.numberOfColumns})
            </span>
          )}
        </div>
        {expandedLocation === l.location.id && <div className={"ms-4"}>{displayChildren(l)}</div>}
      </div>
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={onCancel} id={"move-storage-location-modal"}>
      <ModalHeader toggle={onCancel}>
        <FormattedMessage id="move.storage.location" values={{ name: location?.location.name || "" }} />
      </ModalHeader>
      <ModalBody>{locations.map((l) => asTree(l))}</ModalBody>
      <ModalFooter className={"modal-footer-with-error"}>
        <div className={"error-note"}>
          {moveAllowed && moveAllowed.reason === "duplicate" && <FormattedMessage id={"move.storage.error.duplicate"} />}
          {moveAllowed && moveAllowed.reason === "child" && <FormattedMessage id={"move.storage.error.child"} />}
          {moveAllowed && moveAllowed.reason === "freezerbox" && <FormattedMessage id={"move.storage.error.freezerbox"} />}
        </div>
        <div>
          <Button color="primary" onClick={onMoveClicked} id={"move-storage-location-button"}>
            <FormattedMessage id="move" />
          </Button>
          <CancelButton className="move-storage-cancel-button" onClick={onCancel} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
