import React, { ReactElement, useState } from "react";
import { Col, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Role, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import "./ManageRole.scss";
import { Button } from "@labarchives/ui-design/dist/components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-light-svg-icons";
import { Input } from "@labarchives/ui-design";
import { RoleView } from "../types/views";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { getRequiredPermissions } from "../selectors";
import { arrayUnique } from "../../utils";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";

export interface ManageRoleHooks {
  isNameInvalid: boolean;

  onCancel(): void;

  onSave(): void;

  isPermissionChecked(permissionId: RolePermissions): boolean;

  onPermissionChanged(permissionId: RolePermissions, isChecked: boolean): void;

  onNameChanged(newName: string): void;
}

export function useManageRole(props: ManageRoleProps): ManageRoleHooks {
  const [permissions, setPermissions] = useState(props.roleView.permissions);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [name, setName] = useState(props.roleView.name);

  function getAllPermissions(): RolePermissions[] {
    return arrayUnique([...permissions, ...getRequiredPermissions(permissions)]);
  }

  function isPermissionChecked(permission: RolePermissions): boolean {
    return getAllPermissions().includes(permission);
  }

  function onPermissionChanged(permissionId: RolePermissions, isChecked: boolean): void {
    if (isChecked) {
      setPermissions([...permissions, permissionId]);
    } else {
      setPermissions(permissions.filter((p) => p !== permissionId));
    }
  }

  function getRole(): Role {
    return {
      id: props.roleView.id,
      name: name.trim(),
      permissions: getAllPermissions().map((p) => {
        return {
          permissionId: p,
        };
      }),
    };
  }

  function onNameChanged(newName: string): void {
    const roleName = newName.trim();
    setName(roleName);

    const role = getRole();
    role.name = roleName;

    setIsNameInvalid(!props.isRoleValid(role));
  }

  function onCancel(): void {
    setPermissions(props.roleView.permissions);
    onNameChanged(props.roleView.name);
    setIsNameInvalid(false);

    props.onEditToggle(props.roleView.id);
  }

  function onSave(): void {
    const role = getRole();

    if (props.isRoleValid(role)) {
      props.onRoleUpdated(role);
    } else {
      setIsNameInvalid(true);
    }
  }

  return {
    isNameInvalid,
    onCancel,
    onSave,
    onNameChanged,
    isPermissionChecked,
    onPermissionChanged,
  };
}

export interface ManageRoleProps {
  roleView: RoleView;
  isEditing: boolean;
  isEditingAnother: boolean;

  onEditToggle(id: number): void;

  onDeletePrompt(id: number): void;

  onRoleUpdated(role: Role): void;

  isRoleValid(role: Role): boolean;
}

export function ManageRole(props: ManageRoleProps): ReactElement {
  const { isNameInvalid, onSave, onCancel, isPermissionChecked, onPermissionChanged, onNameChanged } = useManageRole(props);
  const intl = useIntl();

  const isEditable =
    !props.isEditingAnother &&
    !(props.roleView.name.toLocaleLowerCase() === "lab manager" || props.roleView.name.toLocaleLowerCase() === "lab member");

  function getCheckbox(permission: RolePermissions, labelKey: string): ReactElement {
    return (
      <div>
        <CheckboxLabel>
          <CheckboxInput
            id={permission.toString()}
            uncheckedValue="0"
            disabled={!props.isEditing}
            checked={isPermissionChecked(permission)}
            onChange={(e) => onPermissionChanged(permission, e.target.checked)}
          />
          <span>
            <FormattedMessage id={labelKey} />
          </span>
        </CheckboxLabel>
      </div>
    );
  }

  return (
    <div>
      <div className="management-name-container">
        <div className="management-item-name">
          {!props.isEditing && <>{props.roleView.name}</>}
          {props.isEditing && (
            <FormGroup>
              <Label htmlFor={"manage-role-name"} className={"visually-hidden"}>
                <FormattedMessage id={"name"} />
                <RequiredLabelIndicator required={true} />
              </Label>
              <Input
                id={"manage-role-name"}
                type={"text"}
                defaultValue={props.roleView.name}
                required={true}
                onChange={(e) => onNameChanged(e.target.value)}
                invalid={isNameInvalid}
              />
              <FormFeedback>
                <FormattedMessage id={"invalid.role.name"} />
              </FormFeedback>
            </FormGroup>
          )}
        </div>
        {isEditable && !props.isEditing && (
          <div>
            <Button color={"link"} title={intl.formatMessage({ id: "edit.role" })} onClick={() => props.onEditToggle(props.roleView.id)}>
              <FontAwesomeIcon icon={faPencilAlt} className="button-icon" size="lg" />
            </Button>
            <Button
              className={"ms-1"}
              color="link"
              title={intl.formatMessage({ id: "delete.role" })}
              onClick={() => props.onDeletePrompt(props.roleView.id)}
            >
              <FontAwesomeIcon icon={faTrash} className="button-icon" size="lg" />
            </Button>
          </div>
        )}
        {props.isEditing && (
          <div>
            <Button color={"primary"} onClick={onSave}>
              <FormattedMessage id={"save"} />
            </Button>
            <CancelButton onClick={onCancel} />
          </div>
        )}
      </div>
      <Row>
        <Col>
          <div className="manage-role-permission-group">
            <span className="manage-role-permission-group-title">
              <FormattedMessage id="inventory.management" />
            </span>
            <div className="manage-role-permission-details">
              {getCheckbox(RolePermissions.InventoryEditAllItems, "permission.inventory.edit.all.items")}
              {getCheckbox(RolePermissions.InventoryAddCreatedItem, "permission.inventory.add.created.item")}
            </div>
          </div>
          <div className="manage-role-permission-group">
            <span className="manage-role-permission-group-title">
              <FormattedMessage id="searching.role" />
            </span>
            <div className="manage-role-permission-details">
              <div>
                <CheckboxLabel>
                  <CheckboxInput
                    id={RolePermissions.SearchViewAllItems.toString()}
                    uncheckedValue="0"
                    disabled={true}
                    checked={isPermissionChecked(RolePermissions.SearchViewAllItems)}
                  />
                  <span>
                    <FormattedMessage id="permission.search.view.all.items" />
                  </span>
                </CheckboxLabel>
              </div>
            </div>
          </div>
          <div className="manage-role-permission-group">
            <span className="manage-role-permission-group-title">
              <FormattedMessage id="lab.management" />
            </span>
            <div className="manage-role-permission-details">
              {getCheckbox(RolePermissions.LabManagementAllowed, "permission.lab.management.allowed")}
            </div>
          </div>
        </Col>
        <Col>
          <div className="manage-role-permission-group">
            <span className="manage-role-permission-group-title">
              <FormattedMessage id="ordering" />
            </span>
            <div className="manage-role-permission-details">
              {getCheckbox(RolePermissions.OrdersRequestItem, "permission.orders.request.item")}
              {getCheckbox(RolePermissions.OrdersViewAll, "permission.orders.view.all")}
              {getCheckbox(RolePermissions.OrdersUpdateAll, "permission.orders.update.all")}
              {getCheckbox(RolePermissions.OrdersApproveAll, "permission.orders.approve.all")}
              {getCheckbox(RolePermissions.OrdersCancelAll, "permission.orders.cancel.all")}
              {getCheckbox(RolePermissions.OrdersCancelOwn, "permission.orders.cancel.own")}
              {getCheckbox(RolePermissions.OrdersReceiveOwn, "permission.orders.receive.own")}
              {getCheckbox(RolePermissions.OrdersReceiveAll, "permission.orders.receive.all")}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
