import { useEffect, useState } from "react";
import { FreezerBoxDisplayFormat } from "@labarchives/inventory-shared/build/inventory";
import { getFreezerBoxCellLabelFromLocation } from "./selectors";

export interface EditableStorageCellsProps {
  matrixRows: number;
  matrixCols: number;
  selectedCells: string[];
  occupiedCells: string[];
  displayFormat: FreezerBoxDisplayFormat | null;
  idPrefix?: string;

  onSelectedCellsChange(selectedCells: string[]): void;
}

export interface EditableStorageCellsHooks {
  allSelected: boolean;
  rows: number[];
  cols: number[];
  selectedCells: string[];
  occupiedCells: string[];

  wasOriginallySelected(cell: string): boolean;

  isCellSelected(cell: string): boolean;

  isCellOccupied(cell: string): boolean;

  updateCellStates(selected: string[], occupied: string[]): void;

  onCellClick(cell: string): void;

  onCellClickOnly(cell: string, selectedInput: string[], occupiedInput: string[]): { selected: string[]; occupied: string[] };

  onSelectAllCheckboxClick(checked: boolean): void;
}

export function useEditableStorageCells(props: EditableStorageCellsProps): EditableStorageCellsHooks {
  const [allSelected, setAllSelected] = useState(false);
  const [selectedCells, setSelectedCells] = useState(props.selectedCells);
  const [occupiedCells, setOccupiedCells] = useState(props.occupiedCells);
  const rows = Array.from({ length: props.matrixRows }, (v, k) => k + 1);
  const cols = Array.from({ length: props.matrixCols }, (v, k) => k + 1);

  const updateAllSelected = (): void => {
    let isAllSelected = true;
    rows.forEach((r) => {
      cols.forEach((c) => {
        const label = getFreezerBoxCellLabelFromLocation(r, c);
        isAllSelected = isAllSelected && (isCellSelected(label) || wasOriginallySelected(label));
      });
    });
    setAllSelected(isAllSelected);
  };

  function wasOriginallySelected(cell: string): boolean {
    return props.selectedCells.includes(cell);
  }

  function isCellSelected(cell: string): boolean {
    return selectedCells.includes(cell);
  }

  function isCellOccupied(cell: string): boolean {
    return occupiedCells.includes(cell) && !isCellSelected(cell);
  }

  function updateCellStates(selected: string[], occupied: string[]): void {
    setSelectedCells(selected);
    setOccupiedCells(occupied);
  }

  function onCellClick(cell: string): void {
    const { selected, occupied } = onCellClickOnly(cell, selectedCells, occupiedCells);
    updateCellStates(selected, occupied);
    props.onSelectedCellsChange(selected);
  }

  function onCellClickOnly(cell: string, selectedInput: string[], occupiedInput: string[]): { selected: string[]; occupied: string[] } {
    let isSelecting = false;
    let selected = [...selectedInput];
    let occupied = [...occupiedInput];

    if (isCellSelected(cell)) {
      selected = selectedInput.filter((c) => c !== cell);
    } else {
      isSelecting = true;
      selected = [...selected, cell];
    }

    if (isSelecting) {
      occupied = occupiedInput.filter((c) => c !== cell);
    }

    if (!isSelecting && wasOriginallySelected(cell)) {
      occupied = occupiedInput.filter((c) => c !== cell);
    }

    if (!isSelecting && props.occupiedCells.includes(cell) && !wasOriginallySelected(cell)) {
      occupied = [...occupied, cell];
    }

    return { selected, occupied };
  }

  const onSelectAllCheckboxClick = (checked: boolean): void => {
    if (rows.length > 0 && cols.length > 0) {
      let selectedInput = selectedCells;
      let occupiedInput = occupiedCells;
      rows.forEach((r) => {
        cols.forEach((c) => {
          const label = getFreezerBoxCellLabelFromLocation(r, c);
          if (checked) {
            if (!isCellSelected(label) && !wasOriginallySelected(label)) {
              const { selected, occupied } = onCellClickOnly(label, selectedInput, occupiedInput);
              selectedInput = selected;
              occupiedInput = occupied;
            }
          } else if (isCellSelected(label)) {
            const { selected, occupied } = onCellClickOnly(label, selectedInput, occupiedInput);
            selectedInput = selected;
            occupiedInput = occupied;
          }
        });
      });
      updateCellStates(selectedInput, occupiedInput);
      props.onSelectedCellsChange(selectedInput);
      updateAllSelected();
    }
  };

  useEffect(() => {
    updateCellStates(props.selectedCells, props.occupiedCells);
    updateAllSelected();
  });

  return {
    allSelected,
    rows,
    cols,
    selectedCells,
    occupiedCells,
    wasOriginallySelected,
    isCellSelected,
    isCellOccupied,
    updateCellStates,
    onCellClick,
    onCellClickOnly,
    onSelectAllCheckboxClick,
  };
}
