import React, { ReactElement, useEffect } from "react";
import { Settings } from "../utils/Settings";
import { Navigation } from "../components";
import { ApplicationPaths } from "./ApplicationPaths";

export function UnauthenticatedRedirectApp(): ReactElement {
  useEffect(() => {
    const currentUrl = [window.location.protocol, "//", window.location.host, window.location.pathname].join("");
    const returnTo = encodeURI(currentUrl.replace(ApplicationPaths.Logout, ""));
    window.location.href = `${Settings.getAccountUrlBase()}/login?return=${returnTo}`;
  });

  return (
    <div>
      <Navigation />
      <div className="container-fluid">You are not logged in. Redirecting...</div>
    </div>
  );
}
