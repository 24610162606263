import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { AlertWithIcon, Button, Input, Modal } from "@labarchives/ui-design";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faPencilAlt, faPlus, faTrash } from "@fortawesome/pro-light-svg-icons";
import { Form, FormFeedback, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { SiteLocationBuilding } from "@labarchives/inventory-shared/build/inventory";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { DeleteButton } from "../../components/DeleteButton/CancelButton";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { AdminViewLocationFloor, AdminViewLocationFloorProps } from "./AdminViewLocationFloor";
import { AdminViewLocationRoom } from "./AdminViewLocationRoom";

export interface AdminViewLocationBuildingProps extends AdminViewLocationFloorProps {
  canAddBuilding(name: string): boolean;

  canRenameBuilding(id: number, name: string): boolean;

  onBuildingUpdated(buildingId: number, updatedName: string, newFloorName: string | undefined, newRoomName: string | undefined): void;

  building: SiteLocationBuilding;

  expandedIds: number[];

  onExpandToggled(id: number): void;
}

export function AdminViewLocationBuilding(props: AdminViewLocationBuildingProps): ReactElement {
  const { building, locations, currentlyEditedId, expandedIds } = props;
  const intl = useIntl();
  const isEditing = useMemo<boolean>(() => currentlyEditedId === building.id, [currentlyEditedId]);
  const [isAddingFloor, setIsAddingFloor] = useState(false);
  const [isAddingRoom, setIsAddingRoom] = useState(false);
  const [buildingName, setBuildingName] = useState(building.name);
  const [floorName, setFloorName] = useState("");
  const [roomName, setRoomName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const isExpanded = useMemo<boolean>(() => expandedIds.includes(building.id), [expandedIds]);

  useEffect(() => {
    setIsAddingFloor(false);
    setIsAddingRoom(false);
    setBuildingName(building.name);
  }, [isEditing]);

  function onSave(): void {
    if (buildingName.trim() !== "") {
      props.onBuildingUpdated(building.id, buildingName.trim(), floorName.trim() || undefined, roomName.trim() || undefined);
    }
  }

  function onDelete(): void {
    setIsDeleting(false);
    props.onLocationDeleted(building.id);
  }

  function getFloorsAndRooms(): ReactElement {
    const rooms = locations.rooms.filter((r) => r.buildingId === building.id && r.floorId === undefined);
    return (
      <>
        <div className={"floor-list"}>
          {locations.floors
            .filter((f) => f.buildingId === building.id)
            .map((f) => (
              <AdminViewLocationFloor key={f.id} {...props} floor={f} />
            ))}
        </div>

        {rooms.length > 0 && (
          <div className={"no-floor-list"}>
            <div className={"location-management-no-floor"}>
              <div className={"room-list-no-floor"}>Rooms Without Assigned Floor</div>
              <div className={"building-room-list"}>
                {rooms.map((r) => (
                  <AdminViewLocationRoom key={r.id} {...props} room={r} />
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  function getButtons(): ReactElement {
    if (isAddingFloor || isAddingRoom) {
      return <></>;
    }

    return (
      <>
        <Button
          size={"sm"}
          color={"primary"}
          className={"me-1 mb-1 mb-md-0 location-management-add-floor-button"}
          onClick={() => setIsAddingFloor(true)}
        >
          <FontAwesomeIcon icon={faPlus} /> <FormattedHTMLMessage id={"add.floor.within"} values={{ within: buildingName }} />
        </Button>
        <Button size={"sm"} color={"primary"} className={"me-1 location-management-add-room-button"} onClick={() => setIsAddingRoom(true)}>
          <FontAwesomeIcon icon={faPlus} /> <FormattedHTMLMessage id={"add.room.within"} values={{ within: buildingName }} />
        </Button>
      </>
    );
  }

  function getEditFloor(): ReactElement {
    if (!isAddingFloor) {
      return <></>;
    }

    return (
      <FormGroup>
        <Label for={`add-floor-${building.id}`}>
          <FormattedMessage id={"floor.name"} />
          <RequiredLabelIndicator required={true} />
        </Label>
        <Input
          bsSize="sm"
          type={"text"}
          required
          id={`add-floor-${building.id}`}
          onChange={(e) => setFloorName(e.target.value)}
          invalid={!props.canAddFloor(floorName, building.id)}
          maxLength={255}
          autoFocus
          className={"location-management-floor-name-input"}
        />
        <FormFeedback>
          <FormattedMessage id={"floor.names.must.be.unique"} />
        </FormFeedback>
      </FormGroup>
    );
  }

  function getEditRoom(): ReactElement {
    if (!isAddingRoom) {
      return <></>;
    }

    return (
      <FormGroup>
        <Label for={`add-room-${building.id}`}>
          <FormattedMessage id={"room.name"} />
          <RequiredLabelIndicator required={true} />
        </Label>
        <Input
          bsSize="sm"
          type={"text"}
          required
          id={`add-room-${building.id}`}
          onChange={(e) => setRoomName(e.target.value)}
          invalid={!props.canAddRoom(roomName, building.id, undefined)}
          maxLength={255}
          autoFocus
          className={"location-management-room-name-input"}
        />
        <FormFeedback>
          <FormattedMessage id={"room.names.must.be.unique"} />
        </FormFeedback>
      </FormGroup>
    );
  }

  if (isEditing) {
    return (
      <>
        <div key={building.id} className={"location-management-building"}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSave();
            }}
          >
            <div className={"manage-site-locations-edit-container"}>
              <FormGroup>
                <Label for={`building-${building.id}`}>
                  <FormattedMessage id={"building.name"} />
                  <RequiredLabelIndicator required={true} />
                </Label>
                <Input
                  bsSize="sm"
                  type={"text"}
                  required
                  defaultValue={building.name}
                  id={`building-${building.id}`}
                  onChange={(e) => setBuildingName(e.target.value)}
                  invalid={!props.canRenameBuilding(building.id, buildingName)}
                  maxLength={255}
                  autoFocus
                  className={"location-management-building-name-input"}
                />
                <FormFeedback>
                  <FormattedMessage id={"building.names.must.be.unique"} />
                </FormFeedback>
              </FormGroup>

              <div className={"mb-2 ps-2"}>
                {getEditFloor()}
                {getEditRoom()}
                {getButtons()}
              </div>
              <div className={"manage-site-locations-buttons"}>
                <div>
                  <Button color={"primary"} className={"me-1 location-management-update-building-save"}>
                    <FormattedMessage id={"save"} />
                  </Button>
                  <CancelButton onClick={() => props.onEditToggle(null)} />
                </div>
                <div>
                  <Button
                    color={"link"}
                    title={intl.formatMessage({ id: "delete.building" })}
                    onClick={() => setIsDeleting(true)}
                    className={"location-management-delete-button"}
                  >
                    <FontAwesomeIcon icon={faTrash} size={"lg"} />
                  </Button>
                </div>
              </div>
            </div>
          </Form>

          {getFloorsAndRooms()}
        </div>
        <Modal isOpen={isDeleting} toggle={() => setIsDeleting(false)}>
          <ModalHeader toggle={() => setIsDeleting(false)}>
            <FormattedMessage id={"delete.site.location"} />
          </ModalHeader>
          <ModalBody>
            <AlertWithIcon color={"danger"}>
              <FormattedHTMLMessage id={"delete.building.warning"} values={{ name: building.name }} />
            </AlertWithIcon>
          </ModalBody>
          <ModalFooter>
            <DeleteButton onClick={onDelete} id={"location-management-delete-confirm"} />
            <CancelButton onClick={() => setIsDeleting(false)} />
          </ModalFooter>
        </Modal>
      </>
    );
  }

  return (
    <div key={building.id} className={"location-management-building"}>
      <div data-buildingname={building.name} className={isExpanded ? "building-expanded" : "building-collapsed"}>
        <Button
          color={"link"}
          title={isExpanded ? "Collapse" : "Expand"}
          onClick={() => props.onExpandToggled(building.id)}
          className={"me-1 expand-caret"}
        >
          {!isExpanded && <FontAwesomeIcon icon={faChevronRight} />}
          {isExpanded && <FontAwesomeIcon icon={faChevronDown} />}
        </Button>
        <span className={"location-management-building-name"}>{building.name}</span>
        {currentlyEditedId === null && (
          <Button
            color={"link"}
            onClick={() => props.onEditToggle(building.id)}
            className={"ms-2 location-management-edit-button"}
            title={intl.formatMessage({ id: "edit" })}
          >
            <FontAwesomeIcon icon={faPencilAlt} size={"lg"} />
          </Button>
        )}

        {isExpanded && getFloorsAndRooms()}
      </div>
    </div>
  );
}
