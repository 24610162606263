import { Vendor, VendorAssignments } from "@labarchives/inventory-shared/build/inventory";
import { VendorAssignmentsView, VendorView } from "../orders/types/views";
import { VendorState } from "./types/state";

export const getVendorView = (vendor: Vendor): VendorView => {
  return {
    id: vendor.id,
    name: vendor.name,
    url: vendor.url,
    notes: vendor.notes,
    contactInfo: vendor.contactInfo,
  };
};

export const getVendorViews = (vendorState: VendorState, includeDefault = false): VendorView[] => {
  let initial: VendorView[] = [];
  if (includeDefault) {
    initial = [{ id: 0, name: "", contactInfo: "", notes: "", url: "" }];
  }
  return [...initial, ...vendorState.allIds.map((id) => getVendorView(vendorState.byId[id]))].sort((v1, v2) => v1.name.localeCompare(v2.name));
};

export function getVendorFromView(vendorView: VendorView): Vendor {
  return {
    id: vendorView.id,
    contactInfo: vendorView.contactInfo.trim(),
    url: vendorView.url.trim(),
    notes: vendorView.notes.trim(),
    name: vendorView.name.trim(),
  };
}

export function getVendorAssignmentsView(vendorAssignments: VendorAssignments): VendorAssignmentsView {
  return {
    orderCount: vendorAssignments.orderCount,
    itemCount: vendorAssignments.itemCount,
    canBeDeleted: vendorAssignments.itemCount === 0 && vendorAssignments.orderCount === 0,
  };
}
