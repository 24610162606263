export enum RolePermissions {
  InventoryEditAllItems = 1,
  InventoryAddCreatedItem = 2,
  // InventoryDeleteOwnItem = 3,
  // InventoryDeleteAllItems = 4,
  InventoryViewLocation = 5,
  OrdersViewAll = 6,
  OrdersApproveAll = 7,
  OrdersUpdateAll = 8,
  OrdersCancelAll = 9,
  OrdersRequestItem = 10,
  OrdersCancelOwn = 11,
  OrdersReceiveOwn = 12,
  OrdersReceiveAll = 13,
  SearchViewAllItems = 14,
  LabManagementAllowed = 15,
  InventoryReceiveNotifications = 16,
}
