import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Form, FormFeedback, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/pro-light-svg-icons";
import { AlertWithIcon, Button, Input, Modal } from "@labarchives/ui-design";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { SiteLocationRoom, SiteLocations } from "@labarchives/inventory-shared/build/inventory";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { DeleteButton } from "../../components/DeleteButton/CancelButton";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";

export interface AdminViewLocationRoomProps {
  canAddRoom(name: string, buildingId: number, floorId: number | undefined): boolean;

  canRenameRoom(id: number, name: string): boolean;

  onRoomUpdated(roomId: number, updatedName: string): void;

  onEditToggle(id: number | null): void;

  onLocationDeleted(id: number): void;

  locations: SiteLocations;

  currentlyEditedId: number | null;
}

export function AdminViewLocationRoom(props: AdminViewLocationRoomProps & { room: SiteLocationRoom }): ReactElement {
  const { room, currentlyEditedId } = props;
  const intl = useIntl();
  const isEditing = useMemo<boolean>(() => currentlyEditedId === room.id, [currentlyEditedId]);
  const [roomName, setRoomName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setRoomName(room.name);
  }, [isEditing]);

  function onSave(): void {
    if (roomName.trim() !== "") {
      props.onRoomUpdated(room.id, roomName.trim());
    }
  }

  function onDelete(): void {
    setIsDeleting(false);
    props.onLocationDeleted(room.id);
  }

  if (isEditing) {
    return (
      <>
        <div key={room.id} className={"location-management-room"}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSave();
            }}
          >
            <div className={"manage-site-locations-edit-container"}>
              <FormGroup>
                <Label for={`room-${room.id}`}>
                  <FormattedMessage id={"room.name"} />
                  <RequiredLabelIndicator required={true} />
                </Label>
                <Input
                  bsSize="sm"
                  type={"text"}
                  required
                  defaultValue={room.name}
                  id={`floor-${room.id}`}
                  onChange={(e) => setRoomName(e.target.value)}
                  invalid={!props.canRenameRoom(room.id, roomName)}
                  maxLength={255}
                  autoFocus
                  className={"location-management-room-name-input"}
                />
                <FormFeedback>
                  <FormattedMessage id={"room.names.must.be.unique"} />
                </FormFeedback>
              </FormGroup>

              <div className={"manage-site-locations-buttons"}>
                <div>
                  <Button color={"primary"} className={"me-1 location-management-update-room-save"}>
                    <FormattedMessage id={"save"} />
                  </Button>
                  <CancelButton onClick={() => props.onEditToggle(null)} />
                </div>
                <div>
                  <Button
                    color={"link"}
                    title={intl.formatMessage({ id: "delete.room" })}
                    onClick={() => setIsDeleting(true)}
                    className={"location-management-delete-button"}
                  >
                    <FontAwesomeIcon icon={faTrash} size={"lg"} />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <Modal isOpen={isDeleting} toggle={() => setIsDeleting(false)}>
          <ModalHeader toggle={() => setIsDeleting(false)}>
            <FormattedMessage id={"delete.site.location"} />
          </ModalHeader>
          <ModalBody>
            <AlertWithIcon color={"danger"}>
              <FormattedHTMLMessage id={"delete.room.warning"} values={{ name: room.name }} />
            </AlertWithIcon>
          </ModalBody>
          <ModalFooter>
            <DeleteButton onClick={onDelete} id={"location-management-delete-confirm"} />
            <CancelButton onClick={() => setIsDeleting(false)} />
          </ModalFooter>
        </Modal>
      </>
    );
  }

  return (
    <div key={room.id} className={"location-management-room"}>
      <div data-roomname={room.name}>
        <span className={"location-management-room-name"}>{room.name}</span>
        {currentlyEditedId === null && (
          <Button
            color={"link"}
            onClick={() => props.onEditToggle(room.id)}
            className={"ms-2 location-management-edit-button"}
            title={intl.formatMessage({ id: "edit" })}
          >
            <FontAwesomeIcon icon={faPencilAlt} size={"lg"} />
          </Button>
        )}
      </div>
    </div>
  );
}
