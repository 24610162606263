import { InventorySearchResultSetItem, StorageLocation } from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useMemo, useState } from "react";

export interface InventoryFreezerBoxViewProps {
  location: StorageLocation;
  items: InventorySearchResultSetItem[];
  canAddItems: boolean;
  currency: string;

  onQuantityUsed(id: number, quantityUsed: number, unitUsed: string, storageCells: string[]): Promise<void>;
  onItemMoved(id: number, sourceCell: string, destinationCell: string): Promise<void>;
  onItemCopied(id: number, destinationCell: string): Promise<void>;
}

export interface InventoryFreezerBoxViewHooks {
  activeItem: InventorySearchResultSetItem | undefined;
  activeCell: string | undefined;
  selectedUseNowCells: string[];
  allOccupiedCells: string[];
  isUseNowOpen: boolean;
  isMoveActive: boolean;
  isCopyActive: boolean;

  onQuantityUsed(quantityUsed: number, unitUsed: string): Promise<void>;

  onCellActivated(cell: string): void;

  onSelectedUseNowCellsUpdated(cells: string[]): void;

  onUseNowOpened(isOpen: boolean): void;

  onMoveItemActivated(): void;

  onMoveItemCancelled(): void;

  onCopyItemActivated(): void;

  onCopyItemCancelled(): void;

  onDetailsClosed(): void;

  getCellContents(cell: string): InventorySearchResultSetItem[];

  onItemDragStart(item: InventorySearchResultSetItem, sourceCell: string): void;

  onItemDragDrop(destinationCell: string): Promise<void>;

  onMoveItemConfirmed(destinationCell: string): Promise<void>;

  onCopyItemConfirmed(destinationCell: string): Promise<void>;
}

export function useInventoryFreezerBox(props: InventoryFreezerBoxViewProps): InventoryFreezerBoxViewHooks {
  const [activeCell, setActiveCell] = useState<string | undefined>();
  const [selectedUseNowCells, setSelectedUseNowCells] = useState<string[]>([]);
  const [isUseNowOpen, setIsUseNowOpen] = useState(false);
  const [movingItem, setMovingItem] = useState<InventorySearchResultSetItem | undefined>();
  const [movingSourceCell, setMovingSourceCell] = useState<string | undefined>();
  const [isMoveActive, setIsMoveActive] = useState(false);
  const [isCopyActive, setIsCopyActive] = useState(false);

  const { items } = props;

  const activeItem: InventorySearchResultSetItem | undefined = useMemo(() => {
    if (!activeCell) {
      return undefined;
    }

    const cellItems = items.filter((i) => i.storageCells.includes(activeCell));
    if (cellItems.length === 1) {
      return cellItems[0];
    }
    return undefined;
  }, [activeCell, items]);

  const allOccupiedCells = useMemo(() => {
    let all: string[] = [];
    items.forEach((i) => {
      all = [...all, ...i.storageCells];
    });
    return all;
  }, [items]);

  async function onQuantityUsed(quantityUsed: number, unitUsed: string): Promise<void> {
    if (activeItem) {
      await props.onQuantityUsed(activeItem.id, quantityUsed, unitUsed, selectedUseNowCells);
      setIsUseNowOpen(false);
    }
  }

  function onCellActivated(cell: string): void {
    setActiveCell(cell);
  }

  function onSelectedUseNowCellsUpdated(cells: string[]): void {
    setSelectedUseNowCells(cells);
  }

  function onDetailsClosed(): void {
    setActiveCell(undefined);
    setSelectedUseNowCells([]);
    setIsUseNowOpen(false);
    setIsMoveActive(false);
    setIsCopyActive(false);
  }

  function getCellContents(cell: string): InventorySearchResultSetItem[] {
    return items.filter((i) => i.storageCells.includes(cell));
  }

  function onItemDragStart(item: InventorySearchResultSetItem, sourceCell: string): void {
    setMovingItem(item);
    setMovingSourceCell(sourceCell);
  }

  async function onItemDragDrop(destinationCell: string): Promise<void> {
    if (movingItem && movingSourceCell) {
      await props.onItemMoved(movingItem.id, movingSourceCell, destinationCell);
    }
    setMovingItem(undefined);
    setMovingSourceCell(undefined);
  }

  function onMoveItemActivated(): void {
    if (activeItem && activeCell) {
      setIsMoveActive(true);
    }
  }

  function onMoveItemCancelled(): void {
    setIsMoveActive(false);
  }

  async function onMoveItemConfirmed(destinationCell: string): Promise<void> {
    if (isMoveActive && activeItem && activeCell) {
      await props.onItemMoved(activeItem.id, activeCell, destinationCell);
      setActiveCell(destinationCell);
    }
    setIsMoveActive(false);
  }

  function onCopyItemActivated(): void {
    if (activeItem && activeCell) {
      setIsCopyActive(true);
    }
  }

  function onCopyItemCancelled(): void {
    setIsCopyActive(false);
  }

  async function onCopyItemConfirmed(destinationCell: string): Promise<void> {
    if (isCopyActive && activeItem && activeCell) {
      await props.onItemCopied(activeItem.id, destinationCell);
    }
    setIsCopyActive(false);
  }

  useEffect(() => {
    if (activeItem) {
      setSelectedUseNowCells(activeItem.storageCells);
    } else {
      setSelectedUseNowCells([]);
    }
  }, [isUseNowOpen]);

  return {
    activeItem,
    activeCell,
    selectedUseNowCells,
    allOccupiedCells,
    isUseNowOpen,
    isMoveActive,
    isCopyActive,
    onUseNowOpened: setIsUseNowOpen,
    onQuantityUsed,
    onCellActivated,
    onDetailsClosed,
    onSelectedUseNowCellsUpdated,
    getCellContents,
    onItemDragStart,
    onItemDragDrop,
    onMoveItemActivated,
    onMoveItemCancelled,
    onMoveItemConfirmed,
    onCopyItemActivated,
    onCopyItemCancelled,
    onCopyItemConfirmed,
  };
}
