import React, { ReactElement } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/pro-light-svg-icons";
import { FormattedFreezerBoxCells } from "./FormattedFreezerBoxCells";
import { getFreezerBoxCellLabelFromLocation } from "./selectors";
import { EditableStorageCellsRowProps, useEditableStorageCellsRow } from "./EditableStorageCellsRowHooks";

export function EditableStorageCellsRow(props: EditableStorageCellsRowProps): ReactElement {
  const { rowAllChecked, onRowCheckboxClick } = useEditableStorageCellsRow(props);

  return (
    <div className="storage-row" key={`row${props.rowNumber}`}>
      <div
        className="storage-cell"
        role="button"
        aria-pressed={rowAllChecked}
        tabIndex={0}
        onClick={() => onRowCheckboxClick(!rowAllChecked)}
        onKeyPress={() => onRowCheckboxClick(!rowAllChecked)}
        title={"Select Row"}
      >
        {rowAllChecked && <FontAwesomeIcon icon={faCheckSquare} size="lg" />}
        {!rowAllChecked && <FontAwesomeIcon icon={faSquare} size="lg" />}
      </div>
      {props.columns.map((c) => {
        const label = getFreezerBoxCellLabelFromLocation(props.rowNumber, c);
        return (
          <div
            role="button"
            aria-pressed={props.isCellSelected(label)}
            tabIndex={0}
            onClick={() => props.onCellClick(label)}
            onKeyPress={() => props.onCellClick(label)}
            className={classnames("storage-cell", { occupied: props.isCellOccupied(label), selected: props.isCellSelected(label) })}
            key={`cell${props.rowNumber}${c}`}
            title={label}
          >
            <FormattedFreezerBoxCells cells={[label]} format={props.displayFormat} rows={props.matrixRows} cols={props.matrixCols} />
          </div>
        );
      })}
    </div>
  );
}
