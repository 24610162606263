import {
  InventorySearchResultSet,
  InventorySearchResultSetItem,
  ResultSetOptions,
  SortDirection,
} from "@labarchives/inventory-shared/build/inventory";
import { InventorySortableColumn } from "@labarchives/inventory-shared/build/inventory/InventorySortableColumn";
import { StorageLocationView } from "../../../storage/types/views";
import { InventoryApi } from "../../../api/InventoryApi";

export interface InventorySearchResultsHooks {
  onPageChanged(page: number): void;
  onSortChanged(field: string): void;
}

export interface InventorySearchResultsHooksProps {
  onResultsOptionsChanged(resultOptions: ResultSetOptions): void;
  searchResults: InventorySearchResultSet;
}

export function useInventorySearchResults(props: InventorySearchResultsHooksProps): InventorySearchResultsHooks {
  function onPageChanged(page: number): void {
    const { searchResults } = props;
    props.onResultsOptionsChanged({
      pageNumber: page,
      pageSize: searchResults.pageSize,
      sortDirection: searchResults.sortDirection,
      sortField: searchResults.sortField,
    });
  }

  function onSortChanged(field: string): void {
    const { searchResults } = props;
    props.onResultsOptionsChanged({
      pageNumber: searchResults.currentPageNumber,
      pageSize: searchResults.pageSize,
      sortDirection: getSortDirection(field, searchResults),
      sortField: field,
    });
  }

  function getSortDirection(field: string, searchResults: InventorySearchResultSet): SortDirection {
    if (field !== searchResults.sortField) {
      return SortDirection.DEFAULT;
    }

    return searchResults.sortDirection === SortDirection.DESCENDING ? SortDirection.ASCENDING : SortDirection.DESCENDING;
  }

  return {
    onPageChanged,
    onSortChanged,
  };
}

export interface InventorySearchResultsProps {
  isLoading: boolean;
  searchResults: InventorySearchResultSet;
  storageLocations: StorageLocationView;
  currency: string;
  columnOrder: InventorySortableColumn[];
  isReorderDisabled?: boolean;
  isFreezerBoxDisabled?: boolean;
  onUseNow: (item: InventorySearchResultSetItem) => void;
  isUsed: (item: InventorySearchResultSetItem) => boolean;
  itemLink: (itemId: string | number) => string;
  api: InventoryApi;

  onResultsOptionsChanged(options: ResultSetOptions): void;
  onInventoryStorageChanged?(
    item: InventorySearchResultSetItem,
    locationId?: number | null,
    storageCells?: string[] | null,
    notes?: string | null,
  ): void;
}
